import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import "react-select/dist/react-select.css";
// API
import * as API from "../../../../utils/api";
import { showToast } from "../../../../utils/helpers";
import AppCanvasLoader from "../../../App/AppCanvasLoader";
import Modal from "../Modal";
import PreviewTemplateModal from "./PreviewTemplateModal";

class PreviewTaskConfigModal extends Component {
  state = {
    mergeVaribales: [],
    mergeVaribalesValues: {},
    checkArray: [],
    dropdownIsActive: false,
    mergeVaribalesValuesError: false,
    isLoading: true,
    isPreview: false,
    templateName: "",
    isEmpty: false,
    message: {
      value: "",
      hasError: false,
    },
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    if (
      this.props &&
      this.props.optionalProps &&
      this.props.optionalProps.selectedTemp
    ) {
      let data = this.props.optionalProps.selectedTemp;
      if (data.templates && data.templates.length) {
        if (data.templates[0].slug) {
          this.state.message.value = data.templates[0].slug;
          this.setState({
            templateName: data.templates[0].name,
            message: this.state.message,
          });
          this.getMergeVariables(data.templates[0].slug);
        } else {
          this.setState({ isEmpty: true });
        }
      } else {
        this.setState({ isEmpty: true });
      }
    }
  }

  getMergeVariables(name) {
    API.getMergeVariables(this.props.user, name).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.error) {
          showToast(data.error);
        } else {
          if (data && data.data) {
            this.setState({ mergeVaribales: data.data });
          }
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  renderErrorMessage(input) {
    let message = "";

    if (input === "name") {
      message = "Please enter name";
    } else if (input === "email") {
      message = "Please enter a valid email";
    } else if (input === "cc_email") {
      message = "Please enter a valid email";
    } else if (input === "message") {
      message = "Please select template";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  handleModalClose = () => {
    this.props.hideModal();
  };

  dismissFlash = () => {
    this.handleModalClose();
    this.setState((state) => ({
      ...state,
      showFlash: false,
      flashMessage: "",
    }));
  };

  handleMergeVariableValue(value, item) {
    let data = {};
    data[item] = value;
    let index = -1;
    index = this.state.checkArray.findIndex((o) => Object.keys(o)[0] === item);

    if (index === -1) {
      this.state.checkArray.push(data);
    }
    if (value === "" || value === null || value === undefined) {
      this.state.checkArray.splice(index, 1);
    }

    this.state.mergeVaribalesValues[item] = value;

    if (!this.state.mergeVaribalesValues[item]) {
      this.setState({ mergeVaribalesValuesError: true });
    } else {
      this.setState({
        mergeVaribalesValuesError: false,
      });
    }

    this.setState({
      mergeVaribalesValues: this.state.mergeVaribalesValues,
    });
  }

  renderMergeVaribles(item, index) {
    let { mergeVaribalesValues, mergeVaribalesValuesError } = this.state;
    return (
      <div className="admin-form-row" key={item}>
        <div className={`input-container half`}>
          <label htmlFor="name">{item}</label>
          <input
            name={item}
            placeholder={"Enter " + item}
            type="text"
            value={mergeVaribalesValues[item]}
            onChange={(event) =>
              this.handleMergeVariableValue(event.target.value, item)
            }
          />
        </div>
      </div>
    );
  }

  toggleDropdown = (event) => {
    event.preventDefault();

    this.setState({
      dropdownIsActive: !this.state.dropdownIsActive,
    });
  };

  handleClickOutside = (event) => {
    if (this.state.dropdownIsActive) {
      this.toggleDropdown(event);
    }
  };

  handlePreviewClick = () => {
    let { checkArray, mergeVaribales, mergeVaribalesValues } = this.state;
    let check = checkArray.length === mergeVaribales.length;

    if (!mergeVaribalesValues || !check) {
      this.setState({ mergeVaribalesValuesError: true });
    } else {
      this.setState({ isPreview: true });
    }
  };

  hidePreviewModal = () => {
    this.setState({ isPreview: false });
  };

  render() {
    let {
      isEmpty,
      templateName,
      message,
      error,
      isLoading,
      isPreview,
      mergeVaribalesValues,
    } = this.state;
    let styels = { marginRight: `10px`, maxWidth: `30rem`, marginBottom: 0 };

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (isPreview) {
      return (
        <PreviewTemplateModal
          user={this.props.user}
          hideModal={this.hidePreviewModal}
          template={message.value}
          merge_variables={mergeVaribalesValues}
        />
      );
    }

    return (
      <Modal
        optionalClasses="add-account-modal send-new-email-modal scroll"
        title="Template Preview"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
      >
        {isEmpty ? (
          <div className="admin-admins-empty-state">
            <p
              style={{ fontSize: 16, fontWeight: 600 }}
              className="admin-admins-empty-state-heading empty-mesg-mg"
            >
              This task don't have any assigned template.
            </p>
          </div>
        ) : isLoading ? (
          <AppCanvasLoader />
        ) : (
          <form
            className="admin-form"
            onClick={(event) => {
              this.handleClickOutside(event);
            }}
          >
            <div className="app-sidebar-layout-canvas">
              <div className="app-sidebar app-sidebar-left" style={styels}>
                <label>Template</label>
                <div style={{ padding: "5px 0px 1em" }}>{templateName}</div>
                <div>
                  {this.state.mergeVaribales &&
                  this.state.mergeVaribales.length ? (
                    <div>
                      <label>Enter merge variables to preview template</label>
                      {this.state.mergeVaribalesValuesError ? (
                        <div
                          className={`input-container half error`}
                          style={{ marginBottom: 10 }}
                        >
                          {this.renderErrorMessage("mergeVaribalesValuesError")}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {this.state.mergeVaribales &&
                  this.state.mergeVaribales.length ? (
                    this.state.mergeVaribales.map((item, index) => {
                      return this.renderMergeVaribles(item, index);
                    })
                  ) : (
                    <div></div>
                  )}
                </div>
                <div id="send-new-mail" className="modal-actions">
                  <a
                    className={`cta mg-brand2-color`}
                    onClick={(event) => this.handlePreviewClick()}
                  >
                    Preview
                  </a>
                </div>
              </div>
            </div>
          </form>
        )}
      </Modal>
    );
  }
}

export default withRouter(PreviewTaskConfigModal);
