// Packages
import moment from "moment";
import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import companyData from "../../assets/styles/theme.json";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import CardView from "../../components/CardView/CardView";
import ClientsLogo from "../../components/Consumer/ClientsLogo";
import ConsumerSidebar from "../../components/Consumer/ConsumerSidebar";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import ACHPlaidLink from "../../components/Consumer/ACHPlaidLink";
import Tabs from "../../components/Tabs";
// API
import * as API from "../../utils/api";
// Utils
import { getZohoPagesense } from "../../utils/helpers";

class ConsumerAccounts extends Component {
  state = {
    activeTab: 1,
    accountType: "current",
    consumerData: [],
    invoiceData: [],
    consumerInvoice: [],
    hasMoreData: false,
    isLoading: true,
    showLoader: false,
    leftLoader: false,
    offset: 0,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    input: {
      search: {
        value: "",
      },
    }
  };

  componentDidMount() {
    getZohoPagesense();
    ReactGA.pageview("/ConsumerAccounts");
    this.getInvoiceData();
  }

  getInvoiceData = () => {
    let { offset, activeTab } = this.state;
    let is_open = activeTab === 1 ? true : false;
    API.fetchAllConsumerInvoices(this.props.user, offset, is_open).then(
      (data) => {
        if (
          (typeof data != "undefined" || data != null) &&
          data.data != null &&
          data.status_code == 200
        ) {
          this.setState((state) => ({
            ...state,
            consumerInvoice: data.data,
            invoiceData: data.data.invoices,
            hasMoreData: data.data.invoices.length < 15 ? false : true,
            isLoading: false,
            leftLoader: false,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            leftLoader: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    );
  };

  consumerInvoicesPagination = (reload) => {
    let { offset, activeTab } = this.state;
    let is_open = activeTab === 1 ? true : false;
    API.consumerInvoicesPagination(this.props.user, offset, is_open).then(
      (data) => {
        if (
          (typeof data != "undefined" || data != null) &&
          data.data != null &&
          data.status_code == 200
        ) {
          let invoice = reload ? this.state.invoiceData : false;
          let newData = invoice ? invoice.concat(data.data) : data.data;
          this.setState((state) => ({
            ...state,
            invoiceData: newData,
            hasMoreData: data.data.length < 15 ? false : true,
            showLoader: false,
            leftLoader: false,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            showLoader: false,
            leftLoader: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    );
  };

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
          showLoader: true,
        }),
        () => {
          this.consumerInvoicesPagination(true);
        }
      );
    }
  };

  handleRowActionClick = (event) => {
    const invoiceId = event.rowData.id;
    let { history } = this.props;
    if (invoiceId) {
      history.push(`/dashboard/accounts/${invoiceId}`);
    }
  };

  handleInputChange(newPartialInput) {
    const re = /[0-9]+/g;
    if ((newPartialInput.search.value === '' || re.test(newPartialInput.search.value)) && newPartialInput.search.value !== this.state.input.search.value ) {
      this.setState(
        (state) => ({
          ...state,
          input: {
            ...newPartialInput,
          },
        }),
        () => {
          if (this.state.input.search.value === '') {
            this.getInvoiceData();
          } else {
            this.handleKeyPress({ key: "Enter" });
          }
        }
      );
    }
  }

  handleKeyPress(event) {
    if (event.key === "Enter" && this.state.input.search.value !== '') {
      this.setState(
        (state) => ({
          ...state,
          offset: 0,
        }),
        () => {
          this.handleSearch(); //TO_DO
        }
      );
    }
  }

  //search api call
  handleSearch = (reload, isSort) => {
    let { activeTab } = this.state;
    let is_open = activeTab === 1 ? true : false;
    isSort
      ? ""
      : reload
      ? this.setState({ showLoader: true })
      : this.setState({ leftLoader: true });
    // let sort_key = this.getSortKey();
    // let order = this.state.sortType ? "asc" : "desc";
    let queryString = this.state.input.search.value;
    // let filterBy = this.state.consumer_type;
    let user_id = this.props.user.id ? this.props.user.id : this.props.user.user_id ? this.props.user.user_id : ''
    API.consumerSearch(
      this.props.user,
      queryString,
        user_id,
      is_open
    ).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.data != null &&
        data.status_code == 200
      ) {
        this.setState((state) => ({
          ...state,
          consumerInvoice: data.data,
          invoiceData: data.data.invoices,
          hasMoreData: data.data.invoices.length < 15 ? false : true,
          isLoading: false,
          leftLoader: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          leftLoader: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  getPaymentStatus = (invoice) => {
    if (invoice === "Paid") {
      return (
        <div style={{ color: "#409D57", fontFamily: "sofia_pro_bold" }}>
          Paid
        </div>
      );
    }
    let is_past_due = this.checkInvoicePastDue(invoice.due_date);
    let statusStyle = is_past_due
      ? { color: "#fb5c82", fontFamily: "sofia_pro_bold" }
      : { color: "#409D57", fontFamily: "sofia_pro_bold" };
    if (invoice.balance == invoice.total_amt) {
      return <div style={statusStyle}>Unpaid</div>;
    } else {
      return <div style={statusStyle}>Partially Paid</div>;
    }
  };

  checkInvoicePastDue = (date) => {
    return moment(date).isBefore(new Date());
  };

  getPaidAmount = (invoice) => {
    let amount = 0;
    if (invoice) {
      amount = invoice.total_amt
        ? invoice.balance
          ? parseFloat(invoice.total_amt) - parseFloat(invoice.balance)
          : invoice.total_amt
        : invoice.total_amt;
    }
    return amount.toFixed(2);
  };

  getInvoiceListData = (data, activeTab) => {
    let headings = [],
      columns = [];
    headings = [
      "Inv. #",
      "Inv. Date",
      "Due Date",
      "Inv. Total",
      "Amount Paid",
      "Discount",
      "Balance",
      "Status",
      "expander",
    ];

    columns = data.map((row) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Inv. #",
            title: row.invoice_number ? row.invoice_number : "",
          },
          {
            key: "Inv. Date",
            title: row.txn_date
              ? moment(row.txn_date).format("MM/DD/YYYY")
              : "n/a",
          },
          {
            key: "Due Date",
            title: row.due_date
              ? moment(row.due_date).format("MM/DD/YYYY")
              : "n/a",
          },
          {
            key: "Inv. Total",
            title: row.total_amt
              ? "$" + parseFloat(row.total_amt).toFixed(2)
              : "$0.00",
          },
          {
            key: "Amount Paid",
            title: `$${this.getPaidAmount(row)}`,
          },
          {
            key: "Discount",
            title: row.adjustment
              ? "$" + parseFloat(row.adjustment).toFixed(2)
              : "$0.00",
          },
          {
            key: "Balance",
            title: row.balance
              ? "$" + parseFloat(row.balance).toFixed(2)
              : "$0.00",
          },
          {
            key: "Status",
            title:
              activeTab === 2
                ? this.getPaymentStatus("Paid")
                : this.getPaymentStatus(row),
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
          },
        ],
        status: this.getAccountStatus(row),
        rowData: row,
      };
    });

    return {
      headings,
      columns,
    };
  };

  renderInvoiceTable = () => {
    const { activeTab, invoiceData, leftLoader } = this.state;
    if (leftLoader) {
      return (
        <div className="mobile-loader">
          <AppCanvasLoader />
        </div>
      );
    }
    if (invoiceData && invoiceData.length > 0) {
      return (
        <BottomScrollListener
          onBottom={this.handleContainerOnBottom}
          debounce={0}
        >
          {(scrollRef) => (
            <div
              ref={scrollRef}
              className="consumer-scroll-accounts-list scrollbar"
            >
              <div className="big-device-grad invoice-line">
                <CardView
                  data={this.getInvoiceListData(invoiceData, activeTab)}
                  isStatus={activeTab === 1 ? true : false}
                  rowActionClick={(event) => this.handleRowActionClick(event)}
                />
              </div>
              <InvoiceObjectList
                data={this.getInvoiceListData(invoiceData, activeTab)}
                rowActionClick={(event) => this.handleRowActionClick(event)}
                isStatus={activeTab === 1 ? true : false}
                hideForMobile={true}
                fromConsumer={true}
              />
              {this.state.showLoader && (
                <div>
                  <AppCanvasLoader />
                </div>
              )}
            </div>
          )}
        </BottomScrollListener>
      );
    } else if (this.state.input.search.value.length) {
      return (
        <div className="admin-admins-empty-state">
          <p>No results found for:</p>
          <h3 className="admin-admins-empty-state-heading empty-mesg-mg">
            {this.state.input.search.value}
          </h3>
        </div>
      )
    } else {
      return (
        <div
          className="consumer-accounts-empty-state"
          style={{ height: "auto", padding: "6rem 2rem" }}
        >
          <h3 className="consumer-accounts-empty-state-heading consumer-brand">
            No {activeTab === 1 ? "Open" : "Closed"} Invoices
          </h3>
          <p>
            You currently have no {activeTab === 1 ? "open" : "closed"}{" "}
            invoices.
          </p>
        </div>
      );
    }
  };

  getAccountStatus = (rowData) => {
    let flag = this.checkInvoicePastDue(rowData.due_date);
    if (flag) {
      if (this.state.accountType != "past due") {
        this.setState({ accountType: "past due" });
      }
    }
    // let adjustment = rowData.adjustment ? rowData.adjustment : 0;
    // if (
    //   (rowData.balance > 0 && adjustment == 0) ||
    //   (adjustment > 0 && rowData.balance != adjustment)
    // ) {
    //   flag = true;
    // } else if (
    //   (rowData.balance == 0 && adjustment == 0) ||
    //   (adjustment > 0 && adjustment == rowData.balance)
    // ) {
    //   flag = false;
    //   this.setState({ accountType: "past due" });
    // }
    return !flag;
  };

  sidebarContent = () => {
    const { consumerInvoice, accountType } = this.state;

    return (
      <div className="consumer-sidebar">
        <div
          className="sidebar-primary-text consumer-account-type"
          style={{ marginBottom: "2em", display: "flex", color: "#000000" }}
        >
          Your account is {accountType}
        </div>
        <div className="consumer-sidebar-panel-content">
          <div className="consumer-amount-details">Account Balance</div>
          <div className="consumer-amount-details">
            $
            {consumerInvoice && consumerInvoice.account_balance
              ? parseFloat(consumerInvoice.account_balance).toFixed(2)
              : "0.00"}
          </div>
        </div>
        <div className="consumer-sidebar-panel-content">
          <div className="consumer-amount-details">Account Past Due</div>
          <div className="consumer-amount-details">
            $
            {consumerInvoice && consumerInvoice.account_past_due
              ? parseFloat(consumerInvoice.account_past_due).toFixed(2)
              : "0.00"}
          </div>
        </div>
        {/* <div className="consumer-sidebar-panel-content">
          <div className="consumer-amount-details">Last Payment Date</div>
          <div className="consumer-amount-details">
            {consumerInvoice && consumerInvoice.last_payment_date
              ? moment(consumerInvoice.last_payment_date).format("MM/DD/YYYY")
              : "n/a"}
          </div>
        </div> */}
        {/* <div className="consumer-sidebar-panel-content">
          <div className="consumer-amount-details">Last Payment Amount</div>
          <div className="consumer-amount-details">
            $
            {consumerInvoice && consumerInvoice.last_payment_amount
              ? parseFloat(consumerInvoice.last_payment_amount).toFixed(2)
              : "0.00"}
          </div>
        </div> */}
        {/* <div className="consumer-sidebar-panel-content">
          <div className="consumer-amount-details">Next Payment Date</div>
          <div className="consumer-amount-details">
            {consumerInvoice && consumerInvoice.next_payment_date
              ? moment(consumerInvoice.next_payment_date).format("MM/DD/YYYY")
              : "n/a"}
          </div>
        </div> */}
        {/* <div className="consumer-sidebar-panel-content">
          <div className="consumer-amount-details">Next Payment Amount</div>
          <div className="consumer-amount-details">
            $
            {consumerInvoice && consumerInvoice.next_payment_amount
              ? parseFloat(consumerInvoice.next_payment_amount).toFixed(2)
              : "0.00"}
          </div>
        </div> */}
        <div className="consumer-sidebar-panel-content">
          <div className="consumer-amount-details">No. of Open Invoices</div>
          <div className="consumer-amount-details">
            {consumerInvoice && consumerInvoice.open_invoices
              ? consumerInvoice.open_invoices
              : 0}
          </div>
        </div>
      </div>
    );
  };

  getSubTitle = (userData) => {
    let fullname = "";
    if (userData.first_name) {
      fullname = userData.first_name;
    }
    if (userData.last_name && fullname) {
      fullname = fullname + " " + userData.last_name;
    } else {
      fullname = userData.last_name;
    }
    return "Welcome, " + fullname + "!";
  };

  handleTabChange = (tabIndex) => {
    let input = {
      search: {
        value: ""
      }
    }
    this.setState(
      {
        activeTab: tabIndex,
        offset: 0,
        leftLoader: true,
        input: input
      },
      () => {
        this.getInvoiceData();
      }
    );
  };

  getSectionHeaderUtilities() {
    const { activeTab, input } = this.state;
    const tabLabels = ["Open", "Closed"];

    return (
      <div className="consumer-section-header-wrapper">
        <Tabs
          labels={tabLabels}
          activeTarget={activeTab}
          handleTabChange={this.handleTabChange}
          optionalClasses="consumer-pay-tab tp-sp con-fit"
          optionalInTabClasses="mg-consumer-tabs-tab"
        />
        <div className="consumer-search-input-container client-search-c">
          <input
            type="text"
            name="search"
            placeholder="Search By Invoice No."
            value={input.search.value}
            className="consumer-search-input"
            onKeyDown={this.handleKeyPress}
            onChange={(event) =>
              this.handleInputChange({ search: { value: event.target.value } })
            }
          />
          <span
            className="search-icon"
            onClick={() => this.handleKeyPress({ key: "Enter" })}
          />
        </div>
      </div>
    );
  }

  render() {
    const { appName } = this.props;
    const { isLoading, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (isLoading) {
      return <AppCanvasLoader />;
    } else {
      return (
        <div className="scrollbar consumer-scroll-accounts">
          <Helmet>
            <title>{appName} | Invoices</title>
          </Helmet>
          <div className="app-sidebar-layout-canvas">
            <ConsumerSidebar
              user={this.props.user}
              subTitle={this.getSubTitle(this.props.user)}
              sidebarContent={this.sidebarContent()}
            />
            <div className="app-sidebar-layout-canvas-content consumer-right-canvas big-device-grad">
              <ClientsLogo />
              <h1 className="app-section-consumer-heading consumer-brand">
                Invoices
              </h1>
              <div className="section-header1">
                {this.getSectionHeaderUtilities()}
              </div>
              {this.renderInvoiceTable()}
              <br />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ConsumerAccounts;
