import $ from "jquery";
import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import AppCanvasLoader from "../../../../components/App/AppCanvasLoader";
// API
import * as API from "../../../../utils/api";
import { showToast } from "../../../../utils/helpers";
import ToggleSwitch from "../../../ToggleSwitch";
import Modal from "../Modal";

class AdminAssignTask extends Component {
  state = {
    taskData: [],
    isLoading: true,
    templates: [],
    accountId: "",
    templateError: false,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    let { optionalProps } = this.props;
    if (optionalProps && optionalProps.taskData) {
      this.setState(
        {
          taskData: optionalProps.taskData,
          accountId: optionalProps.accountId,
        },
        () => {
          this.getData();
        }
      );
    }
  }

  getData = () => {
    let { taskData, accountId } = this.state;
    API.getOrganizationTaskProfileTemp(this.props.user, accountId).then(
      (data) => {
        if (data && data.status_code && data.status_code == 200) {
          if (data.data) {
            this.setState({ templates: data.data }, () => {
              this.mapDataForOptions();
            });
          }
          this.setState({ isLoading: false });
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    );
  };

  mapDataForOptions = () => {
    let { templates } = this.state;
    let { optionalProps } = this.props;
    if (templates && templates.length) {
      templates.map((item) => {
        let n = optionalProps.taskData.templates.findIndex(
          (o) => o.method_name == item.method_name
        );
        item.selectedValue = "";
        if (n > -1) {
          item.selectedValue = optionalProps.taskData.templates[n].slug;
          item.profile_template_id =
            optionalProps.taskData.templates[n].profile_template_id;
        }
        if (item.templates && item.templates.length) {
          item.templates.map((i) => {
            i.value = i.slug;
            i.label = i.name;
          });
        }
      });
      this.setState({ templates });
    }
  };

  validateForm = () => {
    let { taskData } = this.state;
    if (this.getProfileData() && this.getProfileData().length) {
      return true;
    } else {
      if (!taskData.status) {
        return true;
      }
      this.setState({ templateError: true });
      return false;
    }
  };

  getProfileData = () => {
    let { templates } = this.state;
    let data = [];
    if (templates && templates.length) {
      templates.map((item) => {
        if (item.profile_template_id) {
          data.push({
            profile_template_id: item.profile_template_id,
            method_name: item.method_name,
          });
        }
      });
      return data;
    } else {
      return data;
    }
  };

  handleSubmit = (event) => {
    let { accountId, taskData } = this.state;
    event.preventDefault();
    if (this.validateForm()) {
      $("#edit-task").replaceWith('<div class="spinner"></div>');
      let payload = {
        task_configuration_id: taskData.task_id,
        organization_id: accountId,
        status: taskData.status,
        profile_templates: this.getProfileData(),
      };
      API.adminAssignTaskTemplates(this.props.user, payload).then((data) => {
        if (
          (typeof data != "undefined" || data != null) &&
          data.status_code &&
          data.status_code === 200
        ) {
          this.props.optionalProps.reload();
          this.handleModalClose();
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      });
    }
  };

  toggleButton = (event) => {
    event.preventDefault();
    let { taskData } = this.state;
    taskData.status = !taskData.status;
    this.setState({ taskData });
  };

  handleInputChange(newPartialInput, selectedData) {
    let { templates } = this.state;
    if (templates && templates.length) {
      templates.map((item) => {
        if (item.method_id == selectedData.method_id) {
          item.selectedValue = newPartialInput.value;
          item.profile_template_id = newPartialInput.profile_template_id;
        }
      });
      this.setState({ templates, templateError: false });
    }
  }

  renderErrorMessage(input) {
    let message = "";

    if (input === "reminder_schedule") {
      message = "Please enter a valid days";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  handleModalClose = () => {
    this.props.hideModal();
  };

  render() {
    let { error, taskData, isLoading, templates } = this.state;
    const style = {
      container: { display: "flex" },
      content: { width: "70%", paddingTop: `10px` },
    };

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <Modal
        title="Assign Templates to Task"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        style={{ maxWidth: `35em` }}
        optionalClasses="scroll"
        titleClasses="modal-header"
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <form>
            <div style={style.container}>
              <div style={{ width: `70%`, marginTop: "1rem" }}>Name : </div>
              <div
                className={`input-container`}
                style={{ marginBottom: "1rem", marginTop: "1rem" }}
              >
                {taskData.name ? taskData.name : ""}
              </div>
            </div>
            <div style={style.container}>
              <div style={style.content}>Status : </div>
              <div
                className={`input-container`}
                style={{ marginTop: "10px", marginBottom: "1rem" }}
              >
                <ToggleSwitch
                  isOn={taskData.status}
                  toggleButton={(event) => this.toggleButton(event)}
                />
              </div>
            </div>
            <div style={{ ...style.content, fontWeight: 600 }}>
              Assign Templates
            </div>
            {templates && templates.length
              ? templates.map((item, index) => {
                  return (
                    <div
                      style={{
                        ...style.container,
                        marginBottom:
                          index < templates.length - 1 ? "-1em" : "auto",
                      }}
                      key={item.method_id}
                    >
                      <div style={style.content}>{item.method_name} : </div>
                      <div className={`input-container`}>
                        <Select
                          inputProps={{
                            autoComplete: "none",
                            autoCorrect: "off",
                            spellCheck: "off",
                          }}
                          resetValue=""
                          className="form-select"
                          name={item.method_name}
                          value={item.selectedValue}
                          placeholder="Select email template"
                          options={item.templates}
                          onChange={(event) =>
                            this.handleInputChange(event ? event : "", item)
                          }
                        />
                      </div>
                    </div>
                  );
                })
              : null}
            {this.state.templateError && (
              <div style={style.container}>
                <div style={style.content}></div>
                <div
                  className={`input-container error`}
                  style={{ marginTop: "-1em" }}
                >
                  <div className="input-error-message">
                    Please select a template
                  </div>
                </div>
              </div>
            )}
            <div className="modal-actions" id="edit-task">
              <a
                className="cta cta mg-brand2-color"
                style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                onClick={(event) => this.handleSubmit(event)}
              >
                Submit
              </a>
            </div>
          </form>
        )}
      </Modal>
    );
  }
}

export default withRouter(AdminAssignTask);
