import $ from "jquery";
import React, { Component } from "react";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import shortid from "shortid";
import AppCanvasLoader from "../../../../components/App/AppCanvasLoader";
import MenuItem from "../../../../components/MenuItem";
// API
import * as API from "../../../../utils/api";
import ObjectDebtList from "../../../ObjectList/ObjectDebtList";
import Modal from "../Modal";

class AddEditSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newSubscription: {
        value: "",
        hasError: false,
      },
      reportsList: props.optionalProps.reportsList,
      masterReport: {
        value: "",
        id: "",
        hasError: false,
      },
      isEdit: props.optionalProps.item ? true : false,
      currentSub: props.optionalProps.item,
      emails: [],
      recipientsError: false,
      scheduleError: false,
      hh: "12",
      mm: "00",
      AMPM: "AM",
      hours: [],
      minutes: [],
      format: ["AM", "PM"],
      openedIndex: "",
      data: [
        {
          id: 1,
          is_checked: false,
          name: "Monday",
          time: "12:00 AM",
          openTimer: false,
        },
        {
          id: 2,
          is_checked: false,
          name: "Tuesday",
          time: "12:00 AM",
          openTimer: false,
        },
        {
          id: 3,
          is_checked: false,
          name: "Wednesday",
          time: "12:00 AM",
          openTimer: false,
        },
        {
          id: 4,
          is_checked: false,
          name: "Thursday",
          time: "12:00 AM",
          openTimer: false,
        },
        {
          id: 5,
          is_checked: false,
          name: "Friday",
          time: "12:00 AM",
          openTimer: false,
        },
        {
          id: 6,
          is_checked: false,
          name: "Saturday",
          time: "12:00 AM",
          openTimer: false,
        },
        {
          id: 7,
          is_checked: false,
          name: "Sunday",
          time: "12:00 AM",
          openTimer: false,
        },
        // {
        //   id: 8,
        //   is_checked: false,
        //   name: "Monthly",
        //   time: "12:00 AM",
        //   openTimer: false,
        // },
      ],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setReportOptions();
    for (let index = 1; index < 60; index++) {
      index = index < 10 ? "0" + index : index;
      if (index < 12) {
        this.state.hours.push(index.toString());
      }
      this.state.minutes.push(index.toString());
    }
    this.setState({ minutes: this.state.minutes, hours: this.state.hours });
  }

  setReportOptions = () => {
    let {
      reportsList,
      isEdit,
      masterReport,
      currentSub,
      newSubscription,
    } = this.state;

    if (currentSub) {
      let {
        subscription,
        subscription_recipient_list,
        subscription_schedule_list,
      } = currentSub;
      this.setRecipents(subscription_recipient_list);
      this.setScheduleTime(subscription_schedule_list);

      if (isEdit && subscription) {
        masterReport.id = subscription.master__report_id;
        masterReport.value = subscription.master_report_name;
        masterReport.hasError = false;
        newSubscription.id = subscription.id,
        newSubscription.value = subscription.name;
        newSubscription.hasError = false;
        this.setState({ masterReport, newSubscription });
      }
    }

    if (reportsList && reportsList.length) {
      reportsList.map((item) => {
        (item.label = item.name), (item.value = item.name);
      });
    } else {
      this.setState({ reportsList: [] });
    }
  };

  setRecipents = (recipients) => {
    if (recipients) {
      recipients.map((item) => {
        this.state.emails.push(item.email);
      });
      this.setState({ emails: this.state.emails });
    }
  };

  setScheduleTime = (schedule) => {
    let { data } = this.state;
    if (schedule) {
      schedule.map((item, index) => {
        if (item.day === "mon") {
          data[0].is_checked = true;
          data[0].time = item.time;
        } else if (item.day === "tue") {
          data[1].is_checked = true;
          data[1].time = item.time;
        } else if (item.day === "wed") {
          data[2].is_checked = true;
          data[2].time = item.time;
        } else if (item.day === "thu") {
          data[3].is_checked = true;
          data[3].time = item.time;
        } else if (item.day === "fri") {
          data[4].is_checked = true;
          data[4].time = item.time;
        } else if (item.day === "sat") {
          data[5].is_checked = true;
          data[5].time = item.time;
        } else if (item.day === "sun") {
          data[6].is_checked = true;
          data[6].time = item.time;
        }
      });
      this.setState({ data: this.state.data });
    }
  };

  onCheckboxClick = (event, i) => {
    const consumerId = event.target.name;
    this.state.data.map((item) => {
      if (item.id == consumerId) {
        if (event.target.checked) {
          item.is_checked = true;
          this.setState({ scheduleError: false });
        } else {
          item.is_checked = false;
        }
      }
    });
    this.setState({ data: this.state.data });
  };

  // set data on text input change
  handleChange = (value) => {
    this.state.newSubscription.value = value;
    this.state.newSubscription.hasError = false;
    this.setState({ newSubscription: this.state.newSubscription });
  };

  getSelectedDays = () => {
    let { data } = this.state;
    let days = [];
    data.map((item) => {
      switch (item.name) {
        case "Monday":
          if (item.is_checked) {
            days.push({ day: "mon", time: item.time });
          }
          break;
        case "Tuesday":
          if (item.is_checked) {
            days.push({ day: "tue", time: item.time });
          }
          break;
        case "Wednesday":
          if (item.is_checked) {
            days.push({ day: "wed", time: item.time });
          }
          break;
        case "Thursday":
          if (item.is_checked) {
            days.push({ day: "thu", time: item.time });
          }
          break;
        case "Friday":
          if (item.is_checked) {
            days.push({ day: "fri", time: item.time });
          }
          break;
        case "Saturday":
          if (item.is_checked) {
            days.push({ day: "sat", time: item.time });
          }
          break;
        case "Sunday":
          if (item.is_checked) {
            days.push({ day: "sun", time: item.time });
          }
          break;
        default:
          break;
      }
    });
    return days;
  };

  callCreateAPI = () => {
    let { newSubscription, masterReport, emails } = this.state;
    let payload = {
      name: newSubscription.value,
      master_report_id: masterReport.id,
      email_list: emails,
      days: this.getSelectedDays(),
    };
    $("#save-sub").replaceWith('<div class="spinner"></div>');
    API.createSubscription(this.props.user, payload).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.props.hideModal();
        this.props.optionalProps.reload();
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  callUpdateAPI = () => {
    let { newSubscription, masterReport, emails } = this.state;
    let payload = {
      subscription_id: newSubscription.id,
      name: newSubscription.value,
      master_report_id: masterReport.id,
      email_list: emails,
      days: this.getSelectedDays(),
    };
    $("#save-sub").replaceWith('<div class="spinner"></div>');
    API.updateSubscription(this.props.user, payload).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.props.hideModal();
        this.props.optionalProps.reload();
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  deleteSubscription = () => {
    let message = "Do you want to delete subscription?";
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: message,
      confirmClick: this.callDeleteApi,
      closeOnClickOutside: true
    });
  }

  callDeleteApi = () => {
    let { newSubscription } = this.state;
    let payload = {
      id: newSubscription.id,
    };
    $("#save-sub").replaceWith('<div class="spinner"></div>');
    API.deleteSubscription(this.props.user, payload).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.props.hideModal();
        this.props.optionalProps.reload();
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  handleSubmit = () => {
    if (this.state.isEdit && this.validateForm()) {
      this.callUpdateAPI();
    } else if (this.validateForm()) {
      this.callCreateAPI();
    }
  };

  validateForm = () => {
    let { masterReport, newSubscription, emails } = this.state;
    let errorsArePresent = false;
    if (newSubscription.value === "") {
      errorsArePresent = true;
      newSubscription.hasError = true;
      this.setState({ newSubscription });
    }
    if (masterReport.id === "") {
      errorsArePresent = true;
      masterReport.hasError = true;
      this.setState({ masterReport });
    }
    if (emails.length > 0) {
    } else {
      errorsArePresent = true;
      this.setState({ recipientsError: true });
    }
    if (this.getSelectedDays().length > 0) {
    } else {
      errorsArePresent = true;
      this.setState({ scheduleError: true });
    }

    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  };

  // show errors for empty fields
  renderErrorMessage(input) {
    let message = "";
    if (input === "name") {
      message = "Please enter name";
    } else if (input === "id") {
      message = "Please select report";
    } else if (input === "emails") {
      message = "Please enter recipients email";
    } else if (input === "schedule") {
      message = "Please select days";
    } else {
      message = "Please make sure to complete this field";
    }
    return <div className="input-error-message">{message}</div>;
  }

  // custom time picker to set time
  timePicker = (item, index) => {
    return (
      <div>
        <button
          className="time-picker-done-btn edit-btn"
          onClick={(event) => {
            this.openTimePicker(item);
          }}
        >
          {item.time}
        </button>
        {item.openTimer && this.showTimePicker(index)}
      </div>
    );
  };

  openTimePicker = (item) => {
    this.state.data.map((d, i) => {
      if (d.id === item.id) {
        d.openTimer = !d.openTimer;
      } else {
        d.openTimer = false;
      }
      this.setState({ data: this.state.data, openedIndex: item.id });
    });
  };

  setdata = (value, type) => {
    if (type === "HH") {
      this.setState({ hh: value });
    } else if (type === "MM") {
      this.setState({ mm: value });
    } else if (type === "A") {
      this.setState({ AMPM: value });
    }
  };

  clearData = () => {
    let { hh, mm, AMPM, openedIndex } = this.state;
    (hh = "12"), (mm = "00"), (AMPM = "AM");
    openedIndex = "";
    this.setState({ hh, mm, AMPM, openedIndex });
  };

  onDoneClick = () => {
    let { hh, mm, AMPM, openedIndex } = this.state;
    if (openedIndex) {
      this.state.data.map((item) => {
        if (item.id === openedIndex) {
          item.time = hh + ":" + mm + " " + AMPM;
          item.openTimer = false;
        }
        this.setState({ data: this.state.data }, () => {
          this.clearData();
        });
      });
    }
  };

  showTimePicker = (index) => {
    console.log("index", index);
    let { hours, minutes, format, hh, mm, AMPM } = this.state;
    return (
      <div className={`time-picker${index > 3 ? " ad-tm-pc" : ""}`}>
        <div className="time-picker-header">
          <div className="time-picker-label">{hh + ":" + mm + " " + AMPM}</div>
          <button
            className="time-picker-done-btn time-picker-pr"
            onClick={() => this.onDoneClick()}
          >
            Done
          </button>
        </div>
        <div className="time-picker-content">
          {this.pickerInnerData(hours, "HH")}
          {this.pickerInnerData(minutes, "MM")}
          {this.pickerInnerData(format, "A")}
        </div>
      </div>
    );
  };

  pickerInnerData = (data, type) => {
    return (
      <div className="time-picker-spinner">
        {data &&
          data.map((item) => {
            return (
              <MenuItem
                key={shortid.generate()}
                optionalClasses="time-picker-menu-item"
                handleClick={(event) => {
                  this.setdata(item, type);
                }}
              >
                {item}
              </MenuItem>
            );
          })}
      </div>
    );
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];
    headings = [];

    columns = data.map((item, index) => {
      return {
        primaryColumn: {},
        secondaryColumns: [
          {
            title: (
              <input
                name={item.id}
                value={item.id}
                key={item.id}
                type="checkbox"
                checked={item.is_checked}
                style={{
                  WebkitAppearance: `checkbox`,
                  width: `20px`,
                }}
                onChange={() => {}}
                onClick={(event) => this.onCheckboxClick(event, item)}
              />
            ),
            opClassesPar: "task-checkbox",
          },
          {
            title: item.name,
          },
          {
            title: this.timePicker(item, index),
            opClassesPar: "task task-toggle edit-time",
          },
        ],
        fullRowIsClickable: false,
        rowType: "",
        optionalRowClasses: "object-row-inner-padding",
      };
    });

    return {
      headings,
      columns,
    };
  };

  handleSelection = (event) => {
    let { masterReport } = this.state;
    (masterReport.id = event.id),
      (masterReport.value = event.value),
      (masterReport.hasError = false);
    this.setState({ masterReport });
  };

  render() {
    let {
      data,
      isLoading,
      isEdit,
      newSubscription,
      currentSub,
      reportsList,
      masterReport,
    } = this.state;

    let label = isEdit ? "Subscription Name" : "New Subscription";
    const styles = { marginTop: "0.5em" };
    const mailstyle = { fontSize: "14px" };
    const closestyle = { fontSize: "10px", fontFamily: "sofia_pro_bold" };

    return (
      <Modal
        title={isEdit ? "Edit Subscription" : "New Subscription"}
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        style={{ maxWidth: `35em`, paddingBottom: "1em" }}
        optionalClasses="scroll task-popup-sp"
        titleClasses="modal-header"
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <div>
            <div
              className={`input-container${
                masterReport.hasError ? " error" : ""
              }`}
              style={{ paddingLeft: 0 }}
            >
              <label htmlFor="state">Report</label>
              {isEdit ? (
                <div className="form-select" style={{ paddingTop: "0.5em" }}>
                  {masterReport.value}
                </div>
              ) : (
                <Select
                  inputProps={{
                    autoComplete: "none",
                    autoCorrect: "off",
                    spellCheck: "off",
                  }}
                  className="form-select"
                  name="report"
                  value={masterReport.value}
                  placeholder="Select a Report"
                  options={reportsList}
                  resetValue={""}
                  onChange={(event) => this.handleSelection(event)}
                />
              )}
              {masterReport.hasError ? this.renderErrorMessage("id") : null}
            </div>
            <div
              className={`input-container ${
                newSubscription.hasError ? " error" : ""
              }`}
              style={{ paddingLeft: 0 }}
            >
              <label htmlFor="name">{label}</label>
              {
                <div>
                  <input
                    name="name"
                    type="text"
                    readOnly={isEdit}
                    maxLength={60}
                    placeholder="Enter Subscription Name"
                    value={newSubscription.value}
                    onChange={(event) => this.handleChange(event.target.value)}
                  />
                  {newSubscription.hasError
                    ? this.renderErrorMessage("name")
                    : null}
                </div>
              }
            </div>
            <div
              className={`input-container${
                this.state.recipientsError ? " error" : ""
              }`}
              style={{ paddingLeft: 0 }}
            >
              <label htmlFor="recipients">Recipients</label>
              <div style={styles}>
                <ReactMultiEmail
                  placeholder="Add Recipients Emails"
                  emails={this.state.emails}
                  onChange={(_emails) => {
                    this.setState({ emails: _emails, recipientsError: false });
                  }}
                  validateEmail={(email) => {
                    return isEmail(email);
                  }}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div style={mailstyle} data-tag key={index}>
                        {email}
                        <span
                          style={closestyle}
                          data-tag-handle
                          onClick={() => removeEmail(index)}
                        >
                          X
                        </span>
                      </div>
                    );
                  }}
                />
                {this.state.recipientsError
                  ? this.renderErrorMessage("emails")
                  : null}
              </div>
            </div>
            <label htmlFor="Schedule">Schedule</label>
            <div style={{ marginTop: "0.5em" }}>
              <ObjectDebtList
                hideScrollbar={true}
                data={this.getObjectListData(data)}
              />
            </div>
            <div className={`${this.state.scheduleError ? " dy-er" : ""}`}>
              {this.state.scheduleError
                ? this.renderErrorMessage("schedule")
                : null}
            </div>
            <div
              id="save-sub"
              className="modal-actions edit-sub-modal-btn"
              style={isEdit ? {} : { justifyContent: "center" }}
            >
              <a className="cta mg-brand2-color" onClick={(event) => this.handleSubmit(event)}>
                Save Changes
              </a>
              {isEdit && (
                <a
                  className="cta edit-sub-modal-delete"
                  onClick={(event) => this.deleteSubscription(event)}
                >
                  Delete Subscription
                </a>
              )}
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

export default withRouter(AddEditSubscription);
