import React, { Component } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
// Components
import Logo from "../../components/Logo";
import OnboardingConfirmAccountPhoneForm from "../../components/Onboarding/OnboardingConfirmAccountPhoneForm";
import SessionsModal from "../../components/Sessions/SessionsModal";
import { getZohoPagesense } from "../../utils/helpers";

class OnboardingCreateAccountPhone extends Component {
  componentDidMount() {
    getZohoPagesense();
    ReactGA.pageview("/OnboardingCreateAccountPhone");
  }

  render() {
    let { appName, reconfirmPhoneNumber } = this.props;

    return (
      <div className="sessions fill-screen mg-session">
        <Helmet>
          <title>
            {appName} |{" "}
            {reconfirmPhoneNumber
              ? "Reenter Your Phone Number"
              : "Confirm Your Account"}
          </title>
        </Helmet>
        <SessionsModal>
          <div className="sessions-modal-logo-container mg-logo-container">
            <Logo circular={true} />
          </div>
          <div className="sessions-modal-content">
            <h1 className="sessions-modal-heading" style={{ color: "#008bb2" }}>
              {reconfirmPhoneNumber
                ? "Reenter Your Phone Number"
                : "Confirm your account"}
            </h1>
            <p>
              {reconfirmPhoneNumber
                ? "To resend a confirmation code, please enter your phone number below."
                : "To verify your identity, we need to send a confirmation code to you. Please provide your telephone number below."}
            </p>
            <OnboardingConfirmAccountPhoneForm user={this.props.user} />
          </div>
        </SessionsModal>
      </div>
    );
  }
}

export default OnboardingCreateAccountPhone;
