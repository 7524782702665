import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import $ from 'jquery';
import FileBase64 from 'react-file-base64';
import Popup from "reactjs-popup";

// Packages
import MaskedTextInput from 'react-text-mask';

// API
import * as API from '../../../../utils/api';
// Components
import Modal from '../Modal';

import { numberMask, isValidDate, getStrippedDollarAmount, showToast } from '../../../../utils/helpers';

class MakeAdjustmentModal extends Component {
  state = {
    creditors: [],
    userData: this.props.user,
    activeForm: 1,
    account: {
      debtId: this.props.optionalProps.debtInfo.id,
      debtBalance: this.props.optionalProps.debtInfo.balance_in_dollars_as_currency
    },
    paymentDocument: '',
    input: {
      paymentInformation: {
        paymentAmount: {
          value: "",
          hasError: false,
          isRequired: true
        },
        adjustment: {
          value: "",
          hasError: false
        }
      }
    },
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200,
      message: ''
    }
  }

  componentDidMount() {
    //console.log(this.props);

  }

  handleModalClose = () => {
    this.props.hideModal();
  }

  handlePaymentInfoInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        paymentInformation: {
          ...state.input.paymentInformation,
          ...newPartialInput,
        }
      }
    }))
  }

  handleMakeAdjustmentClick = (event) => {
    event.preventDefault();
    const { input } = this.state;
    let paymentInfo = {
      amount: getStrippedDollarAmount(input.paymentInformation.paymentAmount.value),
      debtID: this.props.optionalProps.debtInfo.id,
      adjustment: getStrippedDollarAmount(input.paymentInformation.adjustment.value)
    }
    if(paymentInfo.adjustment && paymentInfo.adjustment <= getStrippedDollarAmount(this.props.optionalProps.debtInfo.balance_in_dollars_as_currency)){
      API.adjustmentPayment(this.props.user, paymentInfo).then(data => {
        if (((typeof data != 'undefined') || (data != null)) && !data.error) {
          this.props.updateModalState(true, 'SUCCESS', { message: data.message });
          this.setState({ isLoading: false });
          this.props.optionalProps.reloadData(true);
        } else {
          this.setState(state => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              message: data.error,
              statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
            }
          }));
          this.handleModalClose();
        }
      })
    }else{
      let newInputState = { ...this.state.input.paymentInformation }
      newInputState['adjustment'].hasError = true;
      this.setState(state => ({
        ...state,
        paymentInformation: newInputState
      }))
    }
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  renderErrorMessage(input) {
    let message = '';
    if (input === 'adjustment') {
      message = "Please enter valid amount.";
    } else {
      message = 'Please complete this field.';
    }

    return (
      <div className="input-error-message">
        {message}
      </div>
    )
  }

  handleInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
      }
    }))
  }


  render() {
    let { input, slide, error } = this.state;

    const CCstyle = {
      base: {
        'fontSize': '18px',
        'lineHeight': '1.875em',
        'color': '#252525',
        'fontFamily': 'HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica',
        '::placeholder': {
          'color': '#9B9B9B',
        },
      },
      invalid: {
        'color': '#fb5c82',
        ':focus': {
          'color': '#252525',
        },
      }
    };

    const borderBottom = {
      'borderBottom': '1px solid #252525'
    }

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast(error.message);
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    return (
      <Modal
        title="Accept Adjustment"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        style={{ maxWidth: `35em` }}
      >
        <form>
          
            <div>
              <div>
                <div style={style.container}>
                  <div style={style.content}>Balance Amount : </div>
                    <div
                      className="input-container"
                    >
                      <MaskedTextInput
                        guide={true}
                        disabled={true}
                        mask={numberMask}
                        name="debtbBalance"
                        placeholder="$0"
                        placeholderChar={'\u2000'}
                        type="text"
                        value={this.props.optionalProps.debtInfo.balance_in_dollars_as_currency}
                      />
                    </div>
                  </div>
                  
                  <div style={style.container}>
                    <div style={style.content}>Discount Amount : </div>
                    <div
                      className={`input-container${input.paymentInformation.adjustment.hasError ? ' error' : ''}`}
                    >
                      <MaskedTextInput
                        guide={true}
                        mask={numberMask}
                        name="adjustment"
                        placeholder="$0"
                        placeholderChar={'\u2000'}
                        type="text"
                        value={input.paymentInformation.adjustment.value}
                        onChange={event => this.handlePaymentInfoInputChange({
                          adjustment: { value: event.target.value, hasError: false }
                        }
                        )}
                      />
                      {input.paymentInformation.adjustment.hasError ? this.renderErrorMessage('adjustment') : null}
                    </div>
                  </div>
              </div>
              <div className="modal-actions">
                <a
                  className="cta"
                  onClick={(event) => this.handleMakeAdjustmentClick(event)}
                >
                  Accept Adjustment
                </a>
              </div>
            </div>
        </form>
      </Modal>
    )
  }
}

const style = {
  container: { display: 'flex' },
  content: { width: '60%', paddingTop: `10px` }
}

export default MakeAdjustmentModal;
