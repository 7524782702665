import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect, withRouter } from "react-router-dom";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
// Components
import AppSectionHeader from "../../components/App/AppSectionHeader";
import ConsumerHelpForm from "../../components/Consumer/Forms/ConsumerHelpForm";
import * as API from "../../utils/api";
import { getZohoPagesense, showToast } from "../../utils/helpers";

class ClientsHelp extends Component {
  constructor() {
    super();
    this.state = {
      user: [],
      isLoading: false,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
    };
  }

  componentDidMount() {
    getZohoPagesense();
    ReactGA.pageview("/ClientsHepl");
    const { user } = this.props;

    API.getUser(user).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.errors) {
        this.setState((state) => ({
          user: data.user,
          isLoading: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  handleHelpFormSubmit = (inputs) => {
    const { user } = this.state;
    let messageObjectForSubmit = {
      contact_form: {
        name: user.first_name + " " + user.last_name,
        email: user.email,
        phone_number: user.phone_number,
        topic: inputs.inquiry,
        message: inputs.message,
        page: 'client_admin'
      },
    };
    API.consumerRequestHelp(user, messageObjectForSubmit).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.props.updateModalState(true, "SUCCESS", {
          message: "Your message was sent successfully",
        });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  render() {
    const { appName } = this.props;
    const { isLoading, error } = this.state;
    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className="scrollbar client-scroll-profile client-help">
        <Helmet>
          <title>{appName} | Help </title>
        </Helmet>
        <AppSectionHeader
          title="Help"
          optionalClassNames="client-account-utility"
        />
        <ConsumerHelpForm handleSubmit={this.handleHelpFormSubmit} />
      </div>
    );
  }
}

export default withRouter(ClientsHelp);
