import React, { Component } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import CreateOrganization from "../../SuperAdmin/Forms/CreateOrganization";
import AccountCreatedModal from "./Admin/AccountCreatedModal";
import AddAccountModal from "./Admin/AddAccountModal";
import AddTemplateProfileModal from "./Admin/AddTemplateProfileModal";
import AddNewCreditorModal from "./Admin/AddNewCreditorModal";
import AddCommunicationProfileModal from "./Admin/AddCommunicationProfileModal";
import AdminAssignTask from "./Admin/AdminAssignTask";
//Organization Modals
import AddOrganizationAccountModal from "./Admin/AddOrganizationAccountModal";
import AdminOfferAcceptedAndSettledModal from "./Admin/AdminOfferAcceptedAndSettledModal";
// Admin Modals
import CounterofferModal from "./Admin/CounterofferModal";
import OfferAcceptedModal from "./Admin/OfferAcceptedModal";
import OfferRejectedModal from "./Admin/OfferRejectedModal";
import OfferReviewModal from "./Admin/OfferReviewModal";
import ShowImageModal from "./Admin/ShowImageModal";
import AcceptRejectNoteModal from "./Client/AcceptRejectNoteModal";
import AddAdminAccountModal from "./Client/AddAdminAccountModal";
import AddClientAccountModal from "./Client/AddClientAccountModal";
import AddEditSubscription from "./Client/AddEditSubscription";
import AddNewDebt from "./Client/AddNewDebt";
import AssignEmployee from "./Client/AssignEmployee";
import AutomaticNegotiation from "./Client/AutomaticNegotiation";
import EditTaskConfig from "./Client/EditTaskConfig";
import LobFilterModal from "./Client/LobFilterModal";
import MakeAdjustmentModal from "./Client/MakeAdjustmentModal";
import MakePaymentModal from "./Client/MakePaymentModal";
import SendNewLetterModal from "./Client/SendNewLetterModal";
import SendNewMailModal from "./Client/SendNewMailModal";
import RefundPaymentModal from "./Client/RefundPaymentModal";
import CancelRefundPaymentModal from "./Client/CancelRefundPaymentModal";
import PreviewTaskConfigModal from "./Client/PreviewTaskConfigModal";
import PreviewTaskTemplateModal from "./Client/PreviewTaskTemplateModal";
import AddServiceModal from "./Client/AddServiceModal";
import PreviewInvoiceModal from "./Client/PreviewInvoiceModal";
import AddTermModal from "./Client/AddTermModal";
import TermErrorModal from "./Client/TermErrorModal";
//Client Modal
import ShowMailDesciptionModal from "./Client/ShowMailDescriptionModal";
import ShowSkipTraceDetailModal from "./Client/ShowSkipTraceDetailModal";
import UserLevelSkipPayment from "./Client/userLevelSkipPayment";
import ValidationDocumentModal from "./Client/ValidationDocumentModal";
import ConfirmAlertModal from "./ConfirmAlertModal";
import ConfirmActionModal from "./confirmActionModal";
import CounterofferSentModal from "./CounterofferSentModal";
import AddConsumerAccountModal from "./Employee/AddConsumerAccountModal";
import ErrorModal from "./ErrorModal";
import OtherErrorModal from "./OtherErrorModal";
import InvoiceListModal from "./Client/InvoiceListModal";
// General Modals
import SuccessModal from "./SuccessModal";
import AutoOfferRejectedModal from "./User/AutoOfferRejectedModal";
import ShowDebtNoteModal from "./User/ShowDebtNoteModal";
import UserAccountPaymentFailedModal from "./User/UserAccountPaymentFailedModal";
// User Modals
import UserAccountPaymentOfferSubmittedModal from "./User/UserAccountPaymentOfferSubmittedModal";
import UserAccountPaymentSettledModal from "./User/UserAccountPaymentSettledModal";

class ModalConductor extends Component {
  render() {
    let { currentModal, stripePubKey } = this.props;

    switch (currentModal) {
      case "OFFER_REVIEW":
        return <OfferReviewModal {...this.props} />;

      case "COUNTEROFFER":
        return <CounterofferModal {...this.props} />;

      case "COUNTEROFFER_SENT":
        return <CounterofferSentModal {...this.props} />;

      case "OFFER_ACCEPTED":
        return <OfferAcceptedModal {...this.props} />;

      case "OFFER_REJECTED":
        return <OfferRejectedModal {...this.props} />;

      case "AUTO_OFFER_REJECTED":
        return <AutoOfferRejectedModal {...this.props} />;

      case "ADD_ACCOUNT":
        return <AddAccountModal {...this.props} />;

      case "ADD_TEMPLATE_PROFILE":
        return <AddTemplateProfileModal {...this.props} />;

      case "ADD_COMMS_PROFILE":
        return <AddCommunicationProfileModal {...this.props} />;

      case "ACCOUNT_CREATED":
        return <AccountCreatedModal {...this.props} />;

      case "ERROR":
        return <ErrorModal {...this.props} />;
      case "OTHER_ERROR":
        return <OtherErrorModal {...this.props} />;

      case "USER_OFFER_SUBMITTED":
        return <UserAccountPaymentOfferSubmittedModal {...this.props} />;

      case "ADMIN_OFFER_ACCEPTED_AND_SETTLED":
        return <AdminOfferAcceptedAndSettledModal {...this.props} />;

      case "USER_PAYMENT_SETTLED":
        return <UserAccountPaymentSettledModal {...this.props} />;

      case "Add_NEW_CREDITOR":
        return <AddNewCreditorModal {...this.props} />;

      case "ADD_ADMIN_ACCOUNT":
        return <AddAdminAccountModal {...this.props} />;

      case "USER_PAYMENT_FAILED":
        return <UserAccountPaymentFailedModal {...this.props} />;

      case "ADD_ORGANIZATION_ACCOUNT":
        return <AddOrganizationAccountModal {...this.props} />;

      case "ADD_ORGANIZATION_CLIENT_ACCOUNT":
        return <AddClientAccountModal {...this.props} />;

      case "EMPLOYEE_ADD_CONSUMER_ACCOUNT":
        return <AddConsumerAccountModal {...this.props} />;

      case "MAKE_PAYMENT":
        return (
          <StripeProvider apiKey={stripePubKey}>
            <Elements>
              <MakePaymentModal {...this.props} />
            </Elements>
          </StripeProvider>
        );
      case "AUTOMATIC_NEGOTIATION":
        return <AutomaticNegotiation {...this.props} />;

      case "ASSIGN_EMPLOYEE":
        return <AssignEmployee {...this.props} />;

      case "SKIP_PAYMENT":
        return <UserLevelSkipPayment {...this.props} />;

      case "ADD_CONSUMER_DEBT":
        return <AddNewDebt {...this.props} />;

      case "ACCEPT_REJECT_NOTES":
        return <AcceptRejectNoteModal {...this.props} />;

      case "SEND_NEW_LETTER":
        return <SendNewLetterModal {...this.props} />;

      case "SEND_NEW_MAIL":
        return <SendNewMailModal {...this.props} />;

      case "TASK_TEMPLATE_PREVIEW":
        return <PreviewTaskTemplateModal {...this.props} />;

      case "PREVIEW_TASK_CONFIG":
        return <PreviewTaskConfigModal {...this.props} />;

      case "VALIDATION_DOCUMENT":
        return <ValidationDocumentModal {...this.props} />;

      case "LOB_FILTER":
        return <LobFilterModal {...this.props} />;

      case "SHOW_IMAGE":
        return <ShowImageModal {...this.props} />;

      case "CONFIRM_ALERT":
        return <ConfirmAlertModal {...this.props} />;

      case "CONFIRM_ACTION":
        return <ConfirmActionModal {...this.props} />;

      case "SUCCESS":
        return <SuccessModal {...this.props} />;

      case "SHOW_SKIP_TRACE_DETAIL":
        return <ShowSkipTraceDetailModal {...this.props} />;

      case "MAKE_ADJUSTMENT":
        return <MakeAdjustmentModal {...this.props} />;

      case "SHOW_DEBT_NOTE":
        return <ShowDebtNoteModal {...this.props} />;

      case "SHOW_MAIL_DESC":
        return <ShowMailDesciptionModal {...this.props} />;

      case "CREATE_ORGANIZATION":
        return <CreateOrganization {...this.props} />;

      case "EDIT_TASK_CONFIG":
        return <EditTaskConfig {...this.props} />;

      case "ADMIN_ASSIGN_TASK":
        return <AdminAssignTask {...this.props} />;

      case "ADD_EDIT_SUBSCRIPTION":
        return <AddEditSubscription {...this.props} />;

      case "REFUND_PAYMENT":
        return <RefundPaymentModal {...this.props} />;

        case "CANCEL_REFUND_PAYMENT":
        return <CancelRefundPaymentModal {...this.props} />;

      case "ADD_SERVICE":
        return <AddServiceModal {...this.props} />;

      case "PREVIEW_INVOICE":
        return <PreviewInvoiceModal {...this.props} />;

      case "ADD_TERM":
        return <AddTermModal {...this.props} />;
      
      case "SHOW_INVOICE_LIST":
        return <InvoiceListModal {...this.props} />

      case "TERM_ERROR":
        return <TermErrorModal {...this.props} />

      default:
        return null;
    }
  }
}

export default ModalConductor;
