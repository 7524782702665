import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import Intercom from "react-intercom";
import * as API from "../../utils/api";

import { getZohoPagesense } from "../../utils/helpers";

// Components
import AppConsumerHeader from "../../components/App/AppConsumerHeader";
import ConsumerNav from "../../components/Consumer/ConsumerNav";

class ConsumerPortal extends Component {
  static propTypes = {
    location: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    screen: PropTypes.object.isRequired,
    prevPath: PropTypes.string,
  };

  state = {
    intercomUser: "",
    intercomAppId: "",
  };

  componentDidMount() {
    //this.setIntercomUser(this.props.user);
    getZohoPagesense();
  }

  componentWillReceiveProps(nextProps) {
    // this.setIntercomUser(nextProps.user);
  }

  setIntercomUser = (user) => {
    if (user) {
      const intercomUser = {
        user_id: user.user_id,
        email: user.email,
        name: user.first_name + " " + user.last_name,
      };

      this.setState({
        intercomUser,
      });
    } else {
      const { cookies } = this.props;
      cookies.remove("authUser", { path: "/", secure: true});
      cookies.remove("isLoggedIn", { path: "/", secure: true});
      this.setState((state) => ({
        ...state,
        user: {},
        isLoggedIn: false,
      }));
    }
  };

  render() {
    const { intercomUser } = this.state;

    if (!this.props.isLoggedIn || !this.props.user.onboarding_complete) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: this.props.location },
          }}
        />
      );
    }

    //mpig6e59

    const { prevPath, intercomAppId } = this.props;
    return (
      <div className="app-portal">
        {intercomAppId && <Intercom appID={intercomAppId} {...intercomUser} />}
        <AppConsumerHeader 
          user={this.props.user}
          appName={this.props.appName}
          logoPath="/dashboard"
          hideNav={true}
          accountHolder="consumer"
        />
        <div
          className={`app-portal-canvas consumer-portal${
            this.props.removePadding ? " canvas-back-consumer" : ""
          }`}
        >
          <div
            className="wrap"
            style={this.props.removePadding ? { padding: 0 } : {}}
          >
            {this.props.screen}
          </div>
        </div>
      </div>
    );
  }
}

export default ConsumerPortal;
