import React, { Component } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import Logo from "../../components/Logo";
import OnboardingConfirmAccountCodeForm from "../../components/Onboarding/OnboardingConfirmAccountCodeForm";
// Components
import SessionsModal from "../../components/Sessions/SessionsModal";
import { getZohoPagesense } from "../../utils/helpers";

class OnboardingConfirmAccountCode extends Component {
  componentDidMount() {
    getZohoPagesense();
    ReactGA.pageview("/OnboardingConfirmAccountCode");
  }

  render() {
    let { appName } = this.props;

    return (
      <div className="sessions fill-screen mg-session">
        <Helmet>
          <title>{appName} | Confirm Your Account</title>
        </Helmet>
        <SessionsModal optionalClassName="onboarding-confirm-account-code">
          <div className="sessions-modal-logo-container mg-logo-container">
            <Logo circular={true} />
          </div>
          <div className="sessions-modal-content">
            <h1 className="sessions-modal-heading" style={{ color: "#008bb2" }}>
              Confirm your account
            </h1>
            <p>
              Please enter the 6-digit confirmation code below to confirm your
              account.
            </p>
            <OnboardingConfirmAccountCodeForm
              user={this.props.user}
              resendConfirmationCode={this.props.handleResendConfirmationCode}
            />
          </div>
        </SessionsModal>
      </div>
    );
  }
}

export default OnboardingConfirmAccountCode;
