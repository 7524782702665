import React, { Component } from "react";
import { Table } from "reactstrap";
import shortid from "shortid";
import moment from "moment";
import { getDollarAmount } from "../../../utils/helpers";

class ClientsAgingSummaryReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      invoiceReport: false,
    };
  }

  componentDidMount() {
    let { data, customerSummary, invoiceReport } = this.props;
    if (customerSummary) {
      this.setState({ data: this.getCustomerSummary(data) });
    } else if (invoiceReport) {
      this.setState({
        data: this.getInvoiceList(data),
        invoiceReport: invoiceReport,
      });
    } else {
      this.setState({ data: this.getReportData(data) });
    }
  }

  getRowTotal = (item) => {
    let total =
      Number(item.current_sum) +
      Number(item.sum_1_to_30) +
      Number(item.sum_30_to_60) +
      Number(item.sum_60_to_90) +
      Number(item.sum_90_to_120) +
      Number(item.sum_121_plus);
    return Number(total).toFixed(2);
  };

  getLastColumn = (list, data) => {
    data.map((e, i) => {
      list[0] = "Total";
      list[1] = list[1]
        ? Number(list[1]) + Number(e.current_sum)
        : Number(e.current_sum);
      list[2] = list[2]
        ? Number(list[2]) + Number(e.sum_1_to_30)
        : Number(e.sum_1_to_30);
      list[3] = list[3]
        ? Number(list[3]) + Number(e.sum_30_to_60)
        : Number(e.sum_30_to_60);
      list[4] = list[4]
        ? Number(list[4]) + Number(e.sum_60_to_90)
        : Number(e.sum_60_to_90);
      list[5] = list[5]
        ? Number(list[5]) + Number(e.sum_90_to_120)
        : Number(e.sum_90_to_120);
      list[6] = list[6]
        ? Number(list[6]) + Number(e.sum_121_plus)
        : Number(e.sum_121_plus);
      list[7] = list[7]
        ? Number(list[7]) + Number(this.getRowTotal(e))
        : Number(this.getRowTotal(e));
    });
    return list;
  };

  getReportData = (data) => {
    let lastColumn = [];
    return {
      headings: [
        "Client Name",
        "Current",
        "1-30",
        "31-60",
        "61-90",
        "91-120",
        "120 and over",
        "Total",
      ],

      columns: data.map((item, index) => {
        return {
          secondaryColumns: [
            {
              title: item.user_name,
            },
            {
              title: getDollarAmount(Number(item.current_sum)),
            },
            {
              title: getDollarAmount(Number(item.sum_1_to_30)),
            },
            {
              title: getDollarAmount(Number(item.sum_30_to_60)),
            },
            {
              title: getDollarAmount(Number(item.sum_60_to_90)),
            },
            {
              title: getDollarAmount(Number(item.sum_90_to_120)),
            },
            {
              title: getDollarAmount(Number(item.sum_121_plus)),
            },
            {
              title: getDollarAmount(this.getRowTotal(item)),
            },
          ],
        };
      }),
      lastColumn: this.getLastColumn(lastColumn, data),
    };
  };

  getCustomerLastColumn = (list, data) => {
    data.map((e, i) => {
      list[0] = "Total";
      list[1] = list[1] ? Number(list[1]) + Number(e.balance_sum) : Number(e.balance_sum);
    });
    return list;
  };

  getCustomerSummary = (data) => {
    let lastColumn = [];
    return {
      headings: ["Client Name", "Total"],

      columns: data.map((item, index) => {
        return {
          secondaryColumns: [
            {
              title: item.display_name,
            },
            {
              title: getDollarAmount(Number(item.balance_sum)),
            },
          ],
        };
      }),
      lastColumn: this.getCustomerLastColumn(lastColumn, data),
    };
  };

  getDate = (date) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY");
    }
    return "n/a";
  };

  getInvoiceList = (data) => {
    return {
      headings: [
        "Date",
        "Transaction Type",
        "Num",
        "Name",
        "Memo Description",
        "Due Date",
        "Amount",
        "Open Balance",
      ],

      columns: data.map((item, index) => {
        return {
          secondaryColumns: [
            {
              title: this.getDate(item.txn_date),
            },
            {
              title: "Invoice",
            },
            {
              title: item.invoice_number,
            },
            {
              title: item.display_name,
            },
            {
              title: item.customer_memo,
            },
            {
              title: this.getDate(item.due_date),
            },
            {
              title: getDollarAmount(Number(item.total_amt)),
            },
            {
              title: getDollarAmount(Number(item.balance)),
            },
          ],
        };
      }),
    };
  };

  render() {
    let { data, invoiceReport } = this.state;
    let invoice_style = invoiceReport ? "invoice" : "";
    return (
      <Table responsive hover className={`aging-summary-report`}>
        <thead>
          <tr>
            {data.headings &&
              data.headings.map((header) => {
                return (
                  <th key={shortid.generate()} className={invoice_style}>
                    {header}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody>
          {data.columns &&
            data.columns.map((item, index) => {
              return (
                <tr key={shortid.generate()} className={`row-total`}>
                  {item.secondaryColumns &&
                    item.secondaryColumns.map((col) => {
                      return (
                        <td key={shortid.generate()} className={invoice_style}>
                          {col.title}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
          {data.lastColumn && data.lastColumn.length ? (
            <tr className="row-total">
              {data.lastColumn.map((col, i) => {
                return (
                  <td key={shortid.generate()}>
                    {i === 0 ? col : getDollarAmount(Number(col))}
                  </td>
                );
              })}
            </tr>
          ) : (
            <tr className="empty-row" />
          )}
        </tbody>
      </Table>
    );
  }
}

export default ClientsAgingSummaryReport;
