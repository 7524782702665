import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import $ from "jquery";
import FileBase64 from "react-file-base64";
import FileBase64Component from "../../AppInputToBase64"
import Popup from "reactjs-popup";
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
} from "react-stripe-elements";

// Packages
import Select from "react-select";
import MaskedTextInput from "react-text-mask";
import TextareaAutosize from "react-autosize-textarea";

// API
import * as API from "../../../../utils/api";
import Tabs from "../../../../components/Tabs";
// Components
import Modal from "../Modal";
import pdfIcon from "../../../../assets/images/icons/pdf.png";

import {
  amountMask,
  isValidDate,
  getStrippedDollarAmount,
  showToast,
} from "../../../../utils/helpers";
import { includes } from "lodash";
import moment from "moment";

class MakePaymentModal extends Component {
  state = {
    creditors: [],
    userData: this.props.user,
    activeForm: 1,
    account: {
      invoice_id: this.props.optionalProps.debtInfo.id,
      debtBalance: this.props.optionalProps.debtInfo.balance,
    },
    paymentDocument: "",
    input: {
      paymentInformation: {
        paymentType: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        paymentAmount: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        date_of_payment: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        memo: {
          value: "",
          hasError: false,
        },
        document: {
          value: "",
          name: "",
          hasError: false,
        },
        adjustment: {
          value: "",
          hasError: false,
        },
      },
      CardInformation: {
        nameOnCard: {
          value: "",
          empty: true,
          hasError: false,
        },
        cardNumber: {
          value: "",
          empty: true,
          hasError: false,
        },
        cardExpirationDate: {
          value: "",
          empty: true,
          hasError: false,
        },
        cvc: {
          value: "",
          empty: true,
          hasError: false,
        },
        cardZip: {
          value: "",
          empty: true,
          hasError: false,
        },
      },
    },
    isLoading: true,
    docErrorMsg: '',
    error: {
      hasAnyError: false,
      statusCode: 200,
      message: "",
    },
  };
  
  componentDidMount() {
    let temp = {...this.state.input}
    temp.paymentInformation.date_of_payment.value = moment(new Date()).format('MM/DD/YYYY');
    this.setState({input: temp})
  }

  handleModalClose = () => {
    if (!this.state.error.hasAnyError) {
      this.props.hideModal();
    }
  };

  handlePaymentInfoInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        paymentInformation: {
          ...state.input.paymentInformation,
          ...newPartialInput,
        },
      },
    }));
  }

  handleInputBlur = (type) => {
    let { input } = this.state;
    let { paymentInformation } = input;
    if (type && paymentInformation[type]) {
      let value = paymentInformation[type].value;
      if (value && !value.includes(".")) {
        input.paymentInformation[type].value = value + ".00";
      }
    }
    this.setState({ input });
  };

  getFormattedAmount = (amount) => {
    if (amount) {
      amount = parseFloat(amount).toFixed(2);
    }
    return amount;
  };

  // Callback~
  getFiles(files) {
    let newInputState = { ...this.state.input.paymentInformation };
    let imgExtension = files[0].name.split(".")[1];
    let extensionFlag = false;
    let sizeFlag = false;
    let isError = false;
    if (
      imgExtension.toLowerCase() === "png" ||
      imgExtension.toLowerCase() === "jpg" ||
      imgExtension.toLowerCase() === "jpeg" ||
      imgExtension.toLowerCase() === "bmp" ||
      imgExtension.toLowerCase() === "pdf" ||
      imgExtension.toLowerCase() === "pdf"
    ) {
      extensionFlag = true;
    } 
    if (files[0].size <= 1024) {
        // extensionFlag = false;
        sizeFlag = true;
    }

    if (extensionFlag && sizeFlag) {
      this.setState({ document: files[0].base64 });
      newInputState.document.value = files[0].base64;
      newInputState.document.name = files[0].name;
      this.handlePaymentInfoInputChange({
        document: {
          value: files[0].base64,
          name: files[0].name,
          hasError: false,
        },
      })
    } else {
      let errorMSG;
      newInputState.document.hasError = true;
      isError = true;
      if (!extensionFlag){
        errorMSG = 'File not supported.';
      } else if (extensionFlag && !sizeFlag){
        errorMSG = 'File size should not be greater than 1MB.';
      }
      this.handlePaymentInfoInputChange({
        document: {
          value: '',
          hasError: isError,
        },
      })
      this.setState({docErrorMsg: errorMSG})
      // this.setState((state) => ({
      //   ...state,
      //   input: newInputState,
      //   docErrorMsg: errorMSG
      // }));
    }
  }

  handleAccountSettlement = (stripeToken, paymentInfo) => {
    paymentInfo.card_token = stripeToken;
    API.paymentByChequeOrCash(this.props.user, paymentInfo).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.props.updateModalState(true, "SUCCESS", { message: data.message });
        this.setState({ isLoading: false });
        this.props.optionalProps.reloadData(true);
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            message: data && data.error ? data.error : "",
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
        this.handleModalClose();
      }
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { input } = this.state;
    let paymentInfo = {
      type: input.paymentInformation.paymentType.value,
      amount: getStrippedDollarAmount(
        input.paymentInformation.paymentAmount.value
      ),
      last_payment_date: input.paymentInformation.date_of_payment.value,
      memo: input.paymentInformation.memo.value,
      paymentDocument: input.paymentInformation.document.value,
      invoice_id: this.props.optionalProps.debtInfo.id,
      nameOnCard: input.CardInformation.nameOnCard.value,
      cardNumber: input.CardInformation.cardNumber.value,
      cardExpirationDate: input.CardInformation.cardExpirationDate.value,
      cvc: input.CardInformation.cvc.value,
      cardZip: input.CardInformation.cardZip.value,
      adjustment: input.paymentInformation.adjustment.value,
    };

    if (
      this.state.userData.last_4 &&
      this.state.userData.last_4 != null &&
      this.state.userData.last_4 != undefined
    ) {
      //this.handleAccountSettlement('');
    } else {
      if (this.validateCCForm()) {
        $("#submit-form").replaceWith('<div class="spinner"></div>');
        setTimeout(() => {
          this._element.focus();
          this._element.blur();
        }, 20);
        this.props.stripe
          .createToken({
            name: this.state.input.CardInformation.nameOnCard.value,
          })
          .then(({ token }) => {
            this.handleAccountSettlement(token.id, paymentInfo);
          });
      }
    }
  };

  handleAcceptPayment = (paymentInfo) => {
    $("#form-1").replaceWith('<div class="spinner"></div>');
    API.paymentByChequeOrCash(this.props.user, paymentInfo).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.setState({ isLoading: false });
        this.props.optionalProps.reloadData(true);
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            message: data.error,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
        this.handleModalClose();
      }
    });
  };

  handleMakePaymentClick = (event) => {
    event.preventDefault();
    const { input } = this.state;
    if (this.validateForm()) {
      let paymentInfo;
      if(input.paymentInformation.document.value == ''){
        paymentInfo = {
          type: input.paymentInformation.paymentType.value,
          amount: getStrippedDollarAmount(
            input.paymentInformation.paymentAmount.value
          ),
          // last_payment_date: input.paymentInformation.date_of_payment.value,
          last_payment_date: moment(input.paymentInformation.date_of_payment.value).format("YYYY-MM-DD"),
          memo: input.paymentInformation.memo.value,
          invoice_id: this.props.optionalProps.debtInfo.id,
          paymentDocument: input.paymentInformation.document.value,
          adjustment: getStrippedDollarAmount(
            input.paymentInformation.adjustment.value
          ),
        };
      } else {
        paymentInfo = {
          type: input.paymentInformation.paymentType.value,
          amount: getStrippedDollarAmount(
            input.paymentInformation.paymentAmount.value
          ),
          // last_payment_date: input.paymentInformation.date_of_payment.value,
          last_payment_date: moment(input.paymentInformation.date_of_payment.value).format("YYYY-MM-DD"),
          memo: input.paymentInformation.memo.value,
          invoice_id: this.props.optionalProps.debtInfo.id,
          paymentDocument: input.paymentInformation.document.value,
          attachment_name: input.paymentInformation.document.name,
          description: "Accept Payment",
          document_type: 'Invoice',
          adjustment: getStrippedDollarAmount(
            input.paymentInformation.adjustment.value
          ),
        };
      }
      

      if (
        paymentInfo.adjustment + paymentInfo.amount <=
        getStrippedDollarAmount(this.props.optionalProps.debtInfo.balance)
      ) {
        input.paymentInformation.paymentType.value === "CreditCard"
          ? this.setState((state) => ({
              ...state,
              activeForm: 2,
            }))
          : this.handleAcceptPayment(paymentInfo);
      } else {
        console.log("error");
        let newInputState = { ...this.state.input.paymentInformation };
        newInputState["paymentAmount"].hasError = true;
        this.setState((state) => ({
          ...state,
          paymentInformation: newInputState,
        }));
      }
    }
  };

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  validateForm = () => {
    let errorsArePresent = false;

    // Determine which input group to check for errors
    let newInputState = { ...this.state.input.paymentInformation };
    let { debtInfo } = this.props.optionalProps;
    // Check inputs for errors
    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "paymentType") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "paymentAmount") {
        if (
          value.value === "" ||
          getStrippedDollarAmount(value.value) >
            getStrippedDollarAmount(debtInfo.balance) ||
          getStrippedDollarAmount(value.value) < 1
        ) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "date_of_payment") {
        if (
          !isValidDate(value.value) ||
          value.value === "" ||
          value.value === null
        ) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "document") {
        if (
          value.hasError === true
        ) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (value.isRequired && value.value === "") {
        errorsArePresent = true;
      }
    });

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        paymentInformation: newInputState,
      }));
    } else {
      return true;
    }
  };

  validateCCForm = () => {
    let errorsArePresent = false;

    // Determine which input group to check for errors
    let newInputState = { ...this.state.input.CardInformation };

    // Check inputs for errors
    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "nameOnCard") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "cardNumber") {
        if (value.value === "") {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "cardExpirationDate") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "cvc") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "cardZip") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (value.isRequired && value.value === "") {
        errorsArePresent = true;
      }
    });

    let { input } = this.state;
    let checkErrors =
      input.CardInformation.cardNumber.hasError ||
      input.CardInformation.cardExpirationDate.hasError ||
      input.CardInformation.cvc.hasError ||
      input.CardInformation.cardZip.hasError;
    if (checkErrors) {
      errorsArePresent = true;
    }

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        paymentInformation: newInputState,
      }));
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "paymentType") {
      message = "Please select payment type.";
    } else if (input === "paymentAmount") {
      message = "Please enter valid amount.";
    } else if (input === "date_of_payment") {
      message = "Please enter valid date.";
    } else if (input === "nameOnCard") {
      message = "Please enter Name on card";
    } else if (input === "cardNumber") {
      message = "Please enter Credit card number";
    } else if (input === "cardExpirationDate") {
      message = "Please enter Expiration date";
    } else if (input === "cvc") {
      message = "Please enter CVV";
    } else if (input === "cardZip") {
      message = "Please enter billing Zip code";
    } else if (input === "paymentAmount") {
      message = "Please enter amount";
    } else {
      message = "Please complete this field.";
    }

    return <div className="input-error-message">{message}</div>;
  }

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        CardInformation: {
          ...state.input.CardInformation,
          ...newPartialInput,
        },
      },
    }));
  }

  handleTabClick = (tabIndex) => {
    const { input } = this.state;
    switch (parseInt(tabIndex, 10)) {
      case 1:
        this.setState((state) => ({
          ...state,
          activeForm: 1,
        }));
        // eslint-disable-next-line no-unreachable
        break;
      case 2:
        // this.setState(state => ({
        //   ...state,
        //   activeForm : 2
        // }))
        // eslint-disable-next-line no-unreachable
        break;
      default:
        return null;
        // eslint-disable-next-line no-unreachable
        break;
    }
  };

  renderTabsOrHeading = () => {
    const { input } = this.state;

    if (input.paymentInformation.paymentType.value === "CreditCard") {
      const tabLabels = ["Pay", "Credit Card"];

      return (
        <Tabs
          labels={tabLabels}
          activeTarget={this.state.activeForm}
          handleTabChange={this.handleTabClick}
          optionalInTabClasses="mg-tabs-tab"
        />
      );
    } else {
      return "";
    }
  };

  render() {
    let { input, slide, error } = this.state;
    let { stripePubKey } = this.props;
    let { paymentDocument } = this.state;
    let iconImage = paymentDocument ? paymentDocument.document : "";

    if (
      paymentDocument &&
      paymentDocument.document &&
      paymentDocument.document.indexOf("pdf") !== -1
    ) {
      iconImage = pdfIcon;
    } else {
      iconImage = paymentDocument ? paymentDocument.document : "";
    }

    const CCstyle = {
      base: {
        fontSize: "18px",
        lineHeight: "1.875em",
        color: "#252525",
        fontFamily:
          "HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica",
        "::placeholder": {
          color: "#9B9B9B",
        },
      },
      invalid: {
        color: "#fb5c82",
        ":focus": {
          color: "#252525",
        },
      },
    };

    const borderBottom = {
      borderBottom: "1px solid #252525",
    };

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        //showToast(error.message);
        this.props.updateModalState(true, "ERROR", { message: error.message });
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <Modal
        title="Accept Payment"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        style={{ maxWidth: `35em` }}
        optionalClasses="scroll"
      >
        {this.renderTabsOrHeading()}
        <form>
          {this.state.activeForm === 1 ? (
            <div>
              <div>
                <div style={style.container}>
                  <div style={style.content}>Balance Due : </div>
                  <div className="input-container">
                    <MaskedTextInput
                      guide={true}
                      disabled={true}
                      mask={amountMask}
                      name="debtbBalance"
                      placeholder="$0"
                      placeholderChar={"\u2000"}
                      type="text"
                      value={this.getFormattedAmount(
                        this.props.optionalProps.debtInfo.balance
                      )}
                    />
                  </div>
                </div>
                <div style={style.container}>
                  <div style={style.content}>Payment Amount : </div>
                  <div
                    className={`input-container${
                      input.paymentInformation.paymentAmount.hasError
                        ? " error"
                        : ""
                    }`}
                  >
                    <MaskedTextInput
                      guide={true}
                      mask={amountMask}
                      name="creditorTotalDebt"
                      placeholder="$0"
                      placeholderChar={"\u2000"}
                      pattern="^(\d+)(,\d{1,2}|.\d{1,2})?$"
                      type="text"
                      onBlur={() => this.handleInputBlur("paymentAmount")}
                      value={input.paymentInformation.paymentAmount.value}
                      onChange={(event) =>
                        this.handlePaymentInfoInputChange({
                          paymentAmount: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    {input.paymentInformation.paymentAmount.hasError
                      ? this.renderErrorMessage("paymentAmount")
                      : null}
                  </div>
                </div>
                {/* <div style={style.container}>
                  <div style={style.content}>Discount Amount : </div>
                  <div
                    className={`input-container${
                      input.paymentInformation.adjustment.hasError
                        ? " error"
                        : ""
                    }`}
                  >
                    <MaskedTextInput
                      guide={true}
                      mask={amountMask}
                      name="adjustment"
                      placeholder="$0"
                      placeholderChar={"\u2000"}
                      type="text"
                      onBlur={() => this.handleInputBlur("adjustment")}
                      value={input.paymentInformation.adjustment.value}
                      onChange={(event) =>
                        this.handlePaymentInfoInputChange({
                          adjustment: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    {input.paymentInformation.adjustment.hasError
                      ? this.renderErrorMessage("adjustment")
                      : null}
                  </div>
                </div> */}
                <div style={style.container}>
                  <div style={style.content}>Payment Type : </div>
                  <div
                    className={`input-container${
                      input.paymentInformation.paymentType.hasError
                        ? " error"
                        : ""
                    }`}
                  >
                    <Select
                      inputProps={{
                        autoComplete: "none",
                        autoCorrect: "off",
                        spellCheck: "off",
                      }}
                      className="form-select"
                      name="Select"
                      value={input.paymentInformation.paymentType.value}
                      placeholder="Select payment type"
                      options={[
                        { value: "Cash", label: "Cash" },
                        { value: "Cheque", label: "Check" },
                        { value: "CreditCard", label: "Credit Card" },
                      ]}
                      resetValue=""
                      onChange={(event) =>
                        this.handlePaymentInfoInputChange({
                          paymentType: { value: event.value, hasError: false },
                        })
                      }
                    />
                    {input.paymentInformation.paymentType.hasError
                      ? this.renderErrorMessage("paymentType")
                      : null}
                  </div>
                </div>
                <div>
                  <div style={style.container}>
                    <div style={style.content}>Payment Date : </div>
                    <div
                      className={`input-container${
                        input.paymentInformation.date_of_payment.hasError
                          ? " error"
                          : ""
                      }`}
                    >
                      <MaskedTextInput
                        guide={true}
                        mask={[
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        name="birthday"
                        placeholder="mm/dd/yyyy"
                        placeholderChar={"\u2000"}
                        type="text"
                        value={input.paymentInformation.date_of_payment.value}
                        onChange={(event) =>
                          this.handlePaymentInfoInputChange({
                            date_of_payment: {
                              value: event.target.value,
                              hasError: false,
                            },
                          })
                        }
                      />
                      {input.paymentInformation.date_of_payment.hasError
                        ? this.renderErrorMessage("date_of_payment")
                        : null}
                    </div>
                  </div>
                  <div style={style.container}>
                    <div style={style.content}>Memo : </div>
                    <div
                      className={`input-container${
                        input.paymentInformation.memo.hasError ? " error" : ""
                      }`}
                    >
                      <TextareaAutosize
                        name="memo"
                        type="textarea"
                        placeholder="Your memo here..."
                        value={input.paymentInformation.memo.value}
                        onChange={(event) =>
                          this.handlePaymentInfoInputChange({
                            memo: {
                              value: event.target.value,
                              hasError: false,
                            },
                          })
                        }
                      />
                      {input.paymentInformation.memo.hasError
                        ? this.renderErrorMessage("memo")
                        : null}
                    </div>
                  </div>
                  <div className="admin-form-row address-document">
                    <div className={`input-container error`}>
                      {paymentDocument ? (
                        <div>
                          <Popup
                            style={{
                              transform: `translate(50%,-50%)`,
                              minWidth: `auto`,
                            }}
                            trigger={
                              <button>
                                {" "}
                                <img
                                  alt="document"
                                  src={iconImage}
                                  width="50"
                                  height="50"
                                />{" "}
                              </button>
                            }
                            position="top center"
                          >
                            <div>
                              {paymentDocument ? (
                                paymentDocument.document ? (
                                  paymentDocument.document.indexOf("pdf") !==
                                  -1 ? (
                                    <div>
                                      <object
                                        data={paymentDocument.document}
                                        type="application/pdf"
                                        style={{
                                          width: `600px`,
                                          height: `500px`,
                                        }}
                                      >
                                        <embed
                                          src={paymentDocument.document}
                                          type="application/pdf"
                                        />
                                      </object>
                                    </div>
                                  ) : (
                                    <img
                                      alt="document.png"
                                      src={paymentDocument.document}
                                      width="600"
                                      height="500"
                                    />
                                  )
                                ) : (
                                  "No Document Available"
                                )
                              ) : (
                                "No Document Available"
                              )}
                            </div>
                          </Popup>
                        </div>
                      ) : (
                        <div>
                          {/* <FileBase64
                            multiple={true}
                            onDone={this.getFiles.bind(this)}
                          /> */}
                          {input.paymentInformation.document.hasError ? 
                            <div className=" input-error-message" style={{paddingBottom: '10px'}}>{this.state.docErrorMsg}</div> : null}

                          <FileBase64Component
                            acceptDoc=".png, .jpg, .jpeg, .pdf, .bmp, .docx"
                            multiple={true}
                            onDone={this.getFiles.bind(this)}
                          />
                          <div className=" input-error-message">
                            Image upload format (.png, .jpg, .jpeg, .bmp, .pdf){" "}
                            <br /> Size must be less than 1 MB <br />
                            <br />{" "}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-actions">
                <a
                  id="form-1"
                  className="cta mg-brand2-color"
                  onClick={(event) => this.handleMakePaymentClick(event)}
                >
                  {input.paymentInformation.paymentType.value === "CreditCard"
                    ? " Next » "
                    : "Accept Payment"}
                </a>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <div style={style.container}>
                  <div style={style.content}>Name on Card : </div>
                  <div
                    className={`input-container${
                      input.CardInformation.nameOnCard.hasError ? " error" : ""
                    }`}
                  >
                    <input
                      type="text"
                      id="nameOnCard"
                      placeholder="Enter Name"
                      value={input.CardInformation.nameOnCard.value}
                      onChange={(event) =>
                        this.handleInputChange({
                          nameOnCard: {
                            value: event.target.value,
                            hasError: false,
                            empty: event.target.value.length > 0 ? false : true,
                          },
                        })
                      }
                    />
                    {input.CardInformation.nameOnCard.hasError
                      ? this.renderErrorMessage("nameOnCard")
                      : null}
                  </div>
                </div>
                <div>
                  <div style={style.container}>
                    <div style={style.content}>Credit Card Number : </div>
                    <div
                      className={`input-container${
                        input.CardInformation.cardNumber.hasError
                          ? " error"
                          : ""
                      }`}
                    >
                      <div style={borderBottom}>
                        <CardNumberElement
                          style={CCstyle}
                          placeholder="1234 5678 1234 5678"
                          placeholderChar={"\u2000"}
                          onChange={(event) =>
                            this.handleInputChange({
                              cardNumber: {
                                empty: event.empty,
                                hasError: event.error,
                              },
                            })
                          }
                          elementRef={(element) => (this._element = element)}
                        />
                      </div>
                      {input.CardInformation.cardNumber.hasError
                        ? this.renderErrorMessage("cardNumber")
                        : null}
                    </div>
                  </div>
                  <div style={style.container}>
                    <div style={style.content}>Expiration Date : </div>
                    <div
                      className={`input-container${
                        input.CardInformation.cardExpirationDate.hasError
                          ? " error"
                          : ""
                      }`}
                    >
                      <div style={borderBottom}>
                        <CardExpiryElement
                          style={CCstyle}
                          placeholder="01/20"
                          placeholderChar={"\u2000"}
                          onChange={(event) =>
                            this.handleInputChange({
                              cardExpirationDate: {
                                empty: event.empty,
                                hasError: event.error,
                              },
                            })
                          }
                          elementRef={(element) => (this._element = element)}
                        />
                      </div>
                      {input.CardInformation.cardExpirationDate.hasError
                        ? this.renderErrorMessage("cardExpirationDate")
                        : null}
                    </div>
                  </div>
                  <div style={style.container}>
                    <div style={style.content}>CVV : </div>
                    <div
                      className={`input-container${
                        input.CardInformation.cvc.hasError ? " error" : ""
                      }`}
                    >
                      <div style={borderBottom}>
                        <CardCVCElement
                          style={CCstyle}
                          placeholder="***"
                          value={input.CardInformation.cvc.value}
                          onChange={(event) =>
                            this.handleInputChange({
                              cvc: {
                                empty: event.empty,
                                hasError: event.error,
                              },
                            })
                          }
                          elementRef={(element) => (this._element = element)}
                        />
                      </div>
                      {input.CardInformation.cvc.hasError
                        ? this.renderErrorMessage("cvc")
                        : null}
                    </div>
                  </div>
                  <div style={style.container}>
                    <div style={style.content}>Billing Zip Code : </div>
                    <div
                      className={`input-container${
                        input.CardInformation.cardZip.hasError ? " error" : ""
                      }`}
                    >
                      <div style={borderBottom}>
                        <PostalCodeElement
                          style={CCstyle}
                          placeholder="12345"
                          value={input.CardInformation.cardZip.value}
                          onChange={(event) =>
                            this.handleInputChange({
                              cardZip: {
                                empty: event.empty,
                                hasError: event.error,
                              },
                            })
                          }
                        />
                      </div>
                      {input.CardInformation.cardZip.hasError
                        ? this.renderErrorMessage("cardZip")
                        : null}
                    </div>
                  </div>
                </div>
              </div>
              <div id="submit-form" className="modal-actions">
                <a
                  className="cta mg-brand2-color"
                  onClick={(event) => this.handleSubmit(event)}
                >
                  Accept Payment
                </a>
              </div>
            </div>
          )}
        </form>
      </Modal>
    );
  }
}

const style = {
  container: { display: "flex" },
  content: { width: "60%", paddingTop: `10px` },
};

export default injectStripe(MakePaymentModal);
