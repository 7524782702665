import React, { Component } from 'react';
import { withRouter, Redirect } from "react-router-dom";

// Packages
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import TextareaAutosize from 'react-autosize-textarea';
import MaskedTextInput from 'react-text-mask';

// API
import * as API  from '../../../../utils/api';

// Components
import Modal from '../Modal';

// Utils
import stateDataShortNames from '../../../../utils/stateDataShortNames.json';
import {
  convertCreditorsToArrayForSelectComponent,
  getStrippedDollarAmount,
  numberMask,
  isValidDate,
  validateEmail,
  showToast
} from '../../../../utils/helpers';

class AddAccountModal extends Component {
  state = {
    input: {
      consumerInformation: {
        first_name: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        last_name: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        email: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        phone_number: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        date_of_birth: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        ssn: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        street_address_primary: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        street_address_secondary: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        city: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        state: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        zipcode: {
          value: "",
          hasError: false,
          isRequired: false,
        },
      },
      debtInformation: {
        creditor_id: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        total_in_dollars: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        date_sent: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        additional_details: {
          value: "",
          hasError: false,
          isRequired: false,
        },
      }
    },
    slide: 1,
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200
    }
  }

  componentDidMount () {
    API.fetchAllCreditors(this.props.user).then(data => {

      let isAccessDenied =  (data && data.message && data.message == "Access denied.") ? true : false

      if (((typeof data != 'undefined') || (data != null)) && !data.error && !isAccessDenied) {
          const creditorsForSelectField = [...data.creditors];

          let creditorsArray = convertCreditorsToArrayForSelectComponent(creditorsForSelectField);
          this.setState(state => ({
            creditors: creditorsArray,
            isLoading: false
          }))
      }else{
          this.setState(state => ({
                ...state,
                error: {
                  hasAnyError: true,
                  statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
                }
          }))
      }
    });
  }

  handleModalClose = () => {
    this.props.hideModal();
    this.props.history.push("/admin/accounts");
  }

  handleConsumerInfoInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        consumerInformation: {
          ...state.input.consumerInformation,
          ...newPartialInput,
        }
      }
    }))
  }

  handleCreditorInfoInputChange = (newPartialInput) => {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        debtInformation: {
          ...state.input.debtInformation,
          ...newPartialInput,
        }
      }
    }))
  }

  getNumberFromPhoneString = (phoneString) => {
    let strippedNumber = phoneString.replace(/\(|\)|\s|-/g, '');
    return strippedNumber;
  }

  toNextSlide = () => {
    this.scrollToTop();

    if(this.validateForm('consumerInfo')) {
      this.setState(state => ({
        ...state,
        slide: this.state.slide + 1
      }))
    } else {
      this.setFormErrorState('consumerInfo');
    }

  }

  toPreviousSlide = () => {
    this.setState(state => ({
      ...state,
      slide: this.state.slide - 1
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const { input } = this.state;

    if(this.validateForm('debtInfo')) {
     let consumerForSubmission = {
       first_name: input.consumerInformation.first_name.value,
       last_name: input.consumerInformation.last_name.value,
       email: input.consumerInformation.email.value,
       phone_number: this.getNumberFromPhoneString(input.consumerInformation.phone_number.value),
       date_of_birth: input.consumerInformation.date_of_birth.value,
       ssn: input.consumerInformation.ssn.value,
       street_address_primary: input.consumerInformation.street_address_primary.value,
       street_address_secondary: input.consumerInformation.street_address_secondary.value,
       city: input.consumerInformation.city.value,
       state: input.consumerInformation.state.value,
       zipcode: input.consumerInformation.zipcode.value,
       debts_attributes: [
         {
           creditor_id: input.debtInformation.creditor_id.value,
           date_sent: input.debtInformation.date_sent.value,
           total_in_dollars: getStrippedDollarAmount(input.debtInformation.total_in_dollars.value).toFixed(2),
           additional_details: input.debtInformation.additional_details.value
         }
       ]
     }

     API.createConsumer(this.props.user, consumerForSubmission).then(data => {
       this.scrollToTop();
       if (((typeof data != 'undefined') || (data != null)) && !data.error) {
           if (data.message) {
            this.props.updateModalState(true, 'ERROR', {message: data.message});
           } else {
            let optionalProps = {
              account: consumerForSubmission
            }
            this.props.updateModalState(true, 'ACCOUNT_CREATED', optionalProps);
           }
       }else{
           this.setState(state => ({
                   ...state,
                   error: {
                    hasAnyError: true,
                    statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
                  }
           }))
       }
     });
    } else {
     this.setFormErrorState('debtInfo');
    }
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  hasOnlyDigits = (string) => {
    return /^\d+$/.test(string);
  }

  validateForm = (formType) => {
    let errorsArePresent = false;

      // Determine which input group to check for errors
      let inputs =  formType === 'consumerInfo' ?
        {...this.state.input.consumerInformation}
        : {...this.state.input.debtInformation};

      // Check inputs for errors
      Object.entries(inputs).forEach(([key, value]) => {
        if (key === 'date_of_birth') {
          if (!isValidDate(value.value) || value.value === '' || value.value === null) {
            errorsArePresent = true;
          }
        } else if (key === 'email') {
          if (!validateEmail(value.value) && value.value !== '') {
            errorsArePresent = true;
          }
        } else if (key === 'ssn') {
          if (!this.hasOnlyDigits(value.value)) {
            errorsArePresent = true;
          }
        } else if (key === 'creditor_id') {
          if (value.value === '') {
            errorsArePresent = true;
          }
        } else if (value.isRequired && value.value === '') {
          errorsArePresent = true;
        }
      });

    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  }

  setFormErrorState = (formType) => {
    let errorsArePresent = false;

    // Determine which input group to check for errors
    let newInputState = formType === 'consumerInfo' ?
      {...this.state.input.consumerInformation}
      : {...this.state.input.debtInformation}

    // Set error state on necessary fields
    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === 'date_of_birth') {
        if (!isValidDate(value.value) || value.value === '' || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === 'email') {
        if (!validateEmail(value.value) && value.value !== '') {
          newInputState[key].hasError = true;
        }
      } else if (key === 'ssn') {
        if (!this.hasOnlyDigits(value.value)) {
          newInputState[key].hasError = true;
        }
      } else if (key === 'creditor_id') {
        if (value.value === '') {
          newInputState[key].hasError = true;
        }
      } else if (value.isRequired && value.value === '') {
        newInputState[key].hasError = true;
      }

      if (newInputState[key].hasError) {
        errorsArePresent = true
      }
    });

    if (formType === 'consumerInfo' && errorsArePresent) {
      this.setState(state => ({
        ...state,
        input: {
          ...state.input,
          consumerInformation: newInputState
        }
      }))
    } else if (formType === 'debtInfo' && errorsArePresent) {
      this.setState(state => ({
        ...state,
        input: {
          ...state.input,
          debtInformation: newInputState
        }
      }))
    }
  }

  renderErrorMessage(input) {
    let message = '';

    if (input === 'name') {
      message = "Please enter a full name";
    } else if (input === 'date_of_birth') {
      message = 'Please enter a valid date';
    } else if (input === 'email') {
      message = 'Please enter a valid email address';
    } else {
      message = 'Please complete this field';
    }

    return (
      <div className="input-error-message">
        {message}
      </div>
    )
  }

  render() {
    let { input, slide, creditors, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    return (
      <Modal
        optionalClasses="add-account-modal"
        title="Add Account"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
      >
        <form
          className="admin-form"
        >
          <header className="modal-slide-header">
            <div className={`modal-slide-header-tab${slide === 1 ? ' active' : ''}`}>
              Consumer's Information
            </div>
            <div className={`modal-slide-header-tab${slide === 2 ? ' active' : ''}`}>
              Debt Information
            </div>
          </header>
          <div className={`modal-slide${slide === 1 ? '' : ' hide'}`}>
            <div className="form-container">
              <div className="form-column">
                <div
                  className={`input-container${input.consumerInformation.first_name.hasError || input.consumerInformation.last_name.hasError ? ' error' : ''}`}
                >
                  <label htmlFor="name">Consumer's Name</label>
                  <div
                    className="input-container multi-input-field"
                  >
                    <input
                      className="multi-input-field-input"
                      name="name"
                      type="text"
                      placeholder="First Name"
                      value={input.consumerInformation.first_name.value}
                      onChange={event => this.handleConsumerInfoInputChange({first_name: {value: event.target.value, hasError: false}})}
                    />
                    <input
                      className="multi-input-field-input"
                      name="name"
                      type="text"
                      placeholder="Last Name"
                      value={input.consumerInformation.last_name.value}
                      onChange={event => this.handleConsumerInfoInputChange({last_name: {value: event.target.value, hasError: false}})}
                    />
                  </div>
                  {input.consumerInformation.first_name.hasError || input.consumerInformation.last_name.hasError ? this.renderErrorMessage('name') : null}
                </div>
                <div
                  className={`input-container${input.consumerInformation.email.hasError ? ' error' : ''}`}
                >
                  <label htmlFor="email">Consumer's Email Address</label>
                  <input
                    name="email"
                    type="email"
                    placeholder="address@email.com"
                    value={input.consumerInformation.email.value}
                    onChange={event => this.handleConsumerInfoInputChange({email: {value: event.target.value, hasError: false}})}
                  />
                  {input.consumerInformation.email.hasError ? this.renderErrorMessage('email') : null}
                </div>
                <div
                  className={`input-container${input.consumerInformation.phone_number.hasError ? ' error' : ''}`}
                >
                  <label htmlFor="phone">Consumer's Phone Number</label>
                  <MaskedTextInput
                    name="phone"
                    mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    placeholder="(555) 555-5555"
                    placeholderChar={'\u2000'}
                    guide={true}
                    type="text"
                    value={input.consumerInformation.phone_number.value}
                    onChange={event => this.handleConsumerInfoInputChange({phone_number: {value: event.target.value, hasError: false}})}
                  />
                </div>
                <div
                  className={`input-container${input.consumerInformation.date_of_birth.hasError ? ' error' : ''}`}
                >
                  <label htmlFor="birthdate">Consumer's Date Of Birth</label>
                  <MaskedTextInput
                    guide={true}
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    name="birthday"
                    placeholder="mm/dd/yyyy"
                    placeholderChar={'\u2000'}
                    type="text"
                    value={input.consumerInformation.date_of_birth.value}
                    onChange={event => this.handleConsumerInfoInputChange({date_of_birth: {value: event.target.value, hasError: false}})}
                  />
                  {input.consumerInformation.date_of_birth.hasError ? this.renderErrorMessage('date_of_birth') : null}
                </div>
                <div
                  className={`input-container${input.consumerInformation.ssn.hasError ? ' error' : ''}`}
                >
                  <label htmlFor="ssn">Last Four of Consumer's SSN</label>
                  <MaskedTextInput
                    guide={true}
                    mask={[/\d/, /\d/, /\d/, /\d/]}
                    name="ssn"
                    placeholder="####"
                    placeholderChar={'\u2000'}
                    type="text"
                    value={input.consumerInformation.ssn.value}
                    onChange={event => this.handleConsumerInfoInputChange({ssn: {value: event.target.value, hasError: false}})}
                  />
                  {input.consumerInformation.ssn.hasError ? this.renderErrorMessage() : null}
                </div>
              </div>
              <div className="form-column">
                <div
                  className={`input-container${input.consumerInformation.street_address_primary.hasError ? ' error' : ''}`}
                >
                  <label htmlFor="streetAddress">Street Address</label>
                  <input
                    name="streetAddress"
                    type="text"
                    placeholder="Enter an Address"
                    value={input.consumerInformation.street_address_primary.value}
                    onChange={event => this.handleConsumerInfoInputChange({street_address_primary: {value: event.target.value, hasError: false}})}
                  />
                  {input.consumerInformation.street_address_primary.hasError ? this.renderErrorMessage() : null}
                </div>
                <div
                  className={`input-container${input.consumerInformation.street_address_secondary.hasError ? ' error' : ''}`}
                >
                  <label htmlFor="unit">Unit/Apt #</label>
                  <input
                    name="unit"
                    type="text"
                    placeholder="Apt. #"
                    value={input.consumerInformation.street_address_secondary.value}
                    onChange={event => this.handleConsumerInfoInputChange({street_address_secondary: {value: event.target.value, hasError: false}})}
                  />
                  {input.consumerInformation.street_address_secondary.hasError ? this.renderErrorMessage() : null}
                </div>
                <div className="city-state-container">
                  <div
                    className={`input-container${input.consumerInformation.city.hasError ? ' error' : ''}`}
                  >
                    <label htmlFor="city">City</label>
                    <input
                      name="city"
                      type="text"
                      placeholder="Enter a City"
                      value={input.consumerInformation.city.value}
                      onChange={event => this.handleConsumerInfoInputChange({city: {value: event.target.value, hasError: false}})}
                    />
                    {input.consumerInformation.city.hasError ? this.renderErrorMessage() : null}
                  </div>
                  <div
                    className={`input-container${input.consumerInformation.state.hasError ? ' error' : ''}`}
                  >
                    <label htmlFor="state">State</label>
                    <Select
                      inputProps={{autoComplete: 'none', autoCorrect: 'off', spellCheck: 'off' }}
                      className="form-select"
                      name="state"
                      value={input.consumerInformation.state.value}
                      placeholder=""
                      options={stateDataShortNames}
                      resetValue=""
                      onChange={event => this.handleConsumerInfoInputChange({
                        state: {value: event.value, hasError: false}}
                      )}
                    />
                    {input.consumerInformation.state.hasError ? this.renderErrorMessage() : null}
                  </div>
                </div>
                <div
                  className={`input-container${input.consumerInformation.zipcode.hasError ? ' error' : ''}`}
                >
                  <label htmlFor="zipcode">Zip Code</label>
                  <MaskedTextInput
                    guide={true}
                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                    name="zipcode"
                    placeholder="90210"
                    placeholderChar={'\u2000'}
                    type="text"
                    value={input.consumerInformation.zipcode.value}
                    onChange={event => this.handleConsumerInfoInputChange({zipcode: {value: event.target.value, hasError: false}})}
                  />
                  {input.consumerInformation.zipcode.hasError ? this.renderErrorMessage() : null}
                </div>
              </div>
            </div>
            <div className="modal-actions">
              <a
                className="cta"
                onClick={this.toNextSlide}
              >
                Next »
              </a>
              <a
                className="modal-link"
                onClick={this.props.hideModal}
              >
                Cancel
              </a>
            </div>
          </div>
          <div className={`modal-slide${slide === 2 ? '' : ' hide'}`}>
            <div className="form-container">
              <div className="form-column">
                <div
                  className={`input-container${input.debtInformation.creditor_id.hasError ? ' error' : ''}`}
                >
                  <label htmlFor="creditorName">
                    Creditor
                  </label>
                  <Select
                    inputProps={{autoComplete: 'none', autoCorrect: 'off', spellCheck: 'off' }}
                    className="form-select"
                    name="creditorName"
                    value={input.debtInformation.creditor_id.value}
                    placeholder="Select a Creditor"
                    options={creditors}
                    resetValue=""
                    onChange={event => this.handleCreditorInfoInputChange(
                      event ?
                        {creditor_id: {value: event.value , hasError: false}}
                        : {creditor_id: {value: '' , hasError: true}}
                    )}
                  />
                  {input.debtInformation.creditor_id.hasError ? this.renderErrorMessage() : null}
                </div>
                <div
                  className={`input-container${input.debtInformation.total_in_dollars.hasError ? ' error' : ''}`}
                >
                  <label htmlFor="creditorTotalDebt">
                    Total Debt
                  </label>
                  <MaskedTextInput
                    guide={true}
                    mask={numberMask}
                    name="creditorTotalDebt"
                    placeholder="$0"
                    placeholderChar={'\u2000'}
                    type="text"
                    value={input.debtInformation.total_in_dollars.value}
                    onChange={event => this.handleCreditorInfoInputChange(
                      {total_in_dollars: {
                        value: event.target.value,
                        hasError: false,
                      }}
                    )}
                  />
                  {input.debtInformation.total_in_dollars.hasError ? this.renderErrorMessage() : null}
                </div>
                <div
                  className={`input-container${input.debtInformation.date_sent.hasError ? ' error' : ''}`}
                >
                  <label htmlFor="dateSent">Open Date</label>
                  <MaskedTextInput
                    guide={true}
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    name="dateSent"
                    placeholder="mm/dd/yyyy"
                    placeholderChar={'\u2000'}
                    type="text"
                    value={input.debtInformation.date_sent.value}
                    onChange={event => this.handleCreditorInfoInputChange({date_sent: {value: event.target.value, hasError: false}})}
                  />
                  {input.debtInformation.date_sent.hasError ? this.renderErrorMessage() : null}
                </div>
              </div>
              <div className="form-column">
                <div
                  className={`input-container${input.debtInformation.additional_details.hasError ? ' error' : ''}`}
                >
                  <label htmlFor="creditorAdditionalDetails">
                    Additional Details
                  </label>
                  <TextareaAutosize
                    name="creditorAdditionalDetails"
                    type="textarea"
                    placeholder="Your message here..."
                    value={input.debtInformation.additional_details.value}
                    onChange={event => this.handleCreditorInfoInputChange({additional_details: {value: event.target.value, hasError: false}})}
                  />
                  {input.debtInformation.additional_details.hasError ? this.renderErrorMessage() : null}
                </div>
              </div>
            </div>
            <div className="modal-actions">
              <a
                className="cta"
                onClick={(event) => this.handleSubmit(event)}
              >
                Add Account »
              </a>
              <a
                className="modal-link"
                onClick={this.toPreviousSlide}
              >
                « Back
              </a>
            </div>
          </div>
        </form>
      </Modal>
    )
  }
}

export default withRouter(AddAccountModal);
