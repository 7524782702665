import React, { Component } from 'react'
import { Redirect }from 'react-router-dom'

// Utils
import * as API  from '../../utils/api'
import AppCanvasLoader from '../App/AppCanvasLoader';

class SignOut extends Component {

  componentWillMount() {
    API.signout(this.props.user).then(() => {
      this.props.handleSignOut()
    })
  }

  render() {
    if (!this.props.isLoggedIn) {
      return <Redirect to={{
        pathname: '/login',
        state: { from: this.props.location }
      }}/>
    } else {
      return <div style={{marginTop: `40%`}}><AppCanvasLoader /></div>
    }
  }
}

export default SignOut