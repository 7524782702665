import React, { PureComponent } from 'react'
import { Helmet } from "react-helmet"
import { Elements, StripeProvider } from 'react-stripe-elements';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';



// Components
import AppCanvasLoader from '../../components/App/AppCanvasLoader'
import AppSectionHeader from '../../components/App/AppSectionHeader'
import EmployeeProfileForm from '../../components/Employee/Forms/EmployeeProfileForm'
import EmployeeProfileSidebar from '../../components/Employee/EmployeeProfileSidebar'
import FlashMessage from '../../components/FlashMessage'
import ErrorFlashMessage from '../../components/ErrorFlashMessage'


// helpers
import { errorMessage, networkMessage, getZohoPagesense } from '../../utils/helpers';



// Utils
import * as API from '../../utils/api'

class EmployeeProfile extends PureComponent {

  state = {
    user: {},
    addressDocument: {},
    communicationPreferences: {},
    isLoading: true,
    showFlash: false,
    hasAnyError: false
  }


  componentDidMount() {
    const { user } = this.props;
    API.getCleintAdminUser(user).then(data => {

      let isAccessDenied =  (data && data.message && data.message == "Access denied.") ? true : false

      if ((typeof data != 'undefined' || data != null) && (!data.errors) && !isAccessDenied) {
        this.setState(state => ({
          user: data.user,
          communicationPreferences: data.communication_preferences,
          isLoading: false
        }))
      } else {
        this.props.updateModalState(true, 'ERROR', { message: data ? data.message : 'Some thing went wrong!' });
      }
    })
    getZohoPagesense();
    ReactGA.pageview('/EmployeeProfile');
  }

  handleSubmit = (clientUpdate) => {
    API.updateClient(this.props.user, clientUpdate).then(data => {
      if ((typeof data !== 'undefined' || data !== null) && (!data.errors)) {
        this.props.updateModalState(true, 'SUCCESS', { message: 'Profile saved successfully.' });
        API.getCleintAdminUser(this.props.user).then(data => {
          if (!data.errors) {
            this.setState(state => ({
              user: data.user,
              communicationPreferences: data.communication_preferences,
              isLoading: false
            }))
          }
        })
      } else {
        this.setState(state => ({
          ...state,
          isLoading: false
        }))
        this.props.updateModalState(true, 'ERROR', { message: data.message });
      }
    })
  }

  dismissLoader = () => {
    this.setState(state => ({
      ...state,
      isLoading: false
    }))
  }

  handleSubmitCardAction = (stripeToken) => {
    API.createOrUpdateStripeCustomerId(this.props.user, stripeToken).then(data => {
      if ((typeof data !== 'undefined' || data !== null) && (data.cardAdded)) {
        this.props.updateModalState(true, 'SUCCESS', { message: data.message });

        API.getUser(this.props.user).then(data => {
          if (!data.errors) {
            this.setState(state => ({
              user: data.user,
              communicationPreferences: data.communication_preferences,
              isLoading: false
            }))
          }
        })
      } else {
        //console.log('failed!')
        this.props.updateModalState(true, 'ERROR', { message: data.message });
      }
    })
  }


  createOrUpdateStripeAccount = (stripeToken) => {
    if (this.state.user.last_4 != null) {
      confirmAlert({
        title: 'Warning!',
        message: 'You have already added card. do you want to replace the card?.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.handleSubmitCardAction(stripeToken)
          },
          {
            label: 'No',
            onClick: () => this.dismissLoader
          }
        ]
      })
    } else {
      this.handleSubmitCardAction(stripeToken);
    }

  }

  removeCardActionClick = () => {
    API.removeCard(this.props.user).then(data => {
      if (typeof data !== 'undefined' || data !== null) {
        if (data.cardRemoved) {
          this.props.updateModalState(true, 'SUCCESS', { message: data.message });
          API.getUser(this.props.user).then(data => {
            if (!data.errors) {
              this.setState(state => ({
                user: data.user,
                communicationPreferences: data.communication_preferences,
                isLoading: false
              }))
            }
          })
        } else {
          this.setState(state => ({
            ...state,
            isLoading: false
          }))
          this.props.updateModalState(true, 'SUCCESS', { message: data.message });
        }
      } else {
        console.log('failed!')
        this.props.updateModalState(true, 'ERROR', { message: data.message });
      }
    })
  }


  render() {
    const { appName, stripePubKey } = this.props

    const { user, addressDocument, isLoading, communicationPreferences } = this.state;

    if (this.state.hasAnyError || user === undefined) {
      this.props.updateModalState(true, 'OTHER_ERROR', this.state.hasAnyError);
      return <Redirect to={{ pathname: '/sign-out' }} />
    }
    return isLoading
      ? (
        <AppCanvasLoader />
      )
      : (
        <div className="consumer-profile">
          <Helmet>
            <title>{appName} | Accounts</title>
          </Helmet>
          <div className="user-portal-collapse-wrap app-sidebar-layout">
            <AppSectionHeader
              title="Profile"
              optionalClassNames="collapse-to-gradient"
            />
            <div className="scrollbar" style={{ height: window.innerHeight * 0.65, paddingBottom: 100 }}>
              <div className="app-sidebar-layout-canvas">
                <EmployeeProfileSidebar
                  user={user}
                  addressDocument={addressDocument}
                  removeCardActionClick={this.removeCardActionClick}
                />
                <div className="admin-profile-app-sidebar-layout-canvas-content">
                  <EmployeeProfileForm
                    user={user}
                    communicationPreferences={communicationPreferences}
                    handleSubmit={this.handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
  }
}

export default EmployeeProfile
