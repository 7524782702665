import React, { Component } from 'react'

// Packages
import moment from 'moment'

// Components
import Modal from '../Modal'
import companyData from '../../../../assets/styles/theme.json';

class OfferAcceptedModal extends Component {

  getOfferStartDate = (date) => {
    if(date && new Date(date) < new Date()) {
      return moment(new Date()).utc().format('MM/DD/YYYY')
    } else {
      return moment(date).utc().format('MM/DD/YYYY')
    }
  }

  render() {
    let { user, offer, fromConsumer } = this.props.optionalProps
    return (
      <Modal
        hideModal={this.props.hideModal}
        optionalClasses="offer-accepted"
        closeOnClickOutside={true}
      >
        <div className="modal-spotlight-illustration"></div>
        <h1 className="modal-heading">
        {fromConsumer ? "You accepted the offer!" : "Offer has been accepted!"}
        </h1>
        { fromConsumer ?
          <p>
          {companyData.name} team will receive a message stating the offer was accepted. Your payment will be submitted on {this.getOfferStartDate(offer.start_date)}.
        </p>
          :<p>
          {user.first_name} will receive a message stating the offer was accepted. You will receive payment beginning on {this.getOfferStartDate(offer.start_date)}.
        </p>}
        <div className="modal-actions">
          <a
            className="cta"
            data-behavior="offer-accept"
            onClick={this.props.hideModal}
          >
            Done
          </a>
        </div>
      </Modal>
    )
  }
}

export default OfferAcceptedModal