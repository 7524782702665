import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import shortid from "shortid";
import userIcon from "../../assets/images/header/question.svg";
import HeaderMenuItem from "../../components/HeaderMenuItem";
import HeaderOverflowMenu from "../../components/ObjectList/HeaderOverflowMenu";
// Components
import Logo from "../../components/Logo";

class ExpressHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowAction: [
        {
          title: "Get Help",
          dataBehavior: "get-help",
          icon: (
            <div className="app-client-header-icon">
              <img src={userIcon} width="24" height="24" />
            </div>
          ),
        },
        {
          title: "Sign In",
          dataBehavior: "sign-in",
          icon: <div></div>,
        },
      ],
    };
  }

  handleClickAction = (item) => {
    let { history } = this.props;
    if (item && item.id) {
      this.setState(
        { showSearchHeader: false, id: "", searchOptions: [] },
        () => {
          history.push(`/client/invoices/${item.id}`);
        }
      );
    }
  };

  handleClick = (event, rowAction) => {
    let { history } = this.props;
    const actionBehavior =
      rowAction && rowAction.dataBehavior ? rowAction.dataBehavior : "";

    switch (actionBehavior) {
      case "get-help":
        history.push(`/help`);
        break;
      case "sign-in":
        history.push(`/`);
        break;
      default:
        alert("Error: Something went wrong");
    }
  };

  clearData = () => {
    //
  }

  overflowMenu = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <HeaderOverflowMenu showThreeDots={true} clearData={this.clearData}>
          {this.state.rowAction.map((rowAction) => {
            return (
              <HeaderMenuItem
                key={shortid.generate()}
                rowAction={rowAction}
                dataBehavior={rowAction.dataBehavior}
                handleClick={(event, rowAction) =>
                  this.handleClick(event, rowAction)
                }
                optionalClasses="drop-down-options"
              >
                {rowAction}
              </HeaderMenuItem>
            );
          })}
        </HeaderOverflowMenu>
      </div>
    );
  };

  render() {
    let {
      logoPath,
      appName,
      hideMenu
    } = this.props;

    return (
      <header className={`app-client-header consumer-mobile-header`}>
        <Logo
          linkPath={logoPath}
          linkTitle={appName}
          hideNav={true}
          accountHolder="consumer"
        />
        {!hideMenu && <div className={`app-client-header-content`}>
          <div className="overflow-menu">{this.overflowMenu()}</div>
          <div className="app-menu">
            <div className="app-client-header-menu">
              <a
                title="Get Help"
                className="social-link header-link"
                href="/help"
              >
                <img src={userIcon} width="24" height="24" />
              </a>
              <Link
                className="app-client-header-user-nav-link cl-nav-link"
                to="/"
              >
                Sign In
              </Link>
            </div>
          </div>
        </div>}
      </header>
    );
  }
}

export default withRouter(ExpressHeader);
