import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import AppCanvasLoader from "../../../../components/App/AppCanvasLoader";
// API
import * as API from "../../../../utils/api";
import { showToast } from "../../../../utils/helpers";
import Modal from "../Modal";

class AssignEmployee extends Component {
  state = {
    employees: [],
    input: {
      employee_name: {
        value: "",
        id: "",
        hasError: false,
      },
      employee_email: {
        value: "",
        hasError: false,
      },
    },
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    showFlash: false,
    flashMessage: "",
    disable: false,
  };

  componentDidMount() {
    if (this.props.optionalProps.assign === "consumer") {
      API.fetchAllUnassignedConsumers(this.props.user).then((data) => {
        if (
          (typeof data != "undefined" || data != null) &&
          data.consumers &&
          !data.error
        ) {
          let employeeArray = this.convertEmployeeToArray(data.consumers);
          this.setState((state) => ({
            employees: employeeArray,
            isLoading: false,
          }));
        } else {
          this.setState({ isLoading: false });
        }
      });
    } else {
      API.fetchAllClientsEmployee(this.props.user).then((data) => {
        if (
          (typeof data != "undefined" || data != null) &&
          data.employees &&
          !data.error
        ) {
          let employeeArray = this.convertEmployeeToArray(data.employees);
          this.setState((state) => ({
            employees: employeeArray,
            isLoading: false,
          }));
        } else {
          this.setState({ isLoading: false });
        }
      });
    }
  }

  convertEmployeeToArray(creditorsArray) {
    let newEmployees = [...creditorsArray];

    for (var i = 0; i < newEmployees.length; i++) {
      newEmployees[i].value = newEmployees[i]["id"];
      delete newEmployees[i].id;
      newEmployees[i].label =
        newEmployees[i]["first_name"] + " " + newEmployees[i]["last_name"];
      delete newEmployees[i].name;
    }

    return newEmployees;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let { user } = this.props;
    if (this.validateForm()) {
      let payload = {};
      if (this.props.optionalProps.assign === "consumer") {
        payload = {
          user_ids: [this.state.input.employee_name.id],
          employee_id: this.props.optionalProps.consumerId,
        };
      } else {
        payload = {
          user_ids: this.props.optionalProps.consumerId,
          employee_id: this.state.input.employee_name.id,
        };
      }
      API.assignEmployeeToConsumer(user, payload).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          if (this.props.optionalProps.assign === "consumer") {
            this.props.updateModalState(true, "SUCCESS", {
              message: "Account(s) assigned successfully.",
            });
          } else {
            this.props.updateModalState(true, "SUCCESS", {
              message: data.message,
            });
          }
          this.props.optionalProps.releoadTableData();
        } else {
          this.setState((state) => ({
            ...state,
            hasAnyError: true,
            disable: false,
          }));
        }
      });
    }
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  validateForm = () => {
    let errorsArePresent = false;
    let newInputState = this.state.input;
    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "employee_name") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      }
    });

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";

    if (input === "employee_name") {
      message = "Please select a employee";
    } else if (input === "employee_email") {
      message = "Please enter a valid email";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  handleModalClose = () => {
    this.props.hideModal();
  };

  dismissFlash = () => {
    this.handleModalClose();
    this.setState((state) => ({
      ...state,
      showFlash: false,
      flashMessage: "",
    }));
  };

  render() {
    let { employees, input, error, isLoading } = this.state;
    let { optionalProps } = this.props;
    let isConsumer = optionalProps.assign === "consumer" ? true : false;
    const style = {
      container: { display: "flex" },
      content: { width: "60%", paddingTop: `10px` },
      label: { width: "40%", paddingTop: `10px` },
    };

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <Modal
        title={isConsumer ? "Assign Consumer" : "Assign Employee"}
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        style={{ maxWidth: `35em` }}
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <form>
            <div style={{ marginTop: "2em" }}>
              <div style={style.container}>
                <div style={style.content}>Account Name :</div>
                <div
                  className={`input-container${
                    input.employee_name.hasError ? " error" : ""
                  }`}
                >
                  <Select
                    inputProps={{
                      autoComplete: "none",
                      autoCorrect: "off",
                      spellCheck: "off",
                    }}
                    className="form-select"
                    name="employeeName"
                    value={input.employee_name.id}
                    placeholder={"Select An Account"}
                    options={employees}
                    resetValue=""
                    onChange={(event) =>
                      this.handleInputChange({
                        employee_name: {
                          id: event.value,
                          value: event.label,
                          hasError: false,
                        },
                        employee_email: { value: event.email, hasError: false },
                      })
                    }
                  />
                  {input.employee_name.hasError
                    ? this.renderErrorMessage("employee_name")
                    : null}
                </div>
              </div>
              {/* <div style={style.container}>
                                <div style={style.content}>{isConsumer ? "Consumer's Email:" : "Employee's Email:"} </div>
                                <div
                                    className={`input-container${input.employee_email.hasError ? ' error' : ''}`}
                                >
                                    <input
                                        name="email"
                                        type="email"
                                        readOnly={true}
                                        placeholder={isConsumer ? "Consumer email:" : "Employee email:"}
                                        value={input.employee_email.value}
                                        onChange={event => this.handleInputChange(
                                            { employee_email: { value: event.target.value, hasError: false } }
                                        )}
                                    />
                                    {input.employee_email.hasError ? this.renderErrorMessage('employee_email') : null}
                                </div>
                            </div> */}
            </div>
            <div className="modal-actions">
              <a
                className="cta mg-brand2-color"
                onClick={(event) =>
                  this.state.disable
                    ? event.preventDefault()
                    : this.handleSubmit(event)
                }
              >
                Submit
              </a>
            </div>
          </form>
        )}
      </Modal>
    );
  }
}

export default withRouter(AssignEmployee);
