import currencyFormatter from "currency-formatter";
import moment from "moment";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Elements, StripeProvider } from "react-stripe-elements";
import Panel from "../../components/App/Panel";
import ClientsLogo from "../../components/Consumer/ClientsLogo";
import ConsumerAddAccountForm from "../../components/Consumer/ConsumerAddAccountForm";
import ConsumerSidebar from "../../components/Consumer/ConsumerSidebar";
import ACHPlaidLink from "../../components/Consumer/ACHPlaidLink";
import * as API from "../../utils/api";
import companyData from "../../assets/styles/theme.json";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";

const styles = {
  tabPanel: {
    display: "flex",
    marginBottom: "1.5em",
  },
  button: {
    width: "auto",
    paddingLeft: "1.2em",
    paddingRight: "1.2em",
    margin: 0,
  },
  checkboxDiv: {
    display: "flex",
    margin: "0.5em 0px",
  },
  checkbox: {
    WebkitAppearance: `checkbox`,
    width: `16px`,
    marginRight: `10px`,
  },
  acceptAgr: {
    width: "100%",
    lineHeight: "120%",
    marginTop: -2,
    fontSize: 12,
    fontStyle: "italic",
    fontWeight: "bold",
  },
  secText: {
    lineHeight: "120%",
  },
  primText: {
    fontWeight: 600,
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "#000",
    opacity: 0.3,
  },
  error: {
    fontSize: "1em",
    color: "#fb5c82",
    fontFamily: "sofia_pro_bold",
    marginTop: "0.4em",
  },
};

class ExpressPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: {
        value: "",
        hasError: false,
      },
      isCheck: false,
      plaidStatus: true,
      paymentMethod: "ACH",
      paymentDetails: [],
      showBankDetails: false,
      isACH: false,
      isCC: false,
      isPaymentPortal: false,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      isDiscountApplied: false,
      discountAmount: 0.0
    };
  }

  componentDidMount() {
    this.reloadData();
    this.checkDiscount();
  }

  reloadData = () => {
    this.expressPlaidStatus();
    this.getExpressPaymentDetails();
  };

  getExpressPaymentDetails = () => {
    API.getExpressPaymentDetails(this.props.invoiceDetails.id, true).then((data) => { 
      if (data && data.status_code && data.status_code == 200) {
        this.setState({ paymentDetails: data.data }, () => {
          let details = this.state.paymentDetails;
          if (
            details &&
            details.payment_preferences &&
            details.payment_preferences.payment_portal
          ) {
            this.setState({ isPaymentPortal: true });
            let flag = true;
            if (details.payment_preferences.credit_card) {
              this.setState({ paymentMethod: "CC", isCC: true });
              flag = false;
            }
            if (details.payment_preferences.ach) {
              this.setState({ paymentMethod: "ACH", isACH: true });
              flag = false;
            }
            if (flag) {
              this.setState({ paymentMethod: "", showBankDetails: true });
            }
          }
        });
      } else {
        this.props.updateModalState(true, "ERROR", { message: data.message });
      }
    });
  };

  expressPlaidStatus = () => {
    API.expressPlaidStatus(this.props.invoiceDetails.id).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.status_code &&
        data.status_code == 200
      ) {
        if (data.data) {
          this.setState({ plaidStatus: data.data.plaid_verified });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  expressCreatePlaidPayment = () => {
    if (this.validateForm()) {
      let { paymentDetails } = this.state;
      let ind = this.getCardId("bank_account");
      this.setState({ isLoading: true });
      let { invoiceDetails } = this.props;
      let payload = {
        payment_amount: this.state.amount.value,
        invoice_id: this.props.invoiceDetails.id,
        source_id:
          ind > -1 ? paymentDetails.payment_sources[ind].source_id : null,
      };

      API.expressCreatePlaidPayment(payload).then((data) => {
        if (data && data.status_code) {
          if (data.status_code == 200) {
            this.props.updateModalState(true, "SUCCESS", {
              message: data.message,
            });
            this.getExpressPaymentDetails();
            this.props.updateAmount(
              invoiceDetails.invoice_number,
              invoiceDetails.Total_amt,
              true
            );
          } else {
            this.props.updateModalState(true, "ERROR", {
              message: data.message,
            });
          }
          this.state.amount.value = "";
          this.state.amount.hasError = false;
          this.setState({
            amount: this.state.amount,
            isCheck: false,
            isLoading: false,
          });
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      });
    }
  };

  validateForm = () => {
    let { amount } = this.state;
    if (amount.value) {
      amount.hasError = false;
    } else {
      amount.hasError = true;
    }
    this.setState({ amount });
    return !amount.hasError;
  };

  expressSendPlaidData = (token, metadata) => {
    let payload = {
      public_token: token,
      metadata: metadata,
      payment_amount: this.state.amount.value,
      invoice_id: this.props.invoiceDetails.id,
    };
    API.expressSendPlaidData(payload).then((data) => {
      if (data && data.status_code) {
        if (data.status_code == 200) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
        this.state.amount.value = "";
        this.state.amount.hasError = false;
        this.reloadData();
        this.setState({
          amount: this.state.amount,
          isCheck: false,
          isLoading: false,
        });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  toggleLoading = (flag) => {
    this.setState({ isLoading: flag });
  };

  handleFilterChange = (type) => {
    this.setState({ paymentMethod: type });
  };

  handleInputBlur = () => {
    let { amount } = this.state;
    if (amount.value) {
      amount.value = parseFloat(amount.value).toFixed(2);
      this.setState({ amount: this.state.amount });
    }
  };

  handleInputChange(newPartialInput) {
    if (!newPartialInput.amount.value) {
      this.setState((state) => ({
        ...state,
        ...newPartialInput,
      }));
    } else {
      var number = newPartialInput.amount.value.split(".");
      if (number && number.length > 1) {
        if (number[1].length > 2) {
          newPartialInput.amount.value = parseFloat(
            newPartialInput.amount.value
          ).toFixed(2);
        }
      }
      if (
        parseInt(newPartialInput.amount.value) <=
        parseInt(this.props.invoiceDetails.balance)
      ) {
        this.setState((state) => ({
          ...state,
          ...newPartialInput,
        }));
      }
    }
  }

  ConsumerMakePayment = () => {
    // API.consumerMakePayment(this.props.user, payload).then((data) => {
    //   if (data && data.status_code && data.status_code == 200) {
    //     this.setState({ amount: { value: "" }, isSubmit: false });
    //     this.props.updateModalState(true, "SUCCESS", {
    //       message: data.message,
    //     });
    //     this.props.getInvoiceDetails();
    //     this.getCardList();
    //   } else {
    //     this.setState({ amount: "", isSubmit: false });
    //     this.props.updateModalState(true, "ERROR", {
    //       message: data.message ? data.message : data.error,
    //     });
    //   }
    // });
  };

  checkDiscount = () => {
    let { invoiceDetails } = this.props
    console.log('invoiceDetails on payment page ==> ', invoiceDetails)
    //logic for Early payer discount
    let finalEarlyPayerDisc = 0.0;
    let sCT = 0;
    let targetDate;
    let earlyPayerInfoFlag = false;
    if (invoiceDetails && invoiceDetails.invoice_lines && invoiceDetails.invoice_lines.length) {
      invoiceDetails.invoice_lines.map((line) => {
        if (line.item_name && line.item_name.includes('Early Payer Dis')) {
          finalEarlyPayerDisc = parseFloat(line.amount);
          sCT++;
        }
      });

      if (sCT === 0) {
        if (invoiceDetails && invoiceDetails.criteria_discount_days && invoiceDetails.criteria_discount_days > 0) {
          let startdate = invoiceDetails.date;
          // var new_date = moment(startdate, "YYYY-MM-DD").add(5, "days");
          let nn = moment(moment(startdate).add(invoiceDetails.criteria_discount_days, 'd').format('YYYY/MM/DD'))
          let today = new Date();
          targetDate = moment(nn);
          var month = today.getUTCMonth() + 1; //months from 1-12
          var day = today.getUTCDate();
          var year = today.getUTCFullYear();

          let newdate = year + "/" + month + "/" + day;
          let momentToday = moment(newdate);
          let diffNumber = targetDate.diff(momentToday, 'days');
          if (diffNumber >= 0) {
            let value = 0.0;
            if (invoiceDetails && invoiceDetails.criteria_discount_percent_based === true) {

              value = parseFloat(invoiceDetails.total_amount) / 100;
              value = value * parseFloat(invoiceDetails.criteria_discount)

            } else if (invoiceDetails && invoiceDetails.criteria_discount_percent_based === false) {
              value = invoiceDetails.criteria_discount;
            }
            earlyPayerInfoFlag = true;
            
            let discAmount = 0.0;
            if(invoiceDetails && invoiceDetails.criteria_discount_percent_based === true){
              finalEarlyPayerDisc = parseFloat(invoiceDetails.total_amount) * (parseFloat(invoiceDetails.criteria_discount) / 100);
            } else {
              finalEarlyPayerDisc = invoiceDetails.criteria_discount
              // this.setState({isDiscountApplied: true, discountAmount: discAmount}) 
            }
          }
        }
      }
    }
    
    return {
      isApplied: parseFloat(invoiceDetails.balance).toFixed(2) > 0 ? earlyPayerInfoFlag : false,
      amount: finalEarlyPayerDisc,
      discountDate: targetDate
    };
  }

  sidebarContent = () => {
    const { invoiceDetails } = this.props;
    let discObj = this.checkDiscount();
    let isDiscountApplied = discObj.isApplied
    let discountDate = discObj.discountDate
    return (
      <div className="consumer-sidebar">
        <a
          className="app-section-header-back-link"
          style={{ cursor: "pointer", marginBottom: 0, fontSize: 18 }}
          onClick={() => this.props.toggleMakePayment(false)}
        >
          « Back
        </a>
        <h1
          className="app-section-header-heading side-hed-col"
          style={{ marginBottom: "0.5em" }}
        >
          Payments
        </h1>
        <label style={{ marginBottom: "0.3em", fontSize: 16 }}>
          Balance Due
        </label>
        <div
          className="invoice-amt-owed payment-balance"
          style={{ marginBottom: "0.6em" }}
        >
          {invoiceDetails.balance
            ? currencyFormatter.format(invoiceDetails.balance, { code: "USD" })
            : "0.00"}
        </div>
        <label style={{ marginBottom: "0.3em", fontSize: 16 }}>Due Date</label>
        <div
          className="invoice-amt-owed payment-balance"
          style={{ marginBottom: "1em" }}
        >
          {invoiceDetails.balance
            ? moment(invoiceDetails.due_date).format("MM/DD/YYYY")
            : ""}
        </div>

        {isDiscountApplied
        ? <div>
            <label style={{ marginBottom: "0.3em", fontSize: 16 }}>Discount till</label>
            <div
              className="invoice-amt-owed payment-balance"
              style={{ marginBottom: "1em" }}
            >
              {
                invoiceDetails.criteria_discount_days
                ? moment(discountDate).format("MM/DD/YYYY")
                : "NA"
              }
            </div>
          </div>
        : null}

      </div>
    );
  };

  createOrUpdateStripeAccount = (stripeToken) => {
    if (stripeToken) {
      this.handleSubmitCardAction(stripeToken);
    }
  };

  handleSubmitCardAction = (stripeToken) => {
    this.setState({ isSubmit: true, isLoading: true });
    let payload = {
      invoice_id: this.props.invoiceDetails.id,
      cardToken: stripeToken,
    };
    API.addCreaditCardFromExpress(payload).then((data) => {
      if (data && data.cardAdded) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.getExpressPaymentDetails();
      } else {
        this.props.updateModalState(true, "ERROR", { message: data.error });
      }
      this.setState({ isLoading: false });
    });
  };

  getCardId = (type) => {
    let { paymentDetails } = this.state;
    //get card source id
    let ind =
      paymentDetails && paymentDetails.payment_sources
        ? paymentDetails.payment_sources.findIndex((i) => i["object"] == type)
        : -1;
    return ind;
  };

  handleDisconnect = () => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: "Are you sure you want to disconnect your bank?",
      confirmClick: this.callDisconnectBank,
      closeOnClickOutside: true,
    });
  };

  callDisconnectBank = () => {
    let { invoiceDetails } = this.props;
    let { paymentDetails } = this.state;

    //get card source id
    let ind = this.getCardId("bank_account");
    let payload = {
      invoice_id: invoiceDetails.id,
      card_id: ind > -1 ? paymentDetails.payment_sources[ind].source_id : null,
    };

    this.setState({ isLoading: true });
    API.expressDisconnectBank(payload).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.getExpressPaymentDetails();
        this.reloadData();
      } else {
        this.props.updateModalState(true, "ERROR", { message: data.message });
      }
      this.setState({ isLoading: false });
    });
  };

  removeCard = () => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: "Are you sure you want to remove your card?",
      confirmClick: this.callRemoveCard,
      closeOnClickOutside: true,
    });
  };

  callRemoveCard = () => {
    let { invoiceDetails } = this.props;
    let { paymentDetails } = this.state;

    //get card source id
    let ind = this.getCardId("card");
    let payload = {
      invoice_id: invoiceDetails.id,
      card_id: ind > -1 ? paymentDetails.payment_sources[ind].source_id : null,
    };

    this.setState({ isLoading: true });
    API.expressRemoveCard(payload).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.getExpressPaymentDetails();
      } else {
        this.props.updateModalState(true, "ERROR", { message: data.message });
      }
      this.setState({ isLoading: false });
    });
  };

  acceptPayment = () => {
    let { invoiceDetails } = this.props;
    let { amount } = this.state;

    //get souce id
    let { paymentDetails } = this.state;
    let ind = this.getCardId("card");
    let payload = {
      invoice_id: invoiceDetails.id,
      amount: amount.value,
      source_id:
        ind > -1 ? paymentDetails.payment_sources[ind].source_id : null,
    };
    if (amount.value) {
      this.setState({ isSubmit: true, isLoading: true });
      API.expressMakePayment(payload).then((data) => {
        if (data && data.status_code && data.status_code == 200) {
          this.setState({
            amount: { value: "" },
            isSubmit: false,
            isLoading: false,
          });
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
          this.props.updateAmount(
            invoiceDetails.invoice_number,
            invoiceDetails.Total_amt,
            true
          );
          this.getExpressPaymentDetails();
        } else {
          this.setState({ amount: "", isSubmit: false, isLoading: false });
          this.props.updateModalState(true, "ERROR", {
            message: data.message ? data.message : data.error,
          });
        }
      });
    } else {
      this.setState({
        amount: { hasError: true },
        isSubmit: false,
        isLoading: false,
      });
    }
  };

  getTaxAmount = (invoice) => {
    let amount = 0.0;
    if (invoice.tax_and_discount_line && invoice.tax_and_discount_line.length) {
      invoice.tax_and_discount_line.map((item) => {
        if (item.detail_type === "TaxLineDetail") {
          amount = parseFloat(item.amount);
        }
      });
    }
    return amount;
  }
  getSubTotalAmount = (invoice) => {
    let subTotal = 0.0;
    if (invoice.invoice_lines && invoice.invoice_lines.length) {
      invoice.invoice_lines.map((item) => {
        if (item.detail_type === "SalesItemLineDetail" && item.item_name && !item.item_name.includes('Early Payer Dis') && !item.item_name.includes('Slow Payer Penalty') && !item.item_name.includes('Invoice Credit')) {
          item.amount = parseFloat(item.amount);
          subTotal = subTotal + item.amount;
        }
      });
    }
    return subTotal && subTotal > 0 ? subTotal.toFixed(2) : "0.00";
  }

  getInvoiceTotalAmount = (invoice) => {
    let amount = 0.0;
    let tax = this.getTaxAmount(invoice);
    let slowPayer = this.getSlowPayerAmount(invoice);
    let subtotal = this.getSubTotalAmount(invoice);
    let invoiceCred = this.getInvoiceCreditAmount(invoice);

    amount = parseFloat(subtotal) + parseFloat(tax) + parseFloat(slowPayer) - parseFloat(invoiceCred);
    return amount && amount > 0 ? amount.toFixed(2) : "0.00";
  }

  getPaidAmount = (invoice) => {
    let amount = 0.0;
    let taxAmount = this.getTaxAmount(invoice);
    let slowPayer = this.getSlowPayerAmount(invoice);
    let invoiceCred = this.getInvoiceCreditAmount(invoice);
    amount = parseFloat(this.getInvoiceTotalAmount(invoice));
    amount = amount - parseFloat(invoice.balance);
    console.log(`slow payer ==> ${slowPayer}  | invoice cred ==> ${invoiceCred} | ${parseFloat(this.getInvoiceTotalAmount(invoice))}`)
    // if (invoice) {
    //   amount = invoice.Total_amt
    //     ? invoice.balance
    //       ? parseFloat(invoice.Total_amt) - parseFloat(invoice.balance)
    //       : invoice.Total_amt
    //     : invoice.Total_amt;
    // }
    // if(taxAmount > 0){
    //   amount = amount + taxAmount;
    // }

    if (invoice.balance && parseFloat(invoice.balance) === 0) {
      let finalEarlyPayerDisc1 = 0.0;
      let finalEarlyPayerDisc2 = 0.0;
      let sCT = 0;
      if (invoice && invoice.invoice_lines && invoice.invoice_lines.length) {
        invoice.invoice_lines.map((line) => {
          if (line.item_name && line.item_name.includes('Early Payer Dis')) {
            finalEarlyPayerDisc1 = parseFloat(Math.abs(line.amount));
            sCT++;
          }
        });
        let dGCt = 0;
        if (invoice.tax_and_discount_line && invoice.tax_and_discount_line.length) {
          invoice.tax_and_discount_line.map((line) => {
            if (line.item_name && line.item_name.includes('Discounts given')) {
              finalEarlyPayerDisc2 = parseFloat(Math.abs(line.amount));
              dGCt++;
            }
          });
        }
        if (finalEarlyPayerDisc1 !== 0 && sCT > 0) {
          amount = amount - finalEarlyPayerDisc1;
        } else if (finalEarlyPayerDisc2 !== 0 && dGCt > 0) {
          amount = amount - finalEarlyPayerDisc2;
        }
      }
    }
    return amount && amount > 0 ? amount.toFixed(2) : "0.00";
  };

  getPaidEarlyPayerDiscount = (invoice) => {
    let amount = 0;
    if (invoice.tax_and_discount_line && invoice.tax_and_discount_line.length) {
      invoice.tax_and_discount_line.map((line) => {
        if (line.item_name && line.item_name.includes('Discounts given')) {
          amount = parseFloat(line.amount);
        }
      });
    }

    if (invoice && invoice.invoice_lines && invoice.invoice_lines.length) {
      invoice.invoice_lines.map((line) => {
        if (line.item_name && line.item_name.includes('Early Payer Dis')) {
          amount = parseFloat(Math.abs(line.amount));
        }
      });
    }
    return amount && amount > 0 ? amount.toFixed(2) : "0.00";
  }

  getSlowPayerAmount = (invoice) => {
    let amount = 0;
    if (invoice.invoice_lines && invoice.invoice_lines.length) {
      invoice.invoice_lines.map((lineItem) => {
        if (lineItem.item_name === "Slow Payer Penalty") {
          amount = amount + parseFloat(lineItem.amount)
        }
      })
    }
    return amount && amount > 0 ? amount.toFixed(2) : "0.00";
  };

  getInvoiceCreditAmount = (invoice) => {
    let amount = 0;
    if (invoice.invoice_lines && invoice.invoice_lines.length) {
      invoice.invoice_lines.map((lineItem) => {
        if (lineItem.item_name === "Invoice Credit") {
          amount = parseFloat(Math.abs(lineItem.amount))
        }
      })
    }
    return amount && amount > 0 ? amount.toFixed(2) : "0.00";
  };

  creditCardView = () => {
    const { stripePubKey } = this.props;
    return (
      <StripeProvider apiKey={stripePubKey}>
        <Elements>
          <ConsumerAddAccountForm
            amount={this.state.amount.value}
            validateAmount={true}
            updateModalState={this.props.updateModalState}
            handleFormSubmit={this.createOrUpdateStripeAccount}
          />
        </Elements>
      </StripeProvider>
    );
  };

  getBankStatus = (paymentDetails) => {
    let connected = false;
    if (paymentDetails && paymentDetails.payment_sources) {
      paymentDetails.payment_sources.map((item) => {
        if (item.object && item.object == "bank_account") connected = true;
      });
    }
    return this.state.plaidStatus ? connected : false;
  };

  showBankStatus = (paymentDetails) => {
    let style = this.getBankStatus(paymentDetails)
      ? { color: "#409D57", fontFamily: "sofia_pro_bold" }
      : { color: "#fb5c82", fontFamily: "sofia_pro_bold" };

    return (
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          marginBottom: "0.5em",
        }}
      >
        <div style={{ ...styles.primText, lineHeight: "150%", marginRight: 5 }}>Status:</div>
        <div style={style}>
          {this.getBankStatus(paymentDetails) ? "CONNECTED" : "NOT CONNECTED"}
        </div>
        <br />
      </div>
    );
  };

  MakePayment = () => {
    const { stripePubKey, invoiceDetails } = this.props;
    const {
      amount,
      paymentMethod,
      isCheck,
      plaidStatus,
      isCC,
      isACH,
      isPaymentPortal,
      showBankDetails,
      paymentDetails,
    } = this.state;

    let detailsCheck = isPaymentPortal
      ? showBankDetails
        ? false
        : true
      : false;

    //check card added or not, last4 available or not
    let cardInd =
      paymentDetails &&
      paymentDetails.payment_sources &&
      paymentDetails.payment_sources.length
        ? paymentDetails.payment_sources.findIndex(
            (item) => item["object"] == "card"
          )
        : -1;

    let tax_and_discount_line = invoiceDetails.tax_and_discount_line;
    let subTotal = 0;
    let taxData, discountData;
    let total = 0;
    let data = invoiceDetails.invoice_lines;
    let discAmountObj = this.checkDiscount();
    let discAmount = discAmountObj.amount;
    let newDue;

    if (data && data.length) {
      data.map((item) => {
        if (item.detail_type === "SalesItemLineDetail") {
          item.amount = parseFloat(item.amount);
          subTotal = subTotal + item.amount;
        }
      });
    }
    if (tax_and_discount_line && tax_and_discount_line.length) {
      tax_and_discount_line.map((item) => {
        if (item.detail_type === "TaxLineDetail") {
          taxData = parseFloat(item.amount);
        } else if (item.detail_type === "DiscountLineDetail") {
          discountData = parseFloat(item.amount);
        }
      });
    }
    total = subTotal;
    if (taxData) {
      total = total + taxData;
    }
    if (discountData) {
      // total = total - discountData;
      total = total;
    }

    if (parseFloat(discAmount) > 0) {
      newDue = invoiceDetails.balance;
      newDue = parseFloat(invoiceDetails.balance) - parseFloat(discAmount);
      if (newDue > 0) {
        newDue.toFixed(2);
      } else {
        newDue = 0.0;
        newDue.toFixed(2);
      }
    } else {
      newDue = parseFloat(invoiceDetails.balance).toFixed(2);
    }
    const Data = [
      {
        title: "Subtotal",
        value: this.getSubTotalAmount(invoiceDetails),
      },
      { title: "Taxes", value: taxData },
      // { title: "Early Payer Discount", value: discAmount },
      { title: "Invoice Credit", value: this.getInvoiceCreditAmount(invoiceDetails), isSubtract: true },
      { title: "Slow Payer Penalty", value: this.getSlowPayerAmount(invoiceDetails) },
      {
        title: "Invoice Total",
        value: this.getInvoiceTotalAmount(invoiceDetails),
      },
      { title: "Amount Paid", value: `${this.getPaidAmount(invoiceDetails)}`, isSubtract: true },
      {
        title: "Balance Due",
        value:
          invoiceDetails && invoiceDetails.balance
            ? parseFloat(invoiceDetails.balance).toFixed(2)
            : "",
      },
      //  {
      //    title: "Balance Due",
      //    value:
      //      newDue
      //        ? parseFloat(newDue).toFixed(2)
      //        : "",
      //  },
    ];

    if(discAmountObj.isApplied && discAmountObj.amount > 0 && this.getPaidAmount(invoiceDetails) === "0.00") {
      let epObj = {
        title: "Early Payer Discount",
        subTitle: `if paid in full by ${moment(discAmountObj.discountDate).format("MM/DD/YYYY")}`,
        isSubtract: true,
        value: `${discAmountObj.amount}`
      }

      let epBalance = {
        title: "Early Payer Balance Due",
        value: newDue
          ? parseFloat(newDue).toFixed(2)
          : "00.00",
      }
      Data.push(epObj);
      Data.push(epBalance);
    }

    if( invoiceDetails && invoiceDetails.balance && parseFloat(invoiceDetails.balance).toFixed(2) == 0) {
      let amount = this.getPaidEarlyPayerDiscount(invoiceDetails);
      if(parseFloat(amount) > 0) {
        let item = {
          title: "Early Payer Discount",
          value: `${amount}`,
          isSubtract: true,
        }
        Data.splice(6, 0, item);
      }
    }

    return (
      <div className="make-payment-panel" style={{ marginTop: "1.5em" }}>
        <Panel>
          <div
            className={`input-container consumer-input pay-amount-input amount-input${
              amount.value ? " amount-input-value" : ""
            }`}
            style={{ marginTop: 0 }}
          >
            <label>Payment Amount</label>
            {amount.value ? <span>$</span> : null}
            <input
              placeholder="Enter Payment Amount"
              type="number"
              pattern="^(\d+)(,\d{1,2}|.\d{1,2})?$"
              value={amount.value}
              onBlur={this.handleInputBlur}
              onChange={(event) =>
                this.handleInputChange({
                  amount: {
                    value: event.target.value,
                    hasError: false,
                  },
                })
              }
            />
            {amount.hasError && (
              <div style={styles.error}>Please enter payment amount</div>
            )}
          </div>
          {discAmount && parseFloat(discAmount) > 0
          ? <div>
              <div style={{paddingBottom: '15px'}}>
                {'Invoice must be paid in full to receive Early Payer Discount'}
              </div>
            </div>
          : null }

          {Data.map((item, i) => {
            return (
              <div
                style={{
                  display: "flex",
                  padding: 10,
                  backgroundColor: i % 2 == 0 ? "#dbe9f2" : "#FFF",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    fontWeight: item.title == "Balance Due" || item.title == "Early Payer Balance Due" ? 600 : "auto",
                  }}
                >
                  {item.title}
                  <div style={{fontSize: '11px'}}>{item.subTitle ? `(${item.subTitle})` : ''}</div>
                </div>
                <div
                  style={{
                    fontWeight: item.title == "Balance Due" || item.title == "Early Payer Balance Due" ? 600 : "auto",
                  }}
                >
                  {item.isSubtract ? '-' : <span>&nbsp;&nbsp;</span>}
                  ${item.value ? parseFloat(item.value).toFixed(2) : "0.00"}
                </div>
              </div>
            );
          })}
        </Panel>
        {detailsCheck ? (
          <Panel>
            <div
              className={`pay-amount-input amount-input`}
              style={{ marginTop: 0 }}
            >
              <label>Select Payment Method</label>
            </div>
            <div style={styles.tabPanel}>
              {isACH ? (
                <button
                  title="All"
                  className={`task-filter-btns all bt-sp-l${
                    paymentMethod == "ACH" ? " active" : ""
                  }`}
                  style={{ ...styles.button, marginRight: "2em" }}
                  onClick={() => this.handleFilterChange("ACH")}
                >
                  Bank Transfer
                </button>
              ) : null}
              {isCC ? (
                <button
                  title="All"
                  className={`task-filter-btns all bt-sp-l${
                    paymentMethod == "CC" ? " active" : ""
                  }`}
                  style={styles.button}
                  onClick={() => this.handleFilterChange("CC")}
                >
                  Credit Card
                </button>
              ) : null}
            </div>

            {paymentMethod == "ACH" ? (
              <div>
                {this.showBankStatus(paymentDetails)}
                {this.getBankStatus(paymentDetails) ? (
                  <div>
                    <div style={styles.primText}>Your bank is connected:</div>
                    <br />
                    {paymentDetails && paymentDetails.payment_sources
                      ? paymentDetails.payment_sources.map((item) => {
                          if (item.object && item.object == "bank_account") {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <div
                                    style={{
                                      ...styles.primText,
                                      lineHeight: "120%",
                                      fontSize: 14,
                                    }}
                                  >
                                    {item.bank_name}
                                  </div>
                                  <span>xxxxxxx{item.last4}</span>
                                </div>
                                <button
                                  onClick={this.handleDisconnect}
                                  className="cta plaid-disconnect-btn"
                                >
                                  Disconnect
                                </button>
                              </div>
                            );
                          }
                        })
                      : null}
                  </div>
                ) : (
                  <div>
                    <div style={styles.primText}>
                      Connect your bank:
                      <br />
                      <br />
                      <span style={styles.secText}>
                        Easily make payments using ACH bank transfers by
                        connecting to your bank using one of the verification
                        methods below
                      </span>
                    </div>
                    <br />
                    <div style={styles.secText}>
                      Connect immediately with Plaid (immediate) (Recommended)
                      (i) Plaid provides the quickest way to collect and verify
                      your bank information and allows you to make payments
                      immediately.
                    </div>
                  </div>
                )}
                <br />
                <div style={styles.checkboxDiv}>
                  <input
                    name="ach"
                    type="checkbox"
                    checked={isCheck}
                    style={styles.checkbox}
                    onChange={(event) =>
                      this.setState({ isCheck: event.target.checked })
                    }
                  />
                  <div style={styles.acceptAgr}>
                    I authorize {companyData.clientName} to electronically debit
                    my account and, if necessary, electronically credit my
                    account to correct erroneous debits.
                  </div>
                </div>
                <br />
                <ACHPlaidLink
                  auth={false}
                  user={this.props.user}
                  amount={amount.value}
                  invoice_id={invoiceDetails.id}
                  plaidStatus={plaidStatus}
                  isCheck={isCheck}
                  toggleLoading={this.toggleLoading}
                  updateModalState={this.props.updateModalState}
                  sendDataBackToServer={this.expressSendPlaidData}
                  createPlaidPayment={this.expressCreatePlaidPayment}
                />
                {/* <br />
              <br />
              <div style={{ lineHeight: "120%" }}>
                Connect with microdeposits (1-2 Days) (i) two small deposits are
                send to your bank account and will appear on your online bank
                statement with a description that includes ATMS followed by the
                two microdeposit amounts.
              </div>
              <br />
              <button disabled className="cta mg-brand2-color">
                Connect using microdeposits
              </button> */}
              </div>
            ) : stripePubKey && paymentMethod == "CC" ? (
              <div>
                {cardInd > -1 ? (
                  <div>
                    <h4>Credit Card Details</h4>
                    <label style={{ marginBottom: "1em" }}>
                      Card Number: xxxx-xxxx-xxxx-
                      {paymentDetails.payment_sources[cardInd].last4}
                    </label>
                    <a
                      className="text-link"
                      onClick={(e) => this.removeCard(e)}
                    >
                      Remove Card
                    </a>
                    <div style={{ display: "flex", marginTop: "1em" }}>
                      <button
                        id="submit-consumer-paymant"
                        className="cta mg-brand2-color"
                        onClick={(e) =>
                          this.acceptPayment(this.state.amount.value)
                        }
                      >
                        Submit Payment
                      </button>
                    </div>
                  </div>
                ) : (
                  this.creditCardView()
                )}
              </div>
            ) : null}
          </Panel>
        ) : (
          <Panel>
            {paymentDetails &&
              paymentDetails.payment_data &&
              Object.entries(paymentDetails.payment_data).map(
                ([key1, rowData], i) => {
                  if (rowData && rowData.length) {
                    return (
                      <div className="bank-details" key={key1}>
                        <label style={{ marginBottom: "0.3em" }}>{key1}</label>
                        {rowData && rowData.length
                          ? rowData.map((item, index) => {
                              return this.getInputField(item, index);
                            })
                          : null}
                      </div>
                    );
                  }
                }
              )}
          </Panel>
        )}
      </div>
    );
  };

  render() {
    const { invoiceDetails } = this.props;
    const { error, isLoading } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <div className="app-sidebar-layout-canvas">
        <ConsumerSidebar sidebarContent={this.sidebarContent()} exPay={true} />
        <div className="app-sidebar-layout-canvas-content setting-side-canvas big-device-grad scrollbar exp-pay-scroll-inner">
          <ClientsLogo color={"#000"} />
          {/* TODO */}
          <h1 className="app-section-consumer-heading consumer-brand">
            Invoice# {invoiceDetails.invoice_number}
          </h1>
          {this.MakePayment()}
        </div>
        {isLoading ? (
          <div style={styles.loader}>
            <AppCanvasLoader />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ExpressPayment;
