import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// Components
import AppClientHeader from '../../components/App/AppClientHeader';
import ClientsNav from '../../components/Clients/ClientsNav';

import {getZohoPagesense}  from '../../utils/helpers';

class ClientsPortal extends Component {
  static propTypes = {
    location: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    screen: PropTypes.object.isRequired
  }

  componentDidMount(){
   getZohoPagesense();
  }

  render() {
    if (!this.props.isLoggedIn || !this.props.user.client_admin) {
      return <Redirect to={{
        pathname: '/login',
        state: { from: this.props.location }
      }}/>
    }

    return (
      <div className="app-portal">
        <AppClientHeader
          user={ this.props.user }
          appName={ this.props.appName}
          logoPath="/client/accounts/"
          hideNav={true}
          headerTab={this.props.headerTab}
      />
        <div className={`app-portal-canvas consumer-portal canvas-back`}>
          <ClientsNav
            path={ this.props.location }
          />
            <div className="wrap" style={this.props.removePadding ? {padding: 0} : {}}>
              { this.props.screen }
            </div>
        </div>
      </div>
    );
  }
}

export default ClientsPortal;
