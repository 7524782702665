import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
// Components
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import ToggleSwitch from "../../components/ToggleSwitch";
import * as API from "../../utils/api";
import Emitter from "../../utils/event-emitter";

class GeneralSetting extends Component {
  state = {
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    invoiceData: [],
    storedData: [],
    isLoading: true,
  };

  componentDidMount() {
    this.getTasksList();
  }

  reload = () => {
    this.getTasksList();
  };

  getTasksList = () => {
    API.getTasksList(this.props.user).then((data) => {
      Emitter.emit('IS_QUICKBOOK_CONNECTED', data.qb_connected);
      if (
        typeof data != "undefined" ||
        (data != null && data.status_code && data.status_code === 200)
      ) {
        if (data.data) {
          this.setState(
            { invoiceData: data.data, storedData: data.data },
            () => {
              this.formatData();
            }
          );
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  formatData = () => {
    if (this.state.invoiceData && this.state.invoiceData.length) {
      this.state.invoiceData.map((dataItem, index) => {
        dataItem.delivery_method =
          dataItem.delivery_method === "Email"
            ? "Email Only"
            : dataItem.delivery_method === "Letter"
            ? "Letter Only"
            : "Email & Letter";
        if (!dataItem.state) {
          dataItem.is_all_client = true;
          dataItem.state = false;
          dataItem.auto_send = false;
          dataItem.auto_send_on_import = false;
          dataItem.internal = false;
          dataItem.delivery_method = "Email & Letter";
        }
      });
      this.setState({ invoiceData: this.state.invoiceData });
    }
  };

  //calls on row arrow expander click
  rowExpanderClick = (item) => {
    this.setState({ currentItem: item.row });
    let props = {
      item: item.row,
      reload: this.getTasksList,
      user: this.props.user,
    };
    //open Edit Task popup modal
    this.props.updateModalState(true, "EDIT_TASK_CONFIG", props);
  };

  //handle preview eye icon click
  handleViewClick = (e, data) => {
    let props = {
      selectedTemp: data
    };
    //open preview Task popup modal
    this.props.updateModalState(true, "PREVIEW_TASK_CONFIG", props);
  };

  getSelectedConsumers = (data) => {
    let payload = {};
    let { currentItem } = this.state;
    payload.task_id = this.state.currentItem.id;
    payload.is_all_client = false;
    payload.contact_preferences = data;
    this.updateTaskSetting(payload);
  };

  //returns days based on "send_login" flag
  getDays = (days, logic) => {
    let prefix = logic === "before" ? "-" : logic === "after" ? "+" : "";
    if (days && days > 0) {
      if (days === 1) {
        return prefix + days + " Day";
      } else {
        return prefix + days + " Days";
      }
    } else {
      return "0 Days";
    }
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = [
      "Communication Tasks",
      "Task State",
      "Auto Send",
      "Delivery Schedule",
      "Delivery Method",
      "Client Contact Preferences",
      "Preview",
      "expander",
    ];

    columns = data.map((item, index) => {
      return {
        row: item,
        primaryRow: {
          key: "Communication Tasks",
          title: item.name,
        },
        secondaryColumns: [
          {
            title: item.name,
            hideForMobile: true,
          },
          {
            key: "Task State",
            title: <ToggleSwitch isOn={item.state} />,
          },
          {
            key: "Auto Send",
            title: <ToggleSwitch isOn={item.auto_send} />,
          },
          {
            key: "Delivery Schedule",
            title: this.getDays(item.reminder_schedule, item.send_login),
          },
          {
            key: "Delivery Method",
            title: item.delivery_method,
          },
          {
            key: "Client Contact Preferences",
            title: item.is_all_client ? "All Clients" : "Selected",
          },
          {
            key: "Preview",
            title: (
              <a
                className={`object-row-expander`}
                onClick={(event) => this.handleViewClick(event, item)}
                style={{position: "absolute", marginTop: '-8px'}}
              >
                <svg className="icon object-row-view">
                  <use xlinkHref="#view" />
                </svg>
              </a>
            ),
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
          },
        ],
      };
    });

    return {
      headings,
      columns,
    };
  };

  render() {
    const { appName } = this.props;
    const { isLoading, error, invoiceData } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <div style={{ marginTop: "20%" }}>
        <AppCanvasLoader />
      </div>
    ) : (
      <div className="consumer-help">
        <Helmet>
          <title>{appName} | Tasks</title>
        </Helmet>
        <div className="big-device-grad " onFocus={() => this.reload()}>
          <div className="he-hed">Task Configurations</div>
          {/* cardview for mobile device */}
          <ClientsCardView
            data={this.getObjectListData(invoiceData)}
            expanderLabel={"Edit"}
            rowActionClick={(event) => this.rowExpanderClick(event)}
          />
          {/* list view for big devices */}
          <InvoiceObjectList
            data={this.getObjectListData(invoiceData)}
            optionalTableClasses="ts-con-tb"
            rowActionClick={(event) => this.rowExpanderClick(event)}
            hideForMobile={true}
          />
        </div>
      </div>
    );
  }
}

export default GeneralSetting;
