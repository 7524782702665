import React, { Component } from 'react';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement, PostalCodeElement } from 'react-stripe-elements';
import $ from 'jquery';
import { Redirect } from "react-router-dom";
import MaskedTextInput from 'react-text-mask'
import { confirmAlert } from 'react-confirm-alert'; // Import

// API
import * as API from '../../../utils/api';

import { networkMessage, numberMask, getStrippedDollarAmount, showToast } from '../../../utils/helpers'

// Components
import Panel from '../../App/Panel';

class ConsumerCreditCardForm extends Component {

  state = {
    input: {
      nameOnCard: {
        value: "",
        empty: true,
        hasError: false
      },
      cardNumber: {
        empty: true,
        hasError: false
      },
      cardExpirationDate: {
        empty: true,
        hasError: false
      },
      cvc: {
        empty: true,
        hasError: false
      },
      cardZip: {
        empty: true,
        hasError: false
      },
      paymentAmount: {
        value: "",
        empty: false,
        hasError: false
      }
    },
    userData: this.props.user,
    error: {
      hasAnyError: false,
      statusCode: 200
    }
  }

  componentDidMount() {
    API.getUser(this.props.user).then(data => {
      if (((typeof data != 'undefined') || (data != null)) && (!data.error)) {
        this.setState(state => ({
          userData: data.user,
        }))
      } else {
        this.setState(state => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          }
        }))
      }
    })
  }

  handleInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.userData.last_4 && this.state.userData.last_4 != null && this.state.userData.last_4 != undefined) {
      this.props.handleFormSubmit('');
    } else {
      if (this.validateForm()) {
        $('#submit-form').replaceWith('<div class="spinner"></div>');
        setTimeout(() => {
          this._element.focus();
          this._element.blur();
        }, 20);
        this.props.stripe.createToken({ name: this.state.input.nameOnCard.value }).then(({ token }) => {
          this.props.handleFormSubmit(token.id);
        });
      } else {
        this.setFormErrorState();
      }
    }
  }

  handleSubmitNegotiableAmt = (event) => {
    event.preventDefault();
    let message1 = "This payment will be applied to your balance, but there will still be an outstanding due, are you sure you want to proceed?"
    let message2 = "This payment will be applied to your balance";
    let message = getStrippedDollarAmount(this.state.input.paymentAmount.value) === this.props.originalCollectionAmount ? message2 : message1 


    if (this.state.userData.last_4 && this.state.userData.last_4 != null && this.state.userData.last_4 != undefined &&
      this.state.input.paymentAmount.value && (getStrippedDollarAmount(this.state.input.paymentAmount.value) <= this.props.originalCollectionAmount)) {
      confirmAlert({
        // title: 'Confirm',
        message: message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {
              $('#submit-form').replaceWith('<div class="spinner"></div>');
              this.props.handleFormSubmit('', getStrippedDollarAmount(this.state.input.paymentAmount.value));
            }
          },
          {
            label: 'Cancel',
            onClick: () => ''
          }
        ]
      })
    } else if (this.validateForm() && this.state.input.paymentAmount.value &&
      (getStrippedDollarAmount(this.state.input.paymentAmount.value) <= this.props.originalCollectionAmount)) {
      confirmAlert({
        // title: 'Confirm',
        message: message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => this.handlePayNegotiableAmt()
          },
          {
            label: 'Cancel',
            onClick: () => ''
          }
        ]
      })
    } else if (this.state.input.paymentAmount.value == '' || this.state.input.paymentAmount.value == null ||
      (getStrippedDollarAmount(this.state.input.paymentAmount.value) > this.props.originalCollectionAmount)) {
      this.setState({
        ...this.state,
        input: {
          ...this.state.input,
          paymentAmount: {
            ...this.state.input.paymentAmount,
            hasError: true
          }
        }
      })
    } else {
      this.setFormErrorState();
    }
  }

  handlePayNegotiableAmt() {
    $('#submit-form').replaceWith('<div class="spinner"></div>');
    this.props.stripe.createToken({ name: this.state.input.nameOnCard.value }).then(({ token }) => {
      this.props.handleFormSubmit(token.id, getStrippedDollarAmount(this.state.input.paymentAmount.value));
    });
  }

  validateForm = () => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;

    Object.entries(newInputState).forEach(([key, value]) => {
      if (value.empty || value.error) {
        errorsArePresent = true
      }
    });

    let { input } = this.state;
      let checkErrors = input.cardNumber.hasError || input.cardExpirationDate.hasError || input.cvc.hasError || input.cardZip.hasError
      if(checkErrors) {
        errorsArePresent = true
      }

    if (errorsArePresent) {
      this.setFormErrorState();
      return false;
    } else {
      return true;
    }
  }

  setFormErrorState = () => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;
    Object.entries(newInputState).forEach(([key, value]) => {
      if (value.empty || value.error) {
        newInputState[key].hasError = true;
        errorsArePresent = true
      }
    });

    if (errorsArePresent) {
      this.setState(state => ({
        ...state,
        input: newInputState
      }))
    }
  }

  renderErrorMessage(input) {
    let message = '';
    if (input === 'nameOnCard') {
      message = 'Enter valid name';
    } else if (input === 'cardNumber') {
      message = 'Enter valid credit card number';
    } else if (input === 'cardExpirationDate') {
      message = 'Please enter a valid expiration date';
    } else if (input === 'cvc') {
      message = "Invalid CVV";
    } else if (input === 'cardZip') {
      message = "Invalid zip code";
    } else if (input === 'paymentAmount') {
      message = "Please enter valid amount";
    }

    return (
      <div className="input-error-message">
        {message}
      </div>
    )
  }

  generateSubmitText = () => {
    if (this.props.submitText) {
      return this.props.submitText
    } else {
      return "Submit"
    }
  }

  getSubmitText = () => {
    if (this.state.input.paymentAmount.value) {
      return 'Charge the amount: ' + this.state.input.paymentAmount.value
    } else {
      return "Submit"
    }
  }

  render() {
    let { input, userData, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    const style = {
      base: {
        'fontSize': '18px',
        'lineHeight': '1.875em',
        'color': '#252525',
        'fontFamily': 'HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica',
        '::placeholder': {
          'color': '#9B9B9B',
        },
      },
      invalid: {
        'color': '#fb5c82',
        ':focus': {
          'color': '#252525',
        },
      }
    };


    let form = <form
      className="consumer-accounts-payment-form">
      <p className="consumer-accounts-payment-form-description">
        Enter your payment information
      </p>
      <Panel>
        <div className="admin-form-row">
          <div
            className={`input-container${input.nameOnCard.hasError ? ' error' : ''}`}
          >
            <label htmlFor="nameOnCard">
              Name on Card
            </label>
            <input type="text"
              id="nameOnCard"
              placeholder="Enter Name"
              value={input.nameOnCard.value}
              onChange={event => this.handleInputChange(
                { nameOnCard: { value: event.target.value, hasError: false, empty: event.target.value.length > 0 ? false : true } }
              )}
            />
            {input.nameOnCard.hasError ? this.renderErrorMessage('nameOnCard') : null}
          </div>
        </div>
        <div className="admin-form-row">
          <div
            className={`input-container${input.cardNumber.hasError ? ' error' : ''}`}
          >
            <label className="stripe-label">
              Credit Card Number
              <CardNumberElement
                style={style}
                placeholder="1234 5678 1234 5678"
                onChange={event => this.handleInputChange(
                  { cardNumber: { empty: event.empty, hasError: event.error } }
                )}
                elementRef={element => this._element = element}
              />
            </label>
            {input.cardNumber.hasError ? this.renderErrorMessage('cardNumber') : null}
          </div>
        </div>
        <div className="admin-form-row">
          <div
            className={`input-container${input.cardExpirationDate.hasError ? ' error' : ''}`}
          >
            <label className="stripe-label">
              Expiration Date
              <CardExpiryElement
                style={style}
                placeholder="01/20"
                onChange={event => this.handleInputChange(
                  { cardExpirationDate: { empty: event.empty, hasError: event.error } }
                )}
                elementRef={element => this._element = element}
              />
            </label>
            {input.cardExpirationDate.hasError ? this.renderErrorMessage('cardExpirationDate') : null}
          </div>
        </div>
        <div className="admin-form-row">
          <div
            className={`input-container half${input.cvc.hasError ? ' error' : ''}`}
          >
            <label className="stripe-label">
              CVV
              <CardCVCElement
                style={style}
                placeholder="***"
                onChange={event => this.handleInputChange(
                  { cvc: { empty: event.empty, hasError: event.error } }
                )}
                elementRef={element => this._element = element}
              />
            </label>
            {input.cvc.hasError ? this.renderErrorMessage('cvc') : null}
          </div>
          <div
            className={`input-container half${input.cardZip.hasError ? ' error' : ''}`}
          >
            <label className="stripe-label">
              Billing Zip Code
              <PostalCodeElement
                style={style}
                placeholder="12345"
                onChange={event => this.handleInputChange(
                  { cardZip: { empty: event.empty, hasError: event.error } }
                )}
              />
            </label>
            {input.cardZip.hasError ? this.renderErrorMessage('cardZip') : null}
          </div>
        </div>
        {this.props.formType ? this.props.formType == "settle-account" ?
          <div className="admin-form-row">
            <div
              className={`input-container${input.paymentAmount.hasError ? ' error' : ''}`}
            >
              <label htmlFor="name">
                Amount
              <MaskedTextInput
                  guide={true}
                  mask={numberMask}
                  name="paymentAmount"
                  placeholder="$0"
                  placeholderChar={'\u2000'}
                  type="text"
                  maxLength={30}
                  value={input.paymentAmount.value}
                  onChange={event => this.handleInputChange(
                    { paymentAmount: { value: event.target.value, empty: event.empty, hasError: false } }
                  )}
                />
              </label>
              {input.paymentAmount.hasError ? this.renderErrorMessage('paymentAmount') : null}
            </div>
          </div>
          : null : null
        }
      </Panel>
      <div id="submit-form">
        {this.props.formType ? this.props.formType == "settle-account" ?
          <input
            className="cta"
            type="submit"
            style={{ marginBottom: `20px` }}
            value={this.getSubmitText()}
            onClick={(event) => this.handleSubmitNegotiableAmt(event)}
          /> : null : null
        }
        <input
          className="cta"
          type="submit"
          value={this.generateSubmitText()}
          onClick={(event) => this.handleSubmit(event)}
        />
      </div>
    </form>

    if (userData.last_4) {
      form =
        <div style={{ textAlign: `center` }}>
          <form style={{ display: `inline-block` }}>
            <div style={{ display: `inline-block`, textAlign: `left` }}>
              <div className="radio" style={{ marginBottom: `15px` }}>
                <label style={{ width: `auto`, position: `relative`, paddingLeft: `31px`, margin: `0`, display: `inlineBlock`, paddingTop: `2px` }}>
                  <input type="radio" name="pay" value={userData.last_4 ? userData.last_4 : ''} defaultChecked={true} style={{ WebkitAppearance: `radio`, width: `25px`, position: `absolute`, top: `0`, left: `0`, MozAppearance: `radio` }} />
                  <div style={{ padding: `5px 0` }}>{userData.last_4 ? '**** **** **** ' + userData.last_4 : ''}</div>
                </label>
              </div>
            </div>
            {this.props.formType ? this.props.formType == "settle-account" ?
              <div
                className={`input-container${input.paymentAmount.hasError ? ' error' : ''}`}
              >
                <label htmlFor="name">
                  Amount
                <MaskedTextInput
                    guide={true}
                    mask={numberMask}
                    name="paymentAmount"
                    placeholder="$0"
                    placeholderChar={'\u2000'}
                    type="text"
                    maxLength={30}
                    value={input.paymentAmount.value}
                    onChange={event => this.handleInputChange(
                      { paymentAmount: { value: event.target.value, empty: event.empty, hasError: false } }
                    )}
                  />
                </label>
                {input.paymentAmount.hasError ? this.renderErrorMessage('paymentAmount') : null}
              </div>
              : null : null
            }
            <div id="submit-form">
              {this.props.formType ? this.props.formType == "settle-account" ?
                <input
                  className="cta"
                  type="submit"
                  style={{ marginBottom: `20px` }}
                  value={this.getSubmitText()}
                  onClick={(event) => this.handleSubmitNegotiableAmt(event)}
                /> : null : null
              }
              <input
                className="cta"
                type="submit"
                style={{ display: `block` }}
                value={this.generateSubmitText()}
                onClick={(event) => this.handleSubmit(event)}
              />
            </div>
          </form>
        </div>
    }

    return (
      <div>
        {form}
      </div>
    );
  }
}

export default injectStripe(ConsumerCreditCardForm);
