import React, { Component } from 'react'
import { Helmet } from "react-helmet"
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

// Components
import AppCanvasLoader from '../../components/App/AppCanvasLoader'
import AppSectionHeader from '../../components/App/AppSectionHeader'
import ClientsSkipPaymentForm from '../../components/Clients/Forms/ClientsSkipPaymentForm';
import FlashMessage from '../../components/FlashMessage'

// Utils
import * as API from '../../utils/api'
import { errorMessage, networkMessage, showToast, getZohoPagesense } from '../../utils/helpers';

class SkipPayment extends Component {

  state = {
    savedData: {},
    showFlash: false,
    flashMessage: "",
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200
    }
  }

  componentDidMount() {
    const { user } = this.props;
    API.fetchSkipPaymentData(user).then(data => {

      let isAccessDenied =  (data && data.message && data.message == "Access denied.") ? true : false

      if ((typeof data != 'undefined' || data != null) && (!data.errors) && !isAccessDenied) {
        this.setState(state => ({
          savedData: data.skip_payment,
          isLoading: false
        }))
      } else {
        this.setState(state => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          },
          flashMessage: errorMessage,
          isLoading: false
        }))
      }
    })
    getZohoPagesense();
    ReactGA.pageview('/SkipPayment');
  }

  handleHelpFormSubmit = (inputs) => {
    const { user } = this.props;

    API.skipPaymetSetting(user, inputs).then(data => {
     if (((typeof data != 'undefined') || (data != null)) && !data.error) {
        this.setState(state => ({
          ...state,
          showFlash: true,
          flashMessage: 'Skip payment option saved successfully!'
        }))
      } else {
        this.setState(state => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          }
        }))
      }
    })
  }

  dismissFlash = () => {
    this.setState(state => ({
      ...state,
      showFlash: false
    }))
  }

  render() {
    const { appName } = this.props
    const { isLoading, savedData, error } = this.state

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    return isLoading
      ? (
        <div style={{marginTop: '20%'}}>
          <AppCanvasLoader />
        </div>
      )
      : (
        <div className="consumer-help">
          <Helmet>
            <title>{appName} | Skip Payment</title>
          </Helmet>
          <div>
            {this.state.showFlash ? (
              <FlashMessage
                message={this.state.flashMessage}
                handleFlashDismiss={this.dismissFlash}
              />
            ) : null}
            <AppSectionHeader
              title="Skip Payment"
              optionalClassNames="collapse-to-gradient"
            />
            <ClientsSkipPaymentForm
              savedData={savedData}
              handleSubmit={this.handleHelpFormSubmit}
            />
          </div>
        </div>
      )
  }
}

export default SkipPayment
