import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import account from "../../assets/images/navbar/accounts.png";
import dashboard from "../../assets/images/navbar/dashboard.png";
import invoice from "../../assets/images/navbar/invoice.png";
import history from "../../assets/images/navbar/history.png";
import question from "../../assets/images/navbar/question.png";
import report from "../../assets/images/navbar/report.png";
import task from "../../assets/images/navbar/tasks.png";

class ClientsNav extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
  };

  isActive(linkUrl) {
    if (this.props.path.includes(linkUrl)) {
      return "active";
    }
  }

  render() {
    return (
      <nav className="admin-nav client-nav scrollbar">
        <Link
          className={`admin-nav-link client-nav-link ${this.isActive(
            "/client/dashboard"
          )}`}
          to="/client/dashboard"
        >
          <img src={dashboard} width="34" height="34" className="icon" />
          DASHBOARD
        </Link>
        <Link
          className={`admin-nav-link client-nav-link ${this.isActive(
            "/client/accounts"
          )}`}
          to="/client/accounts"
        >
          <img src={account} width="34" height="34" className="icon" />
          ACCOUNTS
        </Link>
        <Link
          className={`admin-nav-link client-nav-link ${this.isActive(
            "/client/invoices"
          )}`}
          to="/client/invoices"
        >
          <img src={invoice} width="34" height="34" className="icon" />
          INVOICES
        </Link>
        <Link
          className={`admin-nav-link client-nav-link ${this.isActive(
            "/client/tasks"
          )}`}
          to="/client/tasks"
        >
          <img src={task} width="34" height="34" className="icon" />
          TASKS
        </Link>
        <Link
          className={`admin-nav-link client-nav-link ${this.isActive(
            "/client/reports"
          )}`}
          to="/client/reports"
        >
          <img src={report} width="34" height="34" className="icon" />
          REPORTS
        </Link>
        <Link
          className={`admin-nav-link client-nav-link ${this.isActive(
            "/client/history"
          )}`}
          to="/client/history"
        >
          <img src={history} width="34" height="34" className="icon" />
          HISTORY
        </Link>
        <Link
          className={`admin-nav-link client-nav-link ${this.isActive(
            "/client/help"
          )}`}
          to="/client/help"
        >
          <img src={question} width="34" height="34" className="icon" />
          HELP
        </Link>
      </nav>
    );
  }
}

export default ClientsNav;
