import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

// Components
import SessionsModal from "../../components/Sessions/SessionsModal";
import ChoosePasswordForm from "../../components/Sessions/ChoosePasswordForm";
import Logo from "../../components/Logo";

import { getZohoPagesense } from "../../utils/helpers";

class ChoosePassword extends Component {
  state = {
    showChoosePasswordForm: true,
  };

  componentDidMount() {
    getZohoPagesense();
    ReactGA.pageview("/ChoosePassword");
  }

  handleNewPasswordFormSubmit = () => {
    this.setState((state) => ({
      showChoosePasswordForm: false,
    }));
  };

  render() {
    let { appName } = this.props;
    let { showChoosePasswordForm } = this.state;

    window.history.pushState({}, "Change Your Password", "/choose-password");

    return (
      <div className="sessions fill-screen mg-session">
        <Helmet>
          <title>{appName} | Choose Password</title>
        </Helmet>
        <SessionsModal>
          <div className="sessions-modal-logo-container mg-logo-container">
            <Logo circular={true} />
          </div>
          <div className="sessions-modal-content">
            <h1 className="sessions-modal-heading" style={{ color: "#008bb2" }}>
              <span>Update Your Password</span>
            </h1>
            {showChoosePasswordForm && (
              <ChoosePasswordForm
                userID={this.props.userID}
                token={this.props.token}
                user={this.props.user}
                updateUserLogin={this.props.updateUserLogin}
                updateModalState={this.props.updateModalState}
                handleSubmit={this.handleNewPasswordFormSubmit}
              />
            )}
            {!showChoosePasswordForm && (
              <div>
                <p>Your password has been updated successfully!</p>
                <Link to="/login" className="cta mg-button">
                  Continue to Login
                </Link>
              </div>
            )}
          </div>
        </SessionsModal>
      </div>
    );
  }
}

export default ChoosePassword;
