import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

// Components
import SessionsModal from "../../components/Sessions/SessionsModal";
import ResetPasswordForm from "../../components/Sessions/ResetPasswordForm";
import Logo from "../../components/Logo";
import FlashMessage from "../../components/FlashMessage";

import { getZohoPagesense } from "../../utils/helpers";

class ResetPassword extends Component {
  state = {
    showFlash: false,
    flashMessage: "",
  };

  componentDidMount() {
    getZohoPagesense();
    ReactGA.pageview("/ResetPassword");
  }

  dismissFlash = () => {
    this.setState((state) => ({
      ...state,
      showFlash: false,
      flashMessage: "",
    }));
  };

  handleResetPasswordFormSubmit = () => {
    this.setState((state) => ({
      ...state,
      showFlash: true,
      flashMessage:
        "An email was sent with instructions to reset your password. Email support@makegoodapp.com for additional support.",
    }));
  };

  render() {
    let { appName } = this.props;

    return (
      <div className="sessions fill-screen mg-session">
        <Helmet>
          <title>{appName} | Reset Password</title>
        </Helmet>
        <SessionsModal title="Reset Your Password">
          <div className="sessions-modal-logo-container mg-logo-container">
            <Logo circular={true} />
          </div>
          <div className="sessions-modal-content">
            <h1 className="sessions-modal-heading" style={{ color: "#008bb2" }}>
              Reset your password
            </h1>
            {this.state.showFlash ? (
              <FlashMessage
                message={this.state.flashMessage}
                handleFlashDismiss={this.dismissFlash}
                aaplyMG={true}
              />
            ) : null}
            <ResetPasswordForm
              handleSubmit={this.handleResetPasswordFormSubmit}
            />
            <div className="sessions-modal-alternative-actions">
              <Link to="/login" className="text-link mg-text-link">
                Back to Login
              </Link>
            </div>
          </div>
        </SessionsModal>
      </div>
    );
  }
}

export default ResetPassword;
