import React, { Component } from 'react'
import { withRouter, Link } from "react-router-dom"
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import moment from 'moment';
import { Helmet } from "react-helmet";
import BottomScrollListener from 'react-bottom-scroll-listener';
import ReactGA from 'react-ga';

// API
import * as API from '../../utils/api'
import filter_icon from '../../assets/icons/filter.svg'

// Components
import MenuItem from '../../components/MenuItem'
import AppSectionHeader from '../../components/App/AppSectionHeader'
import AppCanvasLoader from '../../components/App/AppCanvasLoader'
import ObjectHistoryList from '../../components/ObjectList/ObjectHistoryList'

import {getZohoPagesense}  from '../../utils/helpers';

class EmployeeLobHistory extends Component {

  constructor(props) {
    super();
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onCheckboxClick = this.onCheckboxClick.bind(this);
    this.state = {
      savedData: [],
      filter_icon: filter_icon,
      hasMoreData: false,
      dropdownIsActive: false,
      isLoading: true,
      searchText: '',
      showFlash: false,
      offset: 0,
      search: {
        value: ""
      },
      action: '',
      filterText: '',
      sendFrom: '',
      sendTo: '',
      showLoader: false
    }
  }

  componentDidMount() {
    const { user } = this.props;
    const { searchText, offset } = this.state;
    this.setState(state => ({
      ...state,
      isLoading: true
    }));
    this.getLobData();
    getZohoPagesense();
    ReactGA.pageview('/LobHistory');
  }

  getLobData() {
    this.setState(state => ({ ...state, showLoader: true }));
    const { user } = this.props;
    const { searchText, offset, action, filterText, sendFrom, sendTo } = this.state;
    let queryString = '';
    {
      action ?
        (action === 'date'
          ? queryString = 'send_date_from=' + sendFrom + '&send_date_to=' + sendTo + '&all=' + false
          : queryString = action + '=' + filterText + '&all=' + false
        )
        :
        (searchText ? queryString = 'all=' + false : queryString = 'all=' + true)
    }

    API.fetchLobHistory(user, offset, searchText, queryString).then(data => {

      let isAccessDenied =  (data && data.message && data.message == "Access denied.") ? true : false

      if ((typeof data != 'undefined' || data != null) && (!data.errors) && !isAccessDenied) {
        let savedData = this.state.savedData;
        let newData = savedData ? savedData.concat(data.data) : data.data;

        this.setState(state => ({
          ...state,
          savedData: newData,
          isLoading: false,
          hasMoreData: data.data.length < 20 ? false : true,
          showLoader: false,
          offset: data.offset
        }))
      } else {
        this.setState(state => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          },
        }))
      }
    })
  }

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1
      this.setState(state => ({
        ...state,
        offset: offsetCount
      }),
        () => {
          this.getLobData();
        });
    }
  }

  handleKeyPress(event) {
    let searchText = this.state.search.value
    if (event.key === 'Enter') {
      this.setState(state => ({
        ...state,
        searchText: searchText,
        savedData: [],
        offset: 0,
        action: '',
        filterText: '',
        sendFrom: '',
        sendTo: '',
        showLoader: true
      }),
        () => {
          this.getLobData();
        }
      );
    }
  }

  handleSearchInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      search: {
        value: newPartialInput.search.value
      }
    }), () => {
      if (newPartialInput.search.value.length === 0) {
        this.handleKeyPress({ 'key': 'Enter' });
      }
    }
    )
  }

  handleSendNewLetterClick = (event) => {
    event.preventDefault()
    this.props.updateModalState(true, 'SEND_NEW_LETTER')
  }

  rendeSkipFilterModal(action, title) {
    let props = {
      action: action,
      handlefilterInput: this.handlefilterInput,
      title: title
    }
    this.props.updateModalState(true, 'LOB_FILTER', props);
  }

  handlefilterInput = (input) => {
    let { user } = this.props;
    const { from, to, filterText, action } = input;

    this.setState(state => ({
      ...state,
      savedData: [],
      searchText: '',
      action: action,
      filterText: filterText,
      sendFrom: from,
      sendTo: to,
      offset: 0,
      showLoader: true
    }),
      () => {
        this.getLobData();
      })

  }

  handleClickAction = (action, title) => {
    switch (action) {
      case action:
        this.rendeSkipFilterModal(action, title);
        break;
      default:
        alert('Error: Something went wrong')
    }
  }

  toggleDropdown = (event) => {
    event.preventDefault()

    this.setState(state => ({
      ...state,
      dropdownIsActive: !state.dropdownIsActive
    }))
  }

  handleClickOutside = (event) => {
    if (this.state.dropdownIsActive) {
      this.toggleDropdown(event);
    }
  }

  onCheckboxClick(event) {
    let props = {
      image: event
    }
    this.props.updateModalState(true, 'SHOW_IMAGE', props);
  }

  renderLatestPaymentDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  }

  getObjectListData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      "Letter ID",
      "Send Date",
      "Ex.Delivery Date",
      "From",
      "To",
      "Carrier",
      "Mail Type",
      "Tracking No."
    ]

    columns = data.map((row) => {

      return {
        primaryColumn: {},
        secondaryColumns: [
          {
            title: `${row.letter_id ? row.letter_id : 'n/a'}`,
            optionalClasses: `${row.letter_id ? ` consumer-payment-history-column` : ''}`
          },
          {
            title: row.send_date ? this.renderLatestPaymentDate(row.send_date) : "n/a",
          },
          {
            title: row.expected_delivery_date ? this.renderLatestPaymentDate(row.expected_delivery_date) : "n/a",
          },
          {
            title: `${row.from ? row.from : ''}`,
            optionalClasses: `${row.from ? ` consumer-payment-history-column` : ''}`
          },
          {
            title: `${row.to ? row.to : ''}`,
            optionalClasses: `${row.to ? ` consumer-payment-history-column` : ''}`
          },
          {
            title: `${row.carrier ? row.carrier : ''}`,
            optionalClasses: `${row.carrier ? ` column-data-alignment` : ''}`
          },
          {
            title: `${row.mail_type ? row.mail_type : ''}`,
          },
          {
            title: `${row.tracking_number ? row.tracking_number : 'n/a'}`,
            optionalClasses: `${row.tracking_number ? ` consumer-payment-history-column column-data-alignment` : 'column-data-alignment'}`
          }
        ],
        rowType: 'view',
        imageURL: `${row.image ? row.image : ''}`
      }
    })
    return {
      headings,
      columns
    }
  }

  getSectionHeaderUtilities() {
    const { search, dropdownIsActive } = this.state

    return (
      <div className="app-section-header-utilities">
        {/* <div className="admin-search-input-container">
          <input
            type="text"
            name="search"
            placeholder="Search"
            value={search.value}
            placeholder="Search"
            className="admin-search-input"
            onKeyDown={this.handleKeyPress}
            onChange={event => this.handleSearchInputChange(
              { search: { value: event.target.value } }
            )}
          />
          <span className="search-icon" onClick={() => this.handleKeyPress({ 'key': 'Enter' })} />
        </div> */}
        <a style={{ cursor: `pointer` }}
          onClick={(event) => { this.toggleDropdown(event); this.handleClickOutside(event) }}>
          <img style={{ width: 50, height: 50 }} src={this.state.filter_icon} />
          <li
            className={`dropdown-menu${dropdownIsActive ? ' active' : ''}`} style={{
              right: `110px`, width: `150px`,
              minWidth: `0px`
            }}
          >
            <MenuItem
              handleClick={(event) => this.handleClickAction('from', 'Mail From')}
            >
              From
          </MenuItem>
            <MenuItem
              handleClick={(event) => this.handleClickAction('to', 'Mail To')}
            >
              To
          </MenuItem>
            <MenuItem
              handleClick={(event) => this.handleClickAction('mailType', 'Mail Type')}
            >
              Mail Type
          </MenuItem>
            <MenuItem
              handleClick={(event) => this.handleClickAction('date', 'Date')}
            >
              Date
          </MenuItem>
          </li>
        </a>
        <button
          className={`cta`}
          onClick={this.handleSendNewLetterClick}
        >
          Send New Letter
        </button>
      </div>
    )
  }

  render() {
    const { appName } = this.props;
    const { isLoading } = this.state;
    return isLoading
      ? (
        <AppCanvasLoader />
      )
      : (
        <div className="consumer-debt-detail-form" style={{ paddingTop: 0 }} onClick={(event) => {
          this.handleClickOutside(event)
        }}>
          <Helmet>
            <title>{appName} | Mails</title>
          </Helmet>
          <AppSectionHeader
            title="Mail"
            utilities={this.getSectionHeaderUtilities()}
          />
          {
            this.state.savedData.length > 0 ?
              <BottomScrollListener onBottom={this.handleContainerOnBottom} debounce={0}>
                {scrollRef => (
                  <div>
                    <ObjectHistoryList
                      optionalClasses={"object-debt-list"}
                      data={this.getObjectListData(this.state.savedData)}
                      rowCheckListHandler={this.onCheckboxClick}
                      scrollRef={scrollRef}
                    />
                    {this.state.showLoader && <div><AppCanvasLoader /></div>}
                  </div>
                )}
              </BottomScrollListener>
              :
              <div className="admin-admins-empty-state">
                {
                  this.state.showLoader ? <AppCanvasLoader /> :
                    <h2> No mail history available. </h2>
                }
              </div>
          }
        </div>
      );
  }

}

export default withRouter(EmployeeLobHistory)
