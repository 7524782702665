import React, { Component } from 'react'
import { withRouter, Redirect } from "react-router-dom"
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import moment from 'moment';
import BottomScrollListener from 'react-bottom-scroll-listener';
import ReactGA from 'react-ga';

// API
import * as API from '../../utils/api';

// Components
import AppCanvasLoader from '../../components/App/AppCanvasLoader'
import ObjectHistoryList from '../../components/ObjectList/ObjectHistoryList'

import {getZohoPagesense}  from '../../utils/helpers';

class EmployeePaymentHistoryOffline extends Component {

  constructor() {
    super();
    this.state = {
      savedData: [],
      fromChild: '',
      isLoading: true,
      hasMoreData: false,
      showFlash: false,
      type: {
        value: 'Offline',
        hasError: false,
        isRequired: true,
        offset: 0,
      },
      search: {
        value: ""
      },
      showLoader: false
    }
  }

  componentDidMount() {
    this.getPaymentHistoryData();
    getZohoPagesense();
    ReactGA.pageview('/PaymentHistoryOffline');
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchText !== this.props.searchText) {
      this.setState(state => ({
        ...state,
        savedData: [],
        isLoading: true,
        search: {
          value: nextProps.searchText
        },
        type: {
          ...state.type,
          offset: 0
        }
      }), () => {
        this.getPaymentHistoryData();
      })
    }
  }

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.type.offset) + 1
      this.setState({
        type: {
          ...this.state.type,
          offset: offsetCount
        }
      },
        () => {
          this.getPaymentHistoryData();
        });
    }
  }

  getPaymentHistoryData() {
    const { user } = this.props;
    let offset = this.state.type.offset;
    let searchText = this.state.search.value;

    this.setState(state => ({ ...state, showLoader: true }));
    API.fetchPaymentHistory(user, "employee", "Offline", offset, searchText).then(data => {
      if ((typeof data != 'undefined' || data != null) && (!data.errors)) {
        let savedData = this.state.savedData;
        let newData = savedData.concat(data.data);
        this.setState(state => ({
          savedData: newData,
          isLoading: false,
          hasMoreData: data.data.length < 20 ? false : true,
          showLoader: false
        }))

        this.setState(state => ({
          ...state,
          type: {
            ...state.type,
            offset: data.offset
          }
        }));
      } else {
        this.setState(state => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          },
          showLoader: false
        }))
      }
    })
  }


  renderLatestPaymentDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  }

  getFullName = (firstName, lastName) => {
    let fullName = 'n/a'
    if (firstName || lastName) {
      fullName = firstName + " " + lastName;
    }
    return fullName;
  }


  getObjectListData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      "Name",
      "Transaction ID",
      "Payment Method",
      "Admin Name",
      "Date",
      "Amount",
      "Status"
    ]

    columns = data.map((row) => {
      let statusPillClass = null
      if (row.status == 'SUCCESS') {
        statusPillClass = 'success-message'
      } else {
        statusPillClass = 'fail-message'
      }

      return {
        primaryColumn: {},
        secondaryColumns: [
          {
            title: this.getFullName(row.first_name, row.last_name),
          },
          {
            title: `${row.transaction_id ? row.transaction_id : 'n/a'}`,
            optionalClasses: `${row.transaction_id ? ` consumer-payment-history-column` : ''}`
          },
          {
            title: `${row.payment_type ? row.payment_type : 'n/a'}`,
          },
          {
            title: this.getFullName(row.admin_first_name, row.admin_last_name),
            optionalClasses: 'secondary-cell-utility payment-rows'
          },
          {
            title: row.date ? this.renderLatestPaymentDate(row.date) : row.created_at ? this.renderLatestPaymentDate(row.created_at) : "n/a",
            optionalClasses: 'secondary-cell-utility payment-rows'
          },
          {
            title: row.amount ? `$${(parseFloat(row.amount)).toFixed(2)}` : '',
            optionalClasses: 'secondary-cell-utility payment-rows'
          },
          {
            title: `${row.status === 'SUCCESS' ? row.status : 'FAIL'}`,
            optionalClasses: `${statusPillClass ? statusPillClass + " secondary-cell-utility payment-rows" : 'secondary-cell-utility payment-rows'}`
          }
        ]
      }
    })
    return {
      headings,
      columns
    }
  }

  render() {
    const { isLoading } = this.state;

    return isLoading
      ? (
        <div style={{ marginTop: `10rem` }}>
          <AppCanvasLoader />
        </div>
      )
      : (
        <div>
          {
            this.state.savedData.length > 0 ?
              <div>
                <BottomScrollListener onBottom={this.handleContainerOnBottom} debounce={0}>
                  {scrollRef => (
                    <div>
                      <ObjectHistoryList
                        optionalClasses={"object-debt-list"}
                        data={this.getObjectListData(this.state.savedData)}
                        scrollRef={scrollRef}
                      />
                      {this.state.showLoader && <div><AppCanvasLoader /></div>}
                    </div>
                  )}
                </BottomScrollListener>
              </div>
              :
              <div className="admin-admins-empty-state">
                <h2>
                  No payment history available.
                </h2>
              </div>
          }
        </div>
      );
  }

}

export default withRouter(EmployeePaymentHistoryOffline)
