import { instanceOf } from "prop-types";
import queryString from "query-string";
import React, { Component } from "react";
import { Cookies, withCookies } from "react-cookie";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import companyData from "./assets/styles/theme.json";
import ModalConductor from "./components/App/Modals/ModalConductor";
import SignOut from "./components/Sessions/SignOut";
// Import Components
import SVGSprite from "./components/SVGSprite";
import AdminAccountAddEditView from "./screens/AdminPortal/AdminAccountAddEditView";
import AdminAccounts from "./screens/AdminPortal/AdminAccounts";
import AdminAccountsView from "./screens/AdminPortal/AdminAccountsView";
import AdminAdmins from "./screens/AdminPortal/AdminAdmins";
// import AdminPayments from './screens/AdminPortal/AdminPayments';
import AdminOffers from "./screens/AdminPortal/AdminOffers";
import AdminPendingActions from "./screens/AdminPortal/AdminPendingActions";
// Admin Portal
import AdminPortal from "./screens/AdminPortal/AdminPortal";
import AdminViewAddressDoc from "./screens/AdminPortal/AdminViewAddressDoc";
import CompanyAccounts from "./screens/AdminPortal/CompanyAccounts";
import ViewConsumerConversation from "./screens/AdminPortal/ViewConsumerConversation";
import ViewPendingAddressUser from "./screens/AdminPortal/ViewPendingAddressUser";
// Configure App
import AppConfiguration from "./screens/AppConfiguration";
import AppThirdPartyConfig from "./screens/AppThirdPartyConfig";
import ClientsAccountAddEditView from "./screens/ClientsPortal/ClientsAccountAddEditView";
import ClientsAccounts from "./screens/ClientsPortal/ClientsAccounts";
import ClientsAccountsView from "./screens/ClientsPortal/ClientsAccountsView";
import ClientsEditSettings from "./screens/ClientsPortal/ClientsEditSettings";
import ClientsAdmins from "./screens/ClientsPortal/ClientsAdmins";
import ClientsDashboard from "./screens/ClientsPortal/ClientsDashboard";
import ClientsEmployeeAccountsView from "./screens/ClientsPortal/ClientsEmployeeAccountsView";
import ClientsEmployees from "./screens/ClientsPortal/ClientsEmployees";
import ClientsHistory from "./screens/ClientsPortal/ClientsHistory";
import ClientsInvoices from "./screens/ClientsPortal/ClientsInvoices";
import ClientsInvoicesView from "./screens/ClientsPortal/ClientsInvoicesView";
import ClientsAddInvoices from "./screens/ClientsPortal/ClientsAddInvoices";
import ClientsHelp from "./screens/ClientsPortal/ClientsHelp";
import ClientsCreditMemoView from './screens/ClientsPortal/CreditMemo/ClientsCreditMemoView';
import ClientsAddCreditMemo from './screens/ClientsPortal/CreditMemo/ClientsAddCreditMemo';
// import AdminPayments from './screens/AdminPortal/AdminPayments';
import ClientsOffers from "./screens/ClientsPortal/ClientsOffers";
import ClientsPendingActions from "./screens/ClientsPortal/ClientsPendingActions";
//Clients Portal
import ClientsPortal from "./screens/ClientsPortal/ClientsPortal";
import ClientsProfile from "./screens/ClientsPortal/ClientsProfile";
import ClientsReports from "./screens/ClientsPortal/ClientsReports";
import ClientsReportView from "./screens/ClientsPortal/ClientsReportView";
import ClientsSettings from "./screens/ClientsPortal/ClientsSettings";
import ClientsTasks from "./screens/ClientsPortal/ClientsTasks";
import ClientViewAddressDoc from "./screens/ClientsPortal/ClientViewAddressDoc";
import MailchimpHistory from "./screens/ClientsPortal/history/MailchimpHistory";
import MessageHistory from "./screens/ClientsPortal/history/MessageHistory";
import ViewConsumerChat from "./screens/ClientsPortal/history/ViewConsumerChat";
import LobHistory from "./screens/ClientsPortal/LobHistory";
import SkipPayment from "./screens/ClientsPortal/SkipPayment";
import SkipTrace from "./screens/ClientsPortal/SkipTrace";
import ConsumerAccounts from "./screens/ConsumerPortal/ConsumerAccounts";
import ConsumerAccountsView from "./screens/ConsumerPortal/ConsumerAccountsView";
import ConsumerDocumentValidate from "./screens/ConsumerPortal/ConsumerDocumentValidate";
import ConsumerHelp from "./screens/ConsumerPortal/ConsumerHelp";
// Consumer Portal
import ConsumerPortal from "./screens/ConsumerPortal/ConsumerPortal";
import ConsumerProfile from "./screens/ConsumerPortal/ConsumerProfile";
import DebtDetail from "./screens/ConsumerPortal/DebtDetail";
import ConsumerAccountsNegotiateOneTimePayment from "./screens/ConsumerPortal/Payment/ConsumerAccountsNegotiateOneTimePayment";
import ConsumerAccountsNegotiatePaymentPlan from "./screens/ConsumerPortal/Payment/ConsumerAccountsNegotiatePaymentPlan";
import ConsumerAccountsPaymentNegotiateSelection from "./screens/ConsumerPortal/Payment/ConsumerAccountsPaymentNegotiateSelection";
import ConsumerAccountsPaymentSettleAccount from "./screens/ConsumerPortal/Payment/ConsumerAccountsPaymentSettleAccount";
import ConsumerAccountsPaymentValidate from "./screens/ConsumerPortal/Payment/ConsumerAccountsPaymentValidate";
import EmployeeAccounts from "./screens/EmployeePortal/EmployeeAccounts";
import EmployeeAccountAddEditView from "./screens/EmployeePortal/EmployeeAccountsAddEditView";
import EmployeeAccountsView from "./screens/EmployeePortal/EmployeeAccountsView";
import EmployeeLobHistory from "./screens/EmployeePortal/EmployeeLobHistory";
import EmployeeOffers from "./screens/EmployeePortal/EmployeeOffers";
import EmployeePaymentHistory from "./screens/EmployeePortal/EmployeePaymentHistory";
// Employee Portal
import EmployeePortal from "./screens/EmployeePortal/EmployeePortal";
import EmployeeProfile from "./screens/EmployeePortal/EmployeeProfile";
import EmployeeSkipTrace from "./screens/EmployeePortal/EmployeeSkipTrace";
import EmployeeViewAddressDoc from "./screens/EmployeePortal/EmployeeViewAddressDoc";
import EmployeeMailchimpHistory from "./screens/EmployeePortal/history/EmployeeMailchimpHistory";
import EmployeeMessageHistory from "./screens/EmployeePortal/history/EmployeeMessageHistory";
import EmployeeViewConsumerChat from "./screens/EmployeePortal/history/EmployeeViewConsumerChat";
// Screens
// -----------------------------------------------------------
// Landing
import Landing from "./screens/Landing";
// MakeGood
import MakeGoodPolicy from "./screens/MakeGoodPolicy";
import MarketingContact from "./screens/MarketingContact";
import MarketingContent from "./screens/MarketingContent";
// Marketing
import MarketingHomepage from "./screens/MarketingHomepage";
import MarketingPrivacy from "./screens/MarketingPrivacy";
import MarketingTerms from "./screens/MarketingTerms";
import ConfirmPassword from "./screens/NewLanding/ConfirmPassword";
//New Registration
import LandingHeader from "./screens/NewLanding/LandingHeader";
import NewRegistration from "./screens/NewLanding/NewRegistration";
import PasswordSuccess from "./screens/NewLanding/PasswordSuccess";
import OnboardingCompletion from "./screens/Onboarding/OnboardingCompletion";
import OnboardingConfirmAccountCode from "./screens/Onboarding/OnboardingConfirmAccountCode";
import OnboardingConfirmAccountPhone from "./screens/Onboarding/OnboardingConfirmAccountPhone";
import OnboardingCreateAccount from "./screens/Onboarding/OnboardingCreateAccount";
import OnboardingResidence from "./screens/Onboarding/OnboardingResidence";
import OnboardingUserAgreement from "./screens/Onboarding/OnboardingUserAgreement";
import OnboardingVerifyIdentity from "./screens/Onboarding/OnboardingVerifyIdentity";
import InvoiceAmountForm from "./screens/QuickBook/InvoiceAmountForm";
import QbFailure from "./screens/QuickBook/QbFailure";
import QbSuccess from "./screens/QuickBook/QbSuccess";
import StripePaymentFailed from "./screens/QuickBook/StripePaymentFailed";
import StripePaymentSuccess from "./screens/QuickBook/StripePaymentSuccess";
import ChoosePassword from "./screens/Sessions/ChoosePassword";
import ExpressPaymentSuccess from "./screens/QuickBook/ExpressPaymentSuccess";
import ExpressPaymentFailed from "./screens/QuickBook/ExpressPaymentFailed";
import ConsumerGetHelp from "./screens/QuickBook/ConsumerGetHelp";
// Sessions
import Login from "./screens/Sessions/Login";
import ResetPassword from "./screens/Sessions/ResetPassword";
import SuperAdminAccountAddEditView from "./screens/SuperAdminPortal/SuperAdminAccountAddEditView";
import SuperAdminAccounts from "./screens/SuperAdminPortal/SuperAdminAccounts";
import SuperAdminAccountsView from "./screens/SuperAdminPortal/SuperAdminAccountsView";
import SuperAdminProfile from "./screens/SuperAdminPortal/SuperAdminProfile";
//Super Admin
import SuperAdminPortal from "./screens/SuperAdminPortal/SuperAdminPortal";
import UserLicenseAgreement from "./screens/UserLicenseAgreement";
import accountViewData from "./testData/accountViewData.json";
import userDashboardObjectLists from "./testData/userDashboardObjectLists.json";
// Data
import userData from "./testData/userData.json";
import Favicon from "./assets/icons/favicon.ico";
// Utils
import * as API from "./utils/api";
import { googleAnalyticId } from "./utils/helpers";
import Emitter from "./utils/event-emitter";

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  // App will manage the state of global elements such as the user auth status
  state = {
    user: {},
    isLoggedIn: false,
    showModal: false,
    currentModal: null,
    optionalModalProps: null,
    prevPath: null,
    confirmOnboardingPhoneNumber: false,
    hasAnyError: false,
    appName: companyData.name, //company_name
    clientName: companyData.clientName,
    appEmail: "https://www.debnow.com",
    heapEmail: null,
    heapAppId: null,
    intercomAppId: null,
    stripePubKey: null,
    appData: {},
    userData: {},
  };

  componentWillMount() {
    const { cookies } = this.props;
    const userFromCookies = cookies.get("authUser", {path: '/', secure: true }) || {};
    const isLoggedIn = cookies.get("isLoggedIn", {path: '/', secure: true }) || false;
    const url = window.location.origin;
    // var appsName = this.getAppData();
    this.getAppConfigInfo();
    this.intializeGA();
    this.setState(
      (state) => ({
        ...state,
        user: userFromCookies,
        appEmail: url,
        isLoggedIn: isLoggedIn,
      }),
      () => this.getFullUserProfile(userFromCookies)
    );

    //handle oauth error
    Emitter.on("OAUTH_ERROR", (isError) => {
      cookies.remove("authUser", { path: "/", secure: true});
      cookies.remove("isLoggedIn", { path: "/", secure: true});
      cookies.remove("accessToken", { path: "/", secure: true}); 
      cookies.remove("refreshToken", { path: "/", secure: true});
      console.log('error is here...');
      return <Redirect to={{
        pathname: '/login',
        state: {}
      }}/>
    });
  }

  componentWillUnmount() {
    Emitter.off("OAUTH_ERROR");
  }

  getFullUserProfile = (user) => {
    if (
      user.hasOwnProperty("user_id") &&
      user.user_id &&
      !user.admin &&
      !user.super_admin &&
      !user.client_admin
    ) {
      API.getUser(user).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.errors) {
          const consolidatedUser = Object.assign(user, data.user);
          this.setState((state) => ({
            ...state,
            user: consolidatedUser,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            hasAnyError: true,
          }));
        }
      });
    }
  };

  getAppConfigInfo = () => {
    API.getAppConfigInfo(companyData.organization_key).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.errors) {
        if (data.appConfigData) {
          data.appConfigData.forEach((appConfigData, index) => {
            if (appConfigData.name === "HEAP") {
              this.setState((state) => ({
                ...state,
                loading: false,
                heapEmail: appConfigData.email,
                heapAppId: appConfigData.app_id,
              }));
            } else if (appConfigData.name === "INTERCOM") {
              this.setState((state) => ({
                ...state,
                intercomAppId: appConfigData.app_id,
              }));
            } else if (appConfigData.name === "STRIPE") {
              this.setState((state) => ({
                ...state,
                stripePubKey: appConfigData.token,
              }));
            }
          });

          return true;
        } else {
          this.setState((state) => ({
            ...state,
            loading: false,
          }));
          return false;
        }
      } else {
        this.setState((state) => ({
          ...state,
          loading: false,
        }));
        return false;
      }
    });
  };

  handleUserLogin = (user) => {
    return new Promise((resolve, reject) => {
      const { cookies } = this.props;
      const plusOneYear = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      );
      const cookieOpts = { path: "/", expires: plusOneYear, secure: true };
      cookies.set("authUser", user, cookieOpts);
      cookies.set("isLoggedIn", true, cookieOpts);

      //getGoogleAnalyticKey().pageview('login page');

      this.setState(
        (state) => ({
          ...state,
          user: {
            ...user,
          },
          isLoggedIn: true,
        }),
        () => {
          resolve(true);
        }
      );
    });
  };

  handleUserAgreementAccept = (user) => {
    return new Promise((resolve, reject) => {
      const { cookies } = this.props;
      const plusOneYear = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      );
      const cookieOpts = { path: "/", expires: plusOneYear, secure: true };
      user.onboarding_complete = true;
      cookies.set("authUser", user, cookieOpts);

      this.setState(
        (state) => ({
          ...state,
          user: {
            ...user,
          },
          onboarding_complete: true,
        }),
        () => {
          resolve(true);
        }
      );
    });
  };

  handleUserSignOut = () => {
    const { cookies } = this.props;
    cookies.remove("authUser", { path: "/", secure: true });
    cookies.remove("isLoggedIn", { path: "/", secure: true });
    cookies.remove("accessToken", { path: "/", secure: true }); 
    cookies.remove("refreshToken", { path: "/", secure: true });
    this.setState((state) => ({
      ...state,
      user: {},
      isLoggedIn: false,
    }));
  };

  handleModalChange = (showModalState, modalType, optionalModalProps) => {
    this.setState((state) => ({
      ...state,
      showModal: showModalState,
      currentModal: modalType,
      optionalModalProps,
    }));
  };

  hideAndResetModal = () => {
    this.setState((state) => ({
      ...state,
      showModal: false,
      currentModal: null,
      optionalModalProps: null,
    }));
  };

  handlePrevPathUpdate = (path) => {
    this.setState((state) => ({
      ...state,
      prevPath: path,
    }));
  };

  updateOnboardingConfirmationCodeStatus = (status) => {
    this.setState((state) => ({
      ...state,
      confirmOnboardingPhoneNumber: status,
    }));
  };

  async getAppData() {
    await API.getAppInfo().then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.errors) {
        if (data.appData) {
          this.setState((state) => ({
            ...state,
            appName: this.state.appName, //data.appData.company_name,
            appEmail: data.appData.company_email,
            appData: data.appData,
          }));
        }
      }
    });
  }

  intializeGA = () => {
    ReactGA.initialize(googleAnalyticId);
  };

  setUserData = (data) => {
    this.setState({ userData: data });
  };

  render() {
    let {
      user,
      showModal,
      prevPath,
      confirmOnboardingPhoneNumber,
      appName,
      appEmail,
      stripePubKey,
      appData,
      isAppConfigured,
      isAppconfig,
    } = this.state;
    //const appName = appNameText;

    const modal = showModal ? (
      <ModalConductor
        user={this.state.user}
        stripePubKey={stripePubKey}
        appName={this.state.appName}
        appEmail={this.state.appEmail}
        currentModal={this.state.currentModal}
        hideModal={this.hideAndResetModal}
        updateModalState={this.handleModalChange}
        optionalProps={this.state.optionalModalProps}
      />
    ) : null;

    return (
      <div id="app">
        <script>{this.intializeGA()}</script>
        <Helmet>
          <link rel="shortcut icon" href={Favicon} />
        </Helmet>
        <SVGSprite />
        <Switch>
          {/* Marketing Pages */}
          <Route
            path="/privacypolicy"
            render={(props) => (
              <MakeGoodPolicy
                appName={this.state.appName}
                appEmail={this.state.appEmail}
                location={props.location.pathname}
                companyAddress={this.state.companyAddress}
                adminEmail={this.state.adminEmail}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            path="/eula"
            render={(props) => (
              <UserLicenseAgreement
                appName={this.state.appName}
                appEmail={this.state.appEmail}
                location={props.location.pathname}
                companyAddress={this.state.companyAddress}
                adminEmail={this.state.adminEmail}
                updateModalState={this.handleModalChange}
              />
            )}
          />

          <Route
            exact
            path="/register"
            render={(props) => (
              <LandingHeader
                {...props}
                token={queryString.parse(props.location.search).token}
                setUserData={this.setUserData}
                updateModalState={this.handleModalChange}
                intercomAppId={this.state.intercomAppId}
              />
            )}
          />
          <Route
            exact
            path="/registration-form"
            render={(props) => (
              <NewRegistration
                {...props}
                userData={this.state.userData}
                updateModalState={this.handleModalChange}
                intercomAppId={this.state.intercomAppId}
              />
            )}
          />
          <Route
            exact
            path="/confirm-password"
            render={(props) => (
              <ConfirmPassword
                {...props}
                userData={this.state.userData}
                updateModalState={this.handleModalChange}
                intercomAppId={this.state.intercomAppId}
              />
            )}
          />
          <Route
            exact
            path="/password-success"
            render={(props) => <PasswordSuccess {...props} />}
          />
          <Route
            exact
            path="/"
            render={(props) => (
              <MarketingHomepage
                appName={this.state.appName}
                companyAddress={this.state.companyAddress}
                adminEmail={this.state.adminEmail}
                location={props.location.pathname}
                user={user}
                intercomAppId={this.state.intercomAppId}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path="/configureApp"
            render={(props) => (
              <AppConfiguration
                appName={appName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                appData={appData}
                isLoggedIn={this.state.isLoggedIn}
              />
            )}
          />
          <Route
            exact
            path="/appThirdPartyConfig"
            render={(props) => (
              <AppThirdPartyConfig
                appName={this.state.appName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            path="/contact"
            render={(props) => (
              <MarketingContact
                appName={this.state.appName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            path="/privacy"
            render={(props) => (
              <MarketingPrivacy
                appName={this.state.appName}
                appEmail={this.state.appEmail}
                location={props.location.pathname}
                companyAddress={this.state.companyAddress}
                adminEmail={this.state.adminEmail}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            path="/terms"
            render={(props) => (
              <MarketingTerms
                appName={this.state.appName}
                appEmail={this.state.appEmail}
                companyAddress={this.state.companyAddress}
                adminEmail={this.state.adminEmail}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            path="/content"
            render={() => (
              <MarketingContent
                appName={this.state.appName}
                companyAddress={this.state.companyAddress}
                adminEmail={this.state.adminEmail}
              />
            )}
          />
          <Route
            path="/landing"
            render={() => (
              <Landing
                appName={this.state.appName}
                appEmail={this.state.appEmail}
              />
            )}
          />

          {/* Sessions Screens */}
          <Route
            path="/login"
            render={(props) => (
              <Login
                appName={this.state.appName}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                location={props.location.pathname}
                updateUserLogin={this.handleUserLogin}
                updateModalState={this.handleModalChange}
                heapEmail={this.state.heapEmail}
                heapAppId={this.state.heapAppId}
              />
            )}
          />
          <Route
            path="/sign-out"
            render={(props) => (
              <SignOut
                handleSignOut={this.handleUserSignOut}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            path="/reset-password"
            render={(props) => <ResetPassword appName={this.state.appName} />}
          />
          {/* Sessions Screens -- /choose-password -- Catch Rails user reset url --*/}
          <Route
            path="/users/:id/password/edit"
            render={(props) => (
              <ChoosePassword
                {...props}
                userID={props.match.params.id}
                user={user}
                token={queryString.parse(props.location.search).token}
                appName={this.state.appName}
                location={props.location.pathname}
                updateUserLogin={this.handleUserLogin}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            path="/users/:id/set_password"
            render={(props) => (
              <ChoosePassword
                {...props}
                user={user}
                userID={props.match.params.id}
                token={queryString.parse(props.location.search).token}
                appName={this.state.appName}
                location={props.location.pathname}
                updateUserLogin={this.handleUserLogin}
                updateModalState={this.handleModalChange}
              />
            )}
          />

          {/* Onboarding Screens */}
          <Route
            exact
            path="/create-account"
            render={(props) => (
              <OnboardingCreateAccount
                appName={this.state.appName}
                updateUserLogin={this.handleUserLogin}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path="/confirm-account/phone"
            render={(props) => (
              <OnboardingConfirmAccountPhone
                appName={this.state.appName}
                user={user}
                reconfirmPhoneNumber={confirmOnboardingPhoneNumber}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path="/confirm-account/enter-code"
            render={(props) => (
              <OnboardingConfirmAccountCode
                appName={this.state.appName}
                user={user}
                handleResendConfirmationCode={
                  this.updateOnboardingConfirmationCodeStatus
                }
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path="/onboarding/address"
            render={(props) => (
              <OnboardingResidence
                appName={this.state.appName}
                user={user}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path="/onboarding/verify-identity"
            render={(props) => (
              <OnboardingVerifyIdentity
                appName={this.state.appName}
                user={user}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path="/onboarding/user-agreement"
            render={(props) => (
              <OnboardingUserAgreement
                appName={this.state.appName}
                user={user}
                handleSignOut={this.handleUserSignOut}
                handleAcceptUserAgreement={this.handleUserAgreementAccept}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path="/onboarding/success"
            render={(props) => (
              <OnboardingCompletion
                appName={this.state.appName}
                user={user}
                updateModalState={this.handleModalChange}
              />
            )}
          />

          {/* User Dashboard Screens */}
          <Redirect exact from="/dashboard" to="/dashboard/accounts" push />
          <Route
            exact
            path="/dashboard/accounts"
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                intercomAppId={this.state.intercomAppId}
                removePadding={true}
                screen={
                  <ConsumerAccounts
                    {...props}
                    appName={this.state.appName}
                    user={user}
                    objectListData={userDashboardObjectLists}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/dashboard/accounts/:id"
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                removePadding={true}
                prevPath={"/dashboard/accounts"}
                screen={
                  <ConsumerAccountsView
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    stripePubKey={stripePubKey}
                    updatePrevPath={this.handlePrevPathUpdate}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/dashboard/accounts/:id/document-validate"
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                headerTab={"Accounts"}
                isLoggedIn={this.state.isLoggedIn}
                prevPath={"/dashboard/accounts/:id"}
                screen={
                  <ConsumerDocumentValidate
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    updatePrevPath={this.handlePrevPathUpdate}
                    objectListData={userDashboardObjectLists}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/dashboard/accounts/:id/negotiate"
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                headerTab={"Accounts"}
                isLoggedIn={this.state.isLoggedIn}
                prevPath={"/dashboard/accounts"}
                screen={
                  <ConsumerAccountsPaymentNegotiateSelection
                    {...props}
                    appName={this.state.appName}
                    user={user}
                    updatePrevPath={this.handlePrevPathUpdate}
                    objectListData={userDashboardObjectLists}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/dashboard/accounts/:id/validate"
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                headerTab={"Accounts"}
                isLoggedIn={this.state.isLoggedIn}
                prevPath={"/dashboard/accounts"}
                screen={
                  <ConsumerAccountsPaymentValidate
                    {...props}
                    appName={this.state.appName}
                    user={user}
                    updatePrevPath={this.handlePrevPathUpdate}
                    objectListData={userDashboardObjectLists}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/dashboard/accounts/:id/negotiate/payment-plan"
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                headerTab={"Accounts"}
                isLoggedIn={this.state.isLoggedIn}
                prevPath={prevPath}
                screen={
                  this.state.stripePubKey !== null ? (
                    <ConsumerAccountsNegotiatePaymentPlan
                      {...props}
                      appName={this.state.appName}
                      user={user}
                      updatePrevPath={this.handlePrevPathUpdate}
                      objectListData={userDashboardObjectLists}
                      hideModal={this.hideAndResetModal}
                      updateModalState={this.handleModalChange}
                      stripePubKey={stripePubKey}
                    />
                  ) : (
                    <div />
                  )
                }
              />
            )}
          />
          <Route
            exact
            path="/dashboard/accounts/:id/negotiate/one-time-payment"
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                headerTab={"Accounts"}
                isLoggedIn={this.state.isLoggedIn}
                prevPath={prevPath}
                screen={
                  this.state.stripePubKey !== null ? (
                    <ConsumerAccountsNegotiateOneTimePayment
                      {...props}
                      appName={this.state.appName}
                      user={user}
                      updatePrevPath={this.handlePrevPathUpdate}
                      objectListData={userDashboardObjectLists}
                      hideModal={this.hideAndResetModal}
                      updateModalState={this.handleModalChange}
                      stripePubKey={stripePubKey}
                    />
                  ) : (
                    <div />
                  )
                }
              />
            )}
          />
          <Route
            exact
            path="/dashboard/accounts/:id/settle-account"
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                headerTab={"Accounts"}
                isLoggedIn={this.state.isLoggedIn}
                prevPath={"/dashboard/accounts"}
                screen={
                  this.state.stripePubKey !== null ? (
                    <ConsumerAccountsPaymentSettleAccount
                      {...props}
                      appName={this.state.appName}
                      user={user}
                      updatePrevPath={this.handlePrevPathUpdate}
                      objectListData={userDashboardObjectLists}
                      hideModal={this.hideAndResetModal}
                      updateModalState={this.handleModalChange}
                      stripePubKey={stripePubKey}
                    />
                  ) : (
                    <div />
                  )
                }
              />
            )}
          />
          <Route
            exact
            path="/dashboard/profile"
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                removePadding={true}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  this.state.stripePubKey !== null ? (
                    <ConsumerProfile
                      {...props}
                      appName={this.state.appName}
                      hideModal={this.hideAndResetModal}
                      updateModalState={this.handleModalChange}
                      user={user}
                      stripePubKey={stripePubKey}
                    />
                  ) : (
                    <div />
                  )
                }
              />
            )}
          />

          <Route
            exact
            path="/dashboard/help"
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                removePadding={true}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ConsumerHelp
                    {...props}
                    appName={this.state.appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          {/* Admin Screens */}
          <Redirect exact from="/admin" to="/admin/accounts" push />
          <Route
            exact
            path="/admin/accounts"
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminAccounts
                    appName={this.state.appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/admin/accounts/add"
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminAccountAddEditView
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/admin/accounts/:id"
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminAccountsView
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/admin/accounts/:id/edit"
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminAccountAddEditView
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/admin/offers"
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminOffers
                    user={user}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/admin/admins"
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminAdmins
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/admin/PendingActions"
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminPendingActions
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/admin/companyDashboard"
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <CompanyAccounts
                    appName={this.state.appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/admin/companyDashboard/add"
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminAccountAddEditView
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/admin/companyDashboard/:id"
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminAccountsView
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/admin/companyDashboard/:id/edit"
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminAccountAddEditView
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/admin/view/conversation/:id"
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ViewConsumerConversation
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          <Route
            exact
            path="/dashboard/profile/debtdetail"
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                headerTab={"Debt Detail"}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <DebtDetail
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          <Route
            exact
            path="/admin/accounts/:id/addressDocument"
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminViewAddressDoc
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          <Route
            exact
            path="/admin/pendingAddressUser"
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ViewPendingAddressUser
                    appName={this.state.appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          {/* Clients Screens */}
          <Redirect exact from="/client" to="/client/dashboard" push />
          <Route
            exact
            path="/client/dashboard"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                removePadding={true}
                screen={
                  <ClientsDashboard
                    {...props}
                    appName={this.state.appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          <Redirect exact from="/client" to="/client/accounts" push />
          <Route
            exact
            path="/client/accounts"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsAccounts
                    appName={this.state.appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          <Redirect exact from="/client" to="/client/employees" push />
          <Route
            exact
            path="/client/employees"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsEmployees
                    appName={this.state.appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          <Route
            exact
            path="/client/employees/:id"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsEmployeeAccountsView
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          <Route
            exact
            path="/client/accounts/add"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsAccountAddEditView
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/client/accounts/:id"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                removePadding={true}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsAccountsView
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/client/accounts/:id/edit-setting"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                removePadding={true}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsEditSettings
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/client/accounts/:id/edit"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsAccountAddEditView
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/client/profile"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  this.state.stripePubKey !== null ? (
                    <ClientsProfile
                      {...props}
                      appName={this.state.appName}
                      hideModal={this.hideAndResetModal}
                      updateModalState={this.handleModalChange}
                      user={user}
                      stripePubKey={stripePubKey}
                    />
                  ) : (
                    <div />
                  )
                }
              />
            )}
          />
          <Route
            exact
            path="/client/offers"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsOffers
                    user={user}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/client/tasks"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                removePadding={true}
                screen={
                  <ClientsTasks
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/client/reports"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                removePadding={true}
                screen={
                  <ClientsReports
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/client/reports/:id"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                // removePadding={true}
                screen={
                  <ClientsReportView
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/client/invoices"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsInvoices
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/client/invoices/:id"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                removePadding={true}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsInvoicesView
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/client/add/invoices"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsAddInvoices
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/client/creditMemo/:id"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                removePadding={true}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsCreditMemoView
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/client/add/creditMemo"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsAddCreditMemo
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/client/admins"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsAdmins
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/client/PendingActions"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsPendingActions
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/client/view/conversation/:id"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ViewConsumerConversation
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/client/accounts/:id/addressDocument"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientViewAddressDoc
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          <Route
            exact
            path="/client/pendingAddressUser"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ViewPendingAddressUser
                    appName={appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          <Route
            exact
            path="/client/settings"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                removePadding={true}
                screen={
                  <ClientsSettings
                    {...props}
                    appName={appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                    stripePubKey={stripePubKey}
                  />
                }
              />
            )}
          />

          <Route
            exact
            path="/client/history"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsHistory
                    {...props}
                    appName={appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          <Route
            exact
            path="/client/lobHistory"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <LobHistory
                    {...props}
                    appName={appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          <Route
            exact
            path="/client/messageHistory/:id"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <MessageHistory
                    {...props}
                    appName={appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          <Route
            exact
            path="/client/view/chat/:id"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ViewConsumerChat
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          <Route
            exact
            path="/client/messageHistory/mail/:id"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <MailchimpHistory
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          <Route
            exact
            path="/client/skipTrace"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <SkipTrace
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          <Route
            exact
            path="/client/help"
            render={(props) => (
              <ClientsPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsHelp
                    appName={this.state.appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          {/* Super Admin Screens */}
          <Redirect exact from="/super-admin" to="/super-admin/accounts" push />
          <Route
            exact
            path="/super-admin/accounts"
            render={(props) => (
              <SuperAdminPortal
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <SuperAdminAccounts
                    appName={this.state.appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/super-admin/profile"
            render={(props) => (
              <SuperAdminPortal
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <SuperAdminProfile
                    appName={this.state.appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/super-admin/accounts/:id"
            render={(props) => (
              <SuperAdminPortal
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <SuperAdminAccountsView
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/super-admin/accounts/:id/edit"
            render={(props) => (
              <SuperAdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <SuperAdminAccountAddEditView
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          {/* Employee Screens */}
          <Redirect exact from="/employee" to="/employee/accounts" push />
          <Route
            exact
            path="/employee/accounts"
            render={(props) => (
              <EmployeePortal
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeAccounts
                    appName={this.state.appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/employee/accounts/:id"
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeAccountsView
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/employee/accounts/:id/edit"
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeAccountAddEditView
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/employee/view/conversation/:id"
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ViewConsumerConversation
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/employee/accounts/:id/addressDocument"
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeViewAddressDoc
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/employee/profile"
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  this.state.stripePubKey !== null ? (
                    <EmployeeProfile
                      {...props}
                      appName={this.state.appName}
                      hideModal={this.hideAndResetModal}
                      updateModalState={this.handleModalChange}
                      user={user}
                      stripePubKey={stripePubKey}
                    />
                  ) : (
                    <div />
                  )
                }
              />
            )}
          />
          <Route
            exact
            path="/employee/offers"
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeOffers
                    user={user}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/employee/skipPayment"
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <SkipPayment
                    {...props}
                    appName={appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/employee/paymentHistory"
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeePaymentHistory
                    {...props}
                    appName={appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/employee/lobHistory"
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeLobHistory
                    {...props}
                    appName={appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/employee/messageHistory/:id"
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeMessageHistory
                    {...props}
                    appName={appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/employee/view/chat/:id"
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeViewConsumerChat
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/employee/messageHistory/mail/:id"
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeMailchimpHistory
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path="/employee/skipTrace"
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeSkipTrace
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />

          {/* QuickBook login flow pages */}
          <Route
            exact
            path="/qb-success"
            render={(props) => (
              <QbSuccess
                {...props}
                appName={this.state.appName}
                location={props.location.pathname}
              />
            )}
          />
          <Route
            exact
            path="/qb-failure"
            render={(props) => (
              <QbFailure
                {...props}
                appName={this.state.appName}
                location={props.location.pathname}
              />
            )}
          />
          <Route
            exact
            path="/payment"
            render={(props) => (
              <InvoiceAmountForm
                {...props}
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path="/payment-success"
            render={(props) => (
              <ExpressPaymentSuccess
                {...props}
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                clientName={this.state.clientName}
                location={props.location.pathname}
                invoiceNumber={
                  queryString.parse(props.location.search).invoice_number
                }
                amount={queryString.parse(props.location.search).amount}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path="/payment-failed"
            render={(props) => (
              <ExpressPaymentFailed
                {...props}
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path="/enable-payment/success"
            render={(props) => (
              <StripePaymentSuccess
                {...props}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
              />
            )}
          />
          <Route
            exact
            path="/enable-payment/failed"
            render={(props) => (
              <StripePaymentFailed
                {...props}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                appName={this.state.appName}
                location={props.location.pathname}
              />
            )}
          />
          <Route
            exact
            path="/help"
            render={(props) => (
              <ConsumerGetHelp
                {...props}
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
              />
            )}
          />
        </Switch>
        {showModal ? modal : ""}
        {showModal ? <div className="curtain"></div> : ""}
        <ToastContainer />
      </div>
    );
  }
}

export default withCookies(App);
