
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';
// Components
import AppCanvasLoader from '../../components/App/AppCanvasLoader';
import AppSectionHeader from '../../components/App/AppSectionHeader';
import ConsumerAccountsViewSidebar from '../../components/Consumer/ConsumerAccountsViewSidebar';
import { getZohoPagesense } from '../../utils/helpers';
import ObjectList from '../../components/ObjectList/ObjectList'
import CardView from '../../components/CardView/CardView'
import downloadImg from '../../assets/icons/download.png'
import * as API from '../../utils/api'

class ConsumerDocumentValidate extends Component {
  state = {
      isLoading: true,
      account: {
          id: '',
          companyName: '',
          validationDocuments: ''
      },
      hasAnyError: false
  }      
  componentDidMount() {
    getZohoPagesense();
    ReactGA.pageview('/ConsumerAccountsView');
    const debtId = parseInt(this.props.match.params.id, 10)
    API.getDebt(this.props.user, debtId).then(data => {
        if ((typeof (data) != "undefined") || (data != null)) {
          let { debt, offers } = data
          API.getDebtValidationDocuments(this.props.user, debtId).then(data => {
            if ((typeof (data) != "undefined") || (data != null)) {
              this.setState(state => ({
                account: {
                  id: debt.id,
                  companyName: debt.creditor_name,
                  validationDocuments: data.data
                },
                isLoading: false
              }))
            } else {
              this.setState(state => ({
                ...state,
                hasAnyError: true
              }))
            }
          })
        } else {
          this.setState(state => ({
            ...state,
            hasAnyError: true
          }))
        }
      })
  }

  getCardOptions = (data) => {
    return(
      data.map((document) => {
        return {
          emptyPrimary: true,
          secondaryRows: [
            {
              key: "Name",
              value: document.name
            },
            {
              key: "Description",
              value: document.description
            },
            {
              key: "Download",
              value: <a className="make-payment-button" download={document.name} href={document.document}>
                <img src={downloadImg} width="21" height="21" />
                </a>
            }
          ],
        }
      })
    )
  }

  getObjectListData = (data) => {
    let headings = [],
      columns = []

      headings = [
        "Name",
        "Description",
        "Download"
      ]

      columns = data.map((document) => {
        return {
          primaryColumn: {
              title: document.name,
          },
          secondaryColumns: [
            {
              title: document.description
            },
            {
              title: <a className="make-payment-button" download={document.name} href={document.document}>
                <img src={downloadImg} width="21" height="21" />
                </a>,
              isDownloadBtn: true,
              optionalClasses: 'mobile-hide rmv-right-space'
            }
          ],
        }
      })
    return {
      headings,
      columns
    }
  }

  renderValidationDocuments = (document) => {
    return document && document.length
      ? (
        <div >
          <div className="app-sidebar-layout-canvas">
            <div className="app-sidebar-layout-canvas-content">
                <h2 class="accounts-view-heading">Validation Document</h2>
              <CardView
                 data={this.getCardOptions(document)}
              />
              <ObjectList
                data={this.getObjectListData(document)}
                optionalClasses={"no-row-actions"}
                hideForMobile={true}
              />
            </div>
          </div>
        </div>
      )
      : (
        <div className="consumer-accounts-empty-state">
          <h3 className="consumer-accounts-empty-state-heading">
            No Validation Documents
        </h3>
          <p>
            There are currently no validation documents for this debt.
        </p>
        </div>
      )
  }

  render() {
    const { appName } = this.props;
    let {isLoading, account} = this.state;

    if (this.state.hasAnyError) {
        this.props.updateModalState(true, 'OTHER_ERROR', this.state.hasAnyError);
        return <Redirect to={{ pathname: '/sign-out' }} />
      }

      return isLoading ? 
      <AppCanvasLoader/> : (
        <div className="accounts-view consumer-accounts-view app-sidebar-layout">
          <Helmet>
            <title>{appName} | Account: {account.companyName}</title>
          </Helmet>
          <div className="wrap user-portal-collapse-wrap">
            <AppSectionHeader
              title={account.companyName}
              optionalClassNames="secondary consumer-portal-app-header"
              backLink={{
                text: "Back to Debt Account",
                path: `/dashboard/accounts/${account.id}`
              }}
            />
            <div className="scrollbar" style={{ height: window.innerHeight * 0.65, overflow: 'auto', paddingBottom: 50 }}>
              <div className="app-sidebar-layout-canvas">
                <div className="app-sidebar-layout-canvas-content">
                  {this.renderValidationDocuments(account.validationDocuments)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
  }
}

export default ConsumerDocumentValidate
