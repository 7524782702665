// Packages
import React, { Component } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsLogo from "../../components/Consumer/ClientsLogo";
import * as API from "../../utils/api";
import { getZohoPagesense } from "../../utils/helpers";
import ExpressHeader from "./ExpressHeader";
import InvoiceDetails from "./InvoiceDetails";

class InvoiceAmountForm extends Component {
  state = {
    invoiceDetails: [],
    invoiceNumber: {
      value: "",
      hasError: false,
    },
    amount: {
      value: "0.00",
      hasError: false,
    },
    isLoading: false,
    showDetails: false,
  };

  componentDidMount() {
    getZohoPagesense();
    ReactGA.pageview("/payInvoiceForm");
  }

  handleInputBlur = () => {
    let { amount } = this.state;
    if (amount.value) {
      amount.value = parseFloat(amount.value).toFixed(2);
      this.setState({ amount: this.state.amount });
    }
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      ...newPartialInput,
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      this.getInvoiceDetails(
        this.state.invoiceNumber.value,
        this.state.amount.value
      );
    }
  };

  getInvoiceDetails = (invoiceNumber, amount, withinInvoice) => {
    API.getInvoiceForExpressPayment(invoiceNumber, amount, withinInvoice).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.status_code &&
        data.status_code == 200
      ) {
        this.setState({ invoiceDetails: data.data, showDetails: true });
      } else {
        this.props.updateModalState(true, "ERROR", {
          message: data && data.message ? data.message : "Please try again.",
        });
      }
    });
  };

  validateForm = () => {
    let errorsArePresent = false;
    const { invoiceNumber, amount } = this.state;
    if (invoiceNumber.value === "" || invoiceNumber.value === null) {
      invoiceNumber.hasError = true;
      errorsArePresent = true;
    }
    if (amount.value === "" || amount.value === null || amount.value < 0) {
      amount.hasError = true;
      errorsArePresent = true;
    }
    if (errorsArePresent) {
      this.setState({
        invoiceNumber: this.state.invoiceNumber,
        amount: this.state.amount,
      });
      return false;
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";

    if (input === "number") {
      message = "Please enter an invoice number";
    } else if (input === "amount") {
      message = "Please enter a valid amount";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  render() {
    const { appName, clientName, stripePubKey } = this.props;
    const {
      isLoading,
      invoiceNumber,
      amount,
      invoiceDetails,
      showDetails,
    } = this.state;
    if (showDetails) {
      return (
        <InvoiceDetails
          stripePubKey={stripePubKey}
          appName={appName}
          invoiceNumber={invoiceNumber.value}
          amount={amount.value}
          invoiceDetails={invoiceDetails}
          clientName={clientName}
          updateAmount={this.getInvoiceDetails}
          updateModalState={this.props.updateModalState}
        />
      );
    }
    if (isLoading) {
      return <AppCanvasLoader />;
    } else {
      return (
        <div className="app-portal">
          <Helmet>
            <title>{appName} | Payment</title>
          </Helmet>
          <ExpressHeader appName={appName} />
          <div className="app-sidebar-layout-canvas">
            <div className="app-sidebar-layout-canvas-content express-session-fill">
              <ClientsLogo color={"#000"} />
              <form className="express-pay-form">
                <div
                  className={`input-container consumer-input${
                    invoiceNumber.hasError ? " error" : ""
                  }`}
                >
                  <label>Invoice Number</label>
                  <input
                    name="number"
                    placeholder="Enter Invoice Number"
                    type="text"
                    value={invoiceNumber.value || ""}
                    onChange={(event) =>
                      this.handleInputChange({
                        invoiceNumber: {
                          value: event.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                  {invoiceNumber.hasError
                    ? this.renderErrorMessage("number")
                    : null}
                </div>
                <div
                  className={`input-container consumer-input amount-input ov-amt-in ${
                    amount.hasError ? " error" : ""
                  }${amount.value ? " amount-input-value" : ""}`}
                  style={{ marginTop: 0 }}
                >
                  <label>Invoice Amount</label>
                  {amount.value ? <span>$</span> : null}
                  <input
                    name="amount"
                    placeholder="Enter Invoice Amount"
                    type="number"
                    // pattern="[0-9]*"
                    pattern="^(\d+)(,\d{1,2}|.\d{1,2})?$"
                    inputmode="numeric"
                    value={amount.value || ""}
                    onBlur={() => this.handleInputBlur()}
                    onChange={(event) =>
                      this.handleInputChange({
                        amount: {
                          value: event.target.value
                            ? event.target.value.slice(0, 10)
                            : event.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                  {amount.hasError ? this.renderErrorMessage("amount") : null}
                </div>
                <input
                  style={{ marginTop: "1em" }}
                  className="cta submit-btn-align mg-brand2-color"
                  type="submit"
                  value="Submit"
                  onClick={(event) => this.handleSubmit(event)}
                />
              </form>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default InvoiceAmountForm;
