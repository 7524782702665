import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import locationIcon from "../../../assets/images/navbar/location.png";
import mailIcon from "../../../assets/images/navbar/mail.png";
import phoneIcon from "../../../assets/images/navbar/phone.png";
import Panel from "../../../components/App/Panel";
import shortid from "shortid";

class ClientsCreditMemoSidebar extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    getTitle = (user) => {
      let fullname = "";
      if (user.display_name) {
        fullname = user.display_name;
      } else if (user.company_name) {
        fullname = user.company_name;
      }
      return fullname;
    };

    renderAddress(account) {
      if (
        account.street_address_primary ||
        account.street_address_secondary ||
        account.city ||
        account.state ||
        account.zipcode
      ) {
        return (
          <h4 className="ic-sp">
            {account.street_address_primary ? account.street_address_primary : ""}
            {account.street_address_primary && <br />}
            {account.street_address_secondary
              ? account.street_address_secondary
              : ""}
            {account.street_address_secondary && <br />}
            {account.city ? account.city : ""}
            {account.city && account.state ? ", " : ""}
            {account.state ? account.state : ""}
            {account.state && account.zipcode ? " " : ""}
            {account.zipcode ? account.zipcode : ""}
          </h4>
        );
      }
    }

    render() {
        let { title, optionalClassNames, creditMemoDetails, isShowHistory, backPath } =
          this.props;
        const id = this.props.match.params.id;
        let { user } = creditMemoDetails;
        title = this.getTitle(user);
    
        return (
          <div className="app-sidebar app-sidebar-left accounts-view-sidebar clients-side-bar col1-scroll scrollbar">
            <div
              className={`app-section-header client-section-header${
                optionalClassNames ? " " + optionalClassNames : ""
              }`}
              style={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              <div className="app-section-header-content">
                <Link
                  to={`${isShowHistory ? `/client/invoices/${id}` : backPath}`}
                  className="app-section-header-back-link"
                  onClick={() => this.props.showHistory(false)}
                >
                  « Back
                </Link>
                <h1
                  className="app-section-header-heading side-hed-col"
                  style={{ fontSize: "2em" }}
                >
                  {title}
                </h1>
              </div>
            </div>
            <div className="invoice-sidebar">
              {(user && user.first_name) || user.last_name ? (
                <h4
                  className="panel-heading-secondary"
                  style={{ fontSize: "1em", fontWeight: 600 }}
                >
                  {user && user.first_name + " " + user.last_name}
                </h4>
              ) : null}
              {user && user.email ? (
                <h4 className="panel-heading-secondary ic-sp">{user.email}</h4>
              ) : null}
              {user && user.phone_number ? (
                <h4 className="panel-heading-secondary ic-sp">
                  {user.phone_number}
                </h4>
              ) : null}
              {user ? this.renderAddress(user) : null}
            </div>
          </div>
        );
      }
}

export default ClientsCreditMemoSidebar;