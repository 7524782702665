import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

// Components
import OnboardingHeader from '../../components/Onboarding/OnboardingHeader';
import SessionsModal from '../../components/Sessions/SessionsModal';
import OnboardingVerifyIdentityForm from '../../components/Onboarding/OnboardingVerifyIdentityForm';

import {getZohoPagesense} from '../../utils/helpers';

class OnboardingVerifyIdentity extends Component {

  componentDidMount(){
    getZohoPagesense();
    ReactGA.pageview('/OnboardingVerifyIdentity');
  }

  render() {
    let { appName } = this.props;

    return (
      <div className="sessions fill-screen">
        <Helmet>
          <title>{appName} | Verify Your Identity</title>
        </Helmet>
        <SessionsModal
          optionalClassName="onboarding-verify-identity"
        >
          <OnboardingHeader
            optionalClassName="nav-bar"
          />
          <div className="sessions-modal-content">
            <h1 className="sessions-modal-heading">
              Verify your identity
                </h1>
            <p>
              We need to verify your identity before being able to view your current debt collections accounts. We will not share this information with anyone, ever.
                </p>
            <OnboardingVerifyIdentityForm user={this.props.user} updateModalState={this.props.updateModalState} />
          </div>
        </SessionsModal>
      </div>
    );
  }
}

export default OnboardingVerifyIdentity;
