import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

// Components
import OnboardingHeader from '../../components/Onboarding/OnboardingHeader';
import SessionsModal from '../../components/Sessions/SessionsModal';
import OnboardingCreateAccountForm from '../../components/Onboarding/OnboardingCreateAccountForm';

import {getZohoPagesense} from '../../utils/helpers';

class OnboardingCreateAccount extends Component {

  componentDidMount(){
    getZohoPagesense();
    ReactGA.pageview('/OnboardingCreateAccount');
  }

  render() {
    let { appName } = this.props;

    return (
      <div className="sessions fill-screen">
        <Helmet>
          <title>{appName} | Create an Account</title>
        </Helmet>
        <SessionsModal
          optionalClassName="onboarding-create-account"
        >
          <OnboardingHeader
            optionalClassName="nav-bar"

          />
          <div className="sessions-modal-content">
            <h1 className="sessions-modal-heading">
              Create your account and settle your debt!
                </h1>
            <OnboardingCreateAccountForm updateUserLogin={this.props.updateUserLogin} updateModalState={this.props.updateModalState} />
            <div className="sessions-form-note">
              <span>Already have an account? </span>
              <Link
                to="/login"
                className="text-link">
                Login
                  </Link>
            </div>
          </div>
        </SessionsModal>
      </div>
    );
  }
}

export default OnboardingCreateAccount;
