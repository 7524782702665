import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import * as API from "../utils/api";
import companyData from "../assets/styles/theme.json";
import MakeGoodLogo from "../../src/assets/icons/MG-logo.png";

class Logo extends Component {
  static propTypes = {
    circular: PropTypes.bool,
    linkPath: PropTypes.string,
    linkTitle: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      company_name: companyData.name, //appName
      company_logo: companyData.logo
        ? companyData.logo.replace(/["']/g, "")
        : "",
    };
  }

  componentWillMount() {
    var me = this;
    API.getAppInfo().then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.errors) {
        if (data.appData) {
          me.setState((state) => ({
            ...state,
            company_name: me.state.company_name, //appName
            company_logo: me.state.company_logo,
          }));
        }
      }
    });
  }

  render() {
    const { accountHolder, hideNav, footerLogo } = this.props;
    if (accountHolder && accountHolder === "consumer") {
      return (
        <div>
          {this.state.company_logo && hideNav ? (
            <div style={{ margin: "0 0 0 0", float: "left" }}>
              <img height={40} src={this.state.company_logo} />
            </div>
          ) : (
            this.state.company_logo && (
              <div
                style={
                  footerLogo
                    ? { margin: "0 0 0 0", width: 40, float: "left" }
                    : { margin: "0 0 0 0", width: 200, float: "left" }
                }
              >
                <img
                  style={
                    footerLogo ? { width: "100%", height: 36 } : { width: 200 }
                  }
                  src={this.state.company_logo}
                />
              </div>
            )
          )}
        </div>
      );
    } else {
      return (
        <div>
          {hideNav ? (
            <div
              className="companyLogoDiv"
              style={{ margin: "0 0 0 0", float: "left" }}
            >
              <img
                className="companyLogoDiv-consumer-view"
                src={MakeGoodLogo}
              />
            </div>
          ) : (
            <div
              className="companyLogoDiv"
              style={
                footerLogo
                  ? { margin: "0 0 0 0", width: 40, float: "left" }
                  : { margin: "0 0 0 0", width: 200, float: "left" }
              }
            >
              <img
                style={
                  footerLogo ? { width: "100%", height: 36 } : { width: 200 }
                }
                src={MakeGoodLogo}
              />
            </div>
          )}
        </div>
      );
    }
  }
}

export default Logo;
