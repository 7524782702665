import React from 'react';
import ReactDOM from 'react-dom';
import JavascriptTimeAgo from 'javascript-time-ago'

import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';


import App from './App';
import ScrollToTop from './components/ScrollToTop'
import './assets/styles/style.css';
import registerServiceWorker from './registerServiceWorker';


// The desired locales.
import en from 'javascript-time-ago/locale/en'
import ru from 'javascript-time-ago/locale/ru'


// Initialize the desired locales.
JavascriptTimeAgo.locale(en)
JavascriptTimeAgo.locale(ru)



ReactDOM.render(
  <BrowserRouter>
    <CookiesProvider>
      <ScrollToTop>
          <App />
      </ScrollToTop>
    </CookiesProvider>
  </BrowserRouter>,
  document.getElementById('root')
);



registerServiceWorker();


