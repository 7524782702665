import React, { Component } from 'react'
import { Helmet } from "react-helmet"
import { Redirect } from 'react-router-dom';

// API
import * as API from '../../utils/api'

// Components
import AppSectionHeader from '../../components/App/AppSectionHeader'
import AppCanvasLoader from '../../components/App/AppCanvasLoader'
import AdminSidebar from '../../components/Admin/AdminSidebar'
import ObjectList from '../../components/ObjectList/ObjectList'

import CounterofferModal from '../../components/App/Modals/Admin/CounterofferModal'

// Utils
import {
  getStrippedDollarAmount,
  getNumberWithCommas, networkMessage,
  showToast
} from '../../utils/helpers'

class AdminAccountsView extends Component {

  state = {
    user: {},
    consumer: {},
    debts: [],
    offers: [],
    isLoading: true,
    selectedOfferForCounter: {},
    showCounterOfferModal: false,
    currentModal: null,
    optionalModalProps: null,
    error: {
      hasAnyError: false,
      statusCode: 200
    }
  }

  componentDidMount() {
    const accountId = this.props.match.params.id
    API.getConsumer(this.props.user, accountId).then(data => {
      if (((typeof data != 'undefined') || (data != null)) && (!data.message)) {
        let offersForReview = data.account.offers.filter((offer) => {
          return offer.status === 'pending'
        })

        this.setState(state => ({
          ...state,
          user: this.props.user,
          consumer: data.account.consumer,
          debts: data.account.debts,
          offers: offersForReview,
          isLoading: false
        }))
      } else {
        this.setState(state => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          }
        }))
      }
    })
  }

  getAccountFromData = (id, users) => {
    let currentId = parseInt(id, 10)
    let user = users.filter(function (user) {
      return user.id === currentId
    })

    return user[0]
  }

  handleRowActionClick = (event) => {
    const actionBehavior = event.target.getAttribute('data-behavior')
    const offerId = event.target.getAttribute('data-target')

    switch (actionBehavior) {
      case "offer-accept":
        API.adminAcceptOffer(this.props.user, offerId).then(data => {
         if (((typeof data != 'undefined') || (data != null)) && !data.error) {
            this.handleOfferActionResponse(data, offerId, actionBehavior)
          } else {
            this.setState(state => ({
              ...state,
              error: {
                hasAnyError: true,
                statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
              }
            }))
          }
        })

        break
      case "offer-counter":
        this.handleCounterOffer(offerId)
        break
      case "offer-reject":
        API.adminRejectOffer(this.props.user, offerId).then(data => {
         if (((typeof data != 'undefined') || (data != null)) && !data.error) {
            this.handleOfferActionResponse(data, offerId, actionBehavior)
          } else {
            this.setState(state => ({
              ...state,
              error: {
                hasAnyError: true,
                statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
              }
            }))
          }
        })
        break
      default:
        alert('Error: Something went wrong')
    }
  }

  handleCounterOffer = (offerId) => {
    let selectedOffer = this.getOffer(offerId)
    let modalProps = {}

    if (selectedOffer.number_of_payments === 1) {
      this.setState(state => ({
        ...state,
        showCounterOfferModal: true,
        selectedOfferForCounter: selectedOffer
      }))
    } else {
      modalProps = {
        message: "This offer cannot be countered because the consumer wants to make multiple payments. Please accept or reject this offer."
      }
      this.props.updateModalState(true, 'ERROR', modalProps)
    }
  }

  handleOfferActionResponse = (data, offerId, actionBehavior) => {
    if (data.message) {
      this.hideCounterOfferModal()
      this.props.updateModalState(true, 'ERROR', { message: data.message })
    } else {
      let optionalProps = {}
      let { offers, consumer } = this.state

      switch (actionBehavior) {
        case "offer-accept":

          let selectedOffer = offers.filter((offer) => {
            return offer.id === parseInt(offerId, 10)
          })

          optionalProps = {
            user: consumer,
            offer: selectedOffer[0]
          }
          this.props.updateModalState(true, 'OFFER_ACCEPTED', optionalProps)
          break
        case "offer-counter":
          optionalProps = {
            user: consumer,
            appEmail: this.props.appEmail
          }
          this.props.updateModalState(true, 'COUNTEROFFER_SENT', optionalProps)
          this.hideCounterOfferModal()
          break
        case "offer-reject":
          optionalProps = {
            user: consumer
          }
          this.props.updateModalState(true, 'OFFER_REJECTED', optionalProps)
          break
        default:
          alert('Error: Something went wrong')
      }

      let newOffers = offers.filter((offer) => {
        return offer.id !== parseInt(offerId, 10)
      })

      this.setState(state => ({
        ...state,
        offers: newOffers
      }))
    }
  }

  hideCounterOfferModal = () => {
    this.setState(state => ({
      ...state,
      showCounterOfferModal: false,
    }))
  }

  getOffer = (offerId) => {
    let { offers } = this.state

    let selectedOffer = offers.filter((offer) => {
      return offer.id === parseInt(offerId, 10)
    })

    return selectedOffer[0]
  }

  getObjectListData = (data, tableType) => {
    let headings = [],
      columns = []

    if (tableType === 'review-offers') {
      headings = [
        "Offer For",
        "User's Offer",
        "Original Debt"
      ]

      columns = data.map((offer) => {
        return {
          primaryColumn: {
            title: offer.creditor_name,
          },
          secondaryColumns: [
            {
              title: `$${offer.total_in_dollars_as_currency} (${parseFloat(offer.number_of_payments) === 1 ? 'one time payment' : offer.number_of_payments + ' payments'})`,
            },
            {
              title: `$${offer.original_debt_in_dollars}`
            }
          ],
          rowType: 'overflowMenu',
          rowActions: [
            {
              title: 'Accept Offer',
              optionalClasses: 'alert-positive',
              dataBehavior: 'offer-accept',
              dataTarget: offer.id
            },
            {
              title: 'Counter Offer',
              dataBehavior: 'offer-counter',
              dataTarget: offer.id
            },
            {
              title: 'Reject Offer',
              optionalClasses: 'alert',
              dataBehavior: 'offer-reject',
              dataTarget: offer.id
            }
          ]
        }
      })
    } else {
      headings = [
        "Creditor",
        "Balance",
        "Paid Debt"
      ]

      columns = data.map((debt) => {
        return {
          primaryColumn: {
            title: debt.creditor_name,
          },
          secondaryColumns: [
            {
              title: `$${debt.balance_in_dollars_as_currency}`,
            },
            {
              title:
                `$${getNumberWithCommas(
                  (
                    getStrippedDollarAmount(debt.total_in_dollars_as_currency) - getStrippedDollarAmount(debt.balance_in_dollars_as_currency)
                  ).toFixed(2)
                )}`
            }
          ],
          rowType: 'expander',
          expanderOptions: {
            headings: ['Date', 'Message'],
            rows: debt.additional_details
              ? [
                {
                  columnPrimary: '1/1/2017',
                  columnSecondary: debt.additional_details
                }
              ]
              : null,
          } // end expanderOptions
        }
      })
    } // End if else

    return {
      headings,
      columns
    }
  }

  renderTable = (data, tableType) => {
    let emptyStateMessage = ''
    let tableData = null

    if (tableType === 'review-offers') {
      emptyStateMessage = 'No offers to review.'

      tableData = data
    } else if (tableType === 'open-debts') {
      emptyStateMessage = 'No Open Debts'

      tableData = data.filter((row) => {
        return row.status === 'settled' ? false : true
      })
    } else if (tableType === 'closed-debts') {
      emptyStateMessage = 'No Closed Debts'
      tableData = data.filter((row) => {
        return row.status === 'settled' ? true : false
      })
    }

    return tableData.length > 0
      ? (
        <ObjectList
          data={this.getObjectListData(tableData, tableType)}
          rowActionClick={(event) => this.handleRowActionClick(event)}
        />
      )
      : (
        <div className="object-list-empty-state">
          <span className="object-list-empty-state-message">
            {emptyStateMessage}
          </span>
        </div>
      )
  }

  renderOfferReviewTable = (consumer, offers, tableType) => {
    let emptyStateMessage = 'No offers to review.'
    let activeOffers = offers.filter((offer) => {
      return offer.status !== 'accepted'
    })

    return activeOffers.length > 0
      ? (
        <ObjectList
          optionalClasses="offer-review-table"
          data={this.getObjectListData(activeOffers, tableType)}
          rowActionClick={(event) => this.handleRowActionClick(event)}
        />
      )
      : (
        <div className="object-list-empty-state">
          <span className="object-list-empty-state-message">
            {emptyStateMessage}
          </span>
        </div>
      )
  }

  render() {
    const { appName, user } = this.props
    const {
      consumer,
      debts,
      isLoading,
      offers,
      selectedOfferForCounter,
      showCounterOfferModal,
      error
    } = this.state

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }


    const modal = showCounterOfferModal ? (
      <CounterofferModal
        handleOfferActionResponse={this.handleOfferActionResponse}
        user={user}
        offerRecipient={consumer}
        offer={selectedOfferForCounter}
        hideModal={this.hideCounterOfferModal}
        updateModalState={this.props.updateModalState}
      />
    ) : null

    let first_name = consumer.first_name? consumer.first_name : "";
    let last_name = consumer.last_name? consumer.last_name : "";

    return isLoading
      ? (
        <AppCanvasLoader />
      )
      : (
        <div className="accounts-view app-sidebar-layout">
          <Helmet>
            <title>{appName} | {first_name + ' ' + last_name}</title>
          </Helmet>
          <AppSectionHeader
            title={first_name + ' ' + last_name}
            optionalClassNames="secondary"
            backLink={{
              text: "Back to All Accounts",
              path: "/admin/accounts"
            }}
          />
          <div className="app-sidebar-layout-canvas">
            <AdminSidebar
              account={consumer}
              name={consumer.id}
            />
            <div className="app-sidebar-layout-canvas-content">
              <h2 className="accounts-view-heading">
                Review Offers
                </h2>
              {this.renderOfferReviewTable(consumer, offers, 'review-offers')}
              <h2 className="accounts-view-heading">
                Open Debt
                </h2>
              {this.renderTable(debts, 'open-debts')}
              <h2 className="accounts-view-heading">
                Closed Debt
                </h2>
              {this.renderTable(debts, 'closed-debts')}
            </div>
          </div>
          {showCounterOfferModal ? modal : ''}
          {showCounterOfferModal ? (
            <div className="curtain" ></div>
          ) : ''}
        </div>
      )
  }
}

export default AdminAccountsView