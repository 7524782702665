import React, { Component } from 'react'
import { Helmet } from "react-helmet"
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

// API
import * as API from '../../../utils/api';
import { showToast, getZohoPagesense } from '../../../utils/helpers';

// Components
import AppSectionHeader from '../../../components/App/AppSectionHeader'
import AppCanvasLoader from '../../../components/App/AppCanvasLoader'
import Tabs from '../../../components/Tabs'
import ConsumerAccountsPaymentForm from '../../../components/Consumer/Forms/ConsumerAccountsPaymentForm'
import ConsumerAccountsViewSidebar from '../../../components/Consumer/ConsumerAccountsViewSidebar'


class ConsumerAccountsPaymentSettleAccount extends Component {

  state = {
    account: {
      debtId: '',
      originalCollectionAmount: '',
      creditorName: '',
      numberOfOffers: '',
    },
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200
    }
  }

  componentDidMount() {
    const debtId = parseInt(this.props.match.params.id, 10)

    this.props.updatePrevPath(`/dashboard/accounts/${debtId}`)

    API.getDebt(this.props.user, debtId).then(data => {
      if (data.status_code === 401) {
        this.status.error.hasAnyError = true;
        this.status.error.statusCode = 401;
        this.setState({
          error: this.status.error
        })
      }
      if (((typeof (data) != "undefined") || (data != null)) && !data.error) {
        let { debt, offers } = data

        this.setState(state => ({
          account: {
            debtId: debt.id,
            originalCollectionAmount: debt.balance_in_dollars_as_currency,
            creditorName: debt.creditor_name,
            numberOfOffers: offers.length,
            offers: offers
          },
          debt: debt,
          isLoading: false
        }))
      } else {
        this.setState(state => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          }
        }))
      }
    })

    getZohoPagesense();
    ReactGA.pageview('/ConsumerAccountsPaymentSettleAccount');
  }

  handleTabClick = (tabIndex) => {
    const { account } = this.state

    switch (parseInt(tabIndex, 10)) {
      case 1:
        this.props.history.push(`/dashboard/accounts/${account.debtId}/validate`)
        break
      case 2:
        this.props.history.push(`/dashboard/accounts/${account.debtId}/negotiate`)
        break
      case 3:
        break
      default:
        console.log('error')
        return
    }
  }

  handleFormSubmit = (stripeToken, amount) => {
    this.handleAccountSettlement(stripeToken, amount)
  }

  handleAccountSettlement = (stripeToken, amount) => {
    API.settleAccountPayment(this.props.user, stripeToken, this.state.account.debtId, amount).then(data => {
      if (((typeof (data) != "undefined") || (data != null)) && !data.error) {
        if (data.paymentComplete) {
          this.props.updateModalState(true, 'USER_PAYMENT_SETTLED', data)
        } else {
          this.props.updateModalState(true, 'USER_PAYMENT_FAILED')
        }
        this.props.history.push(`/dashboard/accounts`)
      } else {
        if (data.error) {
          this.props.updateModalState(true, 'ERROR', { message: data.error });
        }
        this.setState(state => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          }
        }))
      }
    })
  }

  renderTabsOrHeading = () => {
    const { account } = this.state

    if (account.numberOfOffers === 0) {
      const tabLabels = ['Validate', 'Negotiate', 'Pay']

      return (
        <Tabs
          labels={tabLabels}
          activeTarget={3}
          optionalClasses="consumer-pay-tab"
          handleTabChange={this.handleTabClick}
        />
      )
    } else {
      return (
        <h2 className="consumer-accounts-payment-heading">
          Make a Payment
        </h2>
      )
    }
  }

  render() {
    const { appName } = this.props
    const { account, error, debt, isLoading } = this.state;
    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    return isLoading
      ? (
        <AppCanvasLoader />
      ) : (
        <div className="accounts-view consumer-accounts-view app-sidebar-layout">
          <Helmet>
            <title>{appName} | Settle an Account</title>
          </Helmet>
          <div className="wrap user-portal-collapse-wrap">
            <AppSectionHeader
              title={account.creditorName}
              subtitle={`$${account.originalCollectionAmount}`}
              optionalClassNames="secondary reversed-title consumer-portal-app-header"
              backLink={{
                text: account.numberOfOffers > 0 ? "Back to Account" : "Back to All Accounts",
                path: account.numberOfOffers > 0 ? `/dashboard/accounts/${account.debtId}` : `/dashboard/accounts/`
              }}
            />
            <div className="scrollbar tab-spacing-negotiate" style={{ height: window.innerHeight * 0.65, overflow: 'auto' }}>
              <div className="app-sidebar-layout-canvas">
                <ConsumerAccountsViewSidebar
                  account={account}
                  debt={debt}
                />
                <div className="app-sidebar-layout-canvas-content">
                  {this.renderTabsOrHeading()}
                  <div>
                    <div className="collapse-wrap-padded-content">
                      <div className="mini-wrap">
                        <ConsumerAccountsPaymentForm
                          handleFormSubmit={this.handleFormSubmit}
                          account={account}
                          user={this.props.user}
                          formType="settle-account"
                          stripePubKey={this.props.stripePubKey}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
  }
}

export default ConsumerAccountsPaymentSettleAccount
