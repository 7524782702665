import React, { Component } from "react";
import ReactGA from "react-ga";
import Intercom from "react-intercom";
import { Link } from "react-router-dom";
import ExpressHeader from "../../screens/QuickBook/ExpressHeader";
// API
import * as API from "../../utils/api";
import { getZohoPagesense, showToast } from "../../utils/helpers";
import AppLoader from "./AppLoader";

class LandingHeader extends Component {
  state = {
    token: {
      value: "",
      hasError: false,
    },
    userData: {},
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    invalidmsg: "",
    showLoader: false,
    showInterCom: false,
  };

  componentDidMount() {
    getZohoPagesense();
    ReactGA.pageview("/MarketingHeader");
    const { token } = this.props;
    this.props.history.push("/register");
    if (token) {
      this.state.token.value = token;
      this.setState({ token: this.state.token });
    }
    setTimeout(() => {
      this.setState({
        showInterCom: true,
      });
    }, 1000);
  }

  submitForm() {
    if (this.state.token.value === "" || this.state.token.value === undefined) {
      this.state.token.hasError = true;
      this.setState({
        token: this.state.token,
      });
    } else {
      this.setState({ showLoader: true });
      API.registrationCodeVerification(this.state.token.value).then((data) => {
        this.setState({ showLoader: false });
        if (data.data && data.message === "success") {
          data.data.token = this.state.token.value;
          this.props.setUserData(data.data);
          this.props.history.push("/registration-form");
        } else {
          if (data && data.message && data.message === "Access denied.") {
            showToast("Access denied.");
          } else if (data && data.message && data.status_code === 400) {
            this.state.token.hasError = true;
            this.setState({
              token: this.state.token,
              invalidmsg: data.message,
            });
          } else {
            //showToast();
          }
        }
      });
    }
  }

  renderErrorMessage() {
    let message = this.state.invalidmsg
      ? "Invalid registration code"
      : "Please enter your registration code";
    return (
      <div style={{ marginTop: "-16px", marginBottom: "1.5em" }}>
        <div className="input-error-message">{message}</div>
      </div>
    );
  }

  handleInputChange(newPartialInput) {
    this.state.token.value = newPartialInput.token.value;
    this.state.token.hasError = newPartialInput.token.hasAnyError;
    this.setState({
      token: this.state.token,
    });
  }

  render() {
    const { token, showLoader, showInterCom } = this.state;
    const { intercomAppId, appName } = this.props;
    // const Logo = companyData.logo ? companyData.logo.replace(/["']/g, "") : '';

    return (
      <div className="landing-root">
        {intercomAppId && showInterCom && <Intercom appID={intercomAppId} />}
        <ExpressHeader appName={appName} hideMenu={true} />
        {showLoader ? (
          <AppLoader />
        ) : (
          <div className="new-landing-hero">
            <div className="new-hero-content">
              <p>MakeGood's Billing & Payment Portal Menu</p>
              <h3>- Review All Invoices Including Invoice History</h3>
              <h3>
                - Make Payments, Review Payment History & Update Payment
                Preferences
              </h3>
              <h3>
                - Manage Billing Contact Information & Billing Preferences
              </h3>
              <h3>- Contact Our Billing Department with Questions</h3>
              <div className="reg-box-content">
                <div className="reg-code">
                  <h2>Registration code</h2>
                </div>
                <div className="reg-text-box">
                  <input
                    type="text"
                    name="text"
                    value={token.value}
                    placeholder="Enter your registration code"
                    className="text-input"
                    onChange={(event) =>
                      this.handleInputChange({
                        token: { value: event.target.value, hasError: false },
                      })
                    }
                  />
                  <a
                    title="Click here"
                    className="submit-btn"
                    onClick={() => this.submitForm()}
                  >
                    <div className="btn-label">GO</div>
                  </a>
                </div>
              </div>
              {token.hasError ? this.renderErrorMessage() : null}
              <div className="login-div">
                <span>Already have an account? </span>
                <Link to="/login" className="login-text-link">
                  Sign In
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default LandingHeader;
