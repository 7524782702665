// Packages
import React, { Component } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect, Link } from "react-router-dom";
import ClientsLogo from "../../components/Consumer/ClientsLogo";
import { getZohoPagesense, validateEmail } from "../../utils/helpers";
import ExpressHeader from "./ExpressHeader";
import * as API from "../../utils/api";

class ExpressPaymentSuccess extends Component {
  state = {
    invoiceNumber: "",
    amount: "",
    email: {
      value: "",
      hasError: false,
    },
    isLoading: false,
    isData: true,
  };

  componentDidMount() {
    getZohoPagesense();
    ReactGA.pageview("/payment-success");
    let { amount, invoiceNumber } = this.props;
    if (!amount || !invoiceNumber) {
      this.setState({ isData: false });
    } else {
      this.setState({ amount, invoiceNumber }, () => {
        this.props.history.push("/payment-success");
      });
    }
  }

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      ...newPartialInput,
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { invoiceNumber, amount, email} = this.state;
    if (this.validateForm()) {
        API.sendPaymentReceipt(invoiceNumber, amount, email.value).then((data) => {
          if (
            (typeof data != "undefined" || data != null) &&
            data.status_code &&
            data.status_code == 200
          ) {
            this.props.updateModalState(true, 'SUCCESS', { message: data.message });
          } else {
            this.props.updateModalState(true, "ERROR", {
              message: data.message,
            });
          }
        });
    }
  };

  validateForm = () => {
    let errorsArePresent = false;
    const { email, amount } = this.state;
    if (email.value === "" || email.value === null) {
      if (!validateEmail(email.value)) {
        errorsArePresent = true;
        this.state.email.hasError = true;
      }
    }
    if (errorsArePresent) {
      this.setState({
        email: this.state.email,
      });
      return false;
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";

    if (input === "email") {
      message = "Please enter a valid email";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  render() {
    const { appName } = this.props;
    const { isLoading, email, isData, invoiceNumber, amount } = this.state;

    if (!isData) {
      return <Redirect to={{ pathname: "/payment" }} />;
    }

    return (
      <div className="app-portal">
        <Helmet>
          <title>{appName}</title>
        </Helmet>
        <ExpressHeader appName={appName} />
        <div className="app-sidebar-layout-canvas">
          <Link to="/payment" className="text-link mg-text-link payment-back-link">« Return to Payment</Link>
          <div className="app-sidebar-layout-canvas-content express-session-fill">
            <ClientsLogo color={"#000"} />
            <form className="express-pay-form">
              <div className="sessions-modal-content">
                <h1 className="sessions-modal-heading ex-payment-success">
                  Payment Successful!
                </h1>
                <p className="qb-msg-text">
                  Your payment of ${amount} for invoice #
                  {invoiceNumber} has been received.
                </p>
              </div>
              <div
                className={`input-container consumer-input${
                  email.hasError ? " error" : ""
                }`}
              >
                <label>Email payment confirmation to:</label>
                <input
                  name="email"
                  placeholder="address@email.com"
                  type="email"
                  value={email.value || ""}
                  onChange={(event) =>
                    this.handleInputChange({
                      email: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
                {email.hasError ? this.renderErrorMessage("email") : null}
              </div>
              <input
                style={{ marginTop: "1em" }}
                className="cta submit-btn-align mg-brand2-color"
                type="submit"
                value="Send"
                onClick={(event) => this.handleSubmit(event)}
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ExpressPaymentSuccess;
