import React, { Component } from 'react';

class Tabs extends Component {
  renderActiveClass(activeTarget, tabIndex) {
    return tabIndex === activeTarget
    ? ' active'
    : ''
  }

  handleTabClick = (event) => {
    let targetElement = event.target.getAttribute('data-target');
    this.props.handleTabChange(parseInt(targetElement, 10) + 1);
  }

  render() {
    const { labels, activeTarget, optionalStyle, optionalClasses, optionalInTabClasses } = this.props;

    return (
      <div className={`tabs${optionalClasses ? ' ' + optionalClasses : ''}`} style={optionalStyle}>
        {
          labels.map((label, index) => {
            return (
              <a
                key={index}
                className={`tabs-tab${this.renderActiveClass(activeTarget, (index + 1))}${optionalInTabClasses ? ' ' + optionalInTabClasses : ''}`}
                data-target={index}
                onClick={(event) => this.handleTabClick(event)}
              >
                {label}
              </a>
            )
          })
        }
      </div>
    );
  }
}

export default Tabs;
