import React, { Component } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Link, Redirect, withRouter } from "react-router-dom";
// Components
import Logo from "../../components/Logo";
import SessionsModal from "../../components/Sessions/SessionsModal";
// Util
import * as API from "../../utils/api";
import { getZohoPagesense, showToast } from "../../utils/helpers";

class OnboardingUserAgreement extends Component {
  state = {
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    getZohoPagesense();
    ReactGA.pageview("/OnboardingUserAgreement");
  }

  acceptUserAgreement = (e) => {
    e.preventDefault();

    API.acceptUserAgreement(this.props.user, true).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.errors) {
        this.props.handleAcceptUserAgreement(this.props.user);
        this.props.history.push("/onboarding/success");
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  declineUserAgreement = (e) => {
    e.preventDefault();

    API.acceptUserAgreement(this.props.user, false).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.errors) {
        this.props.handleSignOut();
        this.props.history.push("/login");
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  render() {
    let { appName } = this.props;
    let { error } = this.state;

    let prevPath = "/onboarding/verify-identity";
    let nextPath = "/onboarding/success";

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <div className="sessions fill-screen mg-session">
        <Helmet>
          <title>{appName} | User Agreement</title>
        </Helmet>
        <SessionsModal optionalClassName="onboarding-user-agreement">
          <div className="sessions-modal-logo-container mg-logo-container">
            <Logo circular={true} />
          </div>
          <div className="sessions-modal-content">
            <h1 className="sessions-modal-heading" style={{ color: "#008bb2" }}>User Agreement</h1>
            <div className="onboarding-user-agreement-text">
              <p className="center">
                By continuing beyond this point by any method other than
                clicking Decline, you confirm that you have read, agree to, and
                shall be bound by the {appName}{" "}
                <a className="mg-text-link" href="https://www.makegood.solutions/terms-of-use" target="_blank">
                  Terms of Use
                </a>
                , and that you have read and understand how we store and collect
                user data outlined in the {appName}{" "}
                <a className="mg-text-link" href="https://makegood.solutions/privacy-policy" target="_blank">
                  Privacy Policy
                </a>
                . If you do not agree to all of the above, please click Decline.
              </p>
            </div>
            <div className="onboarding-user-agreement-actions">
              <Link
                to="/"
                onClick={this.declineUserAgreement}
                className="cta-tertiary-reverse"
              >
                Decline
              </Link>
              <Link
                to={nextPath}
                onClick={this.acceptUserAgreement}
                className="cta mg-button"
              >
                Accept & Continue
              </Link>
            </div>
          </div>
        </SessionsModal>
      </div>
    );
  }
}

export default withRouter(OnboardingUserAgreement);
