import React, { Component } from 'react';
import { withRouter, Redirect } from "react-router-dom";
import MaskedTextInput from 'react-text-mask';
import Select from 'react-select';
import stateDataAbbreviations from '../../utils/stateDataAbbreviations.json';
import ObjectDebtList from '../../components/ObjectList/ObjectDebtList';
import ReactGA from 'react-ga';

// API
import * as API from '../../utils/api';

import { networkMessage, getZohoPagesense } from '../../utils/helpers'


// Components
import Panel from '../../components/App/Panel';
import AppCanvasLoader from '../../components/App/AppCanvasLoader';
import AppSectionHeader from '../../components/App/AppSectionHeader';

class DebtDetail extends Component {

  state = {
    isLoading: true,
    hasAnyError: false,
    debtDetailList: []
  }


  componentDidMount(){
    this.fetchDebtDetail();
    getZohoPagesense();
    ReactGA.pageview('/DebtDetail');
  }

  fetchDebtDetail = () => {
    API.fetchDebtDetail(this.props.user).then(data => {
     if (((typeof data != 'undefined') || (data != null)) && !data.error) {
        this.setState(state => ({
          ...state,
          debtDetailList: data.debts,
          isLoading: false,
          isUploading: false
        }))
      } else {
        this.setState(state => ({
          ...state,
          hasAnyError: true
        }))
      }
    })
  }


  getAccountsObjectListData = () => {
    const { debtDetailList } = this.state

    return {
      headings: [
        "Patient's Name",
        "Birthdate",
        "Patient's SS",
        "Employee's Phone Number",
        "Patient Employer",
        "Origination Date",
        "Bill Provider Id/Name",
        "Procedure",
        "Location Id/Name",
        "Charge",
        "Payments",
        "Discount",
        "Balance"



      ],
      columns: debtDetailList.map((debtDetail) => {
        return {
          primaryColumn: {
          },
          secondaryColumns: [
            {
              title: `${debtDetail.patient_first_name ? debtDetail.patient_first_name : ''} ${debtDetail.patient_last_name ? debtDetail.patient_last_name : ''}`,
            },
            {
              title: `${debtDetail.patient_dob ? debtDetail.patient_dob : ''}`,
            },
            {
              title: `${debtDetail.patient_ss ? debtDetail.patient_ss : ''}`
            },
            {
              title: `${debtDetail.employee_phone_number ? debtDetail.employee_phone_number : ''}`,
            },
            {
              title: `${debtDetail.patient_employer ? debtDetail.patient_employer : ''}`,
            },
            {
              title: `${debtDetail.service_date ? debtDetail.service_date : ''}`,
            },
            {
              title: `${debtDetail.billing_provider_id ? debtDetail.billing_provider_id + "/" : ''} ${debtDetail.billing_provider_name ? debtDetail.billing_provider_name : ''}`
            },
            {
              title: `${debtDetail.procedure_fullname ? debtDetail.procedure_fullname : ''}`
            },
            {
              title: `${debtDetail.location_id ? debtDetail.location_id + "/" : ''} ${debtDetail.location_fullname ? debtDetail.location_fullname : ''}`
            },
            {
              title: `$${debtDetail.charge ? debtDetail.charge : '0.00'}`
            },
            {
              title: `$${debtDetail.payments ? debtDetail.payments : '0.00'}`
            },
            {
              title: `$${debtDetail.adjustment ? debtDetail.adjustment : '0.00'}`
            },
            {
              title: `$${debtDetail.balance ? debtDetail.balance : '0.00'}`
            }
          ]
        }
      })
    }
  }



  render() {
    const { isLoading, debtDetailList } = this.state;

    return isLoading
      ? (
        <AppCanvasLoader />
      )
      : (
        <div className="consumer-debt-detail-form">
          <AppSectionHeader
            title="Debt Detail"
            optionalClassNames="secondary hide-collapse-gradient"
            backLink={{
              text: `Back to Profile`,
              path: `/dashboard/profile`
            }}
          />
          {
            this.state.debtDetailList && this.state.debtDetailList.length > 0 ?
              <ObjectDebtList
                optionalClasses={"object-debt-list"}
                data={this.getAccountsObjectListData()}
              />
              :
              <div className="admin-admins-empty-state">
                <h2 className="empty-message">
                  No Debt Available.
                  </h2>
              </div>
          }
        </div>
      );
  }
}

export default withRouter(DebtDetail);
