import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import filter_icon from "../assets/icons/filter.svg";

// Packages
import onClickOutside from "react-onclickoutside";

class DropdownButton extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
  };

  state = {
    dropdownIsActive: false,
  };

  componentDidUpdate(props) {
    if(props.closeDropDown && props.historyFilter && this.state.dropdownIsActive) {
      this.toggleDropdown();
      this.props.setClose();
    }
  }

  toggleDropdown = () => {
    this.setState((state) => ({
      ...state,
      dropdownIsActive: !state.dropdownIsActive,
    }));
  };

  handleClickOutside = () => {
    if (this.state.dropdownIsActive) {
      this.toggleDropdown();
    }
  };

  renderPrimaryButton(
    title,
    onPrimaryClick,
    primaryLink,
    overrideStyle,
    buttonInnerClasses,
    totalFilter
  ) {
    if (onPrimaryClick && totalFilter) {
      return (
        <a
          className={`mg-brand2-color ${
            buttonInnerClasses ? buttonInnerClasses + " " : ""
          }${
            overrideStyle
              ? "cta dropdown-button-submit override-dropdown-button"
              : "cta dropdown-button-submit"
          }`}
          onClick={() => this.toggleDropdown()}
        >
          {title}
        </a>
      );
    } else if(onPrimaryClick) {
      return (
        <a
          className={`mg-brand2-color ${
            buttonInnerClasses ? buttonInnerClasses + " " : ""
          }${
            overrideStyle
              ? "cta dropdown-button-submit override-dropdown-button"
              : "cta dropdown-button-submit"
          }`}
          onClick={onPrimaryClick}
        >
          {title}
        </a>
      );
    } else {
      return (
        <Link
          className={`mg-brand2-color ${
            buttonInnerClasses ? buttonInnerClasses + " " : ""
          }${
            overrideStyle
              ? "cta dropdown-button-submit override-dropdown-button"
              : "cta dropdown-button-submit"
          }`}
          to={primaryLink}
        >
          {title}
        </Link>
      );
    }
  }

  render() {
    const {
      title,
      children,
      onPrimaryClick,
      primaryLink,
      overrideStyle,
      forFilter,
      parentOptClasses,
      buttonInnerClasses,
      clientMobile,
      historyFilter,
      totalFilter
    } = this.props;

    let { dropdownIsActive } = this.state;

    if (historyFilter) {
      return (
        <a style={{cursor: 'pointer'}} onClick={() => this.toggleDropdown()}>
          <img className="history-filter-icon" src={filter_icon} />
          <ul
            className={`dropdown-menu${dropdownIsActive ? " active" : ""}`}
            onClick={() => this.toggleDropdown()}
            style={this.props.style}
          >
            {children}
          </ul>
        </a>
      );
    }

    return (
      <div
        className={`dropdown-button${
          parentOptClasses ? " " + parentOptClasses : ""
        }`}
      >
        {this.renderPrimaryButton(
          title,
          onPrimaryClick,
          primaryLink,
          overrideStyle,
          buttonInnerClasses,
          totalFilter
        )}
        <a
          className={`cta dropdown-button-toggle mg-brand2-color toggle-sep ${
            dropdownIsActive ? " active" : ""
          }${overrideStyle ? " override-toggle" : ""}`}
          onClick={() => this.toggleDropdown()}
        >
          <div className="caret"></div>
        </a>
        <ul
          className={`dropdown-menu${dropdownIsActive ? " active" : ""}${
            clientMobile ? " mobile-left" : ""
          }`}
          onClick={() => (forFilter ? this.toggleDropdown() : "")}
        >
          {children}
        </ul>
      </div>
    );
  }
}

export default onClickOutside(DropdownButton);
