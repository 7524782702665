import React, { Component } from "react";
import Select from "react-select";
import "react-select/dist/react-select.css";
import MaskedTextInput from "react-text-mask";
import Panel from "../../../components/App/Panel";
import {
  amountMask,
  percentMask,
  quantityMask,
  getDollarAmount,
} from "../../../utils/helpers";
import InfoIcon from "../../../assets/images/quickbooks/ic_info_gray.png";

class InvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.isShow = true;
  }

  //get dollar amount in thousand format
  getThousandAmount = (value) => {
    if (value) return getDollarAmount(value);
    return "";
  };

  handleDiscountFocus = (event) => {
    event.stopPropagation();
    let { qbSetting } = this.props;
    let props = {
      title: "Warning!",
      message:
        "You can't apply Discount without enabling Discounts in your Company Preferences within QuickBooks.",
    };
    if (this.isShow && !qbSetting.allow_discount)
      this.props.updateModalState(true, "SUCCESS", props);
    this.isShow = false;
  };

  renderErrorMessage(input) {
    let message = "";
    console.log('input----------',input)
    if (input === "qty") {
      message = "Please enter Quantity";
    } else if (input === "date") {
      message = "Please enter valid date";
    } else if (input === "rate") {
      message = "Please enter Unit Cost/Hourly Rate";
    } else if (input === "tax") {
      message = "Please enter Tax Rate";
    } else if(input === "discountAmount") {
      message = "Invoice Credit cannot be greater than the Invoice Total.";
    }else{
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  renderInvoiveItem = (invoiceItem, index) => {
    let { serviceList, invoiceDetails } = this.props;
    let isReadOnly = invoiceItem.service.value ? false : true;

    return (
      <Panel>
        {invoiceDetails.length > 1 && (
          <div
            className="close"
            onClick={() => this.props.handleCrossClick("invoice-item", index)}
          />
        )}
        <div className="main-container">
          <div className="half">
            <label htmlFor="service">Goods/Services</label>
            <div
              className={`input-container client-select ${
                invoiceItem.service.hasError ? " error" : ""
              }`}
            >
              <Select
                inputProps={{
                  autoComplete: "none",
                  autoCorrect: "off",
                  spellCheck: "off",
                }}
                className="form-select"
                name="service"
                placeholder="Select Goods/Services"
                options={serviceList}
                value={invoiceItem.service.value}
                onChange={(e) => this.props.saveserviceDetails(e, index)}
                onInputChange={(e) =>
                  e ? this.props.clientServicesSearch(e, () => {}) : ""
                }
              />
              <div className="invoice-tax">
                <input
                  name="taxable"
                  type="checkbox"
                  checked={invoiceItem.taxable.value}
                  style={{
                    WebkitAppearance: `checkbox`,
                    width: `20px`,
                    marginRight: `10px`,
                    marginTop: `1px`,
                  }}
                  onChange={() =>
                    this.props.handleInvoiceDetailsChange(
                      {
                        target: {
                          name: "taxable",
                          value: !invoiceItem.taxable.value,
                        },
                      },
                      index
                    )
                  }
                />
                <div htmlFor="tax">Taxable</div>
              </div>
            </div>
            {invoiceItem.service.hasError && (
              <div className="input-error-message service-error">
                Please select Goods/Services
              </div>
            )}

            <label htmlFor="itemDate">Item Date</label>
            <div
              className={`input-container invoice-fields ${
                invoiceItem.itemDate.hasError ? " error" : ""
              }`}
            >
              <MaskedTextInput
                guide={true}
                mask={[
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                name="itemDate"
                placeholder="mm/dd/yyyy"
                type="text"
                readOnly={isReadOnly}
                value={invoiceItem.itemDate.value}
                onChange={(e) =>
                  this.props.handleInvoiceDetailsChange(e, index)
                }
              />
              {invoiceItem.itemDate.hasError && this.renderErrorMessage("date")}
            </div>
            <div className={`input-container invoice-fields`}>
              <label htmlFor="sedcription">Description</label>
              <input
                name="description"
                placeholder="Enter description"
                type="text"
                readOnly={isReadOnly}
                value={invoiceItem.description.value}
                onChange={(e) =>
                  this.props.handleInvoiceDetailsChange(e, index)
                }
              />
            </div>
          </div>
          <div className="half">
            <div
              className={`input-container invoice-fields ${
                invoiceItem.hours.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="hours">Quantity (Units/Hours)</label>
              <MaskedTextInput
                name="hours"
                placeholder="Enter Quantity"
                mask={quantityMask}
                readOnly={isReadOnly}
                value={invoiceItem.hours.value}
                onChange={(e) =>
                  this.props.handleInvoiceDetailsChange(e, index)
                }
              />
              {invoiceItem.hours.hasError && this.renderErrorMessage("qty")}
            </div>
            <div
              className={`input-container invoice-fields ${
                invoiceItem.rate.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="rateprice">Unit Cost/Hourly Rate</label>
              {invoiceItem.rate.value ? (
                <MaskedTextInput
                  name="rate"
                  readOnly={isReadOnly}
                  mask={amountMask}
                  value={invoiceItem.rate.value}
                  autoFocus={invoiceItem.rate.value.length <= 1 ? true : false}
                  onChange={(e) =>
                    this.props.handleInvoiceDetailsChange(e, index)
                  }
                />
              ) : (
                <input
                  name="rate"
                  placeholder="Enter Unit Cost/Hourly Rate"
                  readOnly={isReadOnly}
                  value={invoiceItem.rate.value}
                  onChange={(e) =>
                    this.props.handleInvoiceDetailsChange(e, index)
                  }
                />
              )}
              {invoiceItem.hours.hasError && this.renderErrorMessage("rate")}
            </div>
            <div className={`input-container invoice-fields`}>
              <label htmlFor="total">Item Amount Total</label>
              <input
                name="total"
                placeholder="$0.00"
                readOnly
                value={
                  invoiceItem.total.value
                    ? this.getThousandAmount(invoiceItem.total.value)
                    : ""
                }
              />
            </div>
          </div>
        </div>
      </Panel>
    );
  };

  render() {
    let {
      isEdit,
      qbSetting,
      invoiceDiscountType,
      invoiceLateFeesType,
      invoiceDetails,
      invoiceSubTotal,
      taxableSubTotal,
      from
    } = this.props;

    let checkForTax =
      !isEdit &&
      qbSetting &&
      qbSetting.using_sales_tax &&
      invoiceSubTotal.taxRate.value > 0
        ? true
        : false;

    let taxInDollar = (taxableSubTotal * invoiceSubTotal.taxRate.value) / 100;

    return (
      <div className="add-invoice-inner-panel">
        {this.props.headerContent(this.props && this.props.headerText ? this.props.headerText : "Invoice Details")}
        {invoiceDetails && invoiceDetails.length
          ? invoiceDetails.map((invoiceItem, index) => {
              return this.renderInvoiveItem(invoiceItem, index);
            })
          : null}
        {this.props.innerButtons(
          <button
            onClick={this.props.handleAddItemClick}
            className="inner-btn mg-brand2-color"
          >
            Add More Items
          </button>
        )}
        <div className="main-container">
          <div className="half right-algn total-section">
            <div className={`input-container invoice-fields`}>
              <label htmlFor="subtotal">Invoice Subtotal</label>
              <input
                name="subtotal"
                readOnly
                className="disabled"
                placeholder="$0.00"
                value={
                  invoiceSubTotal.subTotal.value
                    ? this.getThousandAmount(invoiceSubTotal.subTotal.value)
                    : ""
                }
              />
            </div>
            <div className="inline-input">
              <div
                className={`input-container invoice-fields inner-left ${
                invoiceSubTotal.taxRate.hasError ? " error" : ""
              }`}
              >
                <label htmlFor="taxrate">Tax Rate ( % )</label>
                <MaskedTextInput
                  type="text"
                  name="taxRate"
                  placeholder="Enter Tax Rate"
                  mask={percentMask}
                  readOnly={checkForTax}
                  value={invoiceSubTotal.taxRate.value}
                  onChange={this.props.handleInvoiceSubTotalChange}
                />
                {invoiceSubTotal.taxRate.hasError &&
                  this.renderErrorMessage("tax")}
              </div>
              <div className={`input-container invoice-fields inner-right`}>
                <label htmlFor="taxrate">Tax Amount</label>
                <input
                  placeholder="$0.00"
                  readOnly
                  className="disabled"
                  value={taxInDollar ? this.getThousandAmount(taxInDollar) : ""}
                />
              </div>
            </div>
            {!from || from !== 'credit_memo' ?
            <div className="inline-input" style={{alignItems: 'flex-start'}}>
              <div className={`input-container invoice-fields inner-left `}>
                <label
                  className="label-with-checkbox lwc-phone"
                  htmlFor="discount"
                >
                  <span>
                    {/* Apply Discount to Invoice */}
                    Apply Invoice Credit
                    <img
                      src={InfoIcon}
                      className="info-tooltip"
                      title="Entering a discount here applies an immediate discount to the invoice that is not based on the Discount Terms."
                    />
                  </span>
                  {this.props.renderCheckBoxes(
                    invoiceDiscountType,
                    this.props.handleInvoiceDiscountTypeChange
                  )}
                </label>
                <MaskedTextInput
                  type="text"
                  name="discount"
                  // placeholder="Enter Discount"
                  placeholder="Enter Invoice Credit Percentage"
                  readOnly={invoiceDiscountType == "DOLLAR"}
                  className={`${
                    invoiceDiscountType == "DOLLAR" ? " disabled" : ""
                  }`}
                  mask={percentMask}
                  onFocus={this.handleDiscountFocus}
                  value={invoiceSubTotal.discount.value}
                  onChange={this.props.handleInvoiceSubTotalChange}
                />

              </div>
              <div className={`input-container invoice-fields inner-right ${
                invoiceSubTotal.discountAmount.hasError ? " error" : ""
              }`}>
                <label htmlFor="discount">Invoice Credit Amount</label>
                {invoiceDiscountType == "DOLLAR" ? (
                  <MaskedTextInput
                    name="discountAmount"
                    placeholder="$0.00"
                    readOnly={invoiceDiscountType != "DOLLAR"}
                    mask={amountMask}
                    onFocus={this.handleDiscountFocus}
                    value={invoiceSubTotal.discountAmount.value}
                    onBlur={this.props.handlediscountLatefeeOnBlur}
                    onChange={this.props.handleInvoiceSubTotalChange}
                  />
                ) : (
                  <input
                    readOnly={true}
                    placeholder="$0.00"
                    className={`${
                      invoiceDiscountType != "DOLLAR" ? " disabled" : ""
                    }`}
                    value={this.getThousandAmount(
                      invoiceSubTotal.discountAmount.value
                    )}
                  />
                )}
                {invoiceSubTotal.discountAmount.hasError &&
                this.renderErrorMessage("discountAmount")}
              </div>
            </div> : null}

            {isEdit && (!from || from !== 'credit_memo') ? <div className={`input-container invoice-fields inner-left `} style={{alignItems: 'flex-start'}}>
                <label
                  className="label-with-checkbox lwc-phone"
                  htmlFor="discount"
                >
                  <div>
                    {/* Apply Discount to Invoice */}
                    Applied Invoice Credit (by Credit Memo)
                    {/* <img
                      src={InfoIcon}
                      className="info-tooltip"
                      title="Entering a discount here applies an immediate discount to the invoice that is not based on the Discount Terms."
                    /> */}
                  </div>
                </label>
                <MaskedTextInput
                  type="text"
                  name="appliedCreditAmount"
                  // placeholder="Enter Discount"
                  placeholder="Applied Invoice Credit"
                  readOnly={true}
                  className={`disabled`}
                  mask={amountMask}
                  // onFocus={this.handleDiscountFocus}
                  value={invoiceSubTotal.appliedCreditMemoAmount.value}
                  onChange={this.props.handleInvoiceSubTotalChange}
                  style={{width: 'calc(50% - 8px)', marginTop: '10px'}}
                />
              </div> : null}
            {/* <div className="inline-input">
              <div className={`input-container invoice-fields inner-left`}>
                <label
                  className="label-with-checkbox lwc-phone"
                  htmlFor="latefees"
                >
                  <span>
                    Apply Late Fee to Invoice
                    <img
                      src={InfoIcon}
                      className="info-tooltip"
                      title="Entering a late fee here applies an immediate late fee to the invoice that is not based on the Late Fee Terms."
                    />
                  </span>
                  {this.props.renderCheckBoxes(
                    invoiceLateFeesType,
                    this.props.handleInvoiceLateFeesTypeChange
                  )}
                </label>
                <MaskedTextInput
                  type="text"
                  name="latefees"
                  placeholder="Late Fees"
                  mask={percentMask}
                  readOnly={invoiceLateFeesType == "DOLLAR"}
                  className={`${
                    invoiceLateFeesType == "DOLLAR" ? " disabled" : ""
                  }`}
                  value={invoiceSubTotal.latefees.value}
                  onChange={this.props.handleInvoiceSubTotalChange}
                />
              </div>
              <div className={`input-container invoice-fields inner-right`}>
                <label htmlFor="latefees">Late Fee Amount</label>
                {invoiceLateFeesType == "DOLLAR" ? (
                  <MaskedTextInput
                    type="text"
                    name="latefeeAmount"
                    placeholder="$0.00"
                    mask={amountMask}
                    readOnly={invoiceLateFeesType != "DOLLAR"}
                    value={invoiceSubTotal.latefeeAmount.value}
                    onBlur={this.props.handlediscountLatefeeOnBlur}
                    onChange={this.props.handleInvoiceSubTotalChange}
                  />
                ) : (
                  <input
                    readOnly={true}
                    placeholder="$0.00"
                    className={`${
                      invoiceLateFeesType != "DOLLAR" ? " disabled" : ""
                    }`}
                    value={this.getThousandAmount(
                      invoiceSubTotal.latefeeAmount.value
                    )}
                  />
                )}
              </div>
            </div> */}
          </div>
        </div>
        <div className="inner-bottom-border" /> 
        <div className="half total-section">
          <div className={`input-container invoice-fields right-algn`}>
            <label htmlFor="total">Total</label>
            <input
              name="total"
              placeholder="$0.00"
              className="disabled"
              readOnly
              value={
                invoiceSubTotal.total.value
                  ? this.getThousandAmount(invoiceSubTotal.total.value)
                  : ""
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceDetails;
