import React, { Component } from "react";

class ClientsTaskSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedButton: 0,
    };
  }

  selectedButton = (index, item) => {
    this.setState({ selectedButton: index }, () => {
      this.props.handleTabChange(item);
    });
  };

  render() {
    const { title, optionalClassNames, task_list } = this.props;
    return (
      <div className="app-sidebar app-sidebar-left accounts-view-sidebar clients-side-bar col1-scroll scrollbar">
        <div
          className={`app-section-header client-section-header${
            optionalClassNames ? " " + optionalClassNames : ""
          }`}
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <div className="app-section-header-content">
            <h1 className="app-section-header-heading side-hed-col">{title}</h1>
          </div>
        </div>
        {/* render view */}
        <div className="grid">
          {task_list && task_list.length ? (
            task_list.map((item, index) => {
              return (
                <button
                  key={index.toString()}
                  onClick={() => this.selectedButton(index, item)}
                  className={`task-btns-grid${
                    index === this.state.selectedButton ? " active" : ""
                  }`}
                >
                  {item.name}
                </button>
              );
            })
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }
}

export default ClientsTaskSidebar;
