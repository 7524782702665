import React, { Component } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import ClientsLogo from "../../components/Consumer/ClientsLogo";
import ConsumerHelpForm from "../../components/Consumer/Forms/ConsumerHelpForm";
import FlashMessage from "../../components/FlashMessage";
import ExpressHeader from "./ExpressHeader";
// Utils
import * as API from "../../utils/api";
import { getZohoPagesense } from "../../utils/helpers";

class ConsumerGetHelp extends Component {
  state = {
    user: {},
    showFlash: false,
    flashMessage: "",
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    getZohoPagesense();
    ReactGA.pageview("/ConsumerHelp");
  }

  dismissFlash = () => {
    this.setState((state) => ({
      ...state,
      showFlash: false,
    }));
  };

  handleHelpFormSubmit = (inputs) => {
    const { user } = this.state;
    let messageObjectForSubmit = {
      contact_form: {
        email: inputs.email,
        topic: inputs.inquiry,
        message: inputs.message,
        page: 'express_payment'
      },
    };
    API.consumerRequestHelp(user, messageObjectForSubmit).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.props.updateModalState(true, "SUCCESS", {
          message: "Your message was sent successfully",
        });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  render() {
    const { appName } = this.props;
    const { isLoading, error } = this.state;

    return (
      <div className="app-portal">
        <Helmet>
          <title>{appName} | Help</title>
        </Helmet>
        <ExpressHeader appName={appName} />
        <div className="app-portal-canvas consumer-portal">
          <div className="express-session-fill wrap">
            <ClientsLogo color={"#000"} />
            <div
              onClick={this.props.history.goBack}
                style={{ fontWeight: "bold", cursor: "pointer", color: "#000000", textDecoration: "none" }}
              >
                « Back
            </div>
            <h1
              className="app-section-consumer-heading"
              style={{ marginBottom: "1em" }}
            >
              Get Help
            </h1>
            {this.state.showFlash ? (
              <FlashMessage
                message={this.state.flashMessage}
                handleFlashDismiss={this.dismissFlash}
              />
            ) : null}
            <div className="scrollbar consumer-portal-app-header get-help-scrollbar">
              <ConsumerHelpForm emailRequired={true} handleSubmit={this.handleHelpFormSubmit} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ConsumerGetHelp);
