import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

export const numberMask = createNumberMask({
  prefix: "$",
  allowDecimal: true,
});

export const amountMask = createNumberMask({
  prefix: "$",
  allowDecimal: true,
});

export const percentMask = createNumberMask({
  suffix: "%",
  prefix: "",
  allowDecimal: true,
});

export const quantityMask = createNumberMask({
  prefix: "",
  integerLimit: 4,
  allowDecimal: true,
  includeThousandsSeparator: false
});

export function getTimeToDisplay(time) {
  var a = moment();
  var b = moment(time);

  let days = a.diff(b, "days");
  let hours = a.diff(b, "hours");
  let min = a.diff(b, "minutes");
  let displayDate;

  if (hours == 0) {
    if (min >= 0) {
      if (min == 1) {
        displayDate = min + " MINUTE AGO";
      } else {
        displayDate = min + " MINUTES AGO";
      }
    } else {
      displayDate = "0" + " MINUTE AGO";
    }
  } else if (days == 0) {
    if (hours == 1) {
      displayDate = hours + " HOUR AGO";
    } else {
      displayDate = hours + " HOURS AGO";
    }
  } else if (days == 1) {
    displayDate = days + " DAY AGO";
  } else {
    displayDate = days + " DAYS AGO";
  }
  return displayDate;
}

/* eslint-disable no-useless-escape */
export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

/* eslint-disable no-useless-escape */
export function validateCompanyEmail(email) {
  var re = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return re.test(email);
}

// Validates that the input string is a valid date formatted as "mm/dd/yyyy"
// Source: https://stackoverflow.com/questions/6177975/how-to-validate-date-with-format-mm-dd-yyyy-in-javascript
export function isValidDate(dateString, upperYearLimit) {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

  // Parse the date parts to integers
  var parts = dateString.split("/");
  var month = parseInt(parts[0], 10);
  var day = parseInt(parts[1], 10);
  var year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (upperYearLimit) {
    if (year < 1910 || year > upperYearLimit || month === 0 || month > 12)
      return false;
  } else {
    if (year < 1910 || month === 0 || month > 12) return false;
  }

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

// takes the form field value and returns true on valid number
export function isValidCreditCard(value) {
  // accept only digits, dashes or spaces
  if (/[^0-9-\s]+/.test(value)) return false;

  // The Luhn Algorithm. It's so pretty.
  var nCheck = 0,
    bEven = false;
  value = value.replace(/\D/g, "");

  for (var n = value.length - 1; n >= 0; n--) {
    var cDigit = value.charAt(n),
      eDigit = parseInt(cDigit, 10);

    if (bEven) {
      eDigit *= 2;
      if (eDigit > 9) {
        eDigit -= 9;
      }
    }

    nCheck += eDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
}

export function getDollarAmount(string) {
  let amount = "0.00";
  if (string) {
    amount = parseFloat(string)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  }
  return "$" + amount;
}

export function getWithoutDollarAmount(string, type) {
  if(type && type == 'onlyNumber'){
    let amount = "0";
    if (string) {
      amount = parseInt(string)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    }
  return amount;
  }else {
    let amount = "0.00";
    if (string) {
      amount = parseFloat(string)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    }
    return amount;
  }
}



export function getStrippedDollarAmount(string) {
  let strippedNumber = string ? string.replace(/\$|,/g, "") : "";

  return strippedNumber === "" ? 0 : parseFloat(strippedNumber);
}

export function getStrippedNumberFromString(string) {
  let strippedNumber = string ? string.replace(/[\$\,\(\)\-\ ]/g, "") : "";

  return strippedNumber === "" ? 0 : parseFloat(strippedNumber);
}

/* eslint-enable no-useless-escape */
export function convertCreditorsToArrayForSelectComponent(creditorsArray) {
  let newCreditors = [...creditorsArray];

  for (var i = 0; i < newCreditors.length; i++) {
    newCreditors[i].value = newCreditors[i]["id"];
    delete newCreditors[i].id;
    newCreditors[i].label = newCreditors[i]["name"];
    delete newCreditors[i].name;
  }

  return newCreditors;
}

export function getNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const numberMaskForPercent = createNumberMask({
  prefix: "",
  suffix: "%",
  allowDecimal: true,
  includeThousandsSeparator: false,
  integerLimit: 3,
});

export function getStrippedDollarOrPercentAmount(string) {
  let strippedNumber;
  if (string.includes("$")) {
    strippedNumber = string.replace(/\$|,/g, "");
  } else {
    strippedNumber = string.replace(/\%|,/g, "");
  }
  return strippedNumber === "" ? 0 : parseFloat(strippedNumber);
}

export const showToast = (msg) => {
  toast.dismiss();
  let message = msg
    ? msg
    : "Unable to process a request. Please try again later!";
  toast.info(message, {
    position: toast.POSITION.TOP_RIGHT,
    // className: 'primary-color'
  });
};

export const showConfirmAlert = (msg, sucessCallBack, errorCallBack) => {
  return confirmAlert({
    // title: 'Confirm',
    message: msg,
    buttons: [
      {
        label: "Yes",
        onClick: () => sucessCallBack(),
      },
      {
        label: "Cancel",
        onClick: () => (errorCallBack ? errorCallBack() : ""),
      },
    ],
  });
};

export function getZohoPagesense() {
  return new Promise(function (resolve, reject) {
    var script = document.createElement("script");
    // for staging
    //script.src = "https://cdn.pagesense.io/js/readytechs/7748726604f14d32a5d38ce9c2813e63.js";
    // for production
    script.src =
      "https://cdn.pagesense.io/js/readytechs/fe10fb27979e4aa38d8e9971cef1b295.js";
    script.addEventListener("load", function () {
      resolve();
    });
    script.addEventListener("error", function (e) {
      reject(e);
    });
    document.body.appendChild(script);
  });
}

//export const googleAnalyticId = "UA-148090034-1" //staging
//export const googleAnalyticId = "UA-148090034-2" //production
export const googleAnalyticId = "UA-148090034-3"; //new production

export const networkMessage =
  "Please check your internet connection and try again.";
export const errorMessage = "Something went wrong. Please try again.";
