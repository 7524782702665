import moment from "moment";
import React, { Component } from "react";
import ClientsCardView from "../../CardView/ClientsCardView";
import InvoiceObjectList from "./InvoiceObjectList";
import { getDollarAmount, getWithoutDollarAmount } from "../../../utils/helpers";

class InvoiceAndLineDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandLine: true,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
    };
  }

  rowExpanderClick = (event) => {
    this.setState({ expandLine: !this.state.expandLine });
  };

  getDate = (date) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY");
    }
    return "n/a";
  };

  getPaidAmount = (invoice) => {
    let amount = 0;
    if (invoice) {
      amount = invoice.total_amount
        ? invoice.balance
          ? parseFloat(invoice.total_amount) - parseFloat(invoice.balance)
          : invoice.total_amount
        : invoice.total_amount;
    }
    return amount ? amount.toFixed(2) : "0.00";
  };

  getEarlyPayerDiscountAccount = (data) => {
    let amount = 0.0
    if(data && data.criteria_discount && data.criteria_discount_percent_based && data.criteria_discount_percent_based === true){
      // amount = parseFloat(data.total_amount) / 100;
      // amount = amount * parseFloat(data.criteria_discount);
      amount = data.criteria_discount
      return amount.toString() + '%';
    }else{
      amount = parseFloat(data.criteria_discount)
      return getDollarAmount(amount.toString());
    }
  }

  getSlowPayerDiscountAccount = (data) => {
    let amount = 0.0
    let total = 0.0;
    //calculate subtotal
    // if (data && data.invoice_lines && data.invoice_lines.length) {
    //   data.invoice_lines.map((item) => {
    //     if (item.detail_type === "SalesItemLineDetail") {
    //       item.amount = parseFloat(item.amount);
    //       subTotal = subTotal + item.amount;
    //     }
    //   });
    // }
    if (data && data.total_amount) {
      total = parseFloat(data.total_amount)
    }
    
    if(data && data.criteria_late_fee_percent_based && data.criteria_late_fee_percent_based === true && data.criteria_late_fee){
      // amount = parseFloat(total) / 100;
      // amount = amount * parseFloat(data.criteria_late_fee);
      amount = data.criteria_late_fee;
      return amount.toString() + '%';
    }else{
      if(data.criteria_late_fee){
        amount = parseFloat(data.criteria_late_fee)
      }else {
        amount = 0.0;
      }
      return getDollarAmount(amount.toString());
    }
  }

  getTaxValue = (row) => {
    let taxValue = 0.0;
    let { invoiceDetails } = this.props;
    let item = invoiceDetails.tax_and_discount_line[0];
    taxValue = parseFloat(row.amount) / 100;
    taxValue = taxValue * parseFloat(item && item.discount_percent ? item.discount_percent : 0);

    return getDollarAmount(taxValue.toString());

  }

  getObjectListData = (data, type) => {
    data.date = data.date ? data.date : data.txn_date;
    data.total_amount = data.total_amount ? data.total_amount : data.Total_amt;
    let headings = [],
      columns = [];
    headings =
      type === "Line"
        ? ["Item Date","Item Name", "Description", "Qty", "Rate", "Item Total", "Tax"]
        : [
            // "Invoice Date",
            // "Due Date",
            // "Invoice Amount",
            // "Amount Paid",
            // "Discount",
            // "Balance Due",
            // "expander",
            "Invoice Date",
            "Due Date",
            "Terms",
            "Disc. Days",
            "Early Payer Disc.",
            "Slow Payer Penalty",
            "Client’s File #"
          ];

    columns =
      type === "Line"
        ? data.map((row, index) => {
            return {
              emptyPrimary: true,
              secondaryColumns: [
                {
                  key: "Item Date",
                  title: row.item_date ? moment(row.item_date).utc().format("MM/DD/YYYY") : "n/a"
                },
                {
                  key: "Item Name",
                  title: row.item_name ? row.item_name : "n/a",
                },
                {
                  key: "Description",
                  title: row.description ? row.description : "n/a",
                },
                {
                  key: "Qty",
                  title: row.qty ? row.qty : "n/a",
                },
                {
                  key: "Rate",
                  title: getDollarAmount(row.unit_price),
                },
                {
                  key: "Item Total",
                  title: getDollarAmount(row.amount),
                },
                {
                  key: "Tax",
                  title: <div className="tax-value-wrapper">
                            <input className="tax-checkbox" type="checkbox" id={`tax00${index}`} name={'tax'} value={'tax'} checked={row.is_taxable}  />
                            <span>{row.is_taxable == true ? this.getTaxValue(row) : 'Not Taxable'}</span>
                          </div>
                },
              ],
            };
          })
        : [
            {
              emptyPrimary: true,
              secondaryColumns: [
                {
                  key: "Invoice Date",
                  title: data.date
                    ? moment(data.date).format("MM/DD/YYYY")
                    : "--/--/----",
                },
                {
                  key: "Due Date",
                  title: data.due_date
                    ? moment(data.due_date).format("MM/DD/YYYY")
                    : "--/--/----",
                },
                // {
                //   key: "Terms",
                //   title: getWithoutDollarAmount(data.net_term, 'onlyNumber'),
                // },
                {
                  key: "Terms",
                  title: data.net_term,
                },
                {
                  key: "Disc. Days",
                  title: getWithoutDollarAmount(data.criteria_discount_days, 'onlyNumber'),
                },
                {
                  key: "Early Payer Disc.",
                  title: this.getEarlyPayerDiscountAccount(data),
                },
                {
                  key: "Slow Payer Penalty",
                  title: this.getSlowPayerDiscountAccount(data),
                },
                {
                  key: "Client’s File #",
                  title: data.client_file_no ? data.client_file_no : "n/a",
                },
                // {
                //   isExpanded: this.state.expandLine,
                //   icon: "up-down",
                //   rowType: "arrow-click",
                //   optionalClasses: "expander",
                // },
              ],
              status: !this.checkInvoicePastDue(data.due_date),
              taskStatus: data.balance && data.balance <= 0 ? true : false,
            },
          ];

    return {
      headings,
      columns,
    };
  };

  checkInvoicePastDue = (date) => {
    return moment(date).isBefore(new Date());
  };

  getLinesJson = (item, type, flag, subTotal) => {
    let per =
      type == "Tax"
        ? item.discount_percent
        : (per = this.getDicountAndLateFeePercent(type, subTotal));

    return {
      key: `${type}${per ? "(" + per + "%)" : ""}`,
      percent: parseFloat(per ? per : 0),
      value: parseFloat(item.amount ? item.amount : 0),
      flag: flag,
    };
  };

  addMultipleTaxDiscount = (data, row, type) => {
    if (data && data.key) {
      let per = type == "Tax" ? data.percent + row.percent : row.percent;
      return {
        key: `${type}${per ? "(" + per + "%)" : ""}`,
        percent: per,
        value: data.value + row.value,
        flag: data.flag,
      };
    } else {
      return row;
    }
  };

  getDicountAndLateFeePercent = (type, subtotal) => {
    let { invoiceDetails } = this.props;
    let tax_and_discount_line = invoiceDetails.tax_and_discount_line;
    let value = 0;
    if (tax_and_discount_line.length) {
      tax_and_discount_line.map((item) => {
        if (type == "Discount" && item.detail_type === "DiscountLineDetail") {
          value += parseFloat(item.amount).toFixed(2);
        } else if (
          type == "Late Fee" &&
          item.detail_type === "SalesItemLineDetail"
        ) {
          value += parseFloat(item.amount).toFixed(2);
        }
      });
    }

    if (subtotal > 0 && value > 0)
      return parseFloat((value * 100) / subtotal).toFixed(2);
    return 0;
  };

  showTaskDiscountDetails = (data) => {
    let { invoiceDetails } = this.props;
    let tax_and_discount_line = invoiceDetails.tax_and_discount_line;
    let filterdData = [];
    let subTotal = 0;
    let taxData, discountData, lateFeeData;
    let total = 0;

    //calculate subtotal
    if (data && data.length) {
      data.map((item) => {
        if (item.detail_type === "SalesItemLineDetail" && item.item_name && !item.item_name.includes('Early Payer Dis') && !item.item_name.includes('Slow Payer Penalty') && !item.item_name.includes('Invoice Credit')) {
          item.amount = parseFloat(item.amount);
          subTotal = subTotal + item.amount;
        }
      });
    }

    if (tax_and_discount_line && tax_and_discount_line.length) {
      tax_and_discount_line.map((item) => {
        if (item.detail_type === "TaxLineDetail") {
          //Tax percent/amount
          taxData = this.addMultipleTaxDiscount(
            taxData,
            this.getLinesJson(item, "Tax", ""),
            "Tax"
          );
        } else if (item.detail_type === "DiscountLineDetail") {
          //Discount percent/amount
          discountData = this.addMultipleTaxDiscount(
            discountData,
            this.getLinesJson(item, "Discount", "-", subTotal),
            "Discount"
          );
        } else if (item.detail_type === "SalesItemLineDetail") {
          //late fee
          lateFeeData = this.addMultipleTaxDiscount(
            lateFeeData,
            this.getLinesJson(item, "Late Fee", "", subTotal),
            "Late Fee"
          );
        }
      });
    }

   

    //Add subtotal to final data
    filterdData.push({
      key: "SUBTOTAL",
      flag: "",
      value: subTotal.toFixed(2),
    });
    //add taxData to final data
    taxData
      ? filterdData.push(taxData)
      : filterdData.push(this.getLinesJson([], "Tax", ""));


    //add discountData to final data
    // discountData
    //   ? filterdData.push(discountData)
    //   : filterdData.push(this.getLinesJson([], "Invoice Credit", ""));
    let isInvoiceCredit = false;
    let invoiceCreditValue = 0;
    if (invoiceDetails && invoiceDetails.invoice_lines && invoiceDetails.invoice_lines.length) {
      invoiceDetails.invoice_lines.map((line) => {
        if(line.item_name && line.item_name.includes('Invoice Credit')){
          invoiceCreditValue = parseFloat(Math.abs(line.amount));
          isInvoiceCredit = true;
        }
      });
    }

    if (invoiceDetails && invoiceDetails.payment_history && invoiceDetails.payment_history.length) {
      invoiceDetails.payment_history.map((txn) => {
        if(txn.payment_type && txn.payment_type === 'Credit Memo' && txn.is_cancelled === false){
          invoiceCreditValue = invoiceCreditValue + parseFloat(Math.abs(txn.amount));
          isInvoiceCredit = true;
        }
      });
    }

    if(invoiceCreditValue ){
      filterdData.push({
        key: "Invoice Credit",
        value: parseFloat(invoiceCreditValue).toFixed(2), // parseFloat(discountData.value)
        flag: "-"
      })
    }
    // if(discountData && discountData.value && parseFloat(discountData.value) > 0){
    //   filterdData.push({
    //     key: "Invoice Credit",
    //     value: parseFloat(discountData.value).toFixed(2), // parseFloat(discountData.value)
    //     flag: "-"
    //   })
    // }

    //logic for Early payer discount
    let EarlyPayerDiscVal = 0.0;
    let epdsCT = 0;
    if(invoiceDetails && invoiceDetails.invoice_lines && invoiceDetails.invoice_lines.length){
      invoiceDetails.invoice_lines.map((line) => {
        if(line.item_name && line.item_name.includes('Early Payer Dis')){
          EarlyPayerDiscVal = parseFloat(line.amount);
          epdsCT++;
        }
      });
      let epdGCt = 0;
      if (invoiceDetails.tax_and_discount_line && invoiceDetails.tax_and_discount_line.length) {
        invoiceDetails.tax_and_discount_line.map((line) => {
          if(line.item_name && line.item_name.includes('Discounts given')){
            EarlyPayerDiscVal = parseFloat(line.amount);
            epdGCt++;
          }
        });
      }
      // if(EarlyPayerDiscVal !== 0 && epdsCT > 0){ 
      //   filterdData.push({
      //     key: "Early Payer Discount",
      //     value: parseFloat(finalEarlyPayerDisc).toFixed(2),
      //     flag: "",
      //   });
      // } else if (EarlyPayerDiscVal !== 0 && epdGCt > 0) {
      //   filterdData.push({
      //     key: "Early Payer Discount",
      //     value: parseFloat(EarlyPayerDiscVal).toFixed(2),
      //     flag: "-",
      //   });
      // }
    }

    //Slow Payer Panalty
    let SPPAmountV = 0.0
    if(invoiceDetails && invoiceDetails.invoice_lines && invoiceDetails.invoice_lines.length){
      invoiceDetails.invoice_lines.map((line) => {
        if(line.item_name && line.item_name.includes('Slow Payer Penalty')){
          SPPAmountV = parseFloat(line.amount);
        }
      });
    }  


      // logic to calculate Invoice total
      let taxA = taxData && taxData.value ? taxData.value : 0;
      let discountA = discountData && discountData.value ? discountData.value : 0;
      // let totolA = parseFloat(subTotal) + parseFloat(taxA) - parseFloat(discountA);
      let totolA = parseFloat(subTotal) + parseFloat(taxA)
      if(isInvoiceCredit){
        totolA = totolA - parseFloat(invoiceCreditValue);
      }

    //add INVOICE TOTAL to final data
    filterdData.push({
      key: "INVOICE TOTAL",
      value: totolA.toFixed(2),
    })

    //add Amount Paid to final data
    let invoiceTotal = invoiceDetails && invoiceDetails.total_amount ? parseFloat(invoiceDetails.total_amount) : 0;
    let balValue = invoiceDetails && invoiceDetails.balance ? parseFloat(invoiceDetails.balance) : 0;
    let PaidValue = totolA.toFixed(2) - balValue  + SPPAmountV - Math.abs(EarlyPayerDiscVal);
    if(PaidValue > 0){
      filterdData.push({
        key: "Amount Paid",
        value: PaidValue.toFixed(2),
        flag: '-'
      })
    }

    //logic for Early payer discount
    let finalEarlyPayerDisc = 0.0;
    let sCT = 0;
    let targetDate;
    let earlyPayerInfoFlag = false;
    if(invoiceDetails && invoiceDetails.invoice_lines && invoiceDetails.invoice_lines.length){
      invoiceDetails.invoice_lines.map((line) => {
        if(line.item_name && line.item_name.includes('Early Payer Dis')){
          finalEarlyPayerDisc = parseFloat(line.amount);
          sCT++;
        }
      });
      let dGCt = 0;
      if (invoiceDetails.tax_and_discount_line && invoiceDetails.tax_and_discount_line.length) {
        invoiceDetails.tax_and_discount_line.map((line) => {
          if(line.item_name && line.item_name.includes('Discounts given')){
            finalEarlyPayerDisc = parseFloat(line.amount);
            dGCt++;
          }
        });
      }
      if(finalEarlyPayerDisc !== 0 && sCT > 0){ 
        filterdData.push({
          key: "Early Payer Discount",
          value: parseFloat(finalEarlyPayerDisc).toFixed(2),
          flag: "",
        });
      } else if (finalEarlyPayerDisc !== 0 && dGCt > 0) {
        filterdData.push({
          key: "Early Payer Discount",
          value: parseFloat(finalEarlyPayerDisc).toFixed(2),
          flag: "-",
        });
      }
      if(sCT === 0){
        if(invoiceDetails && invoiceDetails.criteria_discount_days && invoiceDetails.criteria_discount_days > 0){
          let startdate = invoiceDetails.date;
          // var new_date = moment(startdate, "YYYY-MM-DD").add(5, "days");
          let nn = moment(moment(startdate).add(invoiceDetails.criteria_discount_days, 'd').format('YYYY/MM/DD'))
          let today = new Date();
          targetDate = moment(nn);
          var month = today.getUTCMonth() + 1; //months from 1-12
          var day = today.getUTCDate();
          var year = today.getUTCFullYear();
  
          let newdate = year + "/" + month + "/" + day;
          let momentToday = moment(newdate);
          let diffNumber = targetDate.diff(momentToday, 'days');
          if(diffNumber >= 0){
            let value = 0.0;
            if(invoiceDetails && invoiceDetails.criteria_discount_percent_based === true){
                
              value = parseFloat(invoiceDetails.total_amount) / 100;
              value = value * parseFloat(invoiceDetails.criteria_discount)
  
            }else if(invoiceDetails && invoiceDetails.criteria_discount_percent_based === false){
                value = invoiceDetails.criteria_discount;
            }
            earlyPayerInfoFlag = true;
            // filterdData.push({
            //   key: "Early Payer Discount",
            //   value: parseFloat(value).toFixed(2),
            //   flag: "",
            // });
          }
        }
      }
    }

    

    //add discountData to final data
    // discountData
    //   ? filterdData.push(discountData)
    //   : filterdData.push(this.getLinesJson([], "Early Payer Discount", ""));

    //add late fee to final data
    // lateFeeData
    //   ? filterdData.push(lateFeeData)
    //   : filterdData.push(this.getLinesJson([], "Late Fee", ""));

    //calculations for total based on subtotal, tax and discount
    total = subTotal;
    if (taxData && taxData.value) {
      total = total + taxData.value;
    }
    if (discountData && discountData.value) {
      total = total - discountData.value;
    }
    if (lateFeeData && lateFeeData.value) {
      total = total + lateFeeData.value;
    }
    
    //Slow Payer Panalty
    let SPPValue = 0.0
    if(invoiceDetails && invoiceDetails.invoice_lines && invoiceDetails.invoice_lines.length){
      invoiceDetails.invoice_lines.map((line) => {
        if(line.item_name && line.item_name.includes('Slow Payer Penalty')){
          SPPValue = SPPValue + parseFloat(line.amount);
        }
      });
      if(SPPValue !== 0){ 
        filterdData.push({
          key: "Slow Payer Penalty",
          value: parseFloat(SPPValue).toFixed(2),
          flag: "",
        });
      }
    }

    filterdData.push({
      key: "BALANCE DUE",
      value: invoiceDetails.balance,
      flag: "",
    });

    return filterdData && filterdData.length ? (
      <div className={`panel tax-discount-content`}>
        {filterdData.map((item, index) => {
          return (
            <div key={index.toString()} className={`tax-details ${(index + 1) === filterdData.length ? 'no-border' : ''}`}>
              <div
                className={`tax-details-key${
                  item.key == "BALANCE DUE" || item.key == "SUBTOTAL" || item.key == "INVOICE TOTAL" ? " tax-details-total highlighted-key" : ""
                }`}
              >
                {item.key}
                {item.key.includes('Tax') ? <span className="tax-text">tax calc <b>$xx.00</b> x Rate <b>xx%</b></span> : null}
              </div>
              <div
                className={`tax-details-value${
                  item.key == "BALANCE DUE" || item.key == "SUBTOTAL" || item.key == "INVOICE TOTAL"  ? " tax-details-total highlighted-key" : ""
                }`}
              >
                <div>$</div>
                <div>
                  {item.flag ? item.flag : ""}
                  {getWithoutDollarAmount(item.value)}
                </div>
              </div>
            </div>
          );
        })}
        {earlyPayerInfoFlag && !(PaidValue.toFixed(2) > 0) ?
          <div className="early-payer-discount-info">
                <div className="tax-details border-line">{`Early Payer Discount if paid by ${moment(targetDate).format('MM/DD/YYYY')}:`}</div>
                {invoiceDetails && invoiceDetails.criteria_discount_percent_based === true
                ? <div className="tax-details">{`$${invoiceDetails.total_amount} x ${invoiceDetails.criteria_discount}% = ${getDollarAmount(parseFloat(invoiceDetails.total_amount) * parseFloat(invoiceDetails.criteria_discount) / 100)}`}</div>
                : <div className="tax-details">{`$${invoiceDetails.criteria_discount}`}</div>}
          </div> : null}
      </div>
    ) : null;
  };

  showInvoiceLines = (data, header, type, emptyMsg) => {
    let { expandLine } = this.state;
    let flag = expandLine && type === "Line" ? true : false;
    if(data && data.length){
      let temp = [];
      data.map((elm) => {
        if(elm.item_name && !elm.item_name.includes('Slow Payer Penalty') && !elm.item_name.includes('Early Payer Dis') && !elm.item_name.includes('Invoice Credit')){
          temp.push(elm);
        }
      })
      data = temp;
    }
    return flag ? (
      <div>
        <div className="invoice-line-header">Invoice Details</div>
        {data && data.length ? (
          <div>
            <ClientsCardView data={this.getObjectListData(data, type)} />
            <InvoiceObjectList
              data={this.getObjectListData(data, type)}
              hideForMobile={true}
              optionalTableClasses={"client-inv-line"}
            />
            {/* {this.showTaskDiscountDetails(data)} */}
          </div>
        ) : (
          this.renderEmpty(emptyMsg)
        )}
      </div>
    ) : null;
  };

  renderEmpty = (msg) => {
    return (
      <div
        className="admin-admins-empty-state"
        style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
      >
        <p>{msg}</p>
      </div>
    );
  };

  render() {
    const { invoiceDetails } = this.props;
    return (
      <div className="main-invoice-wrapper">
        <div className="sub-invoice-wrapper1">
          <div>
            {/* cardview for mobile device */}
            <ClientsCardView
              data={this.getObjectListData(invoiceDetails)}
              isStatus={true}
              rowActionClick={(event) => this.rowExpanderClick(event)}
            />
            {/* list view for big devices */}
            <InvoiceObjectList
              data={this.getObjectListData(invoiceDetails)}
              isStatus={true}
              hideForMobile={true}
              rowActionClick={(event) => this.rowExpanderClick(event)}
            />
          </div>
          {this.showInvoiceLines(
            invoiceDetails.invoice_lines,
            "Invoice Line",
            "Line",
            "No invoice line available"
          )}
        </div>
        <div className="sub-invoice-wrapper2">{this.showTaskDiscountDetails(invoiceDetails.invoice_lines)}</div>
      </div>
    );
  }
}

export default InvoiceAndLineDetails;
