import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components
import SessionsModal from "../../components/Sessions/SessionsModal";
import Logo from "../../components/Logo";

class ChoosePassword extends Component {
  render() {
    return (
      <div className="sessions fill-screen mg-session">
        <SessionsModal>
          <div className="sessions-modal-logo-container mg-logo-container">
            <Logo circular={true} />
          </div>
          <div className="sessions-modal-content">
            <h1 className="sessions-modal-heading" style={{ color: "#008bb2" }}>
              <span>Thank you!</span>
            </h1>
            <div>
              <p>Your password has been updated successfully!</p>
              <Link to="/login" className="cta mg-button">
                Continue to Login
              </Link>
            </div>
          </div>
        </SessionsModal>
      </div>
    );
  }
}

export default ChoosePassword;
