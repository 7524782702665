import React, { Component } from "react";

// Packages
import Select from "react-select";
import "react-select/dist/react-select.css";
import TextareaAutosize from "react-autosize-textarea";
import { getZohoPagesense, validateEmail } from "../../../utils/helpers";

// Components
import Panel from "../../App/Panel";

class ConsumerHelpForm extends Component {
  state = {
    input: {
      inquiry: {
        value: "",
        hasError: false,
      },
      message: {
        value: "",
        hasError: false,
      },
      email: {
        value: "",
        hasError: false,
      },
    },
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  resetInputFields = () => {
    this.setState((state) => ({
      input: {
        inquiry: {
          value: "",
          hasError: false,
        },
        message: {
          value: "",
          hasError: false,
        },
        email: {
          value: "",
          hasError: false,
        },
      },
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateFormAndSetErrorsIfNecessary()) {
      let { input } = this.state;
      const { emailRequired } = this.props;
      let inputValues = {}
      if(emailRequired) {
        inputValues = {
          inquiry: input.inquiry.value,
          message: input.message.value,
          email: input.email.value
        };
      } else {
        inputValues = {
          inquiry: input.inquiry.value,
          message: input.message.value,
        };
      }
      this.props.handleSubmit(inputValues);
      this.resetInputFields();
    }
  };

  validateFormAndSetErrorsIfNecessary = () => {
    let errorsArePresent = false;
    let newInputState = this.state.input;
    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "email" && this.props.emailRequired) {
        if (!validateEmail(value.value)) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key == "inquiry" && value.value === "") {
        newInputState[key].hasError = true;
        errorsArePresent = true;
      } else if (key == "message" && value.value === "") {
        newInputState[key].hasError = true;
        errorsArePresent = true;
      }
    });
    this.setState({input: newInputState})
    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";

    if (input === "inquiry") {
      message = "Please select an option";
    } else if (input === "message") {
      message = "Please enter your message";
    } else if (input === "email") {
      message = "Please enter a valid email";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  render() {
    const { input } = this.state;
    const { emailRequired } = this.props;

    var selectOptions = [
      { value: "Issue", label: " I have an issue (account or payment)" },
      { value: "Something Else", label: "Question about something else" },
    ];

    return (
      <form
        className="consumer-help-form"
        onSubmit={(event) => this.handleSubmit(event)}
      >
        <Panel heading="Send us a message">
          {emailRequired && (
            <div className="admin-form-row">
              <div
                className={`input-container${
                  input.email.hasError ? " error" : ""
                }`}
              >
                <label htmlFor="message">Email</label>
                <input
                  name="email"
                  type="email"
                  placeholder="address@email.com"
                  value={input.email.value}
                  onChange={(event) =>
                    this.handleInputChange({
                      email: { value: event.target.value, hasError: false },
                    })
                  }
                />
                {input.message.hasError
                  ? this.renderErrorMessage("email")
                  : null}
              </div>
            </div>
          )}
          <div className="admin-form-row">
            <div
              className={`input-container${
                input.inquiry.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="name">Select Category</label>
              <Select
                inputProps={{
                  autoComplete: "none",
                  autoCorrect: "off",
                  spellCheck: "off",
                }}
                className="form-select"
                name="form-field-name"
                value={input.inquiry.value}
                placeholder="Select an option"
                options={selectOptions}
                resetValue=""
                onChange={(event) =>
                  this.handleInputChange({
                    inquiry: {
                      value: event ? event.value : "",
                      hasError: false,
                    },
                  })
                }
              />
              {input.inquiry.hasError
                ? this.renderErrorMessage("inquiry")
                : null}
            </div>
          </div>
          <div className="admin-form-row">
            <div
              className={`input-container${
                input.message.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="message">Your Message</label>
              <TextareaAutosize
                name="creditorAdditionalDetails"
                type="textarea"
                maxRows={6}
                placeholder="Your message here..."
                value={input.message.value}
                onChange={(event) =>
                  this.handleInputChange({
                    message: { value: event.target.value, hasError: false },
                  })
                }
              />
              {input.message.hasError
                ? this.renderErrorMessage("message")
                : null}
            </div>
          </div>
        </Panel>
        <input
          type="submit"
          value="Submit"
          className="cta help-submit mg-brand2-color"
        />
      </form>
    );
  }
}

export default ConsumerHelpForm;
