import React, { Component } from "react";
import { Table } from "reactstrap";
import shortid from "shortid";
import moment from "moment";
import { getDollarAmount } from "../../../utils/helpers";

class ClientsAgingDetailReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    let { data } = this.props;
    this.setState({ data: this.getReportData(data) });
  }

  getRowTotal = (item) => {
    let total =
      Number(item.current_sum) +
      Number(item.sum_1_to_30) +
      Number(item.sum_30_to_60) +
      Number(item.sum_60_to_90) +
      Number(item.sum_90_to_120) +
      Number(item.sum_121_plus);
    return Number(total).toFixed(2);
  };

  getLastColumn = (title, list, data) => {
    data &&
      data.map((e, i) => {
        list[0] = title;
        list[1] = list[1]
          ? Number(list[1]) + Number(e.total_amt)
          : Number(e.total_amt);
        list[2] = list[2]
          ? Number(list[2]) + Number(e.balance)
          : Number(e.balance);
      });
    return list;
  };

  getOverAllTotal = () => {
    let { data } = this.state;
    let list = [];
    let total = [];
    data.total_current && list.push(data.total_current);
    data.total_1_30 && list.push(data.total_1_30);
    data.total_30_60 && list.push(data.total_30_60);
    data.total_60_90 && list.push(data.total_60_90);
    data.total_90_120 && list.push(data.total_90_120);
    data.total_121_plus && list.push(data.total_121_plus);

    list &&
      list.map((item, i) => {
        total[0] = "Total";
        total[1] = total[1]
          ? Number(total[1]) + Number(item[1])
          : Number(item[1]);
        total[2] = total[2]
          ? Number(total[2]) + Number(item[2])
          : Number(item[2]);
      });
    return total;
  };

  getDate = (date) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY");
    }
    return "n/a";
  };

  getReportData = (data) => {
    let total_current = [];
    let total_1_30 = [];
    let total_30_60 = [];
    let total_60_90 = [];
    let total_90_120 = [];
    let total_121_plus = [];

    return {
      headings: [
        "Date",
        "Transaction Type",
        "Num",
        "Customer",
        "Due Date",
        "Amount",
        "Open Balance",
      ],

      current_list_data:
        data.current_list_data &&
        data.current_list_data.map((item, index) => {
          return {
            secondaryColumns: [
              {
                title: this.getDate(item.txn_date),
              },
              {
                title: "Invoice",
              },
              {
                title: item.invoice_number,
              },
              {
                title: item.display_name,
              },
              {
                title: this.getDate(item.due_date),
              },
              {
                title: getDollarAmount(Number(item.total_amt)),
              },
              {
                title: getDollarAmount(Number(item.balance)),
              },
            ],
          };
        }),
      data_1_to_30:
        data.data_1_to_30 &&
        data.data_1_to_30.map((item, index) => {
          return {
            secondaryColumns: [
              {
                title: this.getDate(item.txn_date),
              },
              {
                title: "Invoice",
              },
              {
                title: item.invoice_number,
              },
              {
                title: item.display_name,
              },
              {
                title: this.getDate(item.due_date),
              },
              {
                title: getDollarAmount(Number(item.total_amt)),
              },
              {
                title: getDollarAmount(Number(item.balance)),
              },
            ],
          };
        }),
      data_30_to_60:
        data.data_30_to_60 &&
        data.data_30_to_60.map((item, index) => {
          return {
            secondaryColumns: [
              {
                title: this.getDate(item.txn_date),
              },
              {
                title: "Invoice",
              },
              {
                title: item.invoice_number,
              },
              {
                title: item.display_name,
              },
              {
                title: this.getDate(item.due_date),
              },
              {
                title: getDollarAmount(Number(item.total_amt)),
              },
              {
                title: getDollarAmount(Number(item.balance)),
              },
            ],
          };
        }),
      data_60_to_90:
        data.data_60_to_90 &&
        data.data_60_to_90.map((item, index) => {
          return {
            secondaryColumns: [
              {
                title: this.getDate(item.txn_date),
              },
              {
                title: "Invoice",
              },
              {
                title: item.invoice_number,
              },
              {
                title: item.display_name,
              },
              {
                title: this.getDate(item.due_date),
              },
              {
                title: getDollarAmount(Number(item.total_amt)),
              },
              {
                title: getDollarAmount(Number(item.balance)),
              },
            ],
          };
        }),
      data_90_to_120:
        data.data_90_to_120 &&
        data.data_90_to_120.map((item, index) => {
          return {
            secondaryColumns: [
              {
                title: this.getDate(item.txn_date),
              },
              {
                title: "Invoice",
              },
              {
                title: item.invoice_number,
              },
              {
                title: item.display_name,
              },
              {
                title: this.getDate(item.due_date),
              },
              {
                title: getDollarAmount(Number(item.total_amt)),
              },
              {
                title: getDollarAmount(Number(item.balance)),
              },
            ],
          };
        }),
      data_121_plus:
        data.data_121_plus &&
        data.data_121_plus.map((item, index) => {
          return {
            secondaryColumns: [
              {
                title: this.getDate(item.txn_date),
              },
              {
                title: "Invoice",
              },
              {
                title: item.invoice_number,
              },
              {
                title: item.display_name,
              },
              {
                title: this.getDate(item.due_date),
              },
              {
                title: getDollarAmount(Number(item.total_amt)),
              },
              {
                title: getDollarAmount(Number(item.balance)),
              },
            ],
          };
        }),
      total_current: this.getLastColumn(
        "Total for Current",
        total_current,
        data.current_list_data
      ),
      total_1_30: this.getLastColumn(
        "Total for 1 - 30 days past due",
        total_1_30,
        data.data_1_to_30
      ),
      total_30_60: this.getLastColumn(
        "Total for 30 - 60 days past due",
        total_30_60,
        data.data_30_to_60
      ),
      total_60_90: this.getLastColumn(
        "Total for 60 - 90 days past due",
        total_60_90,
        data.data_60_to_90
      ),
      total_90_120: this.getLastColumn(
        "Total for 90 - 120 days past due",
        total_90_120,
        data.data_90_to_120
      ),
      total_121_plus: this.getLastColumn(
        "Total for 120+ days past due",
        total_121_plus,
        data.data_121_plus
      ),
    };
  };

  renderTableBody = (headerTitle, data, lastData) => {
    return (
      <tbody>
        <tr>
          <td className="inner-title">{headerTitle}</td>
        </tr>
        {data &&
          data.map((item, index) => {
            return (
              <tr key={shortid.generate()} className="row-total">
                {item.secondaryColumns &&
                  item.secondaryColumns.map((col) => {
                    return <td key={shortid.generate()}>{col.title}</td>;
                  })}
              </tr>
            );
          })}
        <tr className="row-total">
          {lastData &&
            lastData.map((col, i) => {
              return (
                <td key={shortid.generate()} className={"total-sub-header"}>
                  {i === 0 ? col : getDollarAmount(Number(col))}
                </td>
              );
            })}
        </tr>
      </tbody>
    );
  };

  renderOverAllTotal = () => {
    let total = this.getOverAllTotal();
    return (
      <tbody>
        <tr className="row-total over-all-total">
          {total &&
            total.map((col, i) => {
              return (
                <td key={shortid.generate()} className={"total-sub-header"}>
                  {i === 0 ? col : getDollarAmount(Number(col))}
                </td>
              );
            })}
        </tr>
      </tbody>
    );
  };

  render() {
    let { data } = this.state;
    return (
      <Table responsive hover className="aging-detail-report">
        <thead>
          <tr>
            {data.headings &&
              data.headings.map((header) => {
                return <th key={shortid.generate()}>{header}</th>;
              })}
          </tr>
        </thead>
        {data.current_list_data && data.current_list_data.length ? (
          this.renderTableBody(
            "Current",
            data.current_list_data,
            data.total_current
          )
        ) : (
          <tbody />
        )}
        {data.data_1_to_30 && data.data_1_to_30.length ? (
          this.renderTableBody(
            "1 - 30 days past due",
            data.data_1_to_30,
            data.total_1_30
          )
        ) : (
          <tbody />
        )}
        {data.data_30_to_60 && data.data_30_to_60.length ? (
          this.renderTableBody(
            "30 - 60 days past due",
            data.data_30_to_60,
            data.total_30_60
          )
        ) : (
          <tbody />
        )}
        {data.data_60_to_90 && data.data_60_to_90.length ? (
          this.renderTableBody(
            "60 - 90 days past due",
            data.data_60_to_90,
            data.total_60_90
          )
        ) : (
          <tbody />
        )}
        {data.data_90_to_120 && data.data_90_to_120.length ? (
          this.renderTableBody(
            "90 - 120 days past due",
            data.data_90_to_120,
            data.total_90_120
          )
        ) : (
          <tbody />
        )}
        {data.data_121_plus && data.data_121_plus.length ? (
          this.renderTableBody(
            "120+ days past due",
            data.data_121_plus,
            data.total_121_plus
          )
        ) : (
          <tbody />
        )}
        {this.renderOverAllTotal()}
      </Table>
    );
  }
}

export default ClientsAgingDetailReport;
