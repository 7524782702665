import React, { Component } from "react";
import { Table } from "reactstrap";
import shortid from "shortid";
import moment from "moment";
import { getDollarAmount } from "../../../utils/helpers";

class ClientsCustomerBalanceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.overAllTotal = [];
  }

  componentDidMount() {
    let { data } = this.props;
    this.setState({ data: this.getReportData(data) });
  }

  getDate = (date) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY");
    }
    return "n/a";
  };

  getReportData = (data) => {
    return {
      headings: [
        "Date",
        "Transaction Type",
        "Num",
        "Due Date",
        "Amount",
        "Open Balance",
        "Balance",
      ],

      columns: data,
    };
  };

  renderOverAllTotal = () => {
    return (
      <tbody>
        <tr className="row-total over-all-total">
          {this.overAllTotal &&
            this.overAllTotal.map((col, i) => {
              return (
                <td key={shortid.generate()} className={"bal-sub-total"}>
                  {i === 0
                    ? col
                    : col
                    ? getDollarAmount(Number(col))
                    : i === 3
                    ? ""
                    : "$0.00"}
                </td>
              );
            })}
        </tr>
      </tbody>
    );
  };

  getTotal = (title, e, total) => {
    total[0] = "Total for " + title;
    total[1] = total[1]
      ? Number(total[1]) + Number(e.total_amt)
      : Number(e.total_amt);
    total[2] = total[2]
      ? Number(total[2]) + Number(e.balance)
      : Number(e.balance);
    total[3] = "";

    //calculate overall total
    this.overAllTotal[0] = "Total";
    this.overAllTotal[1] = this.overAllTotal[1]
      ? Number(this.overAllTotal[1]) + Number(e.total_amt)
      : Number(e.total_amt);
    this.overAllTotal[2] = this.overAllTotal[2]
      ? Number(this.overAllTotal[2]) + Number(e.balance)
      : Number(e.balance);
    this.overAllTotal[3] = "";
    return total;
  };

  getTableRows = (item, calBal) => {
    this.balance = calBal
      ? Number(item.balance) + Number(calBal)
      : Number(item.balance).toFixed(2);
    return [
      {
        title: this.getDate(item.txn_date),
      },
      {
        title: "Invoice",
      },
      {
        title: item.invoice_number,
      },
      {
        title: this.getDate(item.due_date),
      },
      {
        title: getDollarAmount(Number(item.total_amt)),
      },
      {
        title: getDollarAmount(Number(item.balance)),
      },
      {
        title: getDollarAmount(Number(this.balance)),
      },
    ];
  };

  renderTableBody = (data) => {
    return (
      data &&
      data.map((item) => {
        let total = [];
        this.balance = "";
        return (
          <tbody key={shortid.generate()}>
            {item.list && item.list.length ? (
              <tr>
                <td className="inner-title">
                  {item.user_name ? item.user_name : ""}
                </td>
              </tr>
            ) : (
              <tr className="empty-row" />
            )}
            {item.list && item.list.length ? (
              item.list.map((d, i) => {
                let rows = this.getTableRows(d, this.balance);
                total = this.getTotal(item.user_name, d, total);
                return (
                  <tr key={shortid.generate()} className="row-total">
                    {rows &&
                      rows.map((col) => {
                        return <td key={shortid.generate()}>{col.title}</td>;
                      })}
                  </tr>
                );
              })
            ) : (
              <tr className="empty-row" />
            )}
            {total && total.length ? (
              <tr className="row-total">
                {total.map((col, i) => {
                  return (
                    <td key={shortid.generate()} className={"bal-sub-total"}>
                      {i === 0
                        ? col
                        : col
                        ? getDollarAmount(Number(col))
                        : i === 3
                        ? ""
                        : "$0.00"}
                    </td>
                  );
                })}
              </tr>
            ) : (
              <tr className="empty-row" />
            )}
          </tbody>
        );
      })
    );
  };

  render() {
    let { data } = this.state;
    return (
      <Table responsive hover className="aging-detail-report collection-report">
        <thead>
          <tr>
            {data.headings &&
              data.headings.map((header) => {
                return <th key={shortid.generate()}>{header}</th>;
              })}
          </tr>
        </thead>
        {data.columns && data.columns.length ? (
          this.renderTableBody(data.columns)
        ) : (
          <div />
        )}
        {this.renderOverAllTotal()}
      </Table>
    );
  }
}

export default ClientsCustomerBalanceDetail;
