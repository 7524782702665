import React, { Component } from "react";
import Select from "react-select";
import "react-select/dist/react-select.css";
import MaskedTextInput from "react-text-mask";
import { amountMask, percentMask } from "../../../utils/helpers";

const options = [
  { value: "Net 15", label: "Net 15" },
  { value: "Net 30", label: "Net 30" },
  { value: "Net 45", label: "Net 45" },
  { value: "Net 60", label: "Net 60" },
  { value: "Net 90", label: "Net 90" },
  { value: "Net 120", label: "Net 120" },
  { value: "Due On Receipt", label: "Due On Receipt" },
];

class TermsAndDates extends Component {
  constructor(props) {
    super(props);
  }

  renderErrorMessage(input) {
    let message = "";
    if (input === "invDate") {
      message = "Please enter invoice date";
    } else if (input === "dueDate") {
      message = "Please enter due date";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  render() {
    let {
      termsAndDates,
      termsLateFeesType,
      termsDiscountRateType,
      termsList
    } = this.props;

    return (
      <div>
        {this.props.headerContent("Terms & Dates")}
        <div className="main-container">
          <div className="half">
            <label htmlFor="client">Terms</label>
            <div className={`input-container term-select`}>
              <Select
                inputProps={{
                  autoComplete: "none",
                  autoCorrect: "off",
                  spellCheck: "off",
                }}
                className="form-select"
                name="terms"
                value={termsAndDates.terms.value}
                options={termsList}
                onChange={(e) =>
                  e
                    ? this.props.handleTermsAndDatesChange({
                        target: { name: "terms", data: e, value: e.value },
                      })
                    : null
                }
                // onChange={(e) =>
                //   e
                //     ? this.props.handleTermsAndDatesChange({
                //         target: { name: "terms", value: e.value },
                //       })
                //     : null
                // }
              />
            </div>
            <div
              className={`input-container invoice-fields ${
                termsAndDates.invDate.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="invDate">Inv. Date</label>
              <MaskedTextInput
                guide={true}
                mask={[
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                name="invDate"
                placeholder="MM/DD/YYYY"
                type="text"
                value={termsAndDates.invDate.value}
                onChange={this.props.handleTermsAndDatesChange}
              />
              {termsAndDates.invDate.hasError &&
                this.renderErrorMessage("invDate")}
            </div>
            <div
              className={`input-container invoice-fields ${
                termsAndDates.dueDate.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="dueDate">Due Date</label>
              <MaskedTextInput
                guide={true}
                mask={[
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                name="dueDate"
                placeholder="MM/DD/YYYY"
                type="text"
                value={termsAndDates.dueDate.value}
                onChange={this.props.handleTermsAndDatesChange}
              />
              {termsAndDates.dueDate.hasError &&
                this.renderErrorMessage("dueDate")}
            </div>
          </div>
          <div className="half">
            <label htmlFor="discount">Discount Days</label>
            <div className={`input-container invoice-fields`}>
              <input
                name="discount"
                placeholder="Enter # of days - optional"
                type="number"
                readOnly
                className="disabled"
                value={termsAndDates.discount.value}
                onChange={this.props.handleTermsAndDatesChange}
                
              />
            </div>
            <div className={`input-container invoice-fields`}>
              <label className="label-with-checkbox" htmlFor="discountrate">
                Early Payer Discount Rate{" "}
                {this.props.renderCheckBoxes(
                  termsAndDates.isDiscountPercentage.value ? 'PERCENT' : 'DOLLAR',
                  this.props.handleDiscountRateTypeChange,
                  true
                )}
              </label>
              <MaskedTextInput
                type="text"
                name="discountRate"
                placeholder="Enter discount as % or $ - optional"
                mask={amountMask}
                readOnly
                className="disabled"
                style={{
                  display: termsAndDates.isDiscountPercentage.value === false ? "" : "none",
                }}
                value={termsAndDates.discountRate.value}
                onChange={this.props.handleTermsAndDatesChange}
              />
              <MaskedTextInput
                type="text"
                name="discountRate"
                placeholder="Enter discount as % or $ - optional"
                mask={percentMask}
                readOnly
                className="disabled"
                style={{
                  display: termsAndDates.isDiscountPercentage.value === true ? "" : "none",
                }}
                value={termsAndDates.discountRate.value}
                onChange={this.props.handleTermsAndDatesChange}
              />
            </div>
            <div className={`input-container invoice-fields`}>
              <label className="label-with-checkbox" htmlFor="latefees">
                Slow Payer Penalty Rate{" "}
                {this.props.renderCheckBoxes(
                  termsAndDates.isLatefeesPercentage.value ? 'PERCENT' : 'DOLLAR',
                  this.props.handleLateFeesTypeChange,
                  true
                )}
              </label>
              <MaskedTextInput
                type="text"
                name="latefees"
                placeholder="Enter late fee as % or $ - optional"
                mask={amountMask}
                readOnly
                className="disabled"
                style={{ display: termsAndDates.isLatefeesPercentage.value === false ? "" : "none" }}
                value={termsAndDates.latefees.value}
                onChange={this.props.handleTermsAndDatesChange}
              />
              <MaskedTextInput
                type="text"
                name="latefees"
                placeholder="Enter late fee as % or $ - optional"
                mask={percentMask}
                readOnly
                className="disabled"
                style={{
                  display: termsAndDates.isLatefeesPercentage.value === true ? "" : "none",
                }}
                value={termsAndDates.latefees.value}
                onChange={this.props.handleTermsAndDatesChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsAndDates;
