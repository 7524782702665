import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import BottomScrollListener from "react-bottom-scroll-listener";

import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import Tabs from "../../components/Tabs";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import InfoIcon from "../../assets/images/quickbooks/ic_info_gray.png";
import ToggleSwitch from "../../components/ToggleSwitch";
import * as API from "../../utils/api";

class TermsSettings extends Component {
    state = {
        isLoading: true,
        termsList: []
    };

    componentDidMount() {
        this.getClientTermsList();
    }

    //calls when "Add term" button is click
    handleAddTermClick = (e) => {
        e.preventDefault();
        const { user } = this.props;
        let props = {
            user: user,
            activeTab: this.state.activeTab,
            reload: this.reload,
        };
        //opens Add Service popup modal
        this.props.updateModalState(true, "ADD_TERM", props);
    };

    //pagination api call when scroll reached bottom of the screen
    handleContainerOnBottom = () => {
        if (this.state.hasMoreData) {
        let offsetCount = parseInt(this.state.offset) + 1;
        this.setState(
            (state) => ({
            ...state,
            offset: offsetCount,
            showLoader: true,
            }),
            () => {
            // this.getClientServices(true);
            }
        );
        }
    };
    //calls on row arrow click 
    rowExpanderClick = (e) => {
        let props = {
        user: this.props.user,
        activeTab: this.state.activeTab,
        reload: this.reload,
        rowData: e.rowData,
        };
        //opens Edit Service popup modal
        this.props.updateModalState(true, "ADD_TERM", props);
    };

    reload = (flag) => {
        this.getClientTermsList();
      };

    getClientTermsList = (reload, tabLoad) => {
        this.setState({isLoading: true})
        if (tabLoad) this.setState({ tabLoader: true });
    
        API.getTermsListInSettings(this.props.user).then((data) => {
          if (data && data.status && data.status == 'ok') {
            if (data.data) {
              // let check = reload ? serviceList : false;
              // let newData = check ? serviceList.concat(data.data) : data.data;
              let newData = data.data;
              this.setState((state) => ({
                ...state,
               termsList: newData,
                isLoading: false,
                //hasMoreData: data.data.length < 10 ? false : true,
              }));
            }
            this.setState({
              isLoading: false,
              showLoader: false,
              tabLoader: false,
            });
          } else {
            this.setState((state) => ({
              ...state,
              isLoading: false,
              tabLoader: false,
              error: {
                hasAnyError: true,
                message: data && data.error ? data.error : "",
                statusCode: data
                  ? data.status ||
                    (data.message && data.message == "Access denied."
                      ? 401
                      : 500) ||
                    500
                  : 500,
              },
            }));
          }
        });
    };

    UpdateClientTermAPI = (payload, index) => {
        // this.setState({isLoading: true})
        API.clientUpdateTermsInSettings(this.props.user, payload).then((data) => {
          if (data && data.status_code && data.status_code == 200) {
            this.props.updateModalState(true, "SUCCESS", {
                message: data.message,
            })
            this.getClientTermsList();
            this.setState({
              isLoading: false,
              showLoader: false,
              tabLoader: false,
            });
          } else {
            this.setState((state) => ({
              ...state,
              isLoading: false,
              tabLoader: false,
              error: {
                hasAnyError: true,
                message: data && data.error ? data.error : "",
                statusCode: data
                  ? data.status ||
                    (data.message && data.message == "Access denied."
                      ? 401
                      : 500) ||
                    500
                  : 500,
              },
            }));
            this.props.updateModalState(true, "ERROR", {
                message: data.message,
            });
          }
        });
    };
    
    setDefaultTerm = (currentTerm, index) => {
        let payload = {
            name: '',
            due_days: 0,
            early_payer_discount_days: 0,
            early_payer_discount_rate: 0,
            late_payer_penalty_rate: 0,
            early_payer_discount_rate_percent_based: true,
            late_payer_penalty_rate_percent_based: true,
            id: currentTerm.id,
            is_default: true
        }
        payload.name = currentTerm.name;
        payload.due_days = currentTerm.due_days;
        payload.early_payer_discount_days = currentTerm.early_payer_discount_days;
        payload.early_payer_discount_rate_percent_based = currentTerm.early_payer_discount_rate_percent_based;
        payload.early_payer_discount_rate = currentTerm.early_payer_discount_rate;
        payload.late_payer_penalty_rate_percent_based = currentTerm.late_payer_penalty_rate;
        payload.late_payer_penalty_rate = currentTerm.late_payer_penalty_rate_percent_based;

        //TO_DO
        this.UpdateClientTermAPI(payload, index);
    }

    getFormatedEarlyPayerDiscRate = (item) => {
        let amount = '';
        if (item.early_payer_discount_rate_percent_based && item.early_payer_discount_rate_percent_based === true){
            if (item.early_payer_discount_rate) {
                amount = item.early_payer_discount_rate + "%";
            } else {
                amount = "0%";
            }
        } else {
            if (item.early_payer_discount_rate) {
                amount = "$" + item.early_payer_discount_rate;
            } else {
                amount = "$0";
            }    
        }
        return amount;
    }

    getFormatedLatePayerPenaltyRate = (item) => {
        let amount = '';
        if (item.late_payer_penalty_rate_percent_based && item.late_payer_penalty_rate_percent_based === true){
            if (item.late_payer_penalty_rate) {
                amount = item.late_payer_penalty_rate + "%";
            } else {
                amount = "0%";
            }    
        } else {
            if (item.late_payer_penalty_rate) {
                amount =  "$" + item.late_payer_penalty_rate;
            } else {
                amount =  "$0";
            }    
        }
        return amount;
    }

    handleIsDefault = (e, item, index) => {
        // e.stopImmediatePropagation();
        // e.stopPropagation();
        // this.setState({isDefaultValue: e.target.checked})
        if (e.target.checked) {
            this.termDefaultConfirmAlert(item, index);
        } else {
            this.props.updateModalState(true, "ERROR", {
                message: 'Atleast one term should be set as a default term.',
                title: 'Alert'
              });
        }
    }

    termDefaultConfirmAlert = (item, index) => {
        this.props.updateModalState(true, "CONFIRM_ALERT", {
            message: `Are you sure want to set term '${item.name}' as a default term?`,
            confirmClick: () => {
              this.setDefaultTerm(item, index);
            },
            closeOnClickOutside: true,
            cancelClick: () => {
            },
            isCancelCallback: true
          },);
    }

    //returns formatted table and card data
    getObjectListData = (data) => {
        let headings = [],
        columns = [];

        headings = ["Name", "Due Days", "Early Payer Disc. Days", "Early Payer Disc. Rate", "Late Payer Penalty Rate", "Default", "expander"];

        columns = data.map((item, index) => {
        return {
            primaryRow: {
            key: "Name",
            title: item.name ? item.name : "",
            },
            secondaryColumns: [
            {
                title: item.name ? item.name : "",
                hideForMobile: true,
            },
            {
                key: "Due Days",
                title: item.due_days ? item.due_days : "0",
            },
            {
                key: "Early Payer Disc. Days",
                title: item.early_payer_discount_days ? item.early_payer_discount_days : "0",
            },
            {
                key: "Early Payer Disc. Rate",
                title: this.getFormatedEarlyPayerDiscRate(item),
            },
            {
                key: "Late Payer Penalty Rate",
                title: this.getFormatedLatePayerPenaltyRate(item)
            },
            {
                key: "Default",
                title: <div className="tax-value-wrapper">
                <input 
                    className="tax-checkbox"
                    type="checkbox"
                    name='isDefault'
                    id="isDefault"
                    title={item.is_default ? 'This is default term.' : 'Click to set is default'}
                    style={{pointerEvents: 'auto', cursor: 'pointer'}}
                    defaultChecked={item.is_default}
                    checked={item.is_default}
                    onChange={(e)=>this.handleIsDefault(e, item, index)} 
                    />
                {/* <span>{row.is_taxable == true ? this.getTaxValue(row) : 'Not Taxable'}</span> */}
              </div>
            },
            {
                rowType: "arrow-click",
                optionalClasses: "expander",
            },
            ],
            rowData: item,
        };
        });

        return {
        headings,
        columns,
        };
    };

    showLoader = () => {
        return (
            <div style={{ marginTop: "20%" }}>
                <AppCanvasLoader />
            </div>
        );
    };

    render(){
        const { appName } = this.props;
        const { isLoading, termsList } = this.state;

        return isLoading ? (
            this.showLoader()
          ) :(
            <div className="consumer-help ">
                <Helmet>
                    <title>{appName} | Terms</title>
                </Helmet>
                <div className="big-device-grad ">
                    <div className="goods-services-utility term-setting-utility">
                        <div>
                            <div className="he-hed">Terms</div>
                        </div>
                        <input
                            type="submit"
                            value={"+ Add Term"}
                            className="cta mg-brand2-color"
                            style={{ width: "auto" }}
                            onClick={(event) => this.handleAddTermClick(event)}
                        />
                    </div>
                    <br/>

                    <BottomScrollListener
                        onBottom={this.handleContainerOnBottom}
                        debounce={0}
                    >
                        {(scrollRef) => (
                            <div ref={scrollRef} className="send-invoice-scroll scrollbar">
                                {/* cardview for mobile device */}
                                <ClientsCardView
                                    data={this.getObjectListData(termsList)}
                                    rowActionClick={this.rowExpanderClick}
                                />
                                {/* list view for big devices */}
                                <InvoiceObjectList
                                    data={this.getObjectListData(termsList)}
                                    hideForMobile={true}
                                    rowActionClick={this.rowExpanderClick}
                                    optionalTableClasses="terms-settings-list"
                                />
                                {this.state.showLoader && (
                                    <div>
                                        <AppCanvasLoader />
                                    </div>
                                )}
                            </div>
                        )}
                    </BottomScrollListener>
                </div>
            </div>
        )
    }
}

export default TermsSettings;