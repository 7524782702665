import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Table } from "reactstrap";
import InfoIcon from "../../assets/images/quickbooks/ic_info_gray.png";
import GpayIcon from "../../assets/images/payment/g-pay.svg";
import ApplePayIcon from "../../assets/images/payment/apple-pay.svg";
import CopyLinkIcon from "../../assets/images/payment/copy_link_gray.svg";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ToggleSwitch from "../../components/ToggleSwitch";
import * as API from "../../utils/api";

class ClientsSettingPayment extends Component {
  state = {
    isLoading: false,
    prefData: [],
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount = () => {
    this.getStripeConnectDetails();
    this.getPaymentPreferences();
  };

  handleClick = (e) => {
    e.preventDefault();
    API.startExpressPayment(this.props.user).then((data) => {
      if (typeof data != "undefined" || data != null) {
        if (data.data) {
          var stripe = window.Stripe(this.props.stripePubKey, {
            stripeAccount: data.data.connected_account_id,
          });
          stripe
            .redirectToCheckout({
              // Make the id field from the Checkout Session creation API response
              // available to this file, so you can provide it as argument here
              // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
              sessionId: data.data.session_id,
            })
            .then(function (result) {
              // If `redirectToCheckout` fails due to a browser or network
              // error, display the localized error message to your customer
              // using `result.error.message`.
            });
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
              (data.status_code && data.status_code == 401 ? 401 : 500) ||
              500
              : 500,
          },
        }));
      }
    });
  };

  //get payment preferences data
  getPaymentPreferences = () => {
    API.getPaymentPreferences(this.props.user).then((data) => {
      if (typeof data != "undefined" || data != null) {
        if (data.data) {
          this.setState({ prefData: data.data });
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
              (data.status_code && data.status_code == 401 ? 401 : 500) ||
              500
              : 500,
          },
        }));
      }
    });
  };

  //update payment pref on toggler click
  updatePaymentPreferences = (query) => {
    API.updatePaymentPreferences(this.props.user, query).then((data) => {
      if (typeof data != "undefined" || data != null) {
        if (data.status_code && data.status_code != 200) {
          this.getPaymentPreferences();
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
              (data.status_code && data.status_code == 401 ? 401 : 500) ||
              500
              : 500,
          },
        }));
      }
    });
  };

  //get stripe connection details
  getStripeConnectDetails = () => {
    API.getStripeConnectDetails(this.props.user).then((data) => {
      if (typeof data != "undefined" || data != null) {
        if (data.data) {
          this.props.updateStripeStatus(data.data);
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
              (data.status_code && data.status_code == 401 ? 401 : 500) ||
              500
              : 500,
          },
        }));
      }
    });
  };

  //calls on "Enable Payment" click to get redirection click
  enablePayments = (event) => {
    event.preventDefault();
    API.enablePayment(this.props.user).then((data) => {
      if ((typeof data != "undefined" || data != null) && data.data) {
        if (typeof data.data != "undefined" || data.data != null) {
          window.open(data.data, "_self");
          this.setState({ isLoading: true });
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
              (data.status_code && data.status_code == 401 ? 401 : 500) ||
              500
              : 500,
          },
        }));
      }
    });
  };

  toggleButton = (type) => {
    let { prefData } = this.state;
    let query = {};
    if (type == "PORTAL") {
      prefData.payment_portal = !prefData.payment_portal;
      query = { payment_portal: prefData.payment_portal };
    } else if (type == "CC") {
      prefData.credit_card = !prefData.credit_card;
      query = { credit_card: prefData.credit_card };
    } else if (type == "ACH") {
      prefData.ach = !prefData.ach;
      query = { ach: prefData.ach };
    } else if (type == "GPAY") {
      prefData.gpay = !prefData.gpay;
      query = { gpay: prefData.gpay };
    } else if (type == "APPLEPAY") {
      prefData.apple_pay = !prefData.apple_pay;
      query = { apple_pay: prefData.apple_pay };
    }
    this.setState({ prefData });
    if (query) {
      this.updatePaymentPreferences(query);
    }
  };

  getObjectListData = () => {
    let headings = [],
      columns = [];

    headings = [
      "Payment Methods",
      "Bank / Credit Card Types",
      "Pricing",
      "Payment Processing",
    ];

    columns = [
      {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Payment Methods",
            title: "Credit Card",
          },
          {
            key: "Bank / Credit Card Types",
            title: (
              <div>
                Visa, <br />
                Mastercard, <br />
                American Express,
                <br />
                Discover
              </div>
            ),
          },
          {
            key: "Pricing",
            title: (
              <div>
                2.9% + .30 Per Successful Charge
                <br />
                +1% for International Cards
                <br />
                +1% if currency conversion required
                <br />
              </div>
            ),
          },
          {
            key: "Payment Processing",
            title: "1 Days",
          },
        ],
      },
      {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Payment Methods",
            title: "ACH Direct Debit",
          },
          {
            key: "Bank / Credit Card Types",
            title: "US Bank Account",
          },
          {
            key: "Pricing",
            title: (
              <div>
                0.8% - Capped at $5.00
                <br />
                $4 for failed ACH Direct Debits
                <br />
                $15 for disputed ACH Direct Debits
                <br />
              </div>
            ),
          },
          {
            key: "Payment Processing",
            title: "5 - 7 Days",
          },
        ],
      },
    ];

    return {
      headings,
      columns,
    };
  };

  renderConnectStripe = () => {
    return (
      <div className="big-device-grad  tp-sp">
        <div className="he-hed">Hello!</div>
        <div className="set-half">
          <p className="sub-hed-p">
            Enable your payment portal today and start easily accepting payments
            from your customers. Click the Enable Payments button below to get
            started now.
          </p>
          <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <button
              onClick={(e) => this.enablePayments(e)}
              className="cta mg-brand2-color"
            >
              Enable Payments
            </button>
          </div>
          <p className="sub-hed-p">
            We use Stripe to make sure you get paid on time and to keep you bank
            and details secure. Click the Enable Payments above to get started.
          </p>
        </div>
        {this.paymentDescTable()}
        {this.payoutsTable()}
      </div>
    );
  };

  paymentDescTable = () => {
    return (
      <div>
        <div className="pref-selection" style={{ marginBottom: "0.5em" }}>
          <div className="pref-selection-header">
            Payment Processing Fees & Processing Times
          </div>
          <div className="pref-selection-sep" />
        </div>
        <Table responsive bordered className="reactstrap-table pay-method-tab">
          <thead>
            <tr className="list-header">
              <th>Payment Methods</th>
              <th>Bank / Credit Card Types</th>
              <th>Payment Processing Fees</th>
              <th>
                Payment Processing
                <img
                  src={InfoIcon}
                  className="info-tooltip"
                  style={{marginTop: 0}}
                  title="The amount of days from when a payment is submitted to when a payment is settled in your Stripe Balance"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Credit Card</th>
              <th>
                Visa,
                <br />
                Mastercard,
                <br />
                American Express,
                <br />
                Discover
              </th>
              <th>
                2.9% + .30 Per Successful Charge
                <br />
                +1% for International Cards
                <br />
                +1% if currency conversion required
              </th>
              <th>Immediate</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th scope="row">ACH Direct Debit</th>
              <th>US Bank Account</th>
              <th>
                0.8% - Capped at $5.00
                <br />
                $4 for failed ACH Direct Debits
                <br />
                $15 for disputed ACH Direct Debits
              </th>
              <th>4 - 5 Days</th>
            </tr>
          </tbody>
        </Table>
        <br />
        {/* <div>
          <div className="pref-selection">
            <div className="pref-selection-header">Questions?</div>
            <div className="pref-selection-sep" />
          </div>
          <p className="qb-p set-half">
            Click the HELP button at any time for chat, email, or phone support,
            or to visit our FAQ section.
          </p>
        </div> */}
      </div>
    );
  };

  payoutsTable = () => {
    return (
      <div>
        <div className="pref-selection" style={{ marginBottom: "0.5em" }}>
          <div className="pref-selection-header">
            Payouts
          </div>
          <div className="pref-selection-sep" />
        </div>
        <Table responsive bordered className="reactstrap-table pay-method-tab">
          <thead>
            <tr className="list-header">
              <th>Payout Types</th>
              <th>Payout Schedules</th>
              <th>
                Payout Fees
                <img
                  src={InfoIcon}
                  className="info-tooltip"
                  style={{marginTop: 0}}
                  title="Payout volume is the amount of funds sent to the associated bank account."
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Standard Payouts</th>
              <th>
                2 Business Days
                <img
                  src={InfoIcon}
                  className="info-tooltip"
                  style={{marginTop: 0}}
                  title="Funds are deposited from your Stripe balance to your bank account in 2 business days."
                />
              </th>
              <th>
                .25 Cents +
                <br />
                .25% of payout volume
              </th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th scope="row">Instant Payouts</th>
              <th>
                {'< 30 Minutes'}
                <img
                  src={InfoIcon}
                  className="info-tooltip"
                  style={{marginTop: 0}}
                  title="Instant Payouts can be requested 24/7, including weekends and holidays, and funds typically appear in the associated bank account within 30 minutes."
                />
                </th>
              <th>
                1% of payout volume
              </th>
            </tr>
          </tbody>
        </Table>
        <br />
        <div>
          <div className="pref-selection">
            <div className="pref-selection-header">Questions?</div>
            <div className="pref-selection-sep" />
          </div>
          <p className="qb-p set-half">
            Click the HELP button at any time for chat, email, or phone support,
            or to visit our FAQ section.
          </p>
        </div>
      </div>
    );
  };

  renderStripePref = () => {
    let { prefData } = this.state;
    let url =
      window && window.location && window.location.origin
        ? window.location.origin
        : "";
    url = url + "/payment";
    return (
      <div className="big-device-grad  tp-sp">
        <div className="he-hed">Payments Preferences</div>
        <div className="pref-selection">
          <div className="pref-selection-header">Payment Portal</div>
          <div className="pref-selection-sep" />
          <div className="pref-selection-inner">
            <div className="pref-selection-inner-left">
              <p className="pref-selection-rdtx">
                Turn the Payment Portal on to allow your customers to make
                payments through your own branded payment portal
              </p>
              <ToggleSwitch
                isOn={prefData.payment_portal}
                toggleButton={() => this.toggleButton("PORTAL")}
              />
            </div>
          </div>
          <div className="pref-selection-inner md-sp">
            <div style={{ display: "flex" }}>
              <div>
                Here is the link to your payment portal{" "}
                <a href={url} target="_blank" style={{ cursor: "pointer" }}>
                  {url}
                </a>
              </div>
              <CopyToClipboard
                text={url}
              // onCopy={() => this.handleSubmit()}
              >
                <a
                  style={{ cursor: "pointer", marginLeft: 10 }}
                  title="Copy Payment Portal URL"
                >
                  <img src={CopyLinkIcon} height={18} />
                </a>
              </CopyToClipboard>
            </div>
          </div>
        </div>
        <div className="pref-selection">
          <div className="pref-selection-header">Payment Methods</div>
          <div className="pref-selection-sep" />
          <div className="pref-selection-inner">
            <div className="pref-selection-inner-left">
              <p className="pref-selection-rdtx">
                Credit Cards - accept Visa, Mastercard, American Express,
                Discover and more
              </p>
              <ToggleSwitch
                isOn={prefData.credit_card}
                toggleButton={() => this.toggleButton("CC")}
              />
            </div>
            <div className="pref-selection-inner-right">
              <img src={InfoIcon} className="pref-selection-info-ic" />
              <p className="pref-selection-sb-txt">
                If toggled off then CC is not accepted
              </p>
            </div>
          </div>
          <div className="pref-selection-inner md-sp">
            <div className="pref-selection-inner-left">
              <p className="pref-selection-rdtx">
                ACH Direct Debit - accept bank account transfers via ACH
              </p>
              <ToggleSwitch
                isOn={prefData.ach}
                toggleButton={() => this.toggleButton("ACH")}
              />
            </div>
            <div className="pref-selection-inner-right">
              <img src={InfoIcon} className="pref-selection-info-ic" />
              <p className="pref-selection-sb-txt">
                If toggled off then ACH is not accepted
              </p>
            </div>
          </div>
        </div>
        <div className="pref-selection" style={{ marginBottom: "1em" }}>
          <div className="pref-selection-header">Wallets</div>
          <div className="pref-selection-sep" />
          <div className="pref-selection-inner">
            <div className="pref-selection-inner-left">
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <p className="pref-selection-rdtx">GPay -</p>
                <img
                  src={GpayIcon}
                  height={18}
                  style={{ margin: "5px 0px 0px 10px" }}
                />
              </div>
              <ToggleSwitch
                isOn={prefData.gpay}
                toggleButton={() => this.toggleButton("GPAY")}
              />
            </div>
          </div>
          <div className="pref-selection-inner md-sp">
            <div className="pref-selection-inner-left">
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <p className="pref-selection-rdtx">Apple Pay -</p>
                <img
                  src={ApplePayIcon}
                  height={18}
                  style={{ margin: "3px 0px 0px 10px" }}
                />
              </div>
              <ToggleSwitch
                isOn={prefData.apple_pay}
                toggleButton={() => this.toggleButton("APPLEPAY")}
              />
            </div>
          </div>
        </div>
        <br />
        {this.paymentDescTable()}
        {this.payoutsTable()}
      </div>
    );
  };

  render() {
    const { appName, connectStripeData } = this.props;
    const { isLoading, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <div style={{ marginTop: "20%" }}>
        <AppCanvasLoader />
      </div>
    ) : (
      <div className="consumer-help">
        <Helmet>
          <title>{appName} | Payments</title>
        </Helmet>
        {connectStripeData &&
          connectStripeData.stripe_connect_status &&
          connectStripeData.stripe_connect_status == "ACCEPTING PAYMENTS"
          ? this.renderStripePref()
          : this.renderConnectStripe()}
        {/* <button onClick={this.handleClick}>click me</button> */}
      </div>
    );
  }
}

export default ClientsSettingPayment;
