import moment from "moment";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import DropdownButton from "../../components/DropdownButton";
import MenuItem from "../../components/MenuItem";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import AppSectionHeader from "../../components/App/AppSectionHeader";
import Panel from "../../components/App/Panel";
import ClientsAgingDetailReport from "../../components/Clients/Reports/ClientsAgingDetailReport";
import ClientsAgingSummaryReport from "../../components/Clients/Reports/ClientsAgingSummaryReport";
import ClientsCollectionsReport from "../../components/Clients/Reports/ClientsCollectionsReport";
import ClientsCustomerBalanceDetail from "../../components/Clients/Reports/ClientsCustomerBalanceDetail";
import * as API from "../../utils/api";
import companyData from "../../assets/styles/theme.json";

class ClientsReportView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportPeriod: "Today",
      reportSlug: "aging_detail",
      fromDate: "",
      toDate: "",
      data: [],
      AgingSummaryData: [],
      isLoading: true,
      isDownloading: false,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
    };
  }

  componentDidMount() {
    this.getReportData();
  }

  getReportData = () => {
    const reportId = this.props.match.params.id;
    API.arAgingSummaryList(this.props.user, reportId, '', '').then(
      (data) => {
        if (typeof data != "undefined" || data != null) {
          if (data.data) {
            this.setState({
              AgingSummaryData: data.data,
              reportName: data.name,
              reportSlug: data.report_slug,
            });
          }
          this.setState({ isLoading: false });
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    );
  };

  downloadReport = (type) => {
    const reportId = this.props.match.params.id;
    const { reportName } = this.state;
    // $('#download-report').replaceWith('<div class="spinner"></div>');
    this.setState({ isDownloading: true });
    API.downloadReport(
      this.props.user,
      reportId,
      type.toUpperCase(),
      "",
      ""
    ).then((data) => {
      if (typeof data != "undefined" || (data != null && !data.error)) {
        let url = window.URL.createObjectURL(data);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${reportName ? reportName : "report"}.${type}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        this.setState({ isDownloading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  handleInputChange = (value, type) => {
    if (type === "to") {
      this.setState({ toDate: value });
    } else {
      this.setState({ fromDate: value });
    }
  };

  renderDownloadBtn = (isMobile) => {
    let { isDownloading } = this.state;
    const reportId = this.props.match.params.id;

    return (
      <DropdownButton
        title={isDownloading ? "Downloading..." : "Download"}
        // primaryLink={`/client/reports/${reportId}`}
        parentOptClasses={isMobile ? "re-down-mob" : ""}
        buttonInnerClasses={isMobile ? "down-inner" : ""}
        overrideStyle={true}
        forFilter={true}
        onPrimaryClick={(event) => ""}
      >
        <MenuItem handleClick={() => this.downloadReport("pdf")}>
          Download PDF
        </MenuItem>
        <MenuItem handleClick={() => this.downloadReport("csv")}>
          Download CSV
        </MenuItem>
        {/*<MenuItem handleClick={() => this.downloadReport("xls")}>
          Download Excel
        </MenuItem>*/}
      </DropdownButton>
    );
  };

  utilityHeader = () => {
    return (
      <div
        className="report-utility mobile-hide-list"
        style={{ marginLeft: "auto", marginTop: "auto" }}
      >
        {this.renderDownloadBtn()}
      </div>
    );
  };

  render() {
    const { appName } = this.props;
    const {
      reportName,
      isLoading,
      error,
      AgingSummaryData,
      reportSlug,
    } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div>
        <AppSectionHeader
          title={reportName}
          titleClases="report-title"
          backLink={{
            text: `Back to all Reports`,
            path: `/client/reports`,
          }}
          utilities={this.utilityHeader()}
        />
        <div className="mobile-re-btn">{this.renderDownloadBtn(true)}</div>
        <div
          className="app-sidebar-layout-canvas-content scrollbar mobile-hide-list"
          style={{ height: window.innerHeight * 0.7, paddingBottom: 100 }}
        >
          {AgingSummaryData ? (
            <Panel>
              <div className="cl-re">
                <div className="re-com">{companyData.clientName}</div>
                <div className="re-name">{reportName}</div>
                <div className="re-date">
                  As of {moment().format("MMM DD, YYYY")}
                </div>
              </div>
              {reportSlug == "aging_summary" ? (
                <ClientsAgingSummaryReport data={AgingSummaryData} />
              ) : reportSlug == "aging_detail" ? (
                <ClientsAgingDetailReport data={AgingSummaryData} />
              ) : reportSlug == "collection" ? (
                <ClientsCollectionsReport data={AgingSummaryData} />
              ) : reportSlug == "invoice_list" ? (
                <ClientsAgingSummaryReport
                  data={AgingSummaryData}
                  invoiceReport={true}
                />
              ) : reportSlug == "customer_balance_detail" ? (
                <ClientsCustomerBalanceDetail data={AgingSummaryData} />
              ) : reportSlug == "customer_balance_summary" ? (
                <ClientsAgingSummaryReport
                  data={AgingSummaryData}
                  customerSummary={true}
                />
              ) : (
                <div />
              )}
            </Panel>
          ) : (
            <div
              className="admin-admins-empty-state"
              style={{ marginTop: "2em" }}
            >
              <p>Report appears here?</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ClientsReportView;
