import moment from "moment";
import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
// Components
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
// API
import * as API from "../../utils/api";
import { getZohoPagesense, getDollarAmount } from "../../utils/helpers";

class PaymentHistoryOffline extends Component {
  constructor() {
    super();
    this.state = {
      savedData: [],
      fromChild: "",
      isLoading: true,
      hasMoreData: false,
      showFlash: false,
      type: {
        value: "Offline",
        hasError: false,
        isRequired: true,
        offset: 0,
      },
      search: {
        value: "",
      },
      showLoader: false,
    };
  }

  componentDidMount() {
    this.getPaymentHistoryData();
    getZohoPagesense();
    ReactGA.pageview("/PaymentHistoryOffline");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchText !== this.props.searchText) {
      this.setState(
        (state) => ({
          ...state,
          savedData: [],
          isLoading: true,
          search: {
            value: nextProps.searchText,
          },
          type: {
            ...state.type,
            offset: 0,
          },
        }),
        () => {
          this.getPaymentHistoryData();
        }
      );
    }
  }

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.type.offset) + 1;
      this.setState(
        {
          type: {
            ...this.state.type,
            offset: offsetCount,
          },
        },
        () => {
          this.getPaymentHistoryData();
        }
      );
    }
  };

  getPaymentHistoryData() {
    const { user } = this.props;
    let offset = this.state.type.offset;
    let searchText = this.state.search.value;

    this.setState((state) => ({ ...state, showLoader: true }));
    API.fetchPaymentHistory(user, "client", "Offline", offset, searchText).then(
      (data) => {
        if ((typeof data != "undefined" || data != null) && !data.errors) {
          let savedData = this.state.savedData;
          let newData = savedData.concat(data.data);
          this.setState((state) => ({
            savedData: newData,
            isLoading: false,
            hasMoreData: data.data.length < 20 ? false : true,
            showLoader: false,
          }));

          this.setState((state) => ({
            ...state,
            type: {
              ...state.type,
              offset: data.offset,
            },
          }));
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
            showLoader: false,
          }));
        }
      }
    );
  }

  renderLatestPaymentDate = (date) => {
    return moment(date).format("MM/DD/YYYY HH:mm:ss A");
  };

  getFullName = (firstName, lastName) => {
    let fullName = "n/a";
    if (firstName || lastName) {
      fullName = firstName + " " + lastName;
    }
    return fullName;
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      "Invoice Number",
      "Name",
      "Payment Method",
      "Admin Name",
      "Date/Time",
      "Amount",
      "Status",
    ];

    columns = data.map((row) => {
      let statusPillClass = null;
      if(row.is_refund) {
        statusPillClass = "refund-message rm-scrll-w";
      } else if(row.is_cancelled) {
        statusPillClass = "fail-message rm-scrll-w";
      } else if (row.status == "SUCCESS") {
        statusPillClass = "success-message rm-scrll-w";
      } else {
        statusPillClass = "fail-message rm-scrll-w";
      }

      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Invoice Number",
            title: row.invoice_number
          },
          {
            key: "Name",
            title: row.display_name,
          },
          {
            key: "Payment Method",
            title: `${row.payment_type ? row.payment_type == 'Cheque' ? "Check" : row.payment_type : "n/a"}`,
          },
          {
            key: "Admin Name",
            title: this.getFullName(row.admin_first_name, row.admin_last_name),
          },
          {
            key: "Date/Time",
            title: row.date
              ? this.renderLatestPaymentDate(row.date)
              : row.created_at
              ? this.renderLatestPaymentDate(row.created_at)
              : "n/a",
          },
          {
            key: "Amount",
            title: getDollarAmount(row.is_cancelled ? row.cancel_amount : row.amount),
          },
          {
            key: "Status",
            title: `${row.is_cancelled ? "CANCELLED" : row.is_refund ? "REFUNDED" : row.status === "SUCCESS" ? row.status : "FAIL"}`,
            optionalClasses: statusPillClass
          },
        ],
      };
    });
    return {
      headings,
      columns,
    };
  };

  render() {
    const { isLoading } = this.state;
    let isSmallDevice = window.innerWidth <= 680 ? true : false;

    return isLoading ? (
      <div style={{ marginTop: `10rem` }}>
        <AppCanvasLoader />
      </div>
    ) : (
      <div>
        {this.state.savedData.length > 0 ? (
          <div>
            <BottomScrollListener
              onBottom={this.handleContainerOnBottom}
              debounce={0}
            >
              {(scrollRef) => (
                <div>
                  {isSmallDevice ? (
                    <div className="big-device-grad invoice-line">
                      <div ref={scrollRef} className="scrollbar in-sc">
                        <ClientsCardView
                          data={this.getObjectListData(this.state.savedData)}
                          rowActionClick={(event) =>
                            this.handleRowActionClick(event)
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div style={{ marginBottom: "1em" }}>
                      <InvoiceObjectList
                        data={this.getObjectListData(this.state.savedData)}
                        rowActionClick={(event) =>
                          this.handleRowActionClick(event)
                        }
                        hideForMobile={true}
                        optionalClasses="scrollbar in-sc"
                        optionalTableClasses="payment-cols-offline"
                        scrollRef={scrollRef}
                      />
                      {this.state.showLoader && <AppCanvasLoader />}
                    </div>
                  )}
                  {this.state.showLoader && <AppCanvasLoader />}
                </div>
              )}
            </BottomScrollListener>
          </div>
        ) : (
          <div className="admin-admins-empty-state">
            <h3 className="empty-mesg-mg">No payment history available.</h3>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(PaymentHistoryOffline);
