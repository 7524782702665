import React, { Component } from 'react'
import { withRouter, Redirect } from "react-router-dom"
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import moment from 'moment';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

// Components
import AppSectionHeader from '../../components/App/AppSectionHeader'
import AppCanvasLoader from '../../components/App/AppCanvasLoader'
import EmployeePaymentHistoryAll from './EmployeePaymentHistoryAll'
import EmployeePaymentHistoryOffline from './EmployeePaymentHistoryOffline'
import EmployeePaymentHistoryOnline from './EmployeePaymentHistoryOnline'

import {getZohoPagesense}  from '../../utils/helpers';


class EmployeePaymentHistory extends Component {

  constructor(props) {
    super();
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.state = {
      savedData: [],
      flag: false,
      isLoading: true,
      searchData: '',
      showFlash: false,
      type: {
        value: 'All',
        hasError: false,
        isRequired: true,
        offset: 0,
      },
      search: {
        value: ""
      },
    }
  }

  componentDidMount() {
    this.setState(state => ({
      isLoading: false
    }))
    getZohoPagesense();
    ReactGA.pageview('/PaymentHistory');
  }


  handleInputChange(newPartialInput) {
    const { user } = this.props;
    let type = newPartialInput.type.value;
    let offset = 0;

    this.setState(state => ({
      ...state,
      type: {
        ...state.type,
        value: type
      },
      search: {
        value: ''
      },
      searchData: ''
    }));
  }


  handleKeyPress(event) {
    let searchText = this.state.search.value;
    if (event.key === 'Enter') {
      this.setState(state => ({
        ...state,
        searchData: searchText,
      }));
    }
  }

  handleSearchInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      search: {
        value: newPartialInput.search.value,
      }
    }),
      () => {
        if (newPartialInput.search.value.length === 0) {
          this.handleKeyPress({ 'key': 'Enter' });
        }
      }
    );
  }

  renderLatestPaymentDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  }

  getSectionHeaderUtilities() {
    const { type, search } = this.state;

    return (
      <div className="app-section-header-utilities">
        <div className="radio">
          <label style={{ paddingLeft: `31px`}}>
            <input type="radio" name="Type" value={'All'} defaultChecked={true} style={{ WebkitAppearance: `radio`, width: `20px`, position: `absolute`, left: `0`, MozAppearance: `radio` }}
              onClick={event => this.handleInputChange({
                type: { value: 'All', hasError: false }
              }
              )}
            />
            <div style={{ padding: `7px 0` }}>{'All'}</div>
          </label>
        </div>
        <div className="radio">
          <label style={{ paddingLeft: `31px`}}>
            <input type="radio" name="Type" value={'Online'} defaultChecked={false} style={{ WebkitAppearance: `radio`, width: `20px`, position: `absolute`, left: `0`, MozAppearance: `radio` }}
              onClick={event => this.handleInputChange({
                type: { value: 'Online', hasError: false }
              }
              )}
            />
            <div style={{ padding: `5px 0` }}>{'Online Payment'}</div>
          </label>
        </div>
        <div className="radio">
          <label style={{ paddingLeft: `31px`}}>
            <input type="radio" name="Type" value={'Offline'} defaultChecked={false} style={{ WebkitAppearance: `radio`, width: `20px`, position: `absolute`, left: `0`, MozAppearance: `radio` }}
              onClick={event => this.handleInputChange({
                type: { value: 'Offline', hasError: false }
              }
              )}
            />
            <div style={{ padding: `5px 0` }}>{'Offline Payment'}</div>
          </label>
        </div>
        <div className="admin-search-input-container">
          <input
            type="text"
            name="search"
            placeholder="Search by name"
            value={search.value}
            className="admin-search-input"
            onKeyDown={this.handleKeyPress}
            onChange={event => this.handleSearchInputChange(
              { search: { value: event.target.value } }
            )}
          />
          <span className="search-icon" onClick={() => this.handleKeyPress({ 'key': 'Enter' })} />
        </div>
      </div>
    )
  }

  render() {
    const { appName } = this.props;
    const { isLoading, type, searchData, flag } = this.state;
    return isLoading
      ? (
        <AppCanvasLoader />
      )
      : (
        <div className="consumer-debt-detail-form" style={{ paddingTop: 0 }}>
          <Helmet>
            <title>{appName} | Payment History</title>
          </Helmet>
          <AppSectionHeader
            title="History"
            utilities={this.getSectionHeaderUtilities()}
          />
          {
            type.value === 'All' ?
              <EmployeePaymentHistoryAll
                user={this.props.user}
                searchText={searchData}
              />
              :
              type.value === 'Offline' ?
                <EmployeePaymentHistoryOffline
                  user={this.props.user}
                  searchText={searchData}
                />
                :
                type.value === 'Online' ?
                  <EmployeePaymentHistoryOnline
                    user={this.props.user}
                    searchText={searchData}
                  />
                  :
                  <div className="admin-admins-empty-state">
                    <h2>
                      No payment history available.
                  </h2>
                  </div>
          }
        </div>
      );
  }

}

export default withRouter(EmployeePaymentHistory)
