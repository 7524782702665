
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import account from "../../assets/images/navbar/accounts.png";

class SuperAdminNav extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
  }

  isActive(linkUrl) {
    if (this.props.path.includes(linkUrl)) {
      return 'active';
    }
  }

  render() { 
    return (
      <nav className="admin-nav client-nav scrollbar">
        <Link
          className={`admin-nav-link client-nav-link ${this.isActive(
            "/super-admin/accounts"
          )}`}
          to="/super-admin/accounts"
        >
          <img src={account} width="34" height="34" className="icon" />
          ACCOUNTS
        </Link>
        <Link
          className={`admin-nav-link client-nav-link ${this.isActive(
            "/super-admin/profile"
          )}`}
          to="/super-admin/profile"
        >
          <img src={account} width="34" height="34" className="icon" />
          PROFILES
        </Link>
      </nav>
    );
  }
}

export default SuperAdminNav;
