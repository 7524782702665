import PropTypes from "prop-types";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// Components
import AppAdminHeader from "../../components/App/AppAdminHeader";
import SuperAdminNav from "../../components/SuperAdmin/SuperAdminNav";

class SuperAdminPortal extends Component {
  static propTypes = {
    location: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    screen: PropTypes.object.isRequired,
  };

  render() {
    if (!this.props.isLoggedIn || !this.props.user.super_admin) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: this.props.location },
          }}
        />
      );
    }

    return (
      <div className="app-portal">
        <AppAdminHeader
          user={this.props.user}
          appName={this.props.appName}
          logoPath="/super-admin/accounts/"
        />
        <div className="app-portal-canvas">
          <SuperAdminNav path={this.props.location} />
          <div className="wrap">{this.props.screen}</div>
        </div>
      </div>
    );
  }
}

export default SuperAdminPortal;
