import moment from "moment";
import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import MenuItem from "../../components/MenuItem";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import AppSectionHeader from "../../components/App/AppSectionHeader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import * as API from "../../utils/api";

import downloadImg from "../../assets/icons/download.png";
import editImg from "../../assets/icons/edit_blue.svg";
import {
  getDollarAmount,
  getZohoPagesense,
  showToast,
} from "../../utils/helpers";
import Emitter from "../../utils/event-emitter";

class ClientsInvoices extends Component {
  constructor(props) {
    super(props);
    this.dropDownRef = React.createRef();
  }

  state = {
    invoices: [],
    sortHeader: "Invoice Number",
    sortType: false,
    isLoading: true,
    showLoader: false,
    hasMoreData: false,
    dropdownIsActive: false,
    offset: 0,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    getZohoPagesense();
    ReactGA.pageview("/clients-invoices");
    this.getInvoiceList();
    document.addEventListener("click", this.handleOutsideClick.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  }

  handleOutsideClick(event) {
    if (
      this.dropDownRef &&
      this.dropDownRef.current &&
      !this.dropDownRef.current.contains(event.target)
    ) {
      this.setState({ dropdownIsActive: false });
    }
  }

  getInvoiceList = (reload) => {
    let sort_key = this.getSortKey();
    let sort_by = this.state.sortType ? "asc" : "desc";
    API.getInvoiceList(
      this.props.user,
      sort_key,
      sort_by,
      this.state.offset
    ).then((data) => {
      Emitter.emit('IS_QUICKBOOK_CONNECTED', data.qb_connected);
      if (
        typeof data != "undefined" ||
        (data != null && data && data.status_code == 200)
      ) {
        if (data.data) {
          let invoice = reload ? this.state.invoices : false;
          let newData = invoice ? invoice.concat(data.data) : data.data;
          this.setState((state) => ({
            ...state,
            invoices: newData,
            hasMoreData: data.data.length < 15 ? false : true,
          }));
        }
        this.setState({ isLoading: false, showLoader: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          showLoader: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  goToEditInvoice = (id) => {
    this.getInvoiceDetails(id)
  }

  downloadInvoice = (doc_id, fileName) => {
    const { user } = this.props;
    API.getAttachedDocuement(user, doc_id, 'debts').then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let flag = data.data.includes("base64");
          if (!flag) {
            data.data = "data:application/pdf;base64," + data.data;
          }
          let url = data.data;
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
              (data.message && data.message == "Access denied."
                ? 401
                : 500) ||
              500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  //getInvoiceDetails
  getInvoiceDetails = (invoiceId) => {
    let { user } = this.props;
    API.getInvoiceDetails(user, invoiceId).then((data) => {
      Emitter.emit('IS_QUICKBOOK_CONNECTED', data.qb_connected);
      if (
        (typeof data != "undefined" || data != null) &&
        data.message === "success" &&
        !data.error
      ) {
        if (data.data) {
          this.handleEditClick(data.data);
        }
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
              (data.status_code && data.status_code == 401 ? 401 : 500) ||
              500
              : 500,
          },
        }));
      }
    });
  };

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
          showLoader: true,
        }),
        () => {
          this.getInvoiceList(true);
        }
      );
    }
  };

  //go to edit invoice
  handleEditClick = (data) => {
    let { history } = this.props;
    const id = this.props.match.params.id;
    history.push({
      pathname: `/client/add/invoices`,
      state: {
        invoiceDetails: data,
        backPath: `/client/invoices`,
      },
    });
  };

  handleRowActionClick = (event) => {
    const qbId = event.rowData.id;
    let { history } = this.props;
    if (qbId) {
      history.push(`/client/invoices/${qbId}`);
    }
  };

  sortInvoiceData = (header, type) => {
    this.setState(
      {
        sortHeader: header,
        sortType: type,
        offset: 0,
      },
      () => {
        this.getInvoiceList(false);
      }
    );
  };

  getSortKey = () => {
    let { sortHeader } = this.state;
    if (sortHeader === "Invoice Number") {
      return "invoice_number";
    } else if (sortHeader === "Account Name") {
      return "display_name";
    } else if (sortHeader === "Invoice Date") {
      return "txn_date";
    } else if (sortHeader === "Due Date") {
      return "due_date";
    } else if (sortHeader === "Invoice Amount") {
      return "total_amt";
    } else if (sortHeader === "Balance") {
      return "balance";
    }
  };

  getPaidAmount = (invoice) => {
    let amount = 0;
    if (invoice) {
      invoice.total_amt = invoice.total_amt ? invoice.total_amt : 0;
      invoice.tax = invoice.tax_amount ? invoice.tax_amount : 0
      amount = invoice.total_amt
        ? invoice.balance
          ? parseFloat(invoice.total_amt) - parseFloat(invoice.balance) + parseFloat(invoice.tax_amount)
          : parseFloat(invoice.total_amt)
        : parseFloat(invoice.total_amt) ;
    }
    return amount.toFixed(2);
  };

  getInvoiceDate = (date) => {
    if (date) {
      return moment.utc(date).format("MM/DD/YYYY");
    } else {
      return "--/--/----";
    }
  };

  checkInvoicePastDue = (date) => {
    return moment(date).isBefore(new Date());
  };

  getAccountStatus = (rowData) => {
    let flag = this.checkInvoicePastDue(rowData.due_date);
    return !flag;
  };

  toggleDropdown = () => {
    this.setState({ dropdownIsActive: !this.state.dropdownIsActive });
  };

  handleAddInvoiceClick = (e) => {
    let { history } = this.props;
    history.push({
      pathname: `/client/add/invoices`,
      state: { backPath: "/client/invoices" },
    });
  };

  getInvoiceListData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      "Invoice Number",
      "Account Name",
      "Invoice Date",
      "Due Date",
      "Invoice Amount",
      "Paid Amount",
      "Discount",
      "Balance",
      "Action",
      "expander",
    ];

    columns = data.map((row) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Invoice Number",
            title: row.invoice_number ? row.invoice_number : "n/a",
          },
          {
            key: "Account Name",
            title: row.display_name ? row.display_name : "",
          },
          {
            key: "Invoice Date",
            title: this.getInvoiceDate(row.txn_date),
          },
          {
            key: "Due Date",
            title: this.getInvoiceDate(row.due_date),
          },
          {
            key: "Invoice Amount",
            title: getDollarAmount(row.total_amt),
          },
          {
            key: "Paid Amount",
            title: getDollarAmount(this.getPaidAmount(row)),
          },
          {
            key: "Discount",
            title: getDollarAmount(row.adjustment),
          },
          {
            key: "Balance",
            title: getDollarAmount(row.balance),
          },
          {
            key: "Action",
            title: <> <button
              title="Edit"
              style={{
                background: "transparent",
                cursor: "pointer",
                marginRight: 10,
              }}
              onClick={() => this.goToEditInvoice(row.id)}
            >
              <img src={editImg} width="16" height="16" />
            </button>
              <button
                title="Download"
                style={{
                  background: "transparent",
                  cursor: "pointer",
                  marginRight: 10
                }}
                onClick={() => this.downloadInvoice(row.document_id, row.document_name)}
              >
                <img src={downloadImg} width="16" height="16" />
              </button> </>
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
          },
        ],
        rowData: row,
        status: this.getAccountStatus(row),
        taskStatus: row.balance && row.balance <= 0 ? true : false,
      };
    });

    return {
      headings,
      columns,
    };
  };

  renderInvoice = (tableData) => {
    let isSmallDevice = window.innerWidth <= 680 ? true : false;
    return (
      <BottomScrollListener
        onBottom={this.handleContainerOnBottom}
        debounce={0}
      >
        {(scrollRef) => (
          <div>
            {isSmallDevice ? (
              <div className="big-device-grad invoice-line">
                <div ref={scrollRef} className="scrollbar in-sc">
                  <ClientsCardView
                    data={this.getInvoiceListData(tableData)}
                    rowActionClick={(event) => this.handleRowActionClick(event)}
                    isStatus={true}
                  />
                </div>
              </div>
            ) : (
              <div style={{ marginBottom: "1em" }}>
                <InvoiceObjectList
                  data={this.getInvoiceListData(tableData)}
                  rowActionClick={(event) => this.handleRowActionClick(event)}
                  isStatus={true}
                  hideForMobile={true}
                  optionalClasses="scrollbar in-sc"
                  scrollRef={scrollRef}
                  sortHeaders={[this.state.sortHeader, this.state.sortType]}
                  sortInvoiceData={this.sortInvoiceData}
                />
              </div>
            )}
          </div>
        )}
      </BottomScrollListener>
    );
  };

  getSectionHeaderUtilities = () => {
    let { dropdownIsActive } = this.state;
    return (
      <div
        className="dropdown-button"
        style={{ marginLeft: "auto", marginTop: "auto" }}
        ref={this.dropDownRef}
      >
        <a
          onClick={this.toggleDropdown}
          className="mg-brand2-color cta dropdown-button-submit override-dropdown-button"
        >
          Add Invoice
        </a>
        <a
          className={`cta dropdown-button-toggle mg-brand2-color toggle-sep override-toggle`}
          onClick={this.toggleDropdown}
        >
          <div className="caret"></div>
        </a>
        <ul
          className={`dropdown-menu${dropdownIsActive ? " active" : ""}`}
          onClick={this.toggleDropdown}
        >
          <MenuItem handleClick={this.handleAddInvoiceClick}>
            Add Invoice
          </MenuItem>
        </ul>
      </div>
    );
  };

  render() {
    const { invoices, isLoading, error } = this.state;
    const { appName } = this.props;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div>
        <Helmet>
          <title>{appName} | Invoices </title>
        </Helmet>
        <AppSectionHeader
          title="Invoices"
          optionalClassNames="client-invoice-utility"
          utilities={this.getSectionHeaderUtilities()}
        />
        <div className="app-sidebar-layout-canvas-content">
          {invoices && invoices.length ? (
            this.renderInvoice(invoices)
          ) : (
            <div className="admin-admins-empty-state">
              <h3 className="admin-admins-empty-state-heading empty-mesg-mg">
                No invoices Available
              </h3>
              <p>There are currently no invoices available</p>
            </div>
          )}
          {this.state.showLoader && (
            <div style={{ marginTop: "-3em" }}>
              <AppCanvasLoader />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ClientsInvoices;
