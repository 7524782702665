import $ from "jquery";
import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import MaskedTextInput from "react-text-mask";
import ToggleSwitch from "../../../../components/ToggleSwitch";
import * as API from "../../../../utils/api";
import { amountMask, getStrippedDollarAmount } from "../../../../utils/helpers";
import AppCanvasLoader from "../../../App/AppCanvasLoader";
import Modal from "../Modal";

class AddServiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {
        name: {
          value: "",
          hasError: false,
        },
        rate: {
          value: "",
          hasError: false,
        },
        description: {
          value: "",
          hasError: false,
        },
        mapToAccount: {
          value: "",
          hasError: false,
          account: "",
        },
      },
      accountsList: [],
      isTaxable: false,
      isLoading: true,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
    };
  }

  componentDidMount() {
    let { input } = this.state;
    let { optionalProps } = this.props;
    let { rowData } = optionalProps;

    if (rowData && rowData.id) {
      input.name.value = rowData.name;
      input.rate.value = rowData.rate
        ? parseFloat(rowData.rate).toFixed(2)
        : rowData.rate;
      input.description.value = rowData.description;
      this.setState({
        isTaxable: rowData.is_taxable,
        input: input,
        rowData: rowData,
      });
      this.getServiceAccountList(rowData.income_account_id);
    } else {
      this.getServiceAccountList();
    }
  }

  getServiceAccountList = (accountId) => {
    API.getServiceAccountList(this.props.user).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data && data.data.length) {
          data.data.map((account) => {
            account.label = account.name;
            account.value = account.name;
          });
          this.setState({ accountsList: data.data }, () => {
            this.setAccountForEditService(accountId);
          });
        }
        this.setState({ isLoading: false });
      } else {
        this.handleError(data);
      }
    });
  };

  setAccountForEditService = (accountId) => {
    let { accountsList } = this.state;
    let account = accountsList.filter((item) => item.id == accountId);
    if (account && account.length) {
      this.setState({
        ...this.state,
        input: {
          ...this.state.input,
          mapToAccount: {
            value: account[0].name,
            account: account[0],
          },
        },
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let { input, isTaxable, rowData } = this.state;
    let { optionalProps } = this.props;
    let activeTab = optionalProps.activeTab;
    let type = activeTab == 1 ? "NonInventory" : "Service";
    if (this.validateForm()) {
      let payload = {
        type: type,
        name: input.name.value,
        description: input.description.value,
        rate: getStrippedDollarAmount(input.rate.value).toFixed(2),
        is_taxable: isTaxable,
        is_rate_per_unit: type == "Service" ? false : true,
        income_account_id:
          input.mapToAccount &&
          input.mapToAccount.account &&
          input.mapToAccount.account.id
            ? input.mapToAccount.account.id
            : "",
      };

      if (rowData && rowData.id) {
        payload.id = rowData.id;
        this.handleUpdate(payload);
      } else {
        this.handleAdd(payload);
      }
    }
  };

  handleAdd = (payload) => {
    API.clientAddServices(this.props.user, payload).then((data) => {
      this.handleCommonResponse(data);
    });
  };

  handleUpdate = (payload) => {
    API.clientUpdateServices(this.props.user, payload).then((data) => {
      this.handleCommonResponse(data);
    });
  };

  handleCommonResponse = (data) => {
    if (data && data.status_code) {
      if (data.status_code == 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
      } else {
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
        });
      }
      this.props.optionalProps.reload(false);
      this.setState({ isLoading: false });
    } else {
      this.handleError(data);
    }
  };

  handleError = (data) => {
    this.setState((state) => ({
      ...state,
      isLoading: false,
      error: {
        hasAnyError: true,
        message: data && data.error ? data.error : "",
        statusCode: data
          ? data.status ||
            (data.message && data.message == "Access denied." ? 401 : 500) ||
            500
          : 500,
      },
    }));
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  handleInputBlur = () => {
    let { input } = this.state;
    if (input.rate.value) {
      if (!input.rate.value.includes(".")) {
        input.rate.value = input.rate.value + ".00";
      }
    }
    this.setState({ input });
  };

  handleToggle = (e, type) => {
    e.preventDefault();
    this.setState({ [type]: !this.state[type] });
  };

  validateForm = () => {
    let errorsArePresent = false;
    let newInputState = this.state.input;

    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "name") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "rate") {
        if (getStrippedDollarAmount(value.value) <= 0) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "mapToAccount") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "description") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (value.isRequired && value.value === "") {
        errorsArePresent = true;
      }
    });

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";

    if (input === "name") {
      message = "Please enter a name";
    } else if (input === "description") {
      message = "Please enter description";
    } else if (input === "rate") {
      message = "Please enter rate";
    } else if (input === "maptoaccount") {
      message = "Please select account";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  handleModalClose = () => {
    this.props.hideModal();
  };

  render() {
    let {
      input,
      error,
      isLoading,
      isTaxable,
      accountsList,
      rowData,
    } = this.state;
    let { optionalProps } = this.props;

    let activeTab = optionalProps.activeTab;
    let isEdit = rowData && rowData.id ? true : false;

    let title =
      activeTab == 1
        ? isEdit
          ? "Edit Goods"
          : "Add Goods"
        : isEdit
        ? "Edit Services"
        : "Add Services";

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <Modal
        title={title}
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        optionalClasses="scroll"
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <form>
            <div
              className={`input-container ${
                input.name.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="name">Name</label>
              <div>
                <input
                  name="name"
                  type="text"
                  maxLength={60}
                  placeholder={
                    activeTab == 1
                      ? "Enter Name of Good or Product"
                      : " Enter Name of Service"
                  }
                  value={input.name.value}
                  onChange={(event) =>
                    this.handleInputChange({
                      name: { value: event.target.value, hasError: false },
                    })
                  }
                />
                {input.name.hasError ? this.renderErrorMessage("name") : null}
              </div>
            </div>

            <div
              className={`input-container ${
                input.description.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="description">Description</label>
              <div>
                <input
                  name="description"
                  type="text"
                  placeholder={
                    activeTab == 1
                      ? "Enter Description of Good or Product"
                      : "Enter Description of Service"
                  }
                  value={input.description.value}
                  onChange={(event) =>
                    this.handleInputChange({
                      description: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
                {input.description.hasError
                  ? this.renderErrorMessage("description")
                  : null}
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div className={`input-container`} style={{ display: "flex" }}>
                <label style={{ width: "auto", marginRight: 10 }}>
                  Taxable
                </label>
                <ToggleSwitch
                  isOn={isTaxable}
                  toggleButton={(e) => this.handleToggle(e, "isTaxable")}
                />
              </div>
            </div>

            <div
              className={`input-container ${
                input.rate.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="rate">
                {activeTab == 1 ? "Cost Per Unit" : "Rate Per Hour"}
              </label>
              <div>
                <MaskedTextInput
                  mask={amountMask}
                  name="rate"
                  placeholder="$0"
                  placeholderChar={"\u2000"}
                  type="text"
                  value={input.rate.value}
                  onBlur={() => this.handleInputBlur()}
                  onChange={(event) =>
                    this.handleInputChange({
                      rate: { value: event.target.value, hasError: false },
                    })
                  }
                />
                {input.rate.hasError ? this.renderErrorMessage("rate") : null}
              </div>
            </div>

            <div
              className={`input-container ${
                input.mapToAccount.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="item">Map To Accounts</label>
              <Select
                inputProps={{
                  autoComplete: "none",
                  autoCorrect: "off",
                  spellCheck: "off",
                }}
                className="form-select"
                name="item"
                value={input.mapToAccount.value}
                options={accountsList}
                onChange={(event) =>
                  this.handleInputChange({
                    mapToAccount: {
                      value: event.value,
                      hasError: false,
                      account: event,
                    },
                  })
                }
              />
              {input.mapToAccount.hasError
                ? this.renderErrorMessage("maptoaccount")
                : null}
            </div>

            <div className="modal-actions" id="submit-service">
              <a
                className="cta mg-brand2-color"
                onClick={(event) => this.handleSubmit(event)}
                style={{ paddingLeft: "3em", paddingRight: "3em" }}
              >
                Submit
              </a>
            </div>
          </form>
        )}
      </Modal>
    );
  }
}

export default withRouter(AddServiceModal);
