import moment from "moment";
import React, { Component } from "react";
import shortid from "shortid";
import * as API from "../../../utils/api";
import ClientsCardView from "../../../components/CardView/ClientsCardView";
import InvoiceObjectList from "../../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import DropdownButton from "../../../components/DropdownButton";
import MenuItem from "../../../components/MenuItem";
import invoiceIcon from "../../../assets/images/quickbooks/invoice-gray.png";
import paymentIcon from "../../../assets/images/quickbooks/payments-gray.png";
import commIcon from "../../../assets/images/quickbooks/ic_comm_gray.svg";

class InvoiceTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterBy: "all",
      tasksData: [],
      invoiceFilterBy: "All",
      pastDueCount: 0
    };
  }

  componentDidMount() {
    this.combileTasksAndPayments();
  }

  componentDidUpdate(props) {
    if (props.invoiceDetails !== this.props.invoiceDetails) {
      this.combileTasksAndPayments();
    }
  }

  combileTasksAndPayments = () => {
    const { invoiceDetails } = this.props;
    let { recent_activities } = invoiceDetails;
    let { filterBy, invoiceFilterBy } = this.state;
    let newData = [];
    if (filterBy === "payment") {
      newData = this.getPaymentsTasks(recent_activities);
    } else if (filterBy === "comm") {
      newData = this.getCommsTasks();
    } else if (filterBy === "invoice") {
      let filteredArray;
      if(invoiceFilterBy === "Failed"){
        newData = recent_activities.tasks.filter((o) => {
          return o.status === 'Failed'
        })
        //newData = this.getInvoiceTasks(recent_activities);
      } else if(invoiceFilterBy === "Completed"){
        newData = recent_activities.tasks.filter((o) => {
          return o.status === 'Completed'
        })
        //newData = this.getInvoiceTasks(recent_activities);
      }else {
        newData = this.getInvoiceTasks(recent_activities);
      }
    } else {
      newData = this.getCommsTasks(recent_activities);
      newData = newData.concat(this.getInvoiceTasks(recent_activities));
      newData = newData.concat(this.getPaymentsTasks(recent_activities));
      if(invoiceFilterBy === "Failed"){
        newData = recent_activities.tasks.filter((o) => {
          return o.status === 'Failed'
        })
      } else if(invoiceFilterBy === "Completed"){
        newData = recent_activities.tasks.filter((o) => {
          return o.status === 'Completed'
        })
      }else {
        newData = this.getCommsTasks(recent_activities);
        newData = newData.concat(this.getInvoiceTasks(recent_activities));
        newData = newData.concat(this.getPaymentsTasks(recent_activities));
      }
    }
    this.setState({ tasksData: newData });
    if(filterBy !== "invoice" && filterBy !== "all") {
      this.setState({invoiceFilterBy: "Failed"});
    }
    let pastDueCount = 0
    if(recent_activities.tasks.length > 0){
      recent_activities.tasks.map((row, i) => {
        if(row.status == 'Past Due'){
          pastDueCount = pastDueCount + 1
        }
      })
    }
    this.setState({pastDueCount: pastDueCount})
    return newData;
  };

  getPaymentsTasks = (recent_activities) => {
    let paymentData = [
      {
        name: "Cancel Payment",
        task_type: "payment",
        behavior: "cancel-payment",
      },
      {
        name: "Refund Payment",
        task_type: "payment",
        behavior: "refund-payment",
      },
      {
        name: "Accept Payment",
        task_type: "payment",
        behavior: "accept-payment",
      },
    ];
    let newArray = recent_activities.payments
      ? recent_activities.payments.concat(paymentData)
      : paymentData;
    newArray.reverse();
    return newArray;
  };

  getInvoiceTasks = (recent_activities) => {
    let invoiceData = [
      {
        name: "ReImport Invoice",
        task_type: "invoice",
        behavior: "re-import",
      },
      {
        name: "Void Invoice",
        task_type: "invoice",
        behavior: "void",
      },
    ];
    return recent_activities && recent_activities.tasks
      ? recent_activities.tasks.concat(invoiceData)
      : invoiceData;
  };

  getCommsTasks = () => {
    return [
      { name: "Send Invoice", behavior: "send-invoice" },
      { name: "Send Email", behavior: "send-email" },
      { name: "Send Letter", behavior: "send-letter" },
      { name: "Pause Comms" },
    ];
  };

  handleAddTaskNote = (note) => {
    const { user, invoiceDetails } = this.props;
    let payload = {
      invoice_id: invoiceDetails.id,
      note: note,
    };
    API.addInvoiceNote(user, payload).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        //close modal
        this.props.updateModalState(false, "ACCEPT_REJECT_NOTES");
        this.props.reloadData();
      } else {
        //close modal
        this.props.updateModalState(false, "ACCEPT_REJECT_NOTES");
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
              (data.message && data.message == "Access denied."
                ? 401
                : 500) ||
              500
              : 500,
          },
        }));
      }
    });
  };

  rowExpanderClick = (event) => {
    const { invoiceDetails } = this.props;
    const { user } = invoiceDetails;
    let consumerId = user.user_id ? user.user_id : "";
    if (event && event.row) {
      if (event.row.behavior && event.row.behavior === "send-letter") {
        let message =
          "Letter feature not available with your subscription. Please contact support@makegoodapp.com for access to the Letter feature.";
        if (invoiceDetails && invoiceDetails.communication_methods) {
          let i = invoiceDetails.communication_methods.findIndex(
            (o) => o == "Letter"
          );
          if (i > -1) {
            this.renderNewLetterModal(consumerId);
          } else {
            this.props.updateModalState(true, "ERROR", {
              message: message,
            });
          }
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: message,
          });
        }
      } else if (event.row.behavior && event.row.behavior === "send-email") {
        let message =
          "Email feature not available with your subscription. Please contact support@makegoodapp.com for access to the Email feature.";
        if (invoiceDetails && invoiceDetails.communication_methods) {
          let i = invoiceDetails.communication_methods.findIndex(
            (o) => o == "Email"
          );
          if (i > -1) {
            this.renderSendNewMailModal(consumerId);
          } else {
            this.props.updateModalState(true, "ERROR", {
              message: message,
            });
          }
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: message,
          });
        }
      } else if (
        event.row.behavior &&
        event.row.behavior === "accept-payment"
      ) {
        this.handleMakePaymentClick();
      } else if (
        event.row.behavior &&
        event.row.behavior === "refund-payment"
      ) {
        this.handleRefundPaymentClick();
      } else if (
        event.row.behavior &&
        event.row.behavior === "cancel-payment"
      ) {
        this.handleRefundPaymentClick(true);
      } else if (event.row.behavior && event.row.behavior === "re-import") {
        this.props.handleReImportInvoice(event);
      } else if (event.row.behavior && event.row.behavior === "void") {
        this.props.handleVoidInvoice(event);
      } else if (event.row.behavior && event.row.behavior === "send-invoice") {
        this.props.updateModalState(true, "CONFIRM_ALERT", {
          message: "Are you sure you want to send invoice?",
          confirmClick: this.handleSendInvoice,
          closeOnClickOutside: true,
        });
      } else if (!event.row.behavior && (event.row.name.includes('Invoice Past Due') || (event.row.name.includes('Invoice Due') || (event.row.name.includes('Send Invoice To Client'))))) {
          if (event.row.status === 'Past Due') { //Only for newly created invoice.
            this.props.updateModalState(true, "CONFIRM_ACTION", {
              message: "Are you sure you want to manually complete the task?",
              confirmClick: () => {this.openConfirmCompletePastDueModal(event)},
              cancelClick: () => {this.openConfirmCancelPastDueModal(event)},
              closeOnClickOutside: true,
              positiveBTN: 'Manually Complete The Task',
              negativeBTN: 'Cancel The Task'
            });
          }
      }
    }
  };

  openConfirmCompletePastDueModal = (event) => {
    setTimeout(() => {
      this.props.updateModalState(true, "CONFIRM_ALERT", {
        message: "Are you sure you want to manually complete the task?",
        confirmClick: () => this.handlePastDueRemainder(event.row),
        closeOnClickOutside: true,
      });
    }, 300);
  }

  openConfirmCancelPastDueModal = (event) => {
    setTimeout(() => {
      this.props.updateModalState(true, "CONFIRM_ALERT", {
        message: "Are you sure you want to cancel the task?",
        confirmClick: () => this.cancelPastDueRemainder(event.row),
        closeOnClickOutside: true,
      });
    }, 300);
  }

  renderNewLetterModal(consumerId) {
    const { invoiceDetails } = this.props;
    let props = {
      invoiceDetails: invoiceDetails,
      consumerId: consumerId,
    };
    this.props.updateModalState(true, "SEND_NEW_LETTER", props);
  }

  renderSendNewMailModal(consumerId) {
    const { invoiceDetails, user } = this.props;
    let props = {
      user: user,
      consumerId: consumerId,
      invoiceDetails: invoiceDetails,
    };
    this.props.updateModalState(true, "SEND_NEW_MAIL", props);
  }

  handleMakePaymentClick = () => {
    const { invoiceDetails } = this.props;
    let props = {
      debtInfo: invoiceDetails,
      reloadData: this.props.reloadData,
    };
    this.props.updateModalState(true, "MAKE_PAYMENT", props);
  };

  handleSendInvoice = () => {
    const { invoiceDetails } = this.props;

    API.sendInvoice(this.props.user, invoiceDetails.id).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.props.updateModalState(true, "SUCCESS", {
          message: "Invoice send successfully.",
        });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
              (data.message && data.message == "Access denied."
                ? 401
                : 500) ||
              500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  handlePastDueRemainder = (data) => {
    const { invoiceDetails } = this.props;
    let payload = {
      task_id: ''
    }
    payload.task_id = data.task_id;
    API.sendPastDueRemainder(this.props.user, payload).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.props.reloadData();
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message ? data.message : 'Invoice task sent successfully.',
        });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
              (data.message && data.message == "Access denied."
                ? 401
                : 500) ||
              500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  cancelPastDueRemainder = (data) => {
    const { invoiceDetails } = this.props;
    let payload = {
      task_id: ''
    }
    payload.task_id = data.task_id;
    API.cancelPastDueRemainder(this.props.user, payload).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.props.reloadData();
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message ? data.message : 'Invoice task sent successfully.',
        });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
              (data.message && data.message == "Access denied."
                ? 401
                : 500) ||
              500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  handleRefundPaymentClick = (isCancel) => {
    const { invoiceDetails } = this.props;
    let props = {
      invoiceDetails: invoiceDetails,
      reloadData: this.props.reloadData,
    };
    let modal = isCancel ? "CANCEL_REFUND_PAYMENT" : "REFUND_PAYMENT"
    this.props.updateModalState(true, modal, props);
  };

  addNewwNote = () => {
    let optionalProps = {
      action: "AddTaskNote",
      handleAddTaskNote: this.handleAddTaskNote,
    };
    this.props.updateModalState(true, "ACCEPT_REJECT_NOTES", optionalProps);
  };

  checkBalance = () => {
    let { invoiceDetails } = this.props;
    let rowData = invoiceDetails;
    if (rowData) {
      let adjustment = rowData.adjustment ? rowData.adjustment : 0;
      if (
        (rowData.balance > 0 && adjustment == 0) ||
        (adjustment > 0 && rowData.balance != adjustment)
      ) {
        return false;
      } else if (
        (rowData.balance == 0 && adjustment == 0) ||
        (adjustment > 0 && adjustment == rowData.balance)
      ) {
        return true;
      } else return true;
    }
  };

  getDate = (date) => {
    if (date) {
      return moment.utc(date).format("MM/DD/YYYY");
    }
    return "";
  };

  getIcon = (row) => {
    if (row.task_id || (row.task_type && row.task_type === "invoice")) {
      return (
        <icon>
          <img src={invoiceIcon} height="100%" width="100%" />
        </icon>
      );
    } else if (
      row.payment_id ||
      (row.task_type && row.task_type === "payment")
    ) {
      return (
        <icon>
          <img src={paymentIcon} height="100%" width="100%" />
        </icon>
      );
    } else {
      return (
        <icon>
          <img src={commIcon} height="100%" width="100%" />
        </icon>
      );
    }
  };

  getObjectListData = (data, type) => {
    let headings = [],
      columns = [];
    headings =
      type == "note"
        ? ["Name", "Date", "Note"]
        : [
          "Task Name",
          "Status",
          "Due Date",
          "Start Date",
          "Complete Date",
          "expander",
        ];

    columns = data.map((row, i) => {
      return type == "note"
        ? {
          emptyPrimary: true,
          secondaryColumns: [
            {
              key: "Name",
              title: row.display_name ? row.display_name : row.first_name + " " + row.last_name,
            },
            {
              key: "Date",
              title: this.getDate(row.created_at),
            },
            {
              key: "Note",
              title: row.note,
            },
          ],
        }
        : {
          emptyPrimary: true,
          row: row,
          icon: this.getIcon(row, i),
          secondaryColumns: [
            {
              key: "Task Name",
              title: row.name
                ? row.name
                : row.payment_id
                  ? row.is_refund
                    ? "Payment Refunded"
                    : "Payment Processed"
                  : "",
            },
            {
              key: "Status",
              title: row.status
                ? row.status === "Not_Started"
                  ? "Not Started"
                  : row.status
                : "",
            },
            {
              key: "Due Date",
              title: row.payment_id
                ? ""
                : row.date
                  ? moment(row.date).format("MM/DD/YYYY")
                  : "",
            },
            {
              key: "Start Date",
              title: row.start_date
                ? moment(row.start_date).format("MM/DD/YYYY")
                : "",
            },
            {
              key: "Complete Date",
              title: row.complete_date
                ? moment(row.complete_date).format("MM/DD/YYYY")
                : row.payment_id
                  ? moment(row.date).format("MM/DD/YYYY")
                  : "",
            },
            {
              rowType:
                row.payment_id ||
                  (row.behavior == "accept-payment" && this.checkBalance())
                  ? "hide-task"
                  : "arrow-click",
              optionalClasses: "expander",
            },
          ],
        };
    });

    return {
      headings,
      columns,
    };
  };

  handleFilterChange = (filterBy) => {
    if(filterBy === 'invoice') {
      this.setState({invoiceFilterBy: 'Failed'})
    }else if(filterBy === 'all'){
      this.setState({invoiceFilterBy: 'All'})
    }
    this.setState({ filterBy }, () => {
      this.combileTasksAndPayments();
    });
    
  };

  renderEmpty = (msg) => {
    return (
      <div
        className="admin-admins-empty-state"
        style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
      >
        <p>{msg}</p>
      </div>
    );
  };

  //calls when invoice dropdown option clicked
  handleInvoiceFilterClick = (event, type) => {
    event.preventDefault();
    this.setState({invoiceFilterBy: type}, () => {
      this.combileTasksAndPayments()
    });
  };

  render() {
    let { filterBy, tasksData } = this.state;
    const { invoiceDetails } = this.props;
    let { notes } = invoiceDetails;

    return (
      <div>
        <div className="task-co-fl invoice-task-tab-wrapper" style={{ justifyContent: "space-between"}}>
          <div className="task-co-fl" style={{ margin: "1em 0em 0em 0em" }}>
            <div className="he-hed he-sec-hed">Tasks</div>
            <button
              title="All"
              className={`task-filter-btns all bt-sp-l${filterBy === "all" ? " active" : ""
                }`}
              onClick={() => this.handleFilterChange("all")}
            >
              All
          </button>
            <button
              title="Communication"
              className={`task-filter-btns comm${filterBy === "comm" ? " active" : ""
                }`}
              onClick={() => this.handleFilterChange("comm")}
            />
            <button
              title="Invoice"
              className={`task-filter-btns inv bt-sp-l${filterBy === "invoice" ? " active" : ""
                }`}
              onClick={() => this.handleFilterChange("invoice")}
            />
            <button
              title="Payment"
              className={`task-filter-btns pay${filterBy === "payment" ? " active" : ""
                }`}
              onClick={() => this.handleFilterChange("payment")}
            />
            <button
              title="Past Due"
              className={`task-filter-btns all`}
              style={{marginLeft: '1rem', cursor: 'default'}}
            >
              {this.state.pastDueCount}
            </button>
          </div>
          {this.state.filterBy === "invoice" || this.state.filterBy === "all"
            ? <div className="add-account-invoice filter-by-task-status" style={{ margin: "1em 0em 0em 0em" }}>
              <DropdownButton
                title={`Status: ${this.state.invoiceFilterBy}`}
                // primaryLink=""
                overrideStyle={true}
                forFilter={true}
                clientMobile={true}
                totalFilter={true}
                onPrimaryClick={(event) => {}}
              >
                <MenuItem
                  handleClick={(event) => this.handleInvoiceFilterClick(event, 'Failed')}
                  optionalClasses={
                    this.state.invoiceFilterBy === "Failed" ? " filter-select" : " "
                  }
                >
                  Failed
              </MenuItem>
                <MenuItem
                  handleClick={(event) => this.handleInvoiceFilterClick(event, 'Completed')}
                  optionalClasses={
                    this.state.invoiceFilterBy === "Completed" ? " filter-select" : " "
                  } >
                  Completed
              </MenuItem>
                <MenuItem
                  handleClick={(event) => this.handleInvoiceFilterClick(event, 'All')}
                  optionalClasses={
                    this.state.invoiceFilterBy === "All" ? " filter-select" : " "
                  } >
                  All
              </MenuItem>
              </DropdownButton>
            </div>
          : null}
        </div>
        
        {tasksData && tasksData.length ? (
          <div
            className="scrollbar"
            style={{ maxHeight: "30em", overflowY: "auto", marginTop: "0.5em" }}
          >
            {/* cardview for mobile device */}
            <ClientsCardView
              data={this.getObjectListData(tasksData)}
              rowActionClick={(event) => this.rowExpanderClick(event)}
            />
            {/* list view for big devices */}
            <InvoiceObjectList
              data={this.getObjectListData(tasksData)}
              hideForMobile={true}
              isBeforeIcon={true}
              rowActionClick={(event) => this.rowExpanderClick(event)}
              optionalTableClasses="in-task-list"
            />
          </div>
        ) : (
          this.renderEmpty("No tasks available")
        )}
        <div className="in-new-btn">
          <div className="he-hed he-sec-hed">
            Notes ({notes && notes.length ? notes.length : 0})
          </div>
          <button onClick={() => this.addNewwNote()}>New Note</button>
        </div>
        {notes && notes.length ? (
          <div>
            <ClientsCardView data={this.getObjectListData(notes, "note")} />
            <InvoiceObjectList
              data={this.getObjectListData(notes, "note")}
              hideForMobile={true}
              optionalTableClasses="in-task"
            />
          </div>
        ) : (
          this.renderEmpty(`There are currently no notes to review.`)
        )}
      </div>
    );
  }
}

export default InvoiceTasks;
