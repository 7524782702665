import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';
import AppCanvasLoader from '../../components/App/AppCanvasLoader';
import AppSectionHeader from '../../components/App/AppSectionHeader';
import ClientsEmployeeSidebar from '../../components/Clients/ClientsEmployeeSidebar';
import ClientsEmployeeProfileForm from '../../components/Clients/Forms/ClientsEmployeeProfileForm';
// API
import * as API from '../../utils/api';
// Utils
import { showToast } from '../../utils/helpers';

class ClientsEmployeeAccountsView extends Component {

  state = {
    user: {},
    employee:{},
    debts: [],
    offers: [],
    isLoading: true,
    addAdminFormIsVisible: false,
    selectedOfferForCounter: {},
    showCounterOfferModal: false,
    currentModal: null,
    optionalModalProps: null,
    error: {
      hasAnyError: false,
      statusCode: 200
    },
    
  }

  componentDidMount() {
    this.getEmployeeData();
  }

  getEmployeeData = () => {
    const accountId = this.props.match.params.id;
    API.fetchEmployeeDetails(this.props.user, accountId).then(data => {
      console.log(data)
        if((typeof data != 'undefined') || (data != null)) {
        this.setState(state => ({
          ...state,
          user: this.props.user,
          employee: data.user,
          isLoading: false
        }))
      } else {
        this.setState(state => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          }
        }))
      }
    })
  }

  getAccountFromData = (id, users) => {
    let currentId = parseInt(id, 10)
    let user = users.filter(function (user) {
      return user.id === currentId
    })

    return user[0]
  }

  handleSubmit = (updateEmployee) => {
    API.clientUpdateEmployee(this.props.user, this.state.employee.id, updateEmployee).then(data => {
      if ((typeof data !== 'undefined' || data !== null) && (!data.errors) && data.status_code === 200) {
        this.props.updateModalState(true, 'SUCCESS', { message: 'Employee profile saved successfully.' });
        this.getEmployeeData();
      } else {
        this.setState(state => ({
          ...state,
          isLoading: false
        }))
        this.props.updateModalState(true, 'ERROR', { message: data.message });
      }
    })
  }

  render() {
    const { appName } = this.props
    const {
      employee,
      isLoading,
      error
    } = this.state

    let empName = employee.first_name + ' ' + employee.last_name;
    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    return isLoading
      ? (
        <AppCanvasLoader />
      )
      : (
        <div className="accounts-view app-sidebar-layout">
          <Helmet>
            <title>{appName} | {empName}</title>
            <title></title>
          </Helmet>
          <div className="user-portal-collapse-wrap app-sidebar-layout">            
            <AppSectionHeader
              title="Profile"
              optionalClassNames="collapse-to-gradient"
                backLink={{
                text: "Back to All Employees",
                path: "/client/employees"
                }}
            />
            <div className="scrollbar" style={{ height: window.innerHeight * 0.65, paddingBottom: 100 }}>            
              <div className="app-sidebar-layout-canvas">
                <ClientsEmployeeSidebar
                  user={employee}                  
                />
                <div className="consumer-app-sidebar-layout-canvas-content employee-profile">
                  <ClientsEmployeeProfileForm
                    user={this.props.user}
                    employee={employee}
                    handleSubmit={this.handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
  }
}

export default ClientsEmployeeAccountsView