import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import MaskedTextInput from "react-text-mask";
import * as API from "../../../../utils/api";
import { showToast, validateEmail } from "../../../../utils/helpers";
import stateDataShortNames from "../../../../utils/stateDataShortNames.json";
import Modal from "../Modal";

class AddClientAccountModal extends Component {
  state = {
    input: {
      consumerInformation: {
        companyName: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        displayName: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        first_name: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        last_name: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        email: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        phone_number: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        date_of_birth: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        street_address_primary: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        street_address_secondary: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        city: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        state: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        zipcode: {
          value: "",
          hasError: false,
          isRequired: false,
        },
      },
    },
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  handleConsumerInfoInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        consumerInformation: {
          ...state.input.consumerInformation,
          ...newPartialInput,
        },
      },
    }));
  }

  getNumberFromPhoneString = (phoneString) => {
    let strippedNumber = phoneString.replace(/\(|\)|\s|-/g, "");
    return strippedNumber;
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { input } = this.state;

    if (this.validateForm()) {
      let consumerForSubmission = {
        email: input.consumerInformation.email.value,
        company_name: input.consumerInformation.companyName.value,
        display_name: input.consumerInformation.displayName.value,
        first_name: input.consumerInformation.first_name.value,
        last_name: input.consumerInformation.last_name.value,
        phone_number: this.getNumberFromPhoneString(
          input.consumerInformation.phone_number.value
        ),
        billing_address_line1:
          input.consumerInformation.street_address_primary.value,
        billing_address_line2:
          input.consumerInformation.street_address_secondary.value,
        billing_city: input.consumerInformation.city.value,
        billing_state: input.consumerInformation.state.value,
        billing_zip: input.consumerInformation.zipcode.value,
      };
      API.createClientsConsumer(this.props.user, consumerForSubmission).then(
        (data) => {
          if ((typeof data != "undefined" || data != null) && !data.error) {
            if (data.consumer) {
              this.props.updateModalState(true, "SUCCESS", {
                message: data.message,
              });
              this.props.optionalProps.releoadTableData(data.consumer)
            } else {
              this.props.updateModalState(true, "ERROR", {
                message: data.message,
              });
            }
          } else {
            this.setState((state) => ({
              ...state,
              hasAnyError: true,
            }));
            this.props.updateModalState(true, "ERROR", {
              message: data.message,
            });
          }
        }
      );
    } else {
      this.setFormErrorState();
    }
  };

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  validateForm = (formType) => {
    let errorsArePresent = false;

    // Determine which input group to check for errors
    let inputs = { ...this.state.input.consumerInformation };

    // Check inputs for errors
    Object.entries(inputs).forEach(([key, value]) => {
      if (key === "companyName") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "displayName") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "email") {
        if (!validateEmail(value.value) && value.value !== "") {
          errorsArePresent = true;
        }
      } else if (key === "street_address_primary") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "city") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "state") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "zipcode") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      }
    });

    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  };

  setFormErrorState = (formType) => {
    let errorsArePresent = false;

    // Determine which input group to check for errors
    let newInputState = { ...this.state.input.consumerInformation };

    // Set error state on necessary fields
    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "email") {
        if (value.value === "" || !validateEmail(value.value)) {
          newInputState[key].hasError = true;
        }
      } else if (key === "companyName") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "displayName") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "last_name") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "street_address_primary") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "city") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "state") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "zipcode") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      }

      if (newInputState[key].hasError) {
        errorsArePresent = true;
      }
    });

    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        consumerInformation: newInputState,
      },
    }));
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "companyName") {
      message = "Please enter company name";
    } else if (input === "displayName") {
      message = "Please enter a display name";
    } else if (input === "email") {
      message = "Please enter a valid email address";
    } else if (input === "street_address_primary") {
      message = "Please enter a street address";
    } else if (input === "city") {
      message = "Please enter a city";
    } else if (input === "state") {
      message = "Please select a state";
    } else if (input === "zipcode") {
      message = "Please enter a zip code";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  render() {
    let { input, creditors, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <Modal
        optionalClasses="add-account-modal scroll"
        title="Add Account"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
      >
        <form className="admin-form">
          <div className={`modal-slide`}>
            <div className="form-container">
              <div className="form-column">
                <header
                  className="modal-slide-header"
                  style={{ textAlign: "left", marginBottom: "0.5em" }}
                >
                  <div
                    className={`modal-slide-header-tab`}
                    style={{ margin: 0 }}
                  >
                    Billing Contact Information
                  </div>
                </header>
                <div
                  className={`input-container${
                    input.consumerInformation.companyName.hasError
                      ? " error"
                      : ""
                  }`}
                >
                  <label htmlFor="name">Company Name</label>
                  <input
                    name="name"
                    type="text"
                    placeholder="Company Name"
                    value={input.consumerInformation.companyName.value}
                    onChange={(event) =>
                      this.handleConsumerInfoInputChange({
                        companyName: {
                          value: event.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                  {input.consumerInformation.companyName.hasError
                    ? this.renderErrorMessage("companyName")
                    : null}
                </div>
                <div
                  className={`input-container${
                    input.consumerInformation.displayName.hasError
                      ? " error"
                      : ""
                  }`}
                >
                  <label htmlFor="name">Display Name</label>
                  <input
                    name="display_name"
                    type="text"
                    placeholder="Display Name"
                    value={input.consumerInformation.displayName.value}
                    onChange={(event) =>
                      this.handleConsumerInfoInputChange({
                        displayName: {
                          value: event.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                  {input.consumerInformation.displayName.hasError
                    ? this.renderErrorMessage("displayName")
                    : null}
                </div>
                <div
                  className={`input-container`}
                >
                  <label htmlFor="name">Name</label>
                  <div className="input-container multi-input-field">
                    <input
                      className="multi-input-field-input"
                      name="name"
                      type="text"
                      placeholder="First Name"
                      value={input.consumerInformation.first_name.value}
                      onChange={(event) =>
                        this.handleConsumerInfoInputChange({
                          first_name: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    <input
                      className="multi-input-field-input"
                      name="name"
                      type="text"
                      placeholder="Last Name"
                      value={input.consumerInformation.last_name.value}
                      onChange={(event) =>
                        this.handleConsumerInfoInputChange({
                          last_name: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div
                  className={`input-container${
                    input.consumerInformation.email.hasError ? " error" : ""
                  }`}
                >
                  <label htmlFor="email">Email Address</label>
                  <input
                    name="email"
                    type="email"
                    placeholder="address@email.com"
                    value={input.consumerInformation.email.value}
                    onChange={(event) =>
                      this.handleConsumerInfoInputChange({
                        email: { value: event.target.value, hasError: false },
                      })
                    }
                  />
                  {input.consumerInformation.email.hasError
                    ? this.renderErrorMessage("email")
                    : null}
                </div>
                <div
                  className={`input-container${
                    input.consumerInformation.phone_number.hasError
                      ? " error"
                      : ""
                  }`}
                >
                  <label htmlFor="phone">Phone Number</label>
                  <MaskedTextInput
                    name="phone"
                    mask={[
                      "(",
                      /\d/,
                      /\d/,
                      /\d/,
                      ")",
                      " ",
                      /\d/,
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    placeholder="(555) 555-5555"
                    placeholderChar={"\u2000"}
                    guide={true}
                    type="text"
                    value={input.consumerInformation.phone_number.value}
                    onChange={(event) =>
                      this.handleConsumerInfoInputChange({
                        phone_number: {
                          value: event.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className="form-column">
                <header
                  className="modal-slide-header"
                  style={{ textAlign: "left", marginBottom: "0.5em" }}
                >
                  <div
                    className={`modal-slide-header-tab`}
                    style={{ margin: 0 }}
                  >
                    Billing Address Information
                  </div>
                </header>
                <div
                  className={`input-container${
                    input.consumerInformation.street_address_primary.hasError
                      ? " error"
                      : ""
                  }`}
                >
                  <label htmlFor="streetAddress">Street Address</label>
                  <input
                    name="streetAddress"
                    type="text"
                    placeholder="Enter an Address"
                    value={
                      input.consumerInformation.street_address_primary.value
                    }
                    onChange={(event) =>
                      this.handleConsumerInfoInputChange({
                        street_address_primary: {
                          value: event.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                  {input.consumerInformation.street_address_primary.hasError
                    ? this.renderErrorMessage("street_address")
                    : null}
                </div>
                <div className={`input-container`}>
                  <label htmlFor="unit">Suite # / Unit #</label>
                  <input
                    name="unit"
                    type="text"
                    placeholder="Apt. #"
                    value={
                      input.consumerInformation.street_address_secondary.value
                    }
                    onChange={(event) =>
                      this.handleConsumerInfoInputChange({
                        street_address_secondary: {
                          value: event.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                </div>
                <div
                  className={`input-container${
                    input.consumerInformation.city.hasError ? " error" : ""
                  }`}
                >
                  <label htmlFor="city">City</label>
                  <input
                    name="city"
                    type="text"
                    placeholder="Enter a City"
                    value={input.consumerInformation.city.value}
                    onChange={(event) =>
                      this.handleConsumerInfoInputChange({
                        city: { value: event.target.value, hasError: false },
                      })
                    }
                  />
                  {input.consumerInformation.city.hasError
                    ? this.renderErrorMessage("city")
                    : null}
                </div>
                <div
                  className={`input-container${
                    input.consumerInformation.state.hasError ? " error" : ""
                  }`}
                >
                  <label htmlFor="state">State</label>
                  <Select
                    inputProps={{
                      autoComplete: "none",
                      autoCorrect: "off",
                      spellCheck: "off",
                    }}
                    className="form-select"
                    name="state"
                    value={input.consumerInformation.state.value}
                    placeholder="Select"
                    options={stateDataShortNames}
                    resetValue=""
                    onChange={(event) =>
                      this.handleConsumerInfoInputChange({
                        state: { value: event.value, hasError: false },
                      })
                    }
                  />
                  {input.consumerInformation.state.hasError
                    ? this.renderErrorMessage("state")
                    : null}
                </div>
                <div
                  className={`input-container${
                    input.consumerInformation.zipcode.hasError ? " error" : ""
                  }`}
                >
                  <label htmlFor="zipcode">Zip Code</label>
                  <MaskedTextInput
                    guide={true}
                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                    name="zipcode"
                    placeholder="90210"
                    placeholderChar={"\u2000"}
                    type="text"
                    value={input.consumerInformation.zipcode.value}
                    onChange={(event) =>
                      this.handleConsumerInfoInputChange({
                        zipcode: { value: event.target.value, hasError: false },
                      })
                    }
                  />
                  {input.consumerInformation.zipcode.hasError
                    ? this.renderErrorMessage("zipcode")
                    : null}
                </div>
              </div>
            </div>
            <div className="modal-actions">
              <a className="cta mg-brand2-color" onClick={this.handleSubmit}>
                Submit
              </a>
              {/* <a
                className="modal-link"
                onClick={this.props.hideModal}
              >
                Cancel
              </a> */}
            </div>
          </div>
        </form>
      </Modal>
    );
  }
}

export default withRouter(AddClientAccountModal);
