import React, { Component } from 'react'
import { Helmet } from "react-helmet"
import { withRouter, Redirect } from "react-router-dom"

// API
import * as API from '../../utils/api'

import { networkMessage, showToast, showConfirmAlert } from '../../utils/helpers'

// Components
import AppSectionHeader from '../../components/App/AppSectionHeader'
import AppCanvasLoader from '../../components/App/AppCanvasLoader'
import DropdownButton from '../../components/DropdownButton'
import MenuItem from '../../components/MenuItem'
import ObjectList from '../../components/ObjectList/ObjectList'
import FlashMessage from '../../components/FlashMessage'

class CompanyAccounts extends Component {

  state = {
    consumers: [],
    isLoading: true,
    isUploading: false,
    showFlash: false,
    totalPendingOffers: 0,
    error: {
      hasAnyError: false,
      statusCode: 200
    },
    input: {
      search: {
        value: ""
      }
    }
  }

  componentDidMount() {
    this.fetchConsumers()
  }

  fetchConsumers = () => {
    API.fetchAllClientsConsumers(this.props.user).then(data => {
      if (((typeof data != 'undefined') || (data != null)) && !data.error) {
        this.setState(state => ({
          ...state,
          consumers: data.consumers,
          defaultConsumers: data.consumers,
          totalPendingOffers: data.total_pending_offers,
          isLoading: false,
          isUploading: false
        }))
      } else {
        this.setState(state => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          }
        }))
      }
    })
  }

  handleInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...newPartialInput
      }
    }),
      () => { this.handleSearch() }
    )
  }

  setConsumersData = (dataSet = []) => {
    this.setState(state => ({
      ...state,
      consumers: dataSet
    }))
  }

  handleSearch = () => {
    let queryString = this.state.input.search.value
    if (queryString) {
      API.companyAdminSearch(this.props.user, 'consumers', queryString).then(data => {
        if (((typeof data != 'undefined') || (data != null)) && !data.error) {
          if (!data.results) {
            this.setConsumersData()
          } else {
            this.setConsumersData(data.results)
          }
        } else {
          this.setState(state => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
            }
          }))
        }
      })
    } else {
      this.setConsumersData(this.state.defaultConsumers)
    }
  }

  handleAddAccountClick = (event) => {
    event.preventDefault()
    this.props.updateModalState(true, 'ADD_ORGANIZATION_ACCOUNT')
  }

  handleUploadClick = () => {
    this.refs.fileUploader.click()
  }

  uploadFile = (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    let formData = new FormData()
    formData.append('file', file)

    this.setState(state => ({
      ...state,
      isUploading: true
    }),
      () => {
        API.uploadCSV(this.props.user, formData).then(data => {
          if (((typeof data != 'undefined') || (data != null)) && !data.error) {
            if (data.message) {
              this.setState(state => ({
                ...state,
                showFlash: true,
                flashMessage: data.message
              }))
            }
            this.fetchConsumers()
          } else {
            this.setState(state => ({
              ...state,
              error: {
                hasAnyError: true,
                statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
              }
            }))
          }
        })
      }
    )
  }

  handleRowActionClick = (event) => {
    let { history } = this.props
    const actionBehavior = event.target.getAttribute('data-behavior')
    const consumerId = event.target.getAttribute('data-target')

    switch (actionBehavior) {
      case "account-view":
        history.push(`/admin/accounts/${consumerId}`)
        break
      case "account-edit":
        history.push(`/admin/accounts/${consumerId}/edit`)
        break
      case "account-delete":
        showConfirmAlert('Are you sure want to delete account?', () => {
          this.deactivateConsumer(consumerId)
        })
        break
      case "view-conversation":
        history.push(`/admin/view/conversation/${consumerId}`)
        break
      case "approve-address":
        history.push(`/admin/accounts/${consumerId}/addressDocument`)
        break
      default:
        alert('Error: Something went wrong')
    }
  }

  deactivateConsumer = (id) => {
    let consumers = [...this.state.consumers]
    let targetId = parseInt(id, 10)
    let newConsumers = consumers.filter((consumer) => {
      return consumer.id !== targetId
    })

    if (newConsumers.length < consumers.length) {
      API.deactivateConsumer(this.props.user, id)
      this.setState(state => ({
        ...state,
        consumers: newConsumers
      }))
    }
  }

  dismissFlash = () => {
    this.setState(state => ({
      ...state,
      showFlash: false
    }))
  }

  getSectionHeaderUtilities() {
    const { input } = this.state
    let app_api_url = process.env.REACT_APP_API_URL;

    return (
      <div className="app-section-header-utilities">
        <div className="admin-search-input-container">
          <input
            type="text"
            name="search"
            placeholder="Search"
            value={input.search.value}
            className="admin-search-input"
            onChange={event => this.handleInputChange(
              { search: { value: event.target.value } }
            )}
          />
          <span className="search-icon" />
        </div>
        <a
          className="cta"
          onClick={(event) => this.handleAddAccountClick(event)}
        >
          + Add Account
	      </a>
        <div>
        </div>
      </div>
    )
  }

  getAccountsObjectListData = () => {
    const { consumers } = this.state

    return {
      headings: [
        'Organization Name',
        'Organization Admin Email'
      ],
      columns: consumers.map((consumer) => {
        return {
          primaryColumn: {
            title: consumer.user_address_document_status ? consumer.first_name + ' ' + consumer.last_name + ' (' + consumer.user_address_document_status[0] + ')' : consumer.first_name + ' ' + consumer.last_name,
            titleLinkPath: `/admin/accounts/${consumer.id}`,
            secondaryInfo: consumer.email
          },
          secondaryColumns: [
            {
              title: `${consumer.email}`,
            }
          ],
          rowLinkPath: `/admin/accounts/${consumer.id}`,
          rowType: 'overflowMenu',
          rowActions: [
            {
              title: 'Account Details',
              dataBehavior: 'account-view',
              dataTarget: consumer.id
            },
            {
              title: 'Edit Account',
              dataBehavior: 'account-edit',
              dataTarget: consumer.id
            },
            {
              title: 'Delete Account',
              optionalClasses: 'alert',
              dataBehavior: 'account-delete',
              dataTarget: consumer.id
            },
            {
              title: 'View Conversations',
              optionalClasses: 'alert',
              dataBehavior: 'view-conversation',
              dataTarget: consumer.id
            },
            {
              title: 'Approve Address',
              optionalClasses: 'alert',
              dataBehavior: 'approve-address',
              dataTarget: consumer.id
            }
          ]
        }
      })
    }
  }

  render() {
    const { appName } = this.props
    const { isLoading, isUploading, error } = this.state

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }


    return (isLoading || isUploading)
      ? (
        <AppCanvasLoader />
      )
      : (
        <div>
          <Helmet>
            <title>{appName} | Accounts </title>
          </Helmet>
          {this.state.showFlash ? (
            <FlashMessage
              message={this.state.flashMessage}
              handleFlashDismiss={this.dismissFlash}
            />
          ) : null}

          <AppSectionHeader
            title="Clients"
            utilities={this.getSectionHeaderUtilities()}
          />
          {this.state.consumers ? (this.state.consumers.length ? (
            <ObjectList
              optionalClasses={"accounts"}
              data={this.getAccountsObjectListData()}
              rowActionClick={(event) => this.handleRowActionClick(event)}
            />
          ) : (
              this.state.input.search.value.length ? (
                <div className="admin-admins-empty-state">
                  <p>
                    No results found for:
                  </p>
                  <h3 className="admin-admins-empty-state-heading">
                    {this.state.input.search.value}
                  </h3>
                </div>
              ) : (
                  <div className="admin-admins-empty-state">
                    <h3 className="admin-admins-empty-state-heading">
                      No Account Activity
                  </h3>
                    <p>
                      There are currently no collections accounts in progress
                  </p>
                  </div>
                )
            )) : (
              <div className="admin-admins-empty-state">
                <h3 className="admin-admins-empty-state-heading">
                  No Account Activity
                  </h3>
                <p>
                  There are currently no collections accounts in progress
                  </p>
              </div>
            )}
        </div>
      )
  }
}

export default withRouter(CompanyAccounts)
