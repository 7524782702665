import React, { Component } from 'react'
import { withRouter } from "react-router-dom"
import { Helmet } from "react-helmet";
import Select from 'react-select';
import ReactGA from 'react-ga';

// Components
import AppSectionHeader from '../../../components/App/AppSectionHeader';
import Tabs from '../../../components/Tabs';
import EmployeeEmailHistory from './EmployeeEmailHistory';
import EmployeeChatHistory from './EmployeeChatHistory';

import {getZohoPagesense} from '../../../utils/helpers';




class EmployeeMessageHistory extends Component {

    constructor(props) {
        super(props);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.state = {
            savedData: [],
            flag: false,
            searchData: '',
            showFlash: false,
            filterBy: {
                value: 'All',
                hasError: false,
            },
            search: {
                value: ""
            },
            activeTab: parseInt(this.props.match.params.id)
        }
    }

    componentDidMount(){
        getZohoPagesense();
        ReactGA.pageview('/MessageHistory');
    }

    handleSelectChange(newPartialInput) {
        let filterBy = newPartialInput.filterBy.value;

        this.setState(state => ({
            ...state,
            filterBy: {
                ...state.filterBy,
                value: filterBy
            },
            search: {
                value: ''
            },
            searchData: '',
            flag: false
        }));
    }


    handleKeyPress(event) {
        let searchText = this.state.search.value;
        // if (event.key === 'Enter') {
        //     this.setState(state => ({
        //         ...state,
        //         searchData: searchText,
        //         flag: true
        //     }));
        // }
    }

    handleSearchInputChange(newPartialInput) {
        this.setState(state => ({
            ...state,
            search: {
                value: newPartialInput.search.value
            },
            flag: false
        })
        )
    }

    handleTabChange = (tabIndex) => {
        this.setState({
            activeTab: tabIndex
        })
    }

    getSectionHeaderUtilities() {
        const { filterBy, search, activeTab } = this.state;
        const tabLabels = ['Email', 'Chat']

        return (
            <div className="app-section-header-utilities" style={{ marginLeft: 0 }}>
                <div>
                    <Tabs
                        labels={tabLabels}
                        activeTarget={activeTab}
                        handleTabChange={this.handleTabChange}
                        optionalStyle={{ margin: `auto` }}
                    />
                </div>
                {/* <div className="admin-search-input-container">
                    <input
                        type="text"
                        name="search"
                        placeholder="Search"
                        value={search.value}
                        className="admin-search-input"
                        onKeyDown={this.handleKeyPress}
                        onChange={event => this.handleSearchInputChange(
                            { search: { value: event.target.value } }
                        )}
                    />
                    <span className="search-icon" onClick={() => this.handleKeyPress({ 'key': 'Enter' })} />
                </div> */}
            </div>
        )
    }

    render() {
        const { appName } = this.props;
        const { filterBy, searchData, flag, activeTab } = this.state;
        return (
            <div className="consumer-debt-detail-form" style={{ paddingTop: 0 }}>
                <Helmet>
                    <title>{appName} | Message History</title>
                </Helmet>
                <AppSectionHeader
                    title={"History"}
                    utilities={this.getSectionHeaderUtilities()}
                />
                {
                    activeTab === 1 ?
                        <EmployeeEmailHistory
                            user={this.props.user}
                            searchText={searchData}
                            filterBy={filterBy.value}
                            flag={flag}
                        />
                        :
                        activeTab === 2 ?
                            <EmployeeChatHistory
                                user={this.props.user}
                                searchText={searchData}
                                filterBy={filterBy.value}
                                flag={flag}
                            />
                            :
                            <div className="admin-admins-empty-state">
                                <h2> No history available. </h2>
                            </div>
                }
            </div>
        );
    }

}

export default withRouter(EmployeeMessageHistory)
