import React, { Component } from "react";
import Intercom from "react-intercom";
import MaskedTextInput from "react-text-mask";
import companyData from "../../assets/styles/theme.json";
import Panel from "../../components/App/Panel";
import ExpressHeader from "../../screens/QuickBook/ExpressHeader";
// API
import * as API from "../../utils/api";
import { showToast } from "../../utils/helpers";
import AppLoader from "./AppLoader";
import InfoIcon from "../../assets/images/quickbooks/ic_info_gray.png";

class ConfirmPassword extends Component {
  state = {
    userId: "",
    phoneNumber: {
      value: "",
      hasError: false,
    },
    OTP: {
      value: "",
      hasError: false,
    },
    password: {
      value: "",
      hasError: false,
    },
    confirmPassword: {
      value: "",
      hasError: false,
    },
    isOTPVerified: false,
    isOTP: false,
    otpMessage: "",
    showLoader: false,
    showInterCom: false,
    disableSend: true,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        showInterCom: true,
      });
    }, 1000);

    if (this.props && this.props.userData && this.props.userData.user_id) {
      this.setState({
        userId: this.props.userData.user_id,
      });
    } else {
      this.props.history.push("/register");
    }
  }

  getNumberFromPhoneString = (phoneString) => {
    let strippedNumber = phoneString.replace(/\(|\)|\s|-/g, "");
    return strippedNumber;
  };

  showInfo = () => {
    this.props.updateModalState(true, "SUCCESS", {
      message:
        "Enter your cell phone number to receive a one time verification code.",
      title: "Information",
    });
  };

  sendOTP() {
    if (this.validatePhoneForm()) {
      this.setState({ disableSend: true });
      let payload = {
        user_id: this.state.userId,
        phone_number: this.getNumberFromPhoneString(
          this.state.phoneNumber.value
        ),
      };
      API.sendOTP(payload).then((data) => {
        this.setState({ showLoader: false });
        if (data && data.status_code === 200) {
          this.setState({ isOTP: true, disableSend: false });
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
        } else {
          this.setState({ disableSend: false });
          if (data && data.status_code === 400) {
            this.props.updateModalState(true, "ERROR", {
              message: data.message,
            });
          } else {
            //showToast();
          }
        }
      });
    }
  }

  verifyOTP = () => {
    let { OTP } = this.state;
    if (OTP.value) {
      let payload = {
        user_id: this.state.userId,
        otp: this.state.OTP.value,
      };
      API.verifyOTP(payload).then((data) => {
        this.setState({ showLoader: false });
        if (data && data.status_code === 200) {
          this.setState({
            isOTPVerified: true,
            otpMessage: "OTP verified successfully",
          });
        } else {
          if (data && data.status_code === 400) {
            OTP.hasError = true;
            this.setState({ otpMessage: data.message });
          } else {
            //showToast();
          }
        }
      });
    } else {
      this.state.OTP.hasError = true;
      this.setState({ OTP: this.state.OTP });
    }
  };

  submitForm() {
    if (this.validateForm()) {
      this.setState({ showLoader: true });
      let payload = {
        user_id: this.state.userId,
        otp: this.state.OTP.value,
        password: this.state.password.value,
      };
      API.updateUserPassword(payload).then((data) => {
        this.setState({ showLoader: false });
        if (data && data.status_code === 200) {
          this.props.history.push("/password-success");
        } else {
          if (data && data.status_code === 400) {
            this.props.updateModalState(true, "ERROR", {
              message: data.message,
            });
          } else {
            showToast();
          }
        }
      });
    }
  }

  checkPhoneNumber = () => {
    let { phoneNumber } = this.state;
    phoneNumber.value = this.getNumberFromPhoneString(phoneNumber.value);
    if (
      phoneNumber.value === "" ||
      (phoneNumber.value && phoneNumber.value.length < 10)
    ) {
      this.setState({ disableSend: true });
    } else {
      this.setState({ disableSend: false });
    }
  };

  validatePhoneForm() {
    let { phoneNumber } = this.state;
    let errorsArePresent = false;
    phoneNumber.value = this.getNumberFromPhoneString(phoneNumber.value);
    if (
      phoneNumber.value === "" ||
      (phoneNumber.value && phoneNumber.value.length !== 10)
    ) {
      errorsArePresent = true;
      phoneNumber.hasError = true;
      this.setState({ phoneNumber: phoneNumber });
      return false;
    }

    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  }

  validateForm() {
    let errorsArePresent = false;
    let { OTP, password, confirmPassword } = this.state;
    if (password.value === "" || password.value === undefined) {
      password.hasError = true;
      this.setState({ password: password });
      errorsArePresent = true;
    }
    if (password.value) {
      if (
        confirmPassword.value === "" ||
        confirmPassword.value === undefined ||
        confirmPassword.value != password.value
      ) {
        confirmPassword.hasError = true;
        this.setState({ confirmPassword: confirmPassword });
        errorsArePresent = true;
      }
    }
    if (OTP.value === "" || OTP.value === undefined) {
      OTP.hasError = true;
      this.setState({ OTP: OTP });
      errorsArePresent = true;
    }

    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  }

  getActionButtons = () => {
    let { disableSend } = this.state;
    return (
      <div className="info-icon">
        <a
          title="Send"
          className={`mg-brand2-color ${disableSend ? "disable" : ""}`}
          onClick={() => (disableSend ? "" : this.sendOTP())}
        >
          Send
        </a>
        <img
          src={InfoIcon}
          className="pref-selection-info-ic"
          title="Enter your cell phone number to receive a one time verification code."
        />
      </div>
    );
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "OTP" && this.state.otpMessage) {
      message = this.state.otpMessage;
    } else if (input === "OTP") {
      message = "Please enter a valid OTP";
    } else if (input === "password") {
      message = "Please enter a valid password";
    } else if (input === "confirmPassword") {
      message = "Please make sure passwords match";
    } else if (input === "phoneNumber") {
      message = "Please enter a valid phone number";
    } else {
      message = "Please make sure to complete this field";
    }
    return <div className="input-error-message">{message}</div>;
  }

  render() {
    const {
      showLoader,
      showInterCom,
      appName,
      OTP,
      phoneNumber,
      password,
      confirmPassword,
      isOTP,
      isOTPVerified,
      otpMessage,
    } = this.state;
    const { intercomAppId } = this.props;
    const Logo = companyData.logo ? companyData.logo.replace(/["']/g, "") : "";

    return (
      <div className="landing-root">
        {intercomAppId && showInterCom && <Intercom appID={intercomAppId} />}
        <ExpressHeader appName={appName} hideMenu={true} />
        {showLoader ? (
          <AppLoader />
        ) : (
          <div className="new-landing-hero">
            <div className="new-hero-content billing-from-space">
              <p>Almost done!</p>
              <div className="otp-pass-form">
                <Panel>
                  <div className="billing-form">
                    <div
                      className={`input-container${
                        phoneNumber.hasError ? " error" : ""
                      }`}
                    >
                      <label htmlFor="phone">Send a One Time Password</label>
                      {this.getActionButtons()}
                      <MaskedTextInput
                        name="phone"
                        mask={[
                          "(",
                          /\d/,
                          /\d/,
                          /\d/,
                          ")",
                          " ",
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        placeholder="(555) 555-5555"
                        placeholderChar={"\u2000"}
                        guide={true}
                        type="text"
                        value={phoneNumber.value}
                        onChange={(event) => {
                          (phoneNumber.value = event.target.value),
                            (phoneNumber.hasError = false);
                          this.setState(
                            {
                              phoneNumber: this.state.phoneNumber,
                            },
                            () => this.checkPhoneNumber()
                          );
                        }}
                      />
                      {phoneNumber.hasError
                        ? this.renderErrorMessage("phoneNumber")
                        : null}
                    </div>
                    <div
                      className={`input-container${
                        OTP.hasError ? " error" : ""
                      }`}
                      style={{ marginBottom: "1.5em" }}
                    >
                      <label className="label form-alnmt">
                        Enter your One Time Password
                      </label>
                      <input
                        type="text"
                        name="otp"
                        autoComplete="off"
                        value={OTP.value}
                        readOnly={isOTP ? false : true}
                        placeholder="Enter your One Time Password"
                        onChange={(event) => {
                          (OTP.value = event.target.value),
                            (OTP.hasError = false);
                          this.setState({ OTP: OTP });
                        }}
                      />
                      {OTP.hasError ? this.renderErrorMessage("OTP") : null}
                    </div>
                    {isOTPVerified ? (
                      <div className="verification-success-msg">
                        {otpMessage}
                      </div>
                    ) : (
                      <a
                        title="Verify OTP"
                        className={`verify-otp-button mg-brand2-color${
                          isOTP ? "" : " disable"
                        }`}
                        onClick={() => this.verifyOTP()}
                      >
                        Verify OTP
                      </a>
                    )}
                    <div
                      className={`input-container${
                        password.hasError ? " error" : ""
                      }`}
                      style={{ marginTop: "1.5em" }}
                    >
                      <label className="label form-alnmt">
                        Enter New password
                      </label>
                      <input
                        type="password"
                        autoComplete="off"
                        readOnly={isOTPVerified ? false : true}
                        name="password"
                        placeholder="Enter your new password"
                        className={`reg-text-input${
                          password.hasError ? " error" : ""
                        }`}
                        onChange={(event) => {
                          (password.value = event.target.value),
                            (password.hasError = false);
                          this.setState({ password: password });
                        }}
                      />
                      {password.hasError
                        ? this.renderErrorMessage("password")
                        : null}
                    </div>
                    <div
                      className={`input-container${
                        confirmPassword.hasError ? " error" : ""
                      }`}
                    >
                      <label className="label form-alnmt">
                        Confirm New Password
                      </label>
                      <input
                        type="password"
                        autoComplete="off"
                        name="confirm-password"
                        readOnly={isOTPVerified ? false : true}
                        placeholder="Confirm your new password"
                        className={`reg-text-input${
                          confirmPassword.hasError ? " error" : ""
                        }`}
                        onChange={(event) => {
                          (confirmPassword.value = event.target.value),
                            (confirmPassword.hasError = false);
                          this.setState({
                            confirmPassword: confirmPassword,
                          });
                        }}
                      />
                      {confirmPassword.hasError
                        ? this.renderErrorMessage("confirmPassword")
                        : null}
                    </div>
                    <a
                      title="Submit"
                      className={`cta mg-brand2-color${
                        !isOTPVerified ? " disable" : ""
                      }`}
                      onClick={() => (isOTPVerified ? this.submitForm() : "")}
                      style={{
                        paddingLeft: "3em",
                        paddingRight: "3em",
                      }}
                    >
                      Submit
                    </a>
                  </div>
                </Panel>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ConfirmPassword;
