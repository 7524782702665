import React, { Component } from 'react';

// Packages
import Select from 'react-select';

// Components
import Panel from '../../App/Panel';

// Utils
import { validateEmail } from '../../../utils/helpers';

class AdminAddAdminForm extends Component {
  state = {
    input: {
      first_name: {
        value: "",
        hasError: false
      },
      last_name: {
        value: "",
        hasError: false
      },
      email: {
        value: "",
        hasError: false
      },
      role: {
        value: "",
        hasError: false
      }
    }
  }

  handleInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      const { input } = this.state;

      // const name = input.name.value;
      // let firstName = name.substr(0,name.indexOf(' '));
      // let lastName = name.substr(name.indexOf(' ')+1);
      // let superAdmin = false;

      // if (input.role.value === "Super") {
      //   superAdmin = true
      // }

      let newAdminUser = {
        first_name: input.first_name.value,
        last_name: input.last_name.value,
        email_id: input.email.value,
        organization_id: this.props.organization.id
      }

      this.props.handleFormSubmission(newAdminUser);
      this.resetAndCloseForm();
    }
  }

  validateForm = () => {
    let newInputState = {...this.state.input};
    let errorsArePresent = false;
    if (!validateEmail(newInputState.email.value)) {
      newInputState.email.hasError = true;
      errorsArePresent = true;
    }
    if (newInputState.first_name.value === '' || newInputState.last_name.value === '') {
      newInputState.first_name.hasError = true;
      errorsArePresent = true;
    }

    if (errorsArePresent) {
      this.setState(state => ({
        ...state,
        input: newInputState
      }))
    } else {
      return true;
    }
  }

  resetAndCloseForm = () => {
    this.resetForm();
    this.props.closeForm();
  }

  resetForm = () => {
    this.setState(state => ({
      ...state,
      input: {
        name: {
          value: "",
          hasError: false
        },
        email: {
          value: "",
          hasError: false
        },
        // role: {
        //   value: "",
        //   hasError: false
        // }
      }
    }))
  }

  renderErrorMessage(type) {
    return type === 'name'
    ? (
      <div className="input-error-message">
        Please enter a name
      </div>
    )
    : (
      <div className="input-error-message">
        Please enter a valid email address
      </div>
    )
  }

  render() {
    let { input } = this.state;
console.log("this.props", this.props.organization);

    return (
      <form
        className="admin-add-admin-form"
        onSubmit={(event) => this.handleSubmit(event)}
      >
        <Panel>
              <div
                  className={`input-container${input.first_name.hasError || input.last_name.hasError ? ' error' : ''}`}
                >
                  <label htmlFor="name">Admin's Name</label>
                  <div
                    className="input-container multi-input-field multi-input-admin"
                  >
                    <input
                      className="multi-input-field-input"
                      name="name"
                      type="text"
                      placeholder="First Name"
                      value={input.first_name.value}
                      onChange={event => this.handleInputChange(
                        {first_name: {value: event.target.value, hasError: false}}
                    )}
                      />
                    <input
                      className="multi-input-field-input"
                      name="name"
                      type="text"
                      placeholder="Last Name"
                      value={input.last_name.value}
                      onChange={event => this.handleInputChange(
                        {last_name: {value: event.target.value, hasError: false}}
                    )}
                    />
                  </div>
                  {input.first_name.hasError || input.last_name.hasError ? this.renderErrorMessage('name') : null}
                </div>

          <div
            className={`input-container${input.email.hasError ? ' error' : ''}`}
          >
            <label htmlFor="email">
              Admin's Email
            </label>
            <input
              name="email"
              type="email"
              placeholder="Email"
              value={input.email.value}
              onChange={event => this.handleInputChange(
                  {email: {value: event.target.value, hasError: false}}
              )}
            />
            {input.email.hasError ? this.renderErrorMessage('email') : null}
          </div>
          {/* <div className="select-container">
            <label htmlFor="email">
              Role
            </label>
            <Select
              inputProps={{autoComplete: 'none', autoCorrect: 'off', spellCheck: 'off' }}
              className="form-select"
              name="role"
              value={input.role.value}
              placeholder="Select Role"
              options={ [
                {"value":"Admin","label":"Admin"},
                {"value":"Super","label":"Super Admin"}
              ] }
              resetValue=""
              onChange={event => this.handleInputChange({role: {value: event ? event.value : '', hasError: false}})}
            />
          </div> */}
          <div className="admin-add-admin-form-actions">
            <div className="admin-add-admin-form-actions-content">
              <input type="submit" value="+ Add Admin" className="cta mg-brand2-color" />
              <a
                className="text-link"
                onClick={() => this.resetAndCloseForm()}
              >
                Cancel
              </a>
            </div>
          </div>
        </Panel>
      </form>
    );
  }
}

export default AdminAddAdminForm;
