import moment from "moment";
import React, { Component } from "react";
import ClientsCardView from "../../CardView/ClientsCardView";
import InvoiceObjectList from "../../ObjectList/ClientsAccounts/InvoiceObjectList";
import { getDollarAmount, getWithoutDollarAmount } from "../../../utils/helpers";

class CreditMemoLineDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandLine: true,
            error: {
                hasAnyError: false,
                statusCode: 200,
            },
        };
    }

    rowExpanderClick = (event) => {
        this.setState({ expandLine: !this.state.expandLine });
    };

    getDate = (date) => {
        if (date) {
            return moment(date).format("MM/DD/YYYY");
        }
        return "n/a";
    };

    

    getCreditMemoStatus = (data) => {
        let status = '';
        if (parseFloat(data.total_amount) === parseFloat(data.balance)) {
            status = 'Unapplied';
        } else if (parseFloat(data.balance) === 0.0) {
            status = 'Applied';
        } else if ((parseFloat(data.total_amount) - parseFloat(data.balance)) > 0) {
            status = 'Partially Applied';
        }
        return status;
    }

    getObjectListData = (data, type) => {
        data.date = data.date ? data.date : data.txn_date;
        data.total_amount = data.total_amount ? data.total_amount : data.Total_amt;
        let headings = [],
            columns = [];
        headings =
            type === "Line"
                ? ["Item Date", "Item Name", "Description", "Qty", "Rate", "Item Total", "Tax"]
                : type === "invoice-list"
                ? ["Invoice No.", "Date", "Total Amount", "Credit Applied", "Balance Due"]
                : ["Credit-Memo Date","Status",];

        columns =
            type === "Line"
                ? data.map((row, index) => {
                    return {
                        emptyPrimary: true,
                        secondaryColumns: [
                            {
                                key: "Item Date",
                                title: row.item_date ? moment(row.item_date).utc().format("MM/DD/YYYY") : "n/a"
                            },
                            {
                                key: "Item Name",
                                title: row.item_name ? row.item_name : "n/a",
                            },
                            {
                                key: "Description",
                                title: row.description ? row.description : "n/a",
                            },
                            {
                                key: "Qty",
                                title: row.qty ? row.qty : "n/a",
                            },
                            {
                                key: "Rate",
                                title: getDollarAmount(row.unit_price),
                            },
                            {
                                key: "Item Total",
                                title: getDollarAmount(row.amount),
                            },
                            {
                                key: "Tax",
                                title: <div className="tax-value-wrapper">
                                    <input className="tax-checkbox" type="checkbox" id={`tax00${index}`} name={'tax'} value={'tax'} checked={row.is_taxable} />
                                    <span>{row.is_taxable == true ? 'Taxable' : 'Not Taxable'}</span>
                                </div>
                            },
                        ],
                    };
                })
                :   type === "invoice-list"
                ?   data.map((row, index) => {
                    return {
                        emptyPrimary: true,
                        secondaryColumns: [
                            {
                                key: "Invoice No.",
                                title: row.invoice_number ? row.invoice_number : 'n/a'
                            },
                            {
                                key: "Date",
                                title: row.payment_date ? moment(row.payment_date).utc().format("MM/DD/YYYY") : "n/a",
                            },
                            {
                                key: "Total Amount",
                                title: getDollarAmount(row.total_amount),
                            },
                            {
                                key: "Credit Applied",
                                title: getDollarAmount(row.applied_amount),
                            },
                            {
                                key: "Balance Due",
                                title: getDollarAmount(row.balance),
                            }
                        ],
                    };
                })
                :   [
                    {
                        emptyPrimary: true,
                        secondaryColumns: [
                            {
                                key: "Credit-Memo Date",
                                title: data.date
                                    ? moment(data.date).format("MM/DD/YYYY")
                                    : "--/--/----",
                            },
                            {
                                key: "Status",
                                title: this.getCreditMemoStatus(data)
                                // title: data.status,
                            }
                        ],
                    },
                ];

        return {
            headings,
            columns,
        };
    };

    renderEmpty = (msg) => {
        return (
            <div
                className="admin-admins-empty-state"
                style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
            >
                <p>{msg}</p>
            </div>
        );
    };

    addMultipleTaxDiscount = (data, row, type) => {
        if (data && data.key) {
            let per = type == "Tax" ? data.percent + row.percent : row.percent;
            return {
                key: `${type}${per ? "(" + per + "%)" : ""}`,
                percent: per,
                value: data.value + row.value,
                flag: data.flag,
            };
        } else {
            return row;
        }
    };

    getLinesJson = (item, type, flag, subTotal) => {
        let per =
            type == "Tax"
                ? item.discount_percent
                : (per = this.getDicountAndLateFeePercent(type, subTotal));

        return {
            key: `${type}${per ? "(" + per + "%)" : ""}`,
            percent: parseFloat(per ? per : 0),
            value: parseFloat(item.amount ? item.amount : 0),
            flag: flag,
        };
    };

    getTaxValue = (row) => {
        let taxValue = 0.0;
        let { creditMemoDetails } = this.props;
        let item = creditMemoDetails.tax_and_discount_line[0];
        taxValue = parseFloat(row.amount) / 100;
        taxValue = taxValue * parseFloat(item && item.discount_percent ? item.discount_percent : 0);

        return getDollarAmount(taxValue.toString());
    }

    getAppliedCreditAmount = (data) => {
        let amount = 0.0;
        let totalAmount = parseFloat(data.total_amount);
        let remainings = parseFloat(data.balance);
        amount = totalAmount - remainings;
        return amount > 0 ? amount : '0.00';

    }

    getSubtotalCreditAmount = (data) => {
        let amount = 0.0;
        let taxData = this.getTaxAmount(data);
        let tax = taxData.value;
        amount = parseFloat(data.total_amount) - parseFloat(tax) ;
        return amount > 0 ? amount : '0.00';
    }

    getTaxAmount = (data) => {
        let { invoiceDetails } = this.props;
        let tax_and_discount_line = data.tax_and_discount_line;
        let taxData;
        if (tax_and_discount_line && tax_and_discount_line.length) {
            tax_and_discount_line.map((item) => {
                if (item.detail_type === "TaxLineDetail") {
                    //Tax percent/amount
                    taxData = this.addMultipleTaxDiscount(
                        taxData,
                        this.getLinesJson(item, "Tax", ""),
                        "Tax"
                    );
                }
            });
        }
        return taxData;
    }
 
    showTaskDiscountDetails = (data) => {
        let { creditMemoDetails } = this.props;
        let filterdData = [];
        let total = 0;
        let taxData = this.getTaxAmount(creditMemoDetails);
        console.log('taxDate got ==> ', this.getTaxAmount(creditMemoDetails))

        filterdData.push({
            key: "SUBTOTAL",
            value: this.getSubtotalCreditAmount(creditMemoDetails),
            flag: "",
        });

        //add taxData to final data
        taxData
            ? filterdData.push(taxData)
            : filterdData.push(this.getLinesJson([], "Tax", ""));

        filterdData.push({
            key: "TOTAL CREDIT",
            value: creditMemoDetails.total_amount,
            flag: "",
        });

        filterdData.push({
            key: "APPLIED CREDIT",
            value: this.getAppliedCreditAmount(creditMemoDetails),
            flag: "-",
        });
        filterdData.push({
            key: "REMAINING CREDIT",
            value: creditMemoDetails.balance,
            flag: "",
        });

        return filterdData && filterdData.length ? (
            <div className={`panel tax-discount-content`}>
                {filterdData.map((item, index) => {
                    return (
                        <div key={index.toString()} className={`tax-details ${(index + 1) === filterdData.length ? 'no-border' : ''}`}>
                            <div
                                className={`tax-details-key${item.key === "TOTAL CREDIT" || item.key === 'REMAINING CREDIT' || item.key === 'SUBTOTAL' ? " tax-details-total highlighted-key" : ""
                                    }`}
                            >
                                {item.key}
                                {/* {item.key.includes('Tax') ? <span className="tax-text">tax calc <b>$xx.00</b> x Rate <b>xx%</b></span> : null} */}
                            </div>
                            <div
                                className={`tax-details-value${item.key == "TOTAL CREDIT" ? " tax-details-total highlighted-key" : ""
                                    }`}
                            >
                                <div>$</div>
                                <div>
                                    {item.flag ? item.flag : ""}
                                    {getWithoutDollarAmount(item.value)}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        ) : null;
    };

    showLines = (data, header, type, emptyMsg) => {
        console.log(`type => ${type} | data ==> ${data}`)
        let { expandLine } = this.state;
        let flag = expandLine && (type === "Line" || type === "invoice-list" )? true : false;
        if (data && data.length && type === "Line") {
            let temp = [];
            data.map((elm) => {
                if (elm.item_name && !elm.item_name.includes('Slow Payer Penalty') && !elm.item_name.includes('Early Payer Dis') && !elm.item_name.includes('Invoice Credit')) {
                    temp.push(elm);
                }
            })
            data = temp;
            console.log('my data ==>', data)
        }
        return flag ? (
            <div>
                <div className="invoice-line-header">{header}</div>
                {data && data.length ? (
                    <div>
                        <ClientsCardView data={this.getObjectListData(data, type)} />
                        <InvoiceObjectList
                            data={this.getObjectListData(data, type)}
                            hideForMobile={true}
                            optionalTableClasses={"client-inv-line"}
                        />
                        {/* {this.showTaskDiscountDetails(data)} */}
                    </div>
                ) : (
                    this.renderEmpty(emptyMsg)
                )}
            </div>
        ) : null;
    };

    render() {
        const { creditMemoDetails } = this.props;
        return (
            <div className="main-invoice-wrapper">
                <div className="sub-invoice-wrapper1">
                    <div>
                        {/* cardview for mobile device */}
                        <ClientsCardView
                            data={this.getObjectListData(creditMemoDetails)}
                            isStatus={false}
                            rowActionClick={(event) => this.rowExpanderClick(event)}
                        />
                        {/* list view for big devices */}
                        <InvoiceObjectList
                            data={this.getObjectListData(creditMemoDetails)}
                            isStatus={false}
                            hideForMobile={true}
                            rowActionClick={(event) => this.rowExpanderClick(event)}
                        />
                    </div>
                    {this.showLines(
                        creditMemoDetails.credit_memo_lines,
                        "Credit Lines",
                        "Line",
                        "No credit line available"
                    )}
                    {this.showLines(
                        creditMemoDetails.applied_invoice_list,
                        "Invoices",
                        "invoice-list",
                        "No invoice available"
                    )}
                </div>
                <div className="sub-invoice-wrapper2">{this.showTaskDiscountDetails(creditMemoDetails.invoice_lines)}</div>
            </div>
        );
    }
}

export default CreditMemoLineDetails;