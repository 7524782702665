import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Image1 from '../../assets/images//new-landing-page.png';

class RegistrationComComp extends Component {

    render() {
        const { appName } =this.props;
        return (
            <div className="right-div-width">
                <div className='right-img-text-div' style={{display: 'flex'}}>
                    <img src={Image1} className="img-dbt-hpn"/>
                    <p>
                        Debt happens to just about
                        everyone, everywhere, since
                        the beginning of time. But it
                        doesn't have to be a
                        problem!
                </p>
                </div>
                <div  className="landing-divider1"/>
                <h4 className="h4-algnment">
                    Debt is all about how you deal with it.
                </h4>
                <div className="landing-divider2" />
                <p className="p-alnmt-size-change">
                    You can face it, pay it, and move on.
                </p>
                <div className='or-image-div or-text'>
                    {/* <img className='or-img' src={orImage} /> */}
                    <h4 className="h4-algnment">OR</h4>
                </div>
                <p className="p-alnmt-size-change">
                    You can ignore it, turn a blind eye, pretend it will go away.
                </p>
                <div className="landing-divider3"/>
                <h4 className="h4-algnment">
                    But debt won't just go away.
                </h4>
                <div className="landing-divider2"/>
                <div className='bottom-div'>
                    <p className="p-size-change">
                        Debt will get in the way of buying a home, of buying a
                        car, of having a life.
                </p>
                <p className="p-size-change">
                        By signing up with {appName}, it doesn’t have to get that far.
                </p>
                </div>
            </div>
        );
    }
}

export default RegistrationComComp;
