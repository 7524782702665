import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import shortid from "shortid";
import ArrowLink from "../../components/ArrowLink";
import HeaderMenuItem from "../../components/HeaderMenuItem";
import MenuItem from "../../components/MenuItem";
import HeaderOverflowMenu from "../../components/ObjectList/HeaderOverflowMenu";
import * as API from "../../utils/api";
import { showToast } from "../../utils/helpers";
import manageUser from "../../assets/images/header/manage-user.png";
import settings from "../../assets/images/header/settings.png";
import BellIcon from "../../assets/images/header/ic_bell.svg";
import Emitter from "../../utils/event-emitter";
import Logo from "../Logo";
import NotificationModal from "../../components/App/Modals/Client/NotificationModal";

class AppClientHeader extends Component {
  static propTypes = {
    path: PropTypes.string,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      showSearchHeader: false,
      searchOptions: [],
      id: "",
      dropdownIsActive: false,
      showNtfcn: false,
      showCount: false,
      rowAction: [],
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    //calls when event emitted
    Emitter.on("IS_QUICKBOOK_CONNECTED", (isConnected) => {
      this.setState({ showCount: !isConnected }, () => {
        this.setRowActions();
      });
    });
    this.setRowActions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    Emitter.off("IS_QUICKBOOK_CONNECTED");
  }

  updateDimensions = () => {
    // this.setState({ showSearchHeader: false });
  };

  handleKeyPress = () => {
    this.handleSearch(this.state.id);
  };

  handleKeyDown = (e) => {
    let { searchOptions } = this.state;
    if (
      e.keyCode == 13 &&
      searchOptions &&
      searchOptions.length &&
      searchOptions.length == 1
    ) {
      this.handleClickAction(searchOptions[0]);
    }
  };

  showNotification = () => {
    this.setState({ showNtfcn: true });
  };

  setRowActions = () => {
    let { user } = this.props;
    let data = [
      {
        title: this.getFullName(user),
        dataBehavior: "user-profile",
        icon: (
          <div
            className={`app-client-header-user-avatar app-client-header-icon`}
          >
            <span>{this.renderUserInitials(user)}</span>
          </div>
        ),
      },
      {
        title: "Settings",
        dataBehavior: "settings",
        icon: (
          <div className="app-client-header-icon">
            <img src={settings} width="20" height="20" />
          </div>
        ),
      },
      {
        title: "Notifications",
        dataBehavior: "notifications",
        icon: (
          <div className="app-client-header-icon n-icon-mobile">
            {this.state.showCount && (
              <div className="notification-count">1</div>
            )}
            <img src={BellIcon} width="20" height="20" />
          </div>
        ),
      },
      {
        title: "Manage User",
        dataBehavior: "manage-user",
        icon: (
          <div className="app-client-header-icon">
            <img src={manageUser} width="20" height="20" />
          </div>
        ),
      },
      {
        title: "Sign Out",
        dataBehavior: "sign-out",
        icon: <div></div>,
      },
    ];
    this.setState({ rowAction: data });
  };

  handleSearch = (id) => {
    if (id) {
      this.setState(
        {
          id,
        },
        () => {
          API.searchInvoiceId(this.props.user, this.state.id).then((data) => {
            if ((typeof data != "undefined" || data != null) && !data.error) {
              if (data.data) {
                this.setState({
                  searchOptions: data.data,
                  dropdownIsActive: true,
                });
              } else {
                this.setState({ searchOptions: [], dropdownIsActive: true });
              }
            } else {
              this.setState({ searchOptions: [] });
              this.setState((state) => ({
                ...state,
                error: {
                  hasAnyError: true,
                  statusCode: data
                    ? data.status ||
                      (data.message && data.message == "Access denied."
                        ? 401
                        : 500) ||
                      500
                    : 500,
                },
              }));
            }
          });
        }
      );
    } else {
      this.setState({ id, searchOptions: [], dropdownIsActive: false });
    }
  };

  getFullName = (user) => {
    let fullName = "";
    if (user.first_name) {
      fullName = user.first_name;
    }
    if (user.last_name) {
      fullName = fullName ? fullName + " " + user.last_name : user.last_name;
    }
    return fullName;
  };

  renderPrevLink(prevLink) {
    return (
      <ArrowLink
        label="Back"
        arrow="left"
        path={prevLink}
        optionalClassName="onboarding-header-prev-link"
      />
    );
  }

  // User Info
  renderUserInitials = (user) => {
    let initials = "";
    if (user.first_name && user.last_name) {
      initials = user.first_name.charAt(0) + user.last_name.charAt(0);
    }
    return initials.toUpperCase();
  };

  renderUserName = (user) => {
    let userName = user.first_name + " " + user.last_name;

    return userName;
  };

  handleClickAction = (item) => {
    let { history } = this.props;
    if (item && item.id) {
      this.setState(
        { showSearchHeader: false, id: "", searchOptions: [] },
        () => {
          history.push(`/client/invoices/${item.id}`);
        }
      );
    }
  };

  handleClick = (event, rowAction) => {
    let { history } = this.props;
    const actionBehavior =
      rowAction && rowAction.dataBehavior ? rowAction.dataBehavior : "";

    switch (actionBehavior) {
      case "user-profile":
        history.push(`/client/profile`);
        break;
      case "notifications":
        this.state.showCount && this.showNotification();
        break;
      case "settings":
        history.push(`/client/settings`);
        break;
      case "manage-user":
        history.push(`/client/employees`);
        break;
      case "sign-out":
        history.push(`/sign-out`);
        break;
      default:
        alert("Error: Something went wrong");
    }
  };

  handleSearchClick = (event, flag) => {
    this.setState({ showSearchHeader: flag, id: "", searchOptions: [] });
  };

  overflowMenu = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <a
          onClick={(event) => this.handleSearchClick(event, true)}
          className="app-client-header-mobile-search"
        />
        <HeaderOverflowMenu showThreeDots={true}>
          {this.state.rowAction.map((rowAction) => {
            return rowAction.dataBehavior == "notifications" &&
              !this.state.showCount ? null : (
              <HeaderMenuItem
                key={shortid.generate()}
                rowAction={rowAction}
                dataBehavior={rowAction.dataBehavior}
                handleClick={(event, rowAction) =>
                  this.handleClick(event, rowAction)
                }
                optionalClasses="drop-down-options"
              >
                {rowAction}
              </HeaderMenuItem>
            );
          })}
        </HeaderOverflowMenu>
      </div>
    );
  };

  clearData = () => {
    this.setState({ id: "", dropdownIsActive: false });
  };

  showSearchDropDown = () => {
    return (
      <HeaderOverflowMenu
        dropdownIsActive={this.state.dropdownIsActive}
        clearData={this.clearData}
        optionalClasses="search-menu"
        innerOptionalClasses="inner-menu-list scrollbar"
      >
        {this.state.searchOptions && this.state.searchOptions.length ? (
          this.state.searchOptions.map((item) => {
            return (
              <MenuItem
                key={shortid.generate()}
                handleClick={(event) => {
                  this.handleClickAction(item);
                }}
              >
                {item.invoice_number}
              </MenuItem>
            );
          })
        ) : (
          <div className="search-empty-list">
            <p>Invoice id not found</p>
          </div>
        )}
      </HeaderOverflowMenu>
    );
  };

  renderSearchHeader = () => {
    let { hideNav } = this.props;
    return (
      <header
        className={`app-client-header header-search-height${
          hideNav ? " consumer-mobile-header" : ""
        }`}
      >
        <a
          className="back-link"
          onClick={(event) => this.handleSearchClick(event, false)}
        />
        <input
          autoComplete="off"
          type="text"
          name="search"
          placeholder="Search By Invoice Number"
          value={this.state.id}
          className="client-search-header"
          onKeyDown={this.handleKeyDown}
          onChange={(event) => this.handleSearch(event.target.value)}
        />
        {this.state.id && this.showSearchDropDown()}
      </header>
    );
  };

  handleHereClick = () => {
    let { history } = this.props;
    this.setState({ showNtfcn: false });
    history.push(`/client/settings`, { openQB: true });
  };

  handleClickOutside = () => {
    this.setState({ showNtfcn: false });
  };

  renderNotification = () => {
    return (
      <NotificationModal handleClickOutside={this.handleClickOutside}>
        <div class="dropdown-menu active notification-view">
          <div className="notification-text">
            Your connection to QuickBooks has been disconnected. Click{" "}
            <a onClick={this.handleHereClick} style={{ cursor: "pointer" }}>
              here
            </a>{" "}
            to reconnect.
          </div>
        </div>
      </NotificationModal>
    );
  };

  render() {
    let { user, prevLink, logoPath, appName, hideNav, headerTab } = this.props;
    let { showSearchHeader, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (showSearchHeader) {
      return this.renderSearchHeader();
    }

    return (
      <header
        className={`app-client-header${
          hideNav ? " consumer-mobile-header" : ""
        }${user.client_admin ? " client-admin-header" : ""}`}
      >
        {prevLink && this.renderPrevLink(prevLink)}
        {this.state.showNtfcn && this.renderNotification()}
        <Logo linkPath={logoPath} linkTitle={appName} hideNav={hideNav} />
        {/* <div className="app-client-header-label">
          {headerTab ? headerTab : ""}
        </div> */}
        <div className={`app-client-header-content`}>
          <div className="overflow-menu">{this.overflowMenu()}</div>
          <div className="app-menu">
            <div className="client-search-input-container">
              <input
                type="text"
                autoComplete="off"
                name="search"
                placeholder="Search By Invoice Number"
                value={this.state.id}
                className="client-search-input"
                onKeyDown={this.handleKeyDown}
                onChange={(event) => this.handleSearch(event.target.value)}
              />
              <span
                className="client-search-icon"
                onClick={() => this.handleKeyPress()}
              />
              {this.state.id && this.showSearchDropDown()}
            </div>
            <div className="app-client-header-user">
              <a
                title="Profile"
                className={`app-client-header-user-avatar`}
                href="/client/profile"
              >
                <span>{this.renderUserInitials(user)}</span>
              </a>
            </div>
            <div className="app-client-header-menu">
              {this.state.showCount && (
                <a
                  title="Notifications"
                  className="social-link header-link"
                  onClick={this.state.showCount ? this.showNotification : ""}
                  style={{ cursor: "pointer" }}
                >
                  <img src={BellIcon} width="24" height="24" />
                  {/* {this.state.showCount && ( */}
                  <div className="notification-count">1</div>
                  {/* )} */}
                </a>
              )}
              <a
                title="Settings"
                className="social-link header-link"
                href="/client/settings"
              >
                <img src={settings} width="20" height="20" />
              </a>
              <a
                title="Manage User"
                className="social-link header-link"
                href="/client/employees"
              >
                <img src={manageUser} width="20" height="20" />
              </a>
              <Link
                className="app-client-header-user-nav-link cl-nav-link"
                to="/sign-out"
              >
                Sign Out
              </Link>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(AppClientHeader);
