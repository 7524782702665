import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import * as API from "../../utils/api";

const ACHPlaidLink = (props) => {
  const [publicKey, setPublicKey] = useState("");
  const { user, isCheck, amount, invoice_id, plaidStatus, auth } = props;

  useEffect(() => {
    if (!plaidStatus && auth) {
      API.getPlaidLinkToken(props.user, invoice_id).then((data) => {
        if (data && data.status_code && data.status_code == 200) {
          setPublicKey(data.data);
        }
      });
    } else if (!plaidStatus && !auth) {
      API.expressPlaidLinkToken(invoice_id).then((data) => {
        if (data && data.status_code && data.status_code == 200) {
          setPublicKey(data.data);
        }
      });
    }
  }, [plaidStatus]);

  const onSuccess = useCallback(
    (token, metadata) => {
      // send token to server
      props.sendDataBackToServer(token, metadata);
      props.toggleLoading(true);
    },
    [amount]
  );

  const config = {
    env:
      process.env.REACT_APP_STAGE === "production" ? "production" : "sandbox",
    token: publicKey,
    onSuccess,
  };

  const openPlaidModal = () => {
    setTimeout(() => {
      open();
    }, 1000);
    setTimeout(() => {
      props.toggleLoading(false);
    }, 3000);
  };

  const { open, ready, error } = usePlaidLink(config);

  return plaidStatus ? (
    <button
      className="cta mg-brand2-color"
      onClick={props.createPlaidPayment}
      disabled={!ready || !isCheck}
    >
      Submit Payment
    </button>
  ) : (
    <button
      className="cta mg-brand2-color"
      onClick={() => {
        openPlaidModal();
        props.toggleLoading(true);
      }}
      disabled={!ready || !isCheck}
    >
      Connect using Plaid
    </button>
  );
};
export default ACHPlaidLink;
