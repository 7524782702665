import React, { Component } from "react";
import ReactGA from "react-ga";
import Intercom from "react-intercom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import MaskedTextInput from "react-text-mask";
import companyData from "../../assets/styles/theme.json";
import Panel from "../../components/App/Panel";
import ExpressHeader from "../../screens/QuickBook/ExpressHeader";
// API
import * as API from "../../utils/api";
import { getZohoPagesense, validateEmail } from "../../utils/helpers";
import stateDataShortNames from "../../utils/stateDataShortNames.json";
import AppLoader from "./AppLoader";

class NewRegistration extends Component {
  state = {
    userId: "",
    firstName: "",
    input: {
      companyName: {
        value: "",
        hasError: false,
      },
      displayName: {
        value: "",
        hasError: false,
      },
      first_name: {
        value: "",
        hasError: false,
      },
      last_name: {
        value: "",
        hasError: false,
      },
      email: {
        value: "",
        hasError: false,
      },
      phone_number: {
        value: "",
        hasError: false,
      },
      address_line1: {
        value: "",
        hasError: false,
      },
      address_line2: {
        value: "",
        hasError: false,
      },
      city: {
        value: "",
        hasError: false,
      },
      state: {
        value: "",
        hasError: false,
      },
      zipcode: {
        value: "",
        hasError: false,
      },
    },
    showLoader: false,
    showInterCom: false,
  };

  componentDidMount() {
    getZohoPagesense();
    ReactGA.pageview("/NewRegistration");
    const { userData } = this.props;

    setTimeout(() => {
      this.setState({
        showInterCom: true,
      });
    }, 1000);

    if (userData && userData.user_id) {
      this.setState((state) => ({
        firstName: userData.first_name,
        input: {
          first_name: {
            ...state.input.first_name,
            value: userData.billing_first_name,
          },
          last_name: {
            ...state.input.last_name,
            value: userData.billing_last_name,
          },
          phone_number: {
            ...state.input.phone_number,
            value: userData.billing_phone_no,
          },
          email: {
            ...state.input.email,
            value: userData.billing_primary_email,
          },
          address_line1: {
            ...state.input.address_line1,
            value: userData.billing_address_line1,
          },
          address_line2: {
            ...state.input.address_line2,
            value: userData.billing_address_line2,
          },
          city: {
            ...state.input.city,
            value: userData.billing_city,
          },
          state: {
            ...state.input.state,
            value: userData.billing_state,
          },
          zipcode: {
            ...state.input.zipcode,
            value: userData.billing_zip,
          },
          companyName: {
            ...state.input.companyName,
            value: userData.company_name,
          },
          displayName: {
            ...state.input.displayName,
            value: userData.display_name,
          },
        },
      }));
    } else {
      this.props.history.push("/register");
    }
  }

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { input } = this.state;
    let { userData } = this.props;

    if (this.validateForm()) {
      let payload = {
        user_id: userData.user_id,
        company_name: input.companyName.value,
        display_name: input.companyName.value,
        billing_first_name: input.first_name.value,
        billing_last_name: input.last_name.value,
        billing_primary_email: input.email.value,
        billing_phone_no: input.phone_number.value,
        billing_address_line1: input.address_line1.value,
        billing_address_line2: input.address_line2.value,
        billing_city: input.city.value,
        billing_state: input.state.value,
        billing_zip: input.zipcode.value,
      };
      API.updateNewRegisterUser(payload).then((data) => {
        this.setState({ showLoader: false });
        if (data && data.status_code === 200) {
          this.props.history.push("/confirm-password");
        } else {
          if (data.status_code === 400) {
            let modalProps = {
              message: data.message,
            };
            this.props.updateModalState(true, "ERROR", modalProps);
          } else {
            //showToast();
          }
        }
      });
    }
  };

  validateForm = () => {
    let errorsArePresent = false;
    // Determine which input group to check for errors
    let inputs = { ...this.state.input };
    // Check inputs for errors
    Object.entries(inputs).forEach(([key, value]) => {
      if (key === "companyName") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      } else if (key === "displayName") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      } else if (key === "email") {
        if (
          value.value === "" ||
          (!validateEmail(value.value) && value.value !== "")
        ) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      } else if (key === "address_line1") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      } else if (key === "city") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      } else if (key === "state") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      } else if (key === "zipcode") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      }
    });

    this.setState({ input: inputs });

    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "companyName") {
      message = "Please enter company name";
    } else if (input === "displayName") {
      message = "Please enter a display name";
    } else if (input === "email") {
      message = "Please enter a valid email address";
    } else if (input === "street_address") {
      message = "Please enter a street address";
    } else if (input === "city") {
      message = "Please enter a city";
    } else if (input === "state") {
      message = "Please select a state";
    } else if (input === "zipcode") {
      message = "Please enter a zip code";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  render() {
    const { input, showLoader, showInterCom, firstName } = this.state;
    const { intercomAppId, appName } = this.props;

    return (
      <div className="landing-root">
        {intercomAppId && showInterCom && <Intercom appID={intercomAppId} />}
        <ExpressHeader appName={appName} hideMenu={true} />
        {showLoader ? (
          <AppLoader />
        ) : (
          <div className="new-landing-hero">
            <div className="new-hero-content billing-from-space">
              <p>{"Hi, " + (firstName ? firstName + "!" : "")}</p>
              <h3>
                Thank you for choosing to register your account with{" "}
                {companyData.clientName}'s Secure Billing and Payment Portal.
                <br />
                <br />
                Please take a moment to verify your billing information by
                completing the form below.
              </h3>
            </div>
            <div className="billing-form-panel">
              <Panel>
                <div className="edit-setting-screen">
                  <form className="admin-form">
                    <div className={`modal-slide`}>
                      <div className="form-container">
                        <div className="form-column billing-form">
                          <header
                            className="modal-slide-header"
                            style={{ textAlign: "left", marginBottom: "0.5em" }}
                          >
                            <div
                              className={`modal-slide-header-tab`}
                              style={{ margin: 0 }}
                            >
                              Billing Contact Information
                            </div>
                          </header>
                          <div
                            className={`input-container${
                              input.companyName.hasError ? " error" : ""
                            }`}
                          >
                            <label htmlFor="name">Company Name</label>
                            <input
                              name="name"
                              type="text"
                              placeholder="Company Name"
                              value={input.companyName.value}
                              onChange={(event) =>
                                this.handleInputChange({
                                  companyName: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                            {input.companyName.hasError
                              ? this.renderErrorMessage("companyName")
                              : null}
                          </div>
                          <div
                            className={`input-container${
                              input.displayName.hasError ? " error" : ""
                            }`}
                          >
                            <label htmlFor="name">Display Name</label>
                            <input
                              name="display_name"
                              type="text"
                              placeholder="Display Name"
                              value={input.displayName.value}
                              onChange={(event) =>
                                this.handleInputChange({
                                  displayName: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                            {input.displayName.hasError
                              ? this.renderErrorMessage("displayName")
                              : null}
                          </div>
                          <div
                            className={`input-container`}
                          >
                            <label htmlFor="name">Name</label>
                            <div className="input-container multi-input-field">
                              <input
                                className="multi-input-field-input"
                                name="name"
                                type="text"
                                placeholder="First Name"
                                value={input.first_name.value}
                                onChange={(event) =>
                                  this.handleInputChange({
                                    first_name: {
                                      value: event.target.value,
                                      hasError: false,
                                    },
                                  })
                                }
                              />
                              <input
                                className="multi-input-field-input"
                                name="name"
                                type="text"
                                placeholder="Last Name"
                                value={input.last_name.value}
                                onChange={(event) =>
                                  this.handleInputChange({
                                    last_name: {
                                      value: event.target.value,
                                      hasError: false,
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className={`input-container${
                              input.email.hasError ? " error" : ""
                            }`}
                          >
                            <label htmlFor="email">Email Address</label>
                            <input
                              name="email"
                              type="email"
                              placeholder="address@email.com"
                              value={input.email.value}
                              onChange={(event) =>
                                this.handleInputChange({
                                  email: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                            {input.email.hasError
                              ? this.renderErrorMessage("email")
                              : null}
                          </div>
                          <div
                            className={`input-container${
                              input.phone_number.hasError ? " error" : ""
                            }`}
                          >
                            <label htmlFor="phone">Phone Number</label>
                            <MaskedTextInput
                              name="phone"
                              mask={[
                                "(",
                                /\d/,
                                /\d/,
                                /\d/,
                                ")",
                                " ",
                                /\d/,
                                /\d/,
                                /\d/,
                                "-",
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                              ]}
                              placeholder="(555) 555-5555"
                              placeholderChar={"\u2000"}
                              guide={true}
                              type="text"
                              value={input.phone_number.value}
                              onChange={(event) =>
                                this.handleInputChange({
                                  phone_number: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-column billing-form">
                          <header
                            className="modal-slide-header"
                            style={{ textAlign: "left", marginBottom: "0.5em" }}
                          >
                            <div
                              className={`modal-slide-header-tab`}
                              style={{ margin: 0 }}
                            >
                              Billing Address Information
                            </div>
                          </header>
                          <div
                            className={`input-container${
                              input.address_line1.hasError ? " error" : ""
                            }`}
                          >
                            <label htmlFor="streetAddress">
                              Street Address
                            </label>
                            <input
                              name="streetAddress"
                              type="text"
                              placeholder="Enter an Address"
                              value={input.address_line1.value}
                              onChange={(event) =>
                                this.handleInputChange({
                                  address_line1: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                            {input.address_line1.hasError
                              ? this.renderErrorMessage("street_address")
                              : null}
                          </div>
                          <div className={`input-container`}>
                            <label htmlFor="unit">Suite # / Unit #</label>
                            <input
                              name="unit"
                              type="text"
                              placeholder="Apt. #"
                              value={input.address_line2.value}
                              onChange={(event) =>
                                this.handleInputChange({
                                  address_line2: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                          </div>
                          <div
                            className={`input-container${
                              input.city.hasError ? " error" : ""
                            }`}
                          >
                            <label htmlFor="city">City</label>
                            <input
                              name="city"
                              type="text"
                              placeholder="Enter a City"
                              value={input.city.value}
                              onChange={(event) =>
                                this.handleInputChange({
                                  city: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                            {input.city.hasError
                              ? this.renderErrorMessage("city")
                              : null}
                          </div>
                          <div
                            className={`input-container${
                              input.state.hasError ? " error" : ""
                            }`}
                          >
                            <label htmlFor="state">State</label>
                            <Select
                              inputProps={{
                                autoComplete: "none",
                                autoCorrect: "off",
                                spellCheck: "off",
                              }}
                              className="form-select"
                              name="state"
                              value={input.state.value}
                              placeholder="Select"
                              options={stateDataShortNames}
                              resetValue=""
                              onChange={(event) =>
                                this.handleInputChange({
                                  state: {
                                    value: event.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                            {input.state.hasError
                              ? this.renderErrorMessage("state")
                              : null}
                          </div>
                          <div
                            className={`input-container${
                              input.zipcode.hasError ? " error" : ""
                            }`}
                          >
                            <label htmlFor="zipcode">Zip Code</label>
                            <MaskedTextInput
                              guide={true}
                              mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                              name="zipcode"
                              placeholder="90210"
                              placeholderChar={"\u2000"}
                              type="text"
                              value={input.zipcode.value}
                              onChange={(event) =>
                                this.handleInputChange({
                                  zipcode: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                            {input.zipcode.hasError
                              ? this.renderErrorMessage("zipcode")
                              : null}
                          </div>
                        </div>
                      </div>
                      <div className="modal-actions">
                        <a
                          className="cta mg-brand2-color"
                          onClick={this.handleSubmit}
                        >
                          Submit
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </Panel>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default NewRegistration;
