import $ from "jquery";
import { Multiselect } from "multiselect-react-dropdown";
import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
// API
import * as API from "../../../../utils/api";
import { showToast, validateEmail } from "../../../../utils/helpers";
import AppCanvasLoader from "../../../App/AppCanvasLoader";
import Modal from "../Modal";
import PreviewTemplateModal from "./PreviewTemplateModal";

class SendNewMailModal extends Component {
  state = {
    consumerData: {},
    templateData: [],
    filterdTemplateData: [],
    mergeVaribales: [],
    mergeVaribalesValues: {},
    checkArray: [],
    options: [],
    document_ids: [],
    dropdownIsActive: false,
    mergeVaribalesValuesError: false,
    isLoading: true,
    isUpload: false,
    isPreview: false,
    isTempleteDisable: false,
    toArray: [],
    ccArray: [],
    orgMergeData: [],
    input: {
      name: {
        value: "",
        hasError: false,
      },
      email: {
        value: [],
        hasError: false,
      },
      cc_email: {
        value: [],
        hasError: false,
      },
    },
    message: {
      value: "",
      hasError: false,
    },
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    showFlash: false,
    flashMessage: "",
  };

  componentDidMount() {
    let { optionalProps } = this.props;
    let { invoiceDetails, isMultipleInvoices, multipleInvoiceData } = optionalProps;
    if (invoiceDetails && invoiceDetails.user) {
      this.setState({ consumerData: invoiceDetails.user }, () => {
        this.getContactDetails(this.state.consumerData.user_id);
        this.getOrgData(this.state.consumerData.user_id);
      });
    }
    if (isMultipleInvoices) {
      this.setState({isTempleteDisable: true})
      this.getMultiIncoiceDocumentData(multipleInvoiceData.invoiceListArray);
    } else {
      this.getIncoiceDocumentData();
    }
    API.getMandrillTemplate(this.props.user, isMultipleInvoices ? 'mg-send-multiple-invoices' : '').then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.error) {
          showToast(data.error);
        } else {
          if (data.data) {
            this.setState({ templateData: data.data }, () => {
              this.setTemplateData();
              if (isMultipleInvoices) {
                this.setState({ message: {
                  value: "mg-send-multiple-invoices",
                  hasError: false,
                }}, () => {
                    this.getMergeVariables();
                });
              }
            });
          }
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  getContactDetails = (id) => {
    API.getContactSettings(this.props.user, id).then((data) => {
      if (data && data.status_code == 200 && data.data) {
        this.setState(
          {
            toArray: data.data.to_emails ? data.data.to_emails : [],
            ccArray: data.data.cc_emails ? data.data.cc_emails : [],
          },
          () => {
            this.setConsumerData();
          }
        );
      } else {
        this.setConsumerData();
      }
    });
  };

  getOrgData = (id) => {
    API.getOrgMergeVeriableData(this.props.user, id).then((data) => {
      if (data && data.status_code == 200 && data.data) {
        let dataArray = [];
        dataArray.push(data.data)
        this.restructOrgData(dataArray);
      } else {
        console.log('getOrgData el', data)
      }
    });
  };

  getIncoiceDocumentData() {
    const invoiceNumber = this.props.optionalProps.invoiceDetails.id;
    API.getInvoiceDocuments(this.props.user, invoiceNumber).then((data) => {
      let isAccessDenied =
        data && data.message && data.message == "Access denied." ? true : false;
      if (data && data.data && !isAccessDenied) {
        this.setOptionsData(data.data);
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  getMultiIncoiceDocumentData(ids) {
    const invoiceNumber = this.props.optionalProps.invoiceDetails.id;
    let payload = {
      invoice_ids: ids
    }
    API.getMultiInvoiceDocuments(this.props.user, payload).then((data) => {
      let isAccessDenied =
        data && data.message && data.message == "Access denied." ? true : false;
      if (data && data.data && !isAccessDenied) {
        this.setOptionsData(data.data, true);
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  restructOrgData = (data) => {
    let tempArray = [];
    Object.entries(data).map(item => {
      Object.entries(item[1]).map(itemx => {
        if(Array.isArray(itemx[1])) {
          itemx[1].map((val, valIndex) => {
            tempArray.push(val);
          })
        } else{
          let obj = {
            variable_key: 'ORG_LOGO',
            value: itemx[1]
          }
          tempArray.push(obj);
        }
      })
    });
    this.setState({orgMergeData: tempArray});
  }

  setOptionsData = (data, isMultiInvoice) => {
    if (isMultiInvoice) {
      if (data && data.length) {
        data.map((item) => {
          item.id = item.document_id;
          item.name = item.name + '- #' + item.invoice_number;
        });
        this.setState({ options: data });
      }
    } else {
      if (data && data.length) {
        data.map((item) => {
          item.id = item.document_id;
          item.name = item.name;
        });
        this.setState({ options: data });
      }
    }
    
  };

  getMergeVariables() {
    this.setState({ isUpload: true });
    API.getMergeVariables(this.props.user, this.state.message.value).then(
      (data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          if (data.error) {
            showToast(data.error);
          } else {
            if (data && data.data) {
              this.setState({ mergeVaribales: data.data }, () => {
                this.autoFillMergeVariablies();
              });
            }
          }
          this.setState({ isUpload: false });
        } else {
          this.setState((state) => ({
            ...state,
            isUpload: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    );
  }

  onSelect = (selectedList) => {
    let { document_ids } = this.state;
    document_ids = [];
    if (selectedList && selectedList.length) {
      selectedList.map((item) => {
        document_ids.push(item.document_id);
      });
      this.setState({ document_ids });
    }
  };

  setConsumerData() {
    let { consumerData, toArray, ccArray } = this.state;
    if (consumerData.email) {
      if (toArray & toArray.length) {
        let i = toArray.findIndex((email) => email == consumerData.email);
        if (i == -1) {
          toArray.push(consumerData.email);
        }
      }
    }
    if (consumerData.cc_email) {
      if (ccArray & ccArray.length) {
        let i = ccArray.findIndex((email) => email == consumerData.cc_email);
        if (i == -1) {
          ccArray.push(consumerData.cc_email);
        }
      }
    }
    this.state.input.name.value =
      this.state.consumerData.first_name +
      " " +
      this.state.consumerData.last_name;
    this.setState({
      input: this.state.input,
      toArray,
      ccArray,
    });
  }

  autoFillMergeVariablies() {
    let { optionalProps } = this.props;
    let { multipleInvoiceData } = optionalProps;
    if (this.state.mergeVaribales && this.state.mergeVaribales) {
      this.state.mergeVaribales.map((item) => {
        if (item === "FNAME") {
          this.handleMergeVariableValue(
            this.state.consumerData.first_name,
            item,
            true
          );
        } else if (item === "CUSTOM_URL") {
          let url =
            window && window.location && window.location.origin
              ? window.location.origin
              : "";
          this.handleMergeVariableValue(url, item, true);
        } else if (item === "INVOICE_NUMBERS") {
          this.handleMergeVariableValue(
            multipleInvoiceData.invoiceListSTR,
            item, true
          );
        }
      });
    }
    this.setOrgMergeVeriables();
  }

  setOrgMergeVeriables = () => {
    const { orgMergeData } = this.state;
    if (this.state.mergeVaribales && this.state.mergeVaribales) {
      //colors
      orgMergeData.map((val, index) => {
        this.state.mergeVaribales.map((item) => {
          if (item === val.variable_key) {
            this.handleMergeVariableValue(
              val.value,
              item, true
            );
          }
        });
      });
    }
  }

  setTemplateData() {
    if (this.state.templateData) {
      this.state.templateData.map((item, index) => {
        let data = {
          label: item.name ? item.name : "",
          value: item.slug ? item.slug : "",
          data: item ? item : "",
        };
        this.state.filterdTemplateData.push(data);
        this.setState({ filterdTemplateData: this.state.filterdTemplateData });
      });
    }
  }

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  handleInputChangeForMsg(msg, data) {
    this.setState(
      {
        ...this.state.message.value,
        ...msg,
      },
      () => {
        this.getMergeVariables();
      }
    );
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      input,
      message,
      mergeVaribalesValues,
      document_ids,
      toArray,
      ccArray,
    } = this.state;
    let { optionalProps } = this.props;
    let { isMultipleInvoices } = optionalProps;
    if (this.validateForm()) {
      let payload = {
        name: input.name.value,
        email: toArray,
        cc_email: ccArray,
        template: message.value,
        merge_variables: mergeVaribalesValues,
        document_ids: document_ids,
      };
      $("#send-new-mail").replaceWith('<div class="spinner"></div>');
      if (isMultipleInvoices) {
        this.sendMultipleInvoiceEmail(payload);
      } else {
        API.sendMandrillTemplate(this.props.user, payload).then((data) => {
          if ((typeof data != "undefined" || data != null) && !data.error) {
            if (data.error) {
              showToast(data.error);
            } else {
              this.handleModalClose();
              this.props.updateModalState(true, "SUCCESS", {
                message: data.message,
              });
            }
          } else {
            this.setState((state) => ({
              ...state,
              error: {
                hasAnyError: true,
                statusCode: data
                  ? data.status ||
                    (data.message && data.message == "Access denied."
                      ? 401
                      : 500) ||
                    500
                  : 500,
              },
            }));
          }
        });
      }  
    }
  };

  sendMultipleInvoiceEmail = (payload) => {
    API.sendMultipleMandrillTemplate(this.props.user, payload).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.error) {
          showToast(data.error);
        } else {
          this.handleModalClose();
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  validateForm = () => {
    let errorsArePresent = false;
    let { toArray, ccArray } = this.state;

    // Determine which input group to check for errors
    let input = { ...this.state.input };
    let message = { ...this.state.message };

    Object.entries(input).forEach(([key, value]) => {
      if (key === "name") {
        if (value.value === "") {
          input[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "email") {
        if (
          value.value == "" ||
          value.value == null ||
          typeof value.value == undefined
        ) {
          if (!toArray.length) {
            input[key].hasError = true;
            errorsArePresent = true;
          }
        } else if (value.value) {
          if (validateEmail(value.value)) {
            toArray.push(value.value);
            value.value = "";
          } else {
            input[key].hasError = true;
            errorsArePresent = true;
          }
        }
      } else if (key === "cc_email") {
        if (
          value.value == "" ||
          value.value == null ||
          typeof value.value == undefined
        ) {
          if (!ccArray.length) {
            input[key].hasError = false;
            errorsArePresent = false;
          }
        } else if (value.value) {
          if (validateEmail(value.value)) {
            ccArray.push(value.value);
            value.value = "";
          } else {
            input[key].hasError = true;
            errorsArePresent = true;
          }
        }
      } else if (value.isRequired && value.value === "") {
        input[key].hasError = true;
        errorsArePresent = true;
      }
    });

    this.setState({ input, toArray, ccArray });

    if (message.value == "") {
      message.hasError = true;
      errorsArePresent = true;
    }

    let check =
      this.state.checkArray.length === this.state.mergeVaribales.length;

    if (!this.state.mergeVaribalesValues || !check) {
      this.setState({ mergeVaribalesValuesError: true });
      errorsArePresent = true;
    }

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: input,
        message: message,
      }));

      return false;
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";

    if (input === "name") {
      message = "Please enter name";
    } else if (input === "email") {
      message = "Please enter a valid email";
    } else if (input === "cc_email") {
      message = "Please enter a valid email";
    } else if (input === "message") {
      message = "Please select template";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  handleModalClose = () => {
    this.props.hideModal();
  };

  dismissFlash = () => {
    this.handleModalClose();
    this.setState((state) => ({
      ...state,
      showFlash: false,
      flashMessage: "",
    }));
  };

  handleMergeVariableValue(value, item, isReadOnly) {
    let data = {};
    data[item] = value;
    let index = -1;
    let obj = {
      value: '',
      isReadOnly: false,
    }
    index = this.state.checkArray.findIndex((o) => Object.keys(o)[0] === item);

    if (index === -1) {
      if (isReadOnly){
        obj.value = value;
        obj.isReadOnly = true
      }
      data[item] = obj;
      this.state.checkArray.push(data);
    }
    if (value === "" || value === null || value === undefined) {
      this.state.checkArray.splice(index, 1);
    }
    if (isReadOnly){
      obj.value = value;
      obj.isReadOnly = true
    } else {
      obj.value = value;
      obj.isReadOnly = false
    }

    this.state.mergeVaribalesValues[item] = obj;

    if (!this.state.mergeVaribalesValues[item]) {
      this.setState({ mergeVaribalesValuesError: true });
    } else {
      this.setState({
        mergeVaribalesValuesError: false,
      });
    }

    this.setState({
      mergeVaribalesValues: this.state.mergeVaribalesValues,
    });
  }

  renderMergeVaribles(item, index) {
    let { mergeVaribalesValues, mergeVaribalesValuesError } = this.state;
    return (
      <div className="admin-form-row" key={item}>
        <div className={`input-container`}>
          <label htmlFor="name">{item}</label>
          <input
            name={item}
            placeholder={"Enter " + item}
            type="text"
            disabled={mergeVaribalesValues && mergeVaribalesValues[item] && mergeVaribalesValues[item].isReadOnly ? mergeVaribalesValues[item].isReadOnly : false}
            readOnly={mergeVaribalesValues && mergeVaribalesValues[item] && mergeVaribalesValues[item].isReadOnly ? mergeVaribalesValues[item].isReadOnly : false}
            value={mergeVaribalesValues[item] && mergeVaribalesValues[item].value ? mergeVaribalesValues[item].value : ''}
            onChange={(event) =>
              this.handleMergeVariableValue(event.target.value, item, false)
            }
          />
        </div>
      </div>
    );
  }

  toggleDropdown = (event) => {
    event.preventDefault();

    this.setState({
      dropdownIsActive: !this.state.dropdownIsActive,
    });
  };

  handleClickOutside = (event) => {
    if (this.state.dropdownIsActive) {
      this.toggleDropdown(event);
    }
  };

  handlePreviewClick = () => {
    let { checkArray, mergeVaribales, mergeVaribalesValues } = this.state;
    let check = checkArray.length === mergeVaribales.length;

    if (!mergeVaribalesValues || !check) {
      this.setState({ mergeVaribalesValuesError: true });
    } else {
      this.setState({ isPreview: true });
    }
  };

  hidePreviewModal = () => {
    this.setState({ isPreview: false });
  };

  handleKeyPress = (e, type) => {
    let { input, toArray, ccArray } = this.state;
    let isEnterKey = e.keyCode || e.which;
    if (isEnterKey == 13) {
      if (type == "to" && input.email.value) {
        if (!validateEmail(input.email.value)) {
          input.email.hasError = true;
        } else {
          toArray.push(input.email.value);
          input.email.hasError = false;
          input.email.value = "";
        }
      } else if (type == "cc" && input.cc_email.value) {
        if (!validateEmail(input.cc_email.value)) {
          input.cc_email.hasError = true;
        } else {
          ccArray.push(input.cc_email.value);
          input.cc_email.hasError = false;
          input.cc_email.value = "";
        }
      }
    }
    this.setState({ input });
  };

  removeEmail = (i, type) => {
    let { toArray, ccArray } = this.state;
    if (type == "to" && toArray.length) {
      toArray.splice(i, 1);
    } else if (type == "cc" && ccArray.length) {
      ccArray.splice(i, 1);
    }
    this.setState({ toArray, ccArray });
  };

  showEmails = (email, index, type) => {
    return (
      <div
        className="template-col-name"
        style={{ marginBottom: "0.5em" }}
        key={email}
      >
        <span
          style={{
            marginTop: 1,
            fontFamily: "sofia_pro_regular",
            fontSize: 13,
          }}
        >
          {email}
        </span>
        <span
          className="assigned-tem-card-cross"
          onClick={() => this.removeEmail(index, type)}
        >
          x
        </span>
      </div>
    );
  };

  render() {
    let {
      input,
      message,
      error,
      isLoading,
      isUpload,
      isPreview,
      toArray,
      ccArray,
      mergeVaribalesValues,
      mergeVaribalesValuesError,
    } = this.state;
    let styels = { marginRight: `10px`, maxWidth: `30rem`, marginBottom: 0 };

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (isPreview) {
      return (
        <PreviewTemplateModal
          user={this.props.user}
          hideModal={this.hidePreviewModal}
          template={message.value}
          merge_variables={mergeVaribalesValues}
        />
      );
    }

    return (
      <Modal
        optionalClasses="add-account-modal send-new-email-modal scroll"
        title="Send New Email"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <form
            className="admin-form"
            onClick={(event) => {
              this.handleClickOutside(event);
            }}
          >
            <div className="app-sidebar-layout-canvas">
              <div className="app-sidebar app-sidebar-left" style={styels}>
                <div>
                  <div className="admin-form-row">
                    <div
                      className={`input-container ${
                        input.name.hasError ? " error" : ""
                      }`}
                    >
                      <label htmlFor="name">Name:</label>
                      <input
                        name="name"
                        guide={"true"}
                        placeholder="Enter name"
                        type="text"
                        value={input.name.value}
                        onChange={(event) =>
                          this.handleInputChange({
                            name: {
                              value: event.target.value,
                              hasError: false,
                            },
                          })
                        }
                      />
                      {input.name.hasError
                        ? this.renderErrorMessage("name")
                        : null}
                    </div>
                  </div>

                  <div className="admin-form-row">
                    <div
                      className={`input-container ${
                        input.email.hasError ? " error" : ""
                      }`}
                    >
                      <label htmlFor="email">
                        Email:{"  "}
                        {toArray.length
                          ? toArray.map((email, i) =>
                              this.showEmails(email, i, "to")
                            )
                          : null}
                      </label>
                      <input
                        name="email"
                        placeholder="address@email.com"
                        type="text"
                        value={input.email.value}
                        onKeyPress={(e) => this.handleKeyPress(e, "to")}
                        onChange={(event) =>
                          this.handleInputChange({
                            email: {
                              value: event.target.value,
                              hasError: false,
                            },
                          })
                        }
                      />
                      {input.email.hasError
                        ? this.renderErrorMessage("email")
                        : null}
                    </div>
                  </div>

                  <div className="admin-form-row">
                    <div
                      className={`input-container ${
                        input.cc_email.hasError ? " error" : ""
                      }`}
                    >
                      <label htmlFor="email">
                        CC Email:{"  "}
                        {ccArray.length
                          ? ccArray.map((email, i) =>
                              this.showEmails(email, i, "cc")
                            )
                          : null}
                      </label>
                      <input
                        name="cc_email"
                        placeholder="address@email.com"
                        type="text"
                        value={input.cc_email.value}
                        onKeyPress={(e) => this.handleKeyPress(e, "cc")}
                        onChange={(event) =>
                          this.handleInputChange({
                            cc_email: {
                              value: event.target.value,
                              hasError: false,
                            },
                          })
                        }
                      />
                      {input.cc_email.hasError
                        ? this.renderErrorMessage("cc_email")
                        : null}
                    </div>
                  </div>

                  <label>Attach Documents:</label>
                  <div
                    className={`input-container half`}
                    style={{ marginBottom: 20 }}
                  >
                    <Multiselect
                      options={this.state.options}
                      displayValue="name"
                      placeholder="Select attach documents"
                      onSelect={this.onSelect}
                    />
                  </div>

                  <label>Template:</label>
                  <div
                    className={`input-container half${
                      message.hasError ? " error" : ""
                    }`}
                    style={{ marginBottom: 20 }}
                  >
                    <Select
                      disabled={this.state.isTempleteDisable}
                      inputProps={{
                        autoComplete: "none",
                        autoCorrect: "off",
                        spellCheck: "off",
                      }}
                      className="form-select"
                      name="message"
                      //type="textarea"
                      placeholder="Select a template"
                      options={this.state.filterdTemplateData}
                      value={message.value}
                      onChange={(event) =>
                        this.handleInputChangeForMsg({
                          message: { value: event.value, hasError: false },
                        })
                      }
                    />
                    {message.hasError
                      ? this.renderErrorMessage("message")
                      : null}
                  </div>
                  <div>
                    {isUpload ? (
                      <AppCanvasLoader />
                    ) : (
                      <div>
                        {this.state.mergeVaribales &&
                        this.state.mergeVaribales.length ? (
                          <div>
                            <label>Merge Variables:</label>
                            {this.state.mergeVaribalesValuesError ? (
                              <div
                                className={`input-container half error`}
                                style={{ marginBottom: 10 }}
                              >
                                {this.renderErrorMessage(
                                  "mergeVaribalesValuesError"
                                )}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {this.state.mergeVaribales &&
                        this.state.mergeVaribales.length ? (
                          this.state.mergeVaribales.map((item, index) => {
                            return this.renderMergeVaribles(item, index);
                          })
                        ) : (
                          <div></div>
                        )}
                      </div>
                    )}
                  </div>
                  <div id="send-new-mail" className="modal-actions double-btns">
                    <a
                      className={`cta mg-brand2-color`}
                      onClick={(event) => this.handlePreviewClick()}
                    >
                      Preview
                    </a>
                    <a
                      className="cta mg-brand2-color"
                      onClick={(event) => this.handleSubmit(event)}
                    >
                      Submit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Modal>
    );
  }
}

export default withRouter(SendNewMailModal);
