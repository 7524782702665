import React, { Component } from 'react';

// Components
import Modal from './Modal';

class SuccessModal extends Component {

  render() {
    let { message, title, isOKCallback } = this.props.optionalProps;

    return (
      <Modal
        title={title ? title : "Success!"}
        hideModal={this.props.hideModal}
        closeOnClickOutside={true}
      >
        <p style={{ textAlign: 'center' }}>
          {message}
        </p>
        <div className="modal-actions">
          <a
            className="cta mg-brand2-color"
            onClick={() => {
              this.props.hideModal();
              if(this.props && this.props.optionalProps && this.props.optionalProps.isOKCallback) {
                this.props.optionalProps.onOKClick()
              };
            }
          }
          >
            Ok
          </a>
        </div>
      </Modal>
    )
  }
}

export default SuccessModal;