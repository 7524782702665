import moment from "moment";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import InfoIcon from "../../assets/images/quickbooks/ic_info_gray.png";
// Components
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ToggleSwitch from "../../components/ToggleSwitch";
// Utils
import * as API from "../../utils/api";
import Emitter from "../../utils/event-emitter";

class QuickbookSetting extends Component {
  state = {
    isConnectedToQB: false,
    isLoading: true,
    Interval: "",
    timerCount: 0,
    syncDone: 0,
    lastSyncDate: "",
    SyncStatus: "",
    synced_customers: 0,
    synced_invoices: 0,
    synced_payments: 0,
    total_customers_to_sync: 0,
    total_invoices_to_sync: 0,
    total_payments_to_sync: 0,
    customer: 0,
    invoice: 0,
    payment: 0,
    isSyncOn: false,
    accountToggle: true,
    invoiceToggle: true,
    paymentToggle: true,
    autoRecToggle: true,
    accountCreateToggle: true,
    autoSyncServices: true,
    autoImportServices: true,
    autoSelectIncome: true,
    usingSalesTex: false,
    allowDiscount: false,
    autoImportTerms: false,
    autoPushTerms: false,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount = () => {
    this.checkAuthStatus();
    this.getQbSetting();
    this.getQBItemCount();
  };

  componentWillUnmount = () => {
    if (!this.state.isSyncOn) {
      this.dismissCall();
    }
  };

  componentWillReceiveProps = () => {
    const { isEditPref } = this.props;
    if (isEditPref) {
      this.getQbSetting();
    }
  };

  //stop quickbooks account syncing
  cancelQBSync = () => {
    this.setState({ isSyncOn: false });
    this.dismissCall();
  };

  //calls when QB account sync sucessfully completed
  openDashboard = () => {
    //clear all flags and redirect to dashboard
    this.cancelQBSync();
    this.props.history.push("/client/dashboard");
  };

  //initialize the QB data syncing
  getQBData = () => {
    this.setState(
      {
        isSyncOn: true,
        synced_customers: 0,
        total_customers_to_sync: 0,
        synced_invoices: 0,
        synced_payments: 0,
        total_invoices_to_sync: 0,
        total_payments_to_sync: 0,
        syncDone: 0,
        SyncStatus: "",
      },
      () => {
        this.getBackendResponse();
      }
    );
    API.getQBCustomer(this.props.user).then((data) => {
      if (typeof data != "undefined" || data != null) {
        //success
      } else {
        // this.setState((state) => ({
        //   ...state,
        //   error: {
        //     hasAnyError: true,
        //     statusCode: data
        //       ? data.status ||
        //         (data.message && data.message == "Access denied."
        //           ? 401
        //           : 500) ||
        //         500
        //       : 500,
        //   },
        // }));
      }
    });
  };

  //show confirmation alert before sync start
  syncQBData = () => {
    let flag =
      this.state.customer > 0 ||
      this.state.invoice > 0 ||
      this.state.payment > 0
        ? true
        : false;

    let message = flag
      ? "Select 'Yes' to  synchronize QuickBooks Online data. Select 'No' to cancel."
      : "All data from QuickBooks Online is already synced. Select 'Yes' to  synchronize QuickBooks Online now. Select 'No' to cancel.";
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: message,
      confirmClick: this.getQBData,
      closeOnClickOutside: true,
    });
  };

  //clear interval
  dismissCall = () => {
    clearInterval(this.state.Interval);
    this.setState({ isLoading: false });
  };

  //sets data values in percent based on api data
  setDataInPercent = () => {
    if (this.state.synced_customers.toFixed(0) === 100) {
      this.dismissCall();
    } else {
      let total =
        this.state.total_customers_to_sync > 0
          ? this.state.total_customers_to_sync
          : 0;
      total =
        this.state.total_invoices_to_sync > 0
          ? total + this.state.total_invoices_to_sync
          : total;
      total =
        this.state.total_payments_to_sync > 0
          ? total + this.state.total_payments_to_sync
          : total;
      let sync_count =
        this.state.synced_customers > 0 ? this.state.synced_customers : 0;
      sync_count =
        this.state.synced_invoices > 0
          ? sync_count + this.state.synced_invoices
          : sync_count;
      sync_count =
        this.state.synced_payments > 0
          ? sync_count + this.state.synced_payments
          : sync_count;
      if (total > 0) {
        this.setState({
          syncDone: (sync_count * 100) / total,
        });
      } else {
        this.setState({ syncDone: 100 });
      }
    }
  };

  //set synced data
  setSyncData = (data) => {
    this.setState(
      {
        isSyncOn: true,
        timerCount: this.state.timerCount + 1,
        total_customers_to_sync: data.sync_status.total_customers_to_sync,
        synced_customers: data.sync_status.synced_customers,
        synced_invoices: data.sync_status.synced_invoices,
        synced_payments: data.sync_status.synced_payments,
        total_invoices_to_sync: data.sync_status.total_invoices_to_sync,
        total_payments_to_sync: data.sync_status.total_payments_to_sync,
      },
      () => {
        this.setDataInPercent();
        if (this.state.timerCount === 1) {
          this.getBackendResponse();
        }
      }
    );
  };

  //checks sync status and updates data or calls api based on status
  getSyncData = (data) => {
    if (data.sync_status) {
      this.setState({ SyncStatus: data.sync_status.sync_status });
      if (
        data.sync_status.sync_status &&
        data.sync_status.sync_status === "INPROGRESS"
      ) {
        this.setSyncData(data);
      } else if (
        data.sync_status.sync_status &&
        data.sync_status.sync_status === "COMPLETED"
      ) {
        this.dismissCall();
        this.state.isSyncOn && this.setSyncData(data);
        this.setState(
          {
            lastSyncDate: data.sync_status.sync_end_date,
          },
          () => {
            this.props.updateQBInsideBar({
              lastSyncDate: this.state.lastSyncDate,
            });
          }
        );
      }
    }
  };

  //check QB status connected or disconnected
  checkAuthStatus = () => {
    API.checkAuthStatus(this.props.user).then((data) => {
      if (data && data.status_code && data.status_code === 400) {
        this.setState({ isLoading: false });
        //call to update notification bell count
        Emitter.emit("IS_QUICKBOOK_CONNECTED", false);
      } else if (
        (typeof data != "undefined" && data.data) ||
        (data != null && data.data)
      ) {
        if (data.data.status && data.data.status === "ACTIVE") {
          this.setState({ isConnectedToQB: true }, () => {
            this.props.updateQBInsideBar({
              isConnectedToQB: this.state.isConnectedToQB,
            });
          });
          this.getSyncData(data.data);
        }
        this.setState({ isLoading: false });
      } else {
        this.dismissCall();
        this.setState({
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
          isLoading: false,
        });
      }
    });
  };

  getBackendResponse = () => {
    this.dismissCall();
    let Interval = setInterval(this.checkAuthStatus, 5000);
    this.setState({ Interval });
  };

  //calls when "Connect To QB" button click
  callAPI = (event) => {
    event.preventDefault();
    API.validateQB(this.props.user).then((data) => {
      if ((typeof data != "undefined" || data != null) && data.data) {
        if (typeof data.data != "undefined" || data.data != null) {
          window.open(data.data, "_self");
          this.setState({ isLoading: true });
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  getQBItemCount = () => {
    API.getQbItemCount(this.props.user).then((data) => {
      if (data && data.data) {
        this.setState({
          customer: data.data.customer,
          invoice: data.data.invoice,
          payment: data.data.payment,
        });
      } else {
        this.setState({
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
          isLoading: false,
        });
      }
    });
  };

  getQbSetting = () => {
    API.getQbSetting(this.props.user).then((data) => {
      if (data && data.status_code && data.status_code === 200) {
        if (data.data) {
          this.setState({
            accountToggle: data.data.auto_import_accounts,
            invoiceToggle: data.data.auto_import_invoices,
            paymentToggle: data.data.auto_import_payments,
            autoRecToggle: data.data.auto_reconsile_payments,
            accountCreateToggle: data.data.auto_push_accounts,
            autoImportServices: data.data.auto_import_services,
            autoSyncServices: data.data.auto_push_services,
            autoSelectIncome: data.data.auto_select_income_account,
            usingSalesTex: data.data.using_sales_tax,
            allowDiscount: data.data.allow_discount,
            autoImportTerms: data.data.auto_import_terms,
            autoPushTerms: data.data.auto_push_terms,
            isLoading: false,
          });
        } else {
          this.setState({
            accountToggle: true,
            invoiceToggle: true,
            paymentToggle: true,
            autoRecToggle: true,
            accountCreateToggle: true,
            autoImportServices: true,
            autoSelectIncome: true,
            autoSyncServices: true,
            usingSalesTex: false,
            allowDiscount: false,
            isLoading: false,
          });
        }
      } else {
        this.setState({
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
          isLoading: false,
        });
      }
    });
  };

  updateQbSettings = (settings) => {
    API.updateQbSettings(this.props.user, settings).then((data) => {
      if (data && data.status_code) {
        if (data.status_code === 200) {
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  toggleButton = (key) => {
    if (key === "ACCOUNT") {
      this.setState({ accountToggle: !this.state.accountToggle }, () => {
        this.updateQbSettings({
          setting_key: "AUTO_IMPORT_ACCOUNTS",
          value: this.state.accountToggle,
        });
      });
    } else if (key === "ACCOUNT_CREATE") {
      this.setState(
        { accountCreateToggle: !this.state.accountCreateToggle },
        () => {
          this.updateQbSettings({
            setting_key: "AUTO_PUSH_ACCOUNTS",
            value: this.state.accountCreateToggle,
          });
        }
      );
    } else if (key === "INVOICE") {
      this.setState({ invoiceToggle: !this.state.invoiceToggle }, () => {
        this.updateQbSettings({
          setting_key: "AUTO_IMPORT_INVOICES",
          value: this.state.invoiceToggle,
        });
      });
    } else if (key === "PAYMENT") {
      this.setState({ paymentToggle: !this.state.paymentToggle }, () => {
        this.updateQbSettings({
          setting_key: "AUTO_IMPORT_PAYMENTS",
          value: this.state.paymentToggle,
        });
      });
    } else if (key === "AUTO-RE") {
      this.setState({ autoRecToggle: !this.state.autoRecToggle }, () => {
        this.updateQbSettings({
          setting_key: "AUTO_RECONCILE_PAYMENTS",
          value: this.state.autoRecToggle,
        });
      });
    } else if (key === "AUTO_IMPORT_SERVICES") {
      this.setState(
        { autoImportServices: !this.state.autoImportServices },
        () => {
          this.updateQbSettings({
            setting_key: "AUTO_IMPORT_SERVICES",
            value: this.state.autoImportServices,
          });
        }
      );
    } else if (key === "AUTO-SYNC-SERVICES") {
      this.setState({ autoSyncServices: !this.state.autoSyncServices }, () => {
        this.updateQbSettings({
          setting_key: "AUTO_PUSH_SERVICES",
          value: this.state.autoSyncServices,
        });
      });
    } else if (key === "AUTO-SELECT-INCOME") {
      this.setState({ autoSelectIncome: !this.state.autoSelectIncome }, () => {
        this.updateQbSettings({
          setting_key: "AUTO_SELECT_INCOME_ACCOUNT",
          value: this.state.autoSelectIncome,
        });
      });
    } else if (key === "USING-SALES-TAX") {
      this.setState({ usingSalesTex: !this.state.usingSalesTex }, () => {
        this.updateQbSettings({
          setting_key: "USING_SALES_TAX",
          value: this.state.usingSalesTex,
        });
      });
    } else if (key === "ALLOWED-DISCOUNT") {
      this.setState({ allowDiscount: !this.state.allowDiscount }, () => {
        this.updateQbSettings({
          setting_key: "ALLOWED_DISCOUNT",
          value: this.state.allowDiscount,
        });
      });
    } else if (key === "AUTO_IMPORT_TERMS") {
      this.setState({ autoImportTerms: !this.state.autoImportTerms }, () => {
        this.updateQbSettings({
          setting_key: "AUTO_IMPORT_TERMS",
          value: this.state.autoImportTerms,
        });
      });
    } else if (key === "AUTO_PUSH_TERMS") {
      this.setState({ autoPushTerms: !this.state.autoPushTerms }, () => {
        this.updateQbSettings({
          setting_key: "AUTO_PUSH_TERMS",
          value: this.state.autoPushTerms,
        });
      });
    }
  };

  renderSyncQBData = () => {
    let percent = this.state.syncDone > 100 ? 100 : this.state.syncDone;
    return (
      <div className="big-device-grad  tp-sp">
        <div className="he-hed">Items to Sync</div>
        <div className="item-sync">
          <div className="item-sync-inner">
            <div className="pref-selection-sync-header">Accounts</div>
            <div className="pr-avtar ac" />
            {this.state.isSyncOn ? (
              <div className="pr-box">
                {this.state.synced_customers +
                  " / " +
                  this.state.total_customers_to_sync}
              </div>
            ) : (
              <div className="pr-box">{this.state.invoice}</div>
            )}
          </div>
          <div className="item-sync-inner">
            <div className="pref-selection-sync-header">Invoices</div>
            <div className="pr-avtar inv" />
            {this.state.isSyncOn ? (
              <div className="pr-box">
                {this.state.synced_invoices +
                  " / " +
                  this.state.total_invoices_to_sync}
              </div>
            ) : (
              <div className="pr-box">{this.state.invoice}</div>
            )}
          </div>
          <div className="item-sync-inner">
            <div className="pref-selection-sync-header">Payments</div>
            <div className="pr-avtar pay" />
            {this.state.isSyncOn ? (
              <div className="pr-box">
                {this.state.synced_payments +
                  " / " +
                  this.state.total_payments_to_sync}
              </div>
            ) : (
              <div className="pr-box">{this.state.payment}</div>
            )}
          </div>
        </div>
        <div style={{ marginTop: "2.5rem", marginBottom: "1rem" }}>
          {!this.state.isSyncOn ? (
            <button onClick={(event) => this.syncQBData(event)}>
              <div className="sync-qb-btn"></div>
            </button>
          ) : (
            <div className="sync-ldr-container">
              <div
                className="pref-selection-header"
                style={{ marginBottom: "1rem" }}
              >
                Syncing QuickBooks Online Data
              </div>
              <p className="qb-p">
                Your QuickBooks Online data is being imported to MakeGood. Do
                not close this window until the process is complete. If this is
                your first time importing data, this process may take over an
                hour.
              </p>
              <div className="loader-div">
                <div className="sync-prt">
                  <div
                    className="sync-fill-loader"
                    style={{ width: percent + "%" }}
                  />
                </div>
                <div className="per-text-div">
                  <div style={{ fontFamily: "sofia_pro_bold" }}>
                    {percent.toFixed(0) + "%"}
                  </div>
                </div>
              </div>
              {this.state.SyncStatus === "COMPLETED" ? (
                <button
                  className="cta sync-comp-btn"
                  onClick={(event) => this.openDashboard(event)}
                >
                  Sync Complete
                </button>
              ) : (
                <button
                  className="cta qb-cancel-btn"
                  onClick={(event) => this.cancelQBSync(event)}
                >
                  Cancel
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  renderImportPreferences = () => {
    return (
      <div className="big-device-grad  tp-sp">
        <div className="he-hed">QuickBooks Integration Preferences</div>
        <div className="pref-selection">
          <div className="pref-selection-header">Accounts</div>
          <div className="pref-selection-sep" />
          <div className="pref-selection-inner">
            <div className="pref-selection-inner-left">
              <p className="pref-selection-rdtx">
                Automatically synchronize Accounts between QuickBooks Online and MakeGood
              </p>
              <ToggleSwitch
                isOn={this.state.accountToggle}
                isDisable={true}
                toggleButton={() => this.toggleButton("ACCOUNT")}
                isOnOpacityLess={this.state.accountToggle}
              />
            </div>
          </div>
        </div>
        <div className="pref-selection">
          <div className="pref-selection-header">Invoices</div>
          <div className="pref-selection-sep" />
          <div className="pref-selection-inner">
            <div className="pref-selection-inner-left">
              <p className="pref-selection-rdtx">
                Automatically synchronize Invoices between QuickBooks Online and MakeGood
              </p>
              <ToggleSwitch
                isOn={this.state.invoiceToggle}
                isDisable={true}
                toggleButton={() => this.toggleButton("INVOICE")}
                isOnOpacityLess={this.state.invoiceToggle}
              />
            </div>
          </div>
        </div>
        <div className="pref-selection">
          <div className="pref-selection-header">Payments</div>
          <div className="pref-selection-sep" />
          <div className="pref-selection-inner">
            <div className="pref-selection-inner-left">
              <p className="pref-selection-rdtx">
                Automatically synchronize Payments between QuickBooks Online and MakeGood
              </p>
              <ToggleSwitch
                isOn={this.state.paymentToggle}
                isDisable={true}
                toggleButton={() => this.toggleButton("PAYMENT")}
                isOnOpacityLess={this.state.paymentToggle}
              />
            </div>
          </div>
        </div>
        <div className="pref-selection">
          <div className="pref-selection-header">Goods & Services</div>
          <div className="pref-selection-sep" />
          <div className="pref-selection-inner">
            <div className="pref-selection-inner-left">
              <p className="pref-selection-rdtx">
                Auto-Import Non-Inventory & Service Items from your QuickBooks
                account to MakeGood
              </p>
              <ToggleSwitch
                isOn={this.state.autoImportServices}
                isDisable={true}
                toggleButton={() => this.toggleButton("AUTO_IMPORT_SERVICES")}
                isOnOpacityLess={this.state.autoImportServices}
              />
            </div>
          </div>
          <div className="pref-selection-inner md-sp">
            <div className="pref-selection-inner-left">
              <p className="pref-selection-rdtx">
                Auto-Sync Goods & Services to QuickBooks Online
              </p>
              <ToggleSwitch
                isOn={this.state.autoSyncServices}
                isDisable={true}
                toggleButton={() => this.toggleButton("AUTO-SYNC-SERVICES")}
                isOnOpacityLess={this.state.autoSyncServices}
              />
            </div>
          </div>
          <div className="pref-selection-inner md-sp">
            <div className="pref-selection-inner-left">
              <p className="pref-selection-rdtx">
                Auto Select the QuickBooks Online Income account
              </p>
              <ToggleSwitch
                isOn={this.state.autoSelectIncome}
                isDisable={true}
                toggleButton={() => this.toggleButton("AUTO-SELECT-INCOME")}
                isOnOpacityLess={this.state.autoSelectIncome}
              />
            </div>
          </div>
        </div>
        <div className="pref-selection">
          <div className="pref-selection-header">Sales Tax</div>
          <div className="pref-selection-sep" />
          <div className="pref-selection-inner">
            <div className="pref-selection-inner-left">
              <p className="pref-selection-rdtx">Use QuickBooks Online Automated Sales Tax when calculating Sales Tax on Invoices</p>
              <ToggleSwitch
                isOn={this.state.usingSalesTex}
                isDisable={true}
                toggleButton={() => this.toggleButton("USING-SALES-TAX")}
                isOnOpacityLess={this.state.usingSalesTex}
              />
            </div>
          </div>
        </div>
        <div className="pref-selection">
              <div className="pref-selection-header">Discount</div>
            <div className="pref-selection-sep" />
            <div className="pref-selection-inner md-sp">
            <div className="pref-selection-inner-left">
              <p className="pref-selection-rdtx">
                  Automatically associate Early Payer Discounts with the default Discount Account in QuickBooks Online
              </p>
              <ToggleSwitch
                isOn={this.state.allowDiscount}
                isDisable={true}
                toggleButton={() => this.toggleButton("ALLOWED-DISCOUNT")}
                isOnOpacityLess={this.state.allowDiscount}
              />
            </div>
          </div>
        </div>

        {/* TO_DO_MAK490 */}
        <div className="pref-selection">
          <div className="pref-selection-header">Terms</div>
          <div className="pref-selection-sep" />
          <div className="pref-selection-inner md-sp">
            <div className="pref-selection-inner-left">
              <p className="pref-selection-rdtx">
                Automatically synchronize Terms between QuickBooks Online and MakeGood
              </p>
              <ToggleSwitch
                isOn={this.state.autoImportTerms}
                isDisable={true}
                toggleButton={() => this.toggleButton("AUTO_IMPORT_TERMS")}
                isOnOpacityLess={this.state.autoImportTerms}
              />
            </div>

          </div>
        </div>

        <div style={{ marginTop: "2.5rem", marginBottom: "1rem" }}>
          <button onClick={(event) => this.props.viewpreferences(false)}>
            <div className="import-qb-btn"></div>
          </button>
        </div>
        {/* <div className="set-half">
          <p className="qb-p">
            After you import, you’ll be able to confirm your settings before
            syncing your QuickBooks data.
          </p>
        </div> */}
      </div>
    );
  };

  renderConnectQB = () => {
    return (
      <div className="big-device-grad  tp-sp">
        <div className="he-hed">Hello!</div>
        <div className="set-half">
          <p className="sub-hed-p">
            You’re either connecting to QuickBooks for the first time or your
            current connection has expired. Let’s take the next step and connect
            to your QuickBooks Online account by clicking the button below.
          </p>
          <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            <button onClick={(event) => this.callAPI(event)}>
              <div className="connect-qb-btn"></div>
            </button>
          </div>
          <div>
            <h4>Questions?</h4>
            <p className="qb-p">
              Click the HELP button at any time for chat, email, or phone
              support, or to visit our FAQ section.
            </p>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { appName, isEditPref, disconnected } = this.props;
    const { isLoading, error, isConnectedToQB } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (disconnected) {
      return this.renderConnectQB();
    }

    return isLoading ? (
      <div style={{ marginTop: "20%" }}>
        <AppCanvasLoader />
      </div>
    ) : (
      <div className="consumer-help">
        <Helmet>
          <title>{appName} | QuickBooks</title>
        </Helmet>
        {isConnectedToQB
          ? isEditPref
            ? this.renderImportPreferences()
            : this.renderSyncQBData()
          : this.renderConnectQB()}
      </div>
    );
  }
}

export default QuickbookSetting;
