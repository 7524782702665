import React, { Component } from "react";
import Select from "react-select";
import "react-select/dist/react-select.css";

class ClientsAndContact extends Component {
  constructor(props) {
    super(props);
  }

  //callback function after add client click
  callbackFunc = (client) => {
    this.props.setNewlyAddedClient(client);
  };

  //hanlde add client click
  handleAddClientClick = (event) => {
    event.preventDefault();
    let props = {
      releoadTableData: this.callbackFunc,
    };
    this.props.updateModalState(true, "ADD_ORGANIZATION_CLIENT_ACCOUNT", props);
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "client") {
      message = "Please select client";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  render() {
    let {
      clientList,
      clientDetails,
      clientDetailsError,
      disabledClient,
    } = this.props;
    clientDetails = clientDetails || {};

    return (
      <div>
        {this.props.headerContent("Client & Contact")}
        <div className="main-container">
          <div className="half">
            <label htmlFor="client">Client</label>
            <div
              className={`input-container term-select ${
                clientDetailsError ? " error" : ""
              }`}
            >
              <Select
                inputProps={{
                  autoComplete: "none",
                  autoCorrect: "off",
                  spellCheck: "off",
                }}
                className="form-select"
                name="client"
                placeholder="Select client"
                options={clientList}
                style={{ pointerEvents: disabledClient ? "none" : "auto" }}
                value={clientDetails.display_name}
                onChange={(e) => this.props.saveClientDetails(e)}
                onInputChange={(e) =>
                  e ? this.props.invoiceClientSearch(e) : ""
                }
              />
              {clientDetailsError && this.renderErrorMessage("client")}
            </div>
            <label htmlFor="client-contact">Contact</label>
            <div className={`panel client-contact`}>
              <div className="row alt-row">
                <div className="key">Name:</div>
                <div className="value">{clientDetails.display_name}</div>
              </div>
              <div className="row">
                <div className="key">Email:</div>
                <div className="value">
                  {clientDetails.billing_primary_email}
                </div>
              </div>
              <div className="row alt-row">
                <div className="key">Phone:</div>
                <div className="value">{clientDetails.billing_phone_no}</div>
              </div>
              <div className="row">
                <div className="key">Address1:</div>
                <div className="value">
                  {clientDetails.billing_address_line1}
                </div>
              </div>
              <div className="row alt-row">
                <div className="key">Address2:</div>
                <div className="value">
                  {clientDetails.billing_address_line2}
                </div>
              </div>
              <div className="block-row">
                <div className="inline-row-flex">
                  <div className="key">City:</div>
                  <div className="value">{clientDetails.billing_city}</div>
                </div>
                <div className="inline-row-flex">
                  <div className="key">State:</div>
                  <div className="value">{clientDetails.billing_state}</div>
                </div>
                <div className="inline-row-flex">
                  <div className="key">ZipCode:</div>
                  <div className="value">{clientDetails.billing_zip}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="half">
            <button
              onClick={this.handleAddClientClick}
              className={`inner-btn mg-brand2-color add-client-btn`}
            >
              Add Client
            </button>
            <label htmlFor="client-contact">Email Contacts</label>
            <div className={`panel client-contact`}>
              {clientDetails &&
              clientDetails.user_contacts &&
              clientDetails.user_contacts.length ? (
                clientDetails.user_contacts.map((email, index) => {
                  return (
                    <div className={`row ${index % 2 == 0 ? "alt-row" : ""}`}>
                      <div className="key">
                        {email.contact_type && email.contact_type == "TO_EMAIL"
                          ? "To Email:"
                          : "CC Email:"}
                      </div>
                      <div className="value">{email.value}</div>
                    </div>
                  );
                })
              ) : (
                <div className="empty-activity" style={{ marginTop: "2em" }}>
                  <p>Not available.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientsAndContact;
