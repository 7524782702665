import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

// API
import * as API from '../../utils/api';

import { networkMessage, showToast, getZohoPagesense } from '../../utils/helpers'

// Components
import AppSectionHeader from '../../components/App/AppSectionHeader';
import AppCanvasLoader from '../../components/App/AppCanvasLoader';
import EmployeeUpdateConsumerForm from '../../components/Employee/Forms/EmployeeUpdateConsumerForm';

class EmployeeAccountAddEditView extends Component {
  state = {
    consumerAccountId: null,
    consumer: {},
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200
    }
  }

  componentDidMount() {
    const consumerAccountId = this.props.match.params.id;
    API.getClientsConsumer(this.props.user, consumerAccountId).then(data => {

      let isAccessDenied =  (data && data.message && data.message == "Access denied.") ? true : false

     if (((typeof data != 'undefined') || (data != null)) && !data.error && !isAccessDenied) {
        let { consumer, debts } = data.account;

        this.setState(state => ({
          consumerAccountId,
          consumer: {
            ...consumer,
            debts_attributes: debts
          },
          isLoading: false
        }))
      } else {
        this.setState(state => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          }
        }))
      }
    });
    getZohoPagesense();
    ReactGA.pageview('/EmployeeAccountAddEditView');
  }

  showLoader = (isLoading) => {
    this.setState({ isLoading: isLoading });
  }

  render() {
    const { appName } = this.props;
    const { consumer, isLoading, consumerAccountId, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }


    return isLoading
      ? (
        <AppCanvasLoader />
      )
      : (
        <div>
          <Helmet>
            <title>{appName} | {consumer.first_name + ' ' + consumer.last_name}</title>
          </Helmet>
          <AppSectionHeader
            title="Edit Account"
            optionalClassNames="secondary"
            backLink={{
              text: `Cancel and Return to Account`,
              path: `/employee/accounts/${consumerAccountId}`
            }}
          />
          <EmployeeUpdateConsumerForm
            consumerId={consumerAccountId}
            consumer={consumer}
            user={this.props.user}
            hideModal={this.props.hideModal}
            updateModalState={this.props.updateModalState}
            showLoader={this.showLoader}
          />
        </div>
      );
  }
}

export default EmployeeAccountAddEditView;
