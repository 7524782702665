import PropTypes from "prop-types";
import React, { Component } from "react";
// Packages
import onClickOutside from "react-onclickoutside";


class HeaderOverflowMenu extends Component {
  static propTypes = {
    children: PropTypes.array.isRequired,
  };

  state = {
    dropdownIsActive: false,
  };

  componentWillReceiveProps() {
    if(this.props.dropdownIsActive) {
      this.setState({dropdownIsActive: true})
    } else {
      this.setState({dropdownIsActive: false})
    }
  }

  toggleDropdown = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      ...state,
      dropdownIsActive: !state.dropdownIsActive,
    }));
  };

  handleClickOutside = (event) => {
    if (this.state.dropdownIsActive) {
      this.toggleDropdown(event);
      if(this.props.clearData) this.props.clearData();
    }
  };

  render() {
    const { children, showThreeDots, optionalClasses, innerOptionalClasses } = this.props;
    let { dropdownIsActive } = this.state;
    let style = showThreeDots ? { color: "#636466" } : {};

    return (
      <a
        className={`dropdown-overflow-menu override-overflow${optionalClasses ? ' ' + optionalClasses : ''}`}
        style={style}
        onClick={(event) => this.toggleDropdown(event)}
      >
        {showThreeDots && (
          <svg className={`dropdown-overflow-menu-icon`}>
            <use xlinkHref="#overflow-menu" />
          </svg>
        )}
        <ul
          className={`dropdown-menu override-overflow-size${innerOptionalClasses ? ' ' + innerOptionalClasses : ''}${
            dropdownIsActive ? " active" : ""
          }`}
        >
          {children}
        </ul>
      </a>
    );
  }
}

export default onClickOutside(HeaderOverflowMenu);
