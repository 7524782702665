import moment from "moment";
import React, { Component } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import currencyFormatter from "currency-formatter";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsLogo from "../../components/Consumer/ClientsLogo";
import ConsumerInvoiceDetails from "../../components/Consumer/ConsumerInvoiceDetails";
import ConsumerSidebar from "../../components/Consumer/ConsumerSidebar";
import * as API from "../../utils/api";
import { getZohoPagesense } from "../../utils/helpers";
import ConsumerPayment from "./ConsumerPayment";

class ConsumerAccountsView extends Component {
  state = {
    invoiceDetails: [],
    showPaymentScreen: false,
    isLoading: true,
    showLoader: false,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    // Set Previous Path for use with App Header's "Back" Link
    this.props.updatePrevPath("/dashboard/accounts/");
    getZohoPagesense();
    ReactGA.pageview("/ConsumerAccountsView");
    this.getInvoiceDetails();
  }

  getInvoiceDetails = () => {
    const invoiceId = this.props.match.params.id;
    API.fetchConsumerInvoiceDetails(this.props.user, invoiceId).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.data != null &&
        data.status_code == 200
      ) {
        this.setState({ invoiceDetails: data.data, isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  getSubTitle = (userData) => {
    let fullname = "";
    if (userData.first_name) {
      fullname = userData.first_name;
    }
    if (userData.last_name && fullname) {
      fullname = fullname + " " + userData.last_name;
    } else {
      fullname = userData.last_name;
    }
    return "Welcome, " + fullname + "!";
  };

  checkInvoicePastDue = (date) => {
    return moment(date).isBefore(new Date());
  };

  getAccountType = () => {
    const { invoiceDetails } = this.state;
    let flag = this.checkInvoicePastDue(invoiceDetails.due_date);
    if (flag) {
      return "past due";
    } else {
      return "current";
    }
  };

  handlePayNow = () => {
    this.setState({ showPaymentScreen: !this.state.showPaymentScreen });
  };

  sidebarContent = () => {
    const { showPaymentScreen, invoiceDetails } = this.state;
    return (
      <div className="consumer-sidebar">
        <div
          className="sidebar-primary-text"
          style={{ marginBottom: "2em", display: "flex" }}
        >
          This invoice is {this.getAccountType()}
        </div>
        <label style={{ marginBottom: "0.3em" }}>Balance Due</label>
        <div
          className="invoice-amt-owed payment-balance"
          style={{ marginBottom: "1em" }}
        >
          {invoiceDetails.balance
            ? currencyFormatter.format(invoiceDetails.balance, { code: "USD" })
            : "0.00"}
        </div>
        {/* {invoiceDetails &&
        invoiceDetails.balance &&
        invoiceDetails.balance > 0 ? (
          <div className="history-btn">
            <button
              className={`${showPaymentScreen ? "active" : ""}`}
              onClick={() => this.handlePayNow()}
              style={{ marginLeft: 0 }}
            >
              Make Payment
            </button>
          </div>
        ) : null} */}
      </div>
    );
  };

  render() {
    const { appName, user, stripePubKey } = this.props;
    const {
      isLoading,
      error,
      invoiceDetails,
      showLoader,
      showPaymentScreen,
    } = this.state;
    const props = this.props;
    const invoiceId = this.props.match.params.id;

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (showPaymentScreen) {
      return (
        <ConsumerPayment
          user={user}
          appName={appName}
          stripePubKey={stripePubKey}
          invoiceDetails={invoiceDetails}
          getInvoiceDetails={this.getInvoiceDetails}
          updateModalState={this.props.updateModalState}
          BackLink={`/dashboard/accounts/${invoiceId}`}
        />
      );
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className="client-scroll-dashboard scrollbar">
        <Helmet>
          <title>
            {appName} | Invoice# {invoiceDetails.invoice_number}
          </title>
        </Helmet>
        <div className="app-sidebar-layout-canvas">
          <ConsumerSidebar
            user={this.props.user}
            subTitle={this.getSubTitle(this.props.user)}
            sidebarContent={this.sidebarContent()}
            BackLink={"/dashboard/accounts"}
          />

          {showLoader ? (
            <div className="app-sidebar-layout-canvas-content">
              <AppCanvasLoader />
            </div>
          ) : (
            <div
              className="app-sidebar-layout-canvas-content setting-side-canvas big-device-grad col1-scroll scrollbar"
              style={{ paddingBottom: 0 }}
            >
              <ClientsLogo />
              <div className="header-make-payment">
                <h1 className="app-section-consumer-heading consumer-brand">
                  Invoice# {invoiceDetails.invoice_number}
                </h1>
                {invoiceDetails &&
                  invoiceDetails.balance &&
                  invoiceDetails.balance > 0
                  ? <button
                      className="cta make-payment-btn mg-brand2-color"
                      onClick={() => this.handlePayNow()}
                    >
                      Make Payment
                </button> : null}
                </div>
              <ConsumerInvoiceDetails
                user={user}
                invoiceDetails={invoiceDetails}
                updateModalState={this.props.updateModalState}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ConsumerAccountsView;
