import React, { Component } from "react";
import Panel from "../../App/Panel";
import EditIcon from "../../../assets/icons/edit.svg";
import Select from "react-select";
import "react-select/dist/react-select.css";
import MaskedTextInput from "react-text-mask";
import stateDataShortNames from "../../../utils/stateDataShortNames.json";
import { validateEmail } from "../../../utils/helpers";
import * as API from "../../../utils/api";

class ClientsContactSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consumerData: [],
      letterData: [],
      toArray: [],
      toEdit: false,
      ccArray: [],
      ccEdit: false,
      useAddress: false,
      input: {
        inputToEmail: {
          value: "",
          hasError: false,
        },
        inputCCEmail: {
          value: "",
          hasError: false,
        },
        line1: {
          value: "",
          hasError: false,
        },
        line2: {
          value: "",
          hasError: false,
        },
        streetLine1: {
          value: "",
          hasError: false,
        },
        streetLine2: {
          value: "",
          hasError: false,
        },
        state: {
          value: "",
          hasError: false,
        },
        city: {
          value: "",
          hasError: false,
        },
        zipcode: {
          value: "",
          hasError: false,
        },
      },
      getInitialData: this.getInitialData.bind(this),
    };
  }

  componentDidMount() {
    this.setLetterData();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.consumerData !== nextProps.consumerData) {
      return {
        consumerData: nextProps.consumerData,
        model: prevState.getInitialData(nextProps.consumerData),
      };
    }
    return null;
  }

  getInitialData = (data) => {
    this.getContactDetails(data);
    this.getContactLetter(data.id);
  };

  getContactDetails = (ConsData) => {
    let { input, toArray } = this.state;
    toArray = [];
    API.getContactSettings(this.props.user, ConsData.id).then((data) => {
      if (data && data.status_code == 200 && data.data) {
        let flag =
          data.data.to_emails && data.data.to_emails.length ? false : true;
        if (flag) {
          toArray.push(ConsData.email);
        }
        this.setState({
          toArray:
            data.data.to_emails && data.data.to_emails.length
              ? [...data.data.to_emails, ...toArray]
              : toArray,
          ccArray: data.data.cc_emails ? data.data.cc_emails : [],
          toEdit: flag,
          input: input,
        });
      }
    });
  };

  getContactLetter = (id) => {
    API.getContactLetter(this.props.user, id).then((data) => {
      if (data && data.status_code == 200 && data.data) {
        this.setState({ letterData: data.data }, () => {
          this.setLetterData();
        });
      }
    });
  };

  callAddEmailsApi = () => {
    let { toArray, ccArray, consumerData } = this.state;
    let payload = {
      user_id: consumerData.id,
      to_emails: toArray,
      cc_emails: ccArray,
    };
    API.updateContactEmails(this.props.user, payload).then((data) => {
      if (data && data.status_code === 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  setLetterData = () => {
    let { consumerData } = this.props;
    let { useAddress, letterData } = this.state;
    this.setState((state) => ({
      input: {
        inputToEmail: { ...state.inputToEmail },
        inputCCEmail: { ...state.inputCCEmail },
        line1: {
          ...state.input.line1,
          value: useAddress
            ? consumerData.company_name
            : letterData && letterData.line1
            ? letterData.line1
            : "",
        },
        line2: {
          ...state.input.line2,
          value: useAddress
            ? consumerData.display_name
            : letterData && letterData.line2
            ? letterData.line2
            : "",
        },
        streetLine1: {
          ...state.input.streetLine1,
          value: useAddress
            ? consumerData.billing_address_line1
            : letterData && letterData.address_line1
            ? letterData.address_line1
            : "",
        },
        streetLine2: {
          ...state.input.streetLine2,
          value: useAddress
            ? consumerData.billing_address_line2
            : letterData && letterData.address_line2
            ? letterData.address_line2
            : "",
        },
        city: {
          ...state.input.city,
          value: useAddress
            ? consumerData.billing_city
            : letterData && letterData.city
            ? letterData.city
            : "",
        },
        state: {
          ...state.input.state,
          value: useAddress
            ? consumerData.billing_state
            : letterData && letterData.state
            ? letterData.state
            : "",
        },
        zipcode: {
          ...state.input.zipcode,
          value: useAddress
            ? consumerData.billing_zip
            : letterData && letterData.zip
            ? letterData.zip
            : "",
        },
      },
    }));
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  handleKeyPress = (e, type) => {
    let { input, toArray, ccArray, toEdit, ccEdit } = this.state;
    let isEnterKey = e.keyCode || e.which;
    if (isEnterKey == 13) {
      if (type == "to" && input.inputToEmail.value) {
        if (!validateEmail(input.inputToEmail.value)) {
          input.inputToEmail.hasError = true;
        } else {
          toArray.push(input.inputToEmail.value);
          input.inputToEmail.hasError = false;
          input.inputToEmail.value = "";
        }
      } else if (type == "cc" && input.inputCCEmail.value) {
        if (!validateEmail(input.inputCCEmail.value)) {
          input.inputCCEmail.hasError = true;
        } else {
          ccArray.push(input.inputCCEmail.value);
          input.inputCCEmail.hasError = false;
          input.inputCCEmail.value = "";
        }
      }
    }
    this.setState({ input });
  };

  handleCheckboxInputChange = (event) => {
    let target = event.target;
    this.setState({ useAddress: target.checked }, () => {
      this.setLetterData();
    });
  };

  validateContactEmails = () => {
    let { input, toArray, ccArray, toEdit, ccEdit } = this.state;
    let errorsArePresent = false;
    if (input.inputToEmail.value) {
      if (!validateEmail(input.inputToEmail.value)) {
        input.inputToEmail.hasError = true;
        errorsArePresent = true;
      } else {
        toArray.push(input.inputToEmail.value);
        input.inputToEmail.hasError = false;
        input.inputToEmail.value = "";
      }
    }
    if (input.inputCCEmail.value) {
      if (!validateEmail(input.inputCCEmail.value)) {
        input.inputCCEmail.hasError = true;
        errorsArePresent = true;
      } else {
        ccArray.push(input.inputCCEmail.value);
        input.inputCCEmail.hasError = false;
        input.inputCCEmail.value = "";
      }
    }
    this.setState({ input, toEdit: false, ccEdit: false });
    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  };

  handleEmailSubmit = (e) => {
    e.preventDefault();
    if (this.validateContactEmails()) {
      this.callAddEmailsApi();
    }
  };

  handleLetterSubmit = (e) => {
    e.preventDefault();
    let { input, consumerData } = this.state;
    let payload = {
      user_id: consumerData.id,
      line1: input.line1.value,
      line2: input.line2.value,
      address_line1: input.streetLine1.value,
      address_line2: input.streetLine2.value,
      city: input.city.value,
      state: input.state.value,
      zip: input.zipcode.value,
    };
    if (this.validateLetter()) {
      API.updateContactLetter(this.props.user, payload).then((data) => {
        if (data && data.status_code === 200) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      });
    }
  };

  validateLetter = () => {
    let errorsArePresent = false;
    let inputs = { ...this.state.input };
    Object.entries(inputs).forEach(([key, value]) => {
      if (key === "line1") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          value.hasError = true;
        }
      } else if (key === "streetLine1") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          value.hasError = true;
        }
      } else if (key === "city") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          value.hasError = true;
        }
      } else if (key === "state") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          value.hasError = true;
        }
      } else if (key === "zipcode") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          value.hasError = true;
        }
      }
    });
    this.setState({ input: inputs });

    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  };

  removeEmail = (i, type) => {
    let { toArray, ccArray } = this.state;
    if (type == "to" && toArray.length) {
      toArray.splice(i, 1);
    } else if (type == "cc" && ccArray.length) {
      ccArray.splice(i, 1);
    }
    this.setState({ toArray, ccArray });
  };

  showEmails = (email, index, type) => {
    return (
      <div
        className="template-col-name"
        style={{ marginBottom: "0.5em" }}
        key={email}
      >
        <span
          style={{
            marginTop: 1,
            fontFamily: "sofia_pro_regular",
            fontSize: 13,
          }}
        >
          {email}
        </span>
        <span
          className="assigned-tem-card-cross"
          onClick={() => this.removeEmail(index, type)}
        >
          x
        </span>
      </div>
    );
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "email") {
      message = "Please enter a valid email";
    } else if (input === "address") {
      message = "Please enter a street address";
    } else if (input === "city") {
      message = "Please enter a city";
    } else if (input === "state") {
      message = "Please select a state";
    } else if (input === "zipcode") {
      message = "Please enter a zip code";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  render() {
    let { toEdit, ccEdit, input, toArray, ccArray } = this.state;
    return (
      <div style={{ marginTop: "2em" }}>
        <div className="he-hed">Contact Settings</div>
        <div className="contact-settings">
          <Panel>
            <h4>Email Delivery</h4>
            <div className="email-del-to">
              <label>
                To:{" "}
                {toArray.length
                  ? toArray.map((email, i) => this.showEmails(email, i, "to"))
                  : null}
              </label>
              {toEdit ? (
                <div
                  className={`input-container${
                    input.inputToEmail.hasError ? " error" : ""
                  }`}
                >
                  <input
                    name="to_email"
                    placeholder="email@emailaddress.com"
                    type="email"
                    value={input.inputToEmail.value || ""}
                    onKeyPress={(e) => this.handleKeyPress(e, "to")}
                    onChange={(e) =>
                      this.handleInputChange({
                        inputToEmail: {
                          value: e.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                  {input.inputToEmail.hasError
                    ? this.renderErrorMessage("email")
                    : null}
                </div>
              ) : (
                <img
                  src={EditIcon}
                  height={15}
                  width={15}
                  onClick={(event) => this.setState({ toEdit: true })}
                />
              )}
            </div>
            <div className="email-del-to">
              <label>
                CC:{" "}
                {ccArray.length
                  ? ccArray.map((email, i) => this.showEmails(email, i, "cc"))
                  : null}
              </label>
              {ccEdit ? (
                <div
                  className={`input-container${
                    input.inputCCEmail.hasError ? " error" : ""
                  }`}
                >
                  <input
                    name="cc_email"
                    placeholder="email@emailaddress.com"
                    type="email"
                    value={input.inputCCEmail.value || ""}
                    onKeyPress={(e) => this.handleKeyPress(e, "cc")}
                    onChange={(e) =>
                      this.handleInputChange({
                        inputCCEmail: {
                          value: e.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                  {input.inputCCEmail.hasError
                    ? this.renderErrorMessage("email")
                    : null}
                </div>
              ) : (
                <img
                  src={EditIcon}
                  height={15}
                  width={15}
                  onClick={(event) => this.setState({ ccEdit: true })}
                />
              )}
            </div>
            {toEdit || ccEdit ? (
              <div className="modal-actions">
                <a
                  className="cta mg-brand2-color"
                  onClick={(event) => this.handleEmailSubmit(event)}
                >
                  Submit
                </a>
              </div>
            ) : null}
          </Panel>
          <Panel>
            <div
              style={{
                display: `flex`,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h4>Letter Delivery</h4>
              <label style={{ display: `flex` }}>
                <input
                  type="checkbox"
                  style={{
                    WebkitAppearance: `checkbox`,
                    width: `15px`,
                    marginRight: `10px`,
                    marginTop: `-6px`,
                  }}
                  checked={this.state.useAddress}
                  onChange={this.handleCheckboxInputChange}
                />
                Use Billing Address
              </label>
            </div>
            <div
              className={`input-container ${
                input.line1.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="name">Line 1</label>
              <input
                name="name"
                type="text"
                placeholder="Individual Name or Company Name"
                value={input.line1.value || ""}
                onChange={(event) =>
                  this.handleInputChange({
                    line1: {
                      value: event.target.value,
                      hasError: false,
                    },
                  })
                }
              />
              {input.line1.hasError ? this.renderErrorMessage() : ""}
            </div>
            <div className={`input-container`}>
              <label htmlFor="name">Line 2</label>
              <input
                name="display_name"
                type="text"
                placeholder="Company Name, Department, C/O"
                value={input.line2.value || ""}
                onChange={(event) =>
                  this.handleInputChange({
                    line2: {
                      value: event.target.value,
                      hasError: false,
                    },
                  })
                }
              />
            </div>
            <div
              className={`input-container ${
                input.streetLine1.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="streetAddress">Street Line 1</label>
              <input
                name="streetAddress"
                type="text"
                placeholder="Individual or Company Address"
                value={input.streetLine1.value || ""}
                onChange={(event) =>
                  this.handleInputChange({
                    streetLine1: {
                      value: event.target.value,
                      hasError: false,
                    },
                  })
                }
              />
              {input.streetLine1.hasError
                ? this.renderErrorMessage("address")
                : null}
            </div>
            <div className="admin-form-row">
              <div className={`input-container half`}>
                <label htmlFor="streetAddress">Street Line 2</label>
                <input
                  name="unit"
                  type="text"
                  placeholder="Suite # / Unit #"
                  value={input.streetLine2.value || ""}
                  onChange={(event) =>
                    this.handleInputChange({
                      streetLine2: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
              </div>
              <div
                className={`input-container half ${
                  input.city.hasError ? " error" : ""
                }`}
              >
                <label htmlFor="city">City</label>
                <input
                  name="city"
                  type="text"
                  placeholder="Enter a City"
                  value={input.city.value || ""}
                  onChange={(event) =>
                    this.handleInputChange({
                      city: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
                {input.city.hasError ? this.renderErrorMessage("city") : null}
              </div>
            </div>
            <div className="admin-form-row">
              <div
                className={`input-container half ${
                  input.state.hasError ? " error" : ""
                }`}
              >
                <label htmlFor="state">State</label>
                <Select
                  className="form-select"
                  name="state"
                  value={input.state.value || ""}
                  placeholder="Select"
                  options={stateDataShortNames}
                  resetValue=""
                  onChange={(event) =>
                    this.handleInputChange({
                      state: { value: event.value, hasError: false },
                    })
                  }
                />
                {input.state.hasError ? this.renderErrorMessage("state") : null}
              </div>
              <div
                className={`input-container half ${
                  input.zipcode.hasError ? " error" : ""
                }`}
              >
                <label htmlFor="zipcode">Zip Code</label>
                <MaskedTextInput
                  guide={true}
                  mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                  name="zipcode"
                  placeholder="90210"
                  placeholderChar={"\u2000"}
                  type="text"
                  value={input.zipcode.value || ""}
                  onChange={(event) =>
                    this.handleInputChange({
                      zipcode: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
                {input.zipcode.hasError
                  ? this.renderErrorMessage("zipcode")
                  : null}
              </div>
            </div>
            <div className="modal-actions">
              <a
                className="cta mg-brand2-color"
                onClick={(event) => this.handleLetterSubmit(event)}
              >
                Submit
              </a>
            </div>
          </Panel>
        </div>
      </div>
    );
  }
}

export default ClientsContactSetting;
