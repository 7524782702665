import React, { Component } from 'react';
import { Redirect, withRouter } from "react-router-dom";
import $ from 'jquery';
// Packages
import 'react-select/dist/react-select.css';
import { SketchPicker } from 'react-color';
// API
import * as API from '../../../utils/api';
import { showToast, validateEmail, numberMask } from '../../../utils/helpers';
import Modal from '../../../components/App/Modals/Modal';

import FileBase64 from 'react-file-base64';
import MaskedTextInput from 'react-text-mask';
import Panel from '../../App/Panel';
import FlashMessage from '../../../components/FlashMessage'



class CreateOrganization extends Component {
  state = {
    input: {
      companyName: {
        value: "",
        hasError: false
      },
      url: {
        value: "",
        hasError: false
      },
      companyEmail: {
        value: "",
        hasError: false
      },
      contactNo: {
        value: "",
        hasError: false
      },
      logo: {
        value: "",
        hasError: false
      },
      theme: {
        value: "#2de2c3",
        hasError: false
      },
      adminEmail: {
        value: "",
        hasError: false
      },
      first_name: {
        value: "",
        hasError: false
      },
      last_name: {
        value: "",
        hasError: false
      },
      companyAddress: {
        value: "",
        hasError: false
      }
    },
    config: {
      intercom: {
        appId: {
          value: '',
          hasError: false
        },
        token: {
          value: '',
          hasError: false
        }
      },
      twillio: {
        appId: {
          value: '',
          hasError: false
        },
        contactNo: {
          value: '',
          hasError: false
        },
        secretKey: {
          value: '',
          hasError: false
        }
      },
      stripe: {
        secretKey: {
          value: '',
          hasError: false
        },
        token: {
          value: '',
          hasError: false
        }
      },
      lob: {
        secretKey: {
          value: '',
          hasError: false
        }
      },
      mandrill: {
        secretKey: {
          value: '',
          hasError: false
        },
        subAccount: {
          value: '',
          hasError: false
        }
      },
      accountPref: {
        qbConnected: {
          value: true
        },
        standAlone: {
          value: false
        }
      }
    },
    slide: 1,
    disableSubmit: false,
    showColorPicker: false,
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200
    },
    showFlash: false,
    flashMessage: "",
  }

  componentDidMount() {


  }

  toNextSlide = () => {
    this.scrollToTop();

    if (this.validateForm()) {
      this.setState(state => ({
        ...state,
        slide: this.state.slide + 1
      }))
    } else {
      //this.setFormErrorState('consumerInfo');
    }

  }

  scrollToTop() {
    window.scrollTo(0, 0)
  }

  dismissFlash = () => {
    this.setState(state => ({
      ...state,
      showFlash: false
    }))
  }

  handleInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput
      }
    }))
  }

  handleTwillioInputChange(input, newPartialInput) {
    if (input === 'appId') {
      this.state.config.twillio.appId = newPartialInput
    }
    if (input === 'secretKey') {
      this.state.config.twillio.secretKey = newPartialInput
    }
    if (input === 'contactNo') {
      this.state.config.twillio.contactNo = newPartialInput
    }
    this.setState({ config: this.state.config })
  }

  handleIntercomInputChange(input, newPartialInput) {
    if (input === 'appId') {
      this.state.config.intercom.appId = newPartialInput
    }
    if (input === 'token') {
      this.state.config.intercom.token = newPartialInput
    }
    this.setState({ config: this.state.config })
  }

  handleStripeInputChange(input, newPartialInput) {
    if (input === 'secretKey') {
      this.state.config.stripe.secretKey = newPartialInput
    }
    if (input === 'token') {
      this.state.config.stripe.token = newPartialInput
    }
    this.setState({ config: this.state.config })
  }

  handleMandrillInputChange(input, newPartialInput) {
    if (input === 'secretKey') {
      this.state.config.mandrill.secretKey = newPartialInput
    }
    if (input === 'subAccount') {
      this.state.config.mandrill.subAccount = newPartialInput
    }
    this.setState({ config: this.state.config })
  }

  handleAccountPrefInputChange(input, newPartialInput) {
    if (input === 'qbConnected') {
      this.state.config.accountPref.qbConnected = newPartialInput
      this.state.config.accountPref.standAlone.value = false
    }
    if (input === 'standAlone') {
      this.state.config.accountPref.standAlone = newPartialInput;
      this.state.config.accountPref.qbConnected.value = false
    }
    this.setState({ config: this.state.config })
  }

  handleLobInputChange(input, newPartialInput) {
    if (input === 'secretKey') {
      this.state.config.lob.secretKey = newPartialInput
    }
    this.setState({ config: this.state.config })
  }

  getNumberFromPhoneString = (phoneString) => {
    let strippedNumber = phoneString.replace(/\(|\)|\s|-/g, '');
    return strippedNumber;
  }


  getFiles(files) {
    let newInputState = { ...this.state.input };
    let imgExtension = files.name.split('.')[1]
    let extensionFlag = false;
    if ((imgExtension.toLowerCase() === "png")) {
      extensionFlag = true;
    } else if (files.size > 1000000) {
      extensionFlag = false;
    }
    if (extensionFlag) {
      this.state.input.logo.value = files.base64
      this.setState({ input: this.state.input })
    } else {
      newInputState.logo.hasError = true;
      this.setState(state => ({
        ...state,
        input: newInputState
      }))
    }
  }

  toPreviousSlide = () => {
    this.setState(state => ({
      ...state,
      slide: this.state.slide - 1
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { input, config } = this.state;

    if (this.validateConfigForm()) {
      this.setState({disableSubmit: true});
      let payload = {
        "org_name": input.companyName.value,
        "website_url": input.url.value,
        "org_contactNo": this.getNumberFromPhoneString(input.contactNo.value),
        "address": input.companyAddress.value,
        "color": input.theme.value,
        "logo": input.logo.value,
        "email": input.companyEmail.value,
        "admin_email_id": input.adminEmail.value,
        "first_name": input.first_name.value,
        "last_name": input.last_name.value,
        "integrated_act_system": config.accountPref.qbConnected.value ? 0 : 1,
        "twillio": {
          "contactNo": this.getNumberFromPhoneString(config.twillio.contactNo.value),
          "appId": config.twillio.appId.value,
          "secretKey": config.twillio.secretKey.value
        },
        "intercom": {
          "appId": config.intercom.appId.value,
          "token": config.intercom.token.value
        },
        "stripe": {
          "token": config.stripe.token.value,
          "secretKey": config.stripe.secretKey.value
        },
        "lob": {
          "secretKey": config.lob.secretKey.value
        },
        "mandrill": {
          "secretKey": config.mandrill.secretKey.value,
          "subaccount": config.mandrill.subAccount.value
        }
      }
      
      API.addOrganization(this.props.user, payload).then(data => {
        if (((typeof data != 'undefined') || (data != null)) && !data.error) {
          this.setState({disableSubmit: false});
          if (data.error || data.status_code === 400) {
            this.setState(state => ({
              ...state,
              showFlash: true,
              flashMessage: data.message,
              slide: 1
            }))
          } else {
            this.handleModalClose();
            this.props.updateModalState(true, 'SUCCESS', { message: data.message });
            this.props.optionalProps.reloadData();
          }
        } else {
          this.setState(state => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
            }
          }))
        }
      });
    }
  }

  validateConfigForm = () => {
    // let errorsArePresent = false;
    // let config = { ...this.state.config }
    
    // Object.entries(config).forEach(([obj, objvalue]) => {

    //   if (obj === 'twillio') {
    //     Object.entries(objvalue).forEach(([key, value]) => {
    //       if (key === 'appId') {
    //         if (value.value === '') {
    //           config.twillio.appId.hasError = true;
    //           errorsArePresent = true;
    //         }
    //       } else if (key === 'secretKey') {
    //         if (value.value === '') {
    //           config.twillio.secretKey.hasError = true;
    //           errorsArePresent = true;
    //         }
    //       } else if (key === 'contactNo') {
    //         if (value.value === '' || (value.value && value.value.length < 14)) {
    //           config.twillio.contactNo.hasError = true;
    //           errorsArePresent = true;
    //         }
    //       }
    //     })
    //   }
    //   if (obj === 'intercom') {
    //     Object.entries(objvalue).forEach(([key, value]) => {
    //       if (key === 'appId') {
    //         if (value.value === '') {
    //           config.intercom.appId.hasError = true;
    //           errorsArePresent = true;
    //         }
    //       } else if (key === 'token') {
    //         if (value.value === '') {
    //           config.intercom.token.hasError = true;
    //           errorsArePresent = true;
    //         }
    //       }
    //     })
    //   }
    //   if (obj === 'stripe') {
    //     Object.entries(objvalue).forEach(([key, value]) => {
    //       if (key === 'secretKey') {
    //         if (value.value === '') {
    //           config.stripe.secretKey.hasError = true;
    //           errorsArePresent = true;
    //         }
    //       } else if (key === 'token') {
    //         if (value.value === '') {
    //           config.stripe.token.hasError = true;
    //           errorsArePresent = true;
    //         }
    //       }
    //     })
    //   }
    //   if (obj === 'mandrill') {
    //     Object.entries(objvalue).forEach(([key, value]) => {
    //       if (key === 'secretKey') {
    //         if (value.value === '') {
    //           config.mandrill.secretKey.hasError = true;
    //           errorsArePresent = true;
    //         }
    //       } else if (key === 'subAccount') {
    //         if (value.value === '') {
    //           config.mandrill.subAccount.hasError = true;
    //           errorsArePresent = true;
    //         }
    //       }
    //     })
    //   }
    //   if (obj === 'lob') {
    //     Object.entries(objvalue).forEach(([key, value]) => {
    //       if (key === 'secretKey') {
    //         if (value.value === '') {
    //           config.lob.secretKey.hasError = true;
    //           errorsArePresent = true;
    //         }
    //       }
    //     })
    //   }
    // })

    // if (errorsArePresent) {
    //   this.setState(state => ({
    //     ...state,
    //     config: config
    //   }))

    //   return false;
    // } else {
    //   return true;
    // }
    return true
  }

  validateForm = () => {
    let errorsArePresent = false;

    // Determine which input group to check for errors
    let input = { ...this.state.input }


    // Check input for errors
    Object.entries(input).forEach(([key, value]) => {
      if (key === 'companyName') {
        if (value.value === '') {
          input[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === 'adminEmail' || key === 'companyEmail') {
        if (!validateEmail(value.value)) {
          input[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === 'url') {
        if (value.value === '') {
          input[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === 'companyAddress') {
        if (value.value === '') {
          input[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === 'first_name' || key === 'last_name') {
        if (value.value === '') {
          input[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === 'contactNo') {
        if (value.value === '' || (value.value && value.value.length < 14)) {
          input[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === 'logo') {
        if (value.value === '') {
          input[key].hasError = true;
          errorsArePresent = true;
        }
      }
    });

    if (errorsArePresent) {
      this.setState(state => ({
        ...state,
        input: input
      }))

      return false;
    } else {
      return true;
    }

  }

  toggleColorPicker = () => {
    this.setState({ showColorPicker: !this.state.showColorPicker })
  }

  handleChangeComplete = (color) => {
    this.state.input.theme.value = color.hex
    this.setState({ input: this.state.input });
  };

  closeOnClickOutside = () => {
    this.setState({ showColorPicker: false })
  }

  renderErrorMessage(input) {
    let message = '';

    if (input === 'companyName') {
      message = "Please enter organization name";
    } else if (input === 'url') {
      message = 'Please enter valid url';
    } else if (input === 'adminEmail') {
      message = 'Please enter valid email';
    } else if (input === 'companyEmail') {
      message = 'Please enter valid email';
    } else if (input === 'companyAddress') {
      message = 'Please enter organization address';
    } else if (input === 'name') {
      message = 'Please enter admin name';
    } else if (input === 'contactNo') {
      message = 'Please enter valid phone number';
    } else if (input === 'appId') {
      message = "Please enter appId";
    } else if (input === 'token') {
      message = "Please enter token";
    } else if (input === 'secretKey') {
      message = "Please enter secret key";
    } else if (input === 'subAccount') {
      message = "Please enter sub account";
    } else {
      message = 'Please complete this field';
    }

    return (
      <div className="input-error-message">
        {message}
      </div>
    )
  }

  handleModalClose = () => {
    this.props.hideModal();
  }

  render() {
    let { input, config, error, showColorPicker, slide, disableSubmit } = this.state;
    let styels = { marginRight: `10px`, maxWidth: `30rem`, marginBottom: 0 }

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    return (
      <Modal
        optionalClasses=" add-account-modal scroll"
        title="Add Organization"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
      >
        <form
          className="admin-form"
          // onSubmit={(event) => { this.handleSubmit(event) }}
        >
          <header className="modal-slide-header">
            <div className={`modal-slide-header-tab${slide === 1 ? ' active' : ''}`}>
              Organization's Details
            </div>
            <div className={`modal-slide-header-tab${slide === 2 ? ' active' : ''}`}>
              Configuration Details
            </div>
          </header>
          {this.state.showFlash ? (
            <FlashMessage
              message={this.state.flashMessage}
              handleFlashDismiss={this.dismissFlash}
            />
          ) : null}
          <div className={`modal-slide${slide === 1 ? '' : ' hide'}`}>
            <div className="form-container">
              <div className="form-column">
                <div
                  className={`input-container${input.companyName.hasError ? ' error' : ''}`}
                >
                  <label>Organization Name</label>
                  <input
                    name="companyName"
                    placeholder="Organization Name"
                    type="text"
                    value={input.companyName.value}
                    onChange={event => this.handleInputChange(
                      { companyName: { value: event.target.value, hasError: false } }
                    )}
                  />
                  {input.companyName.hasError ? this.renderErrorMessage('companyName') : null}
                </div>
                <div className={`input-container${input.url.hasError ? ' error' : ''}`} >
                  <label >Organization Url</label>
                  <input
                    name="url"
                    placeholder="https://deb-staging-550a5.firebaseapp.com"
                    type="url"
                    value={input.url.value}
                    onChange={event => this.handleInputChange(
                      { url: { value: event.target.value, hasError: false } }
                    )}
                  />
                  {input.url.hasError ? this.renderErrorMessage('url') : null}
                </div>
                <div style={{ marginBottom: '1.75rem', marginTop: '-0.5rem' }}>
                  <div className="address-document" >
                    <label>Upload Logo</label>
                    <div className={`input-container error`}>
                      <FileBase64 multiple={false} onDone={this.getFiles.bind(this)} />
                      <div className=" input-error-message">Logo upload format (.png), Size must be less than 1 MB.<br /> </div>
                    </div>
                  </div>
                </div>
                <div className={`input-container${input.companyAddress.hasError ? ' error' : ''}`} >
                  <label >Organization Address</label>
                  <textarea
                    name="companyAddress"
                    type="textarea"
                    placeholder="Organization Address"
                    rows={2}
                    value={input.companyAddress.value}
                    onChange={event => this.handleInputChange(
                      { companyAddress: { value: event.target.value, hasError: false } }
                    )}
                    required />
                  {input.companyAddress.hasError ? this.renderErrorMessage('companyAddress') : null}
                </div>

              </div>
              <div className="form-column">
                <div className={`input-container ${input.companyEmail.hasError ? ' error' : ''}`}>
                  <label htmlFor="companyemail">Email Address</label>
                  <input
                    name="companyemail"
                    placeholder="no-reply@deb.com"
                    type="email"
                    value={input.companyEmail.value}
                    onChange={event => this.handleInputChange({ companyEmail: { value: event.target.value, hasError: false } })}
                  />
                  {input.companyEmail.hasError ? this.renderErrorMessage('companyEmail') : null}
                </div>
                <div className={`input-container ${input.contactNo.hasError ? ' error' : ''}`}>
                  <label htmlFor="contactNo">Phone Number </label>
                  <MaskedTextInput
                    name="phone"
                    mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    placeholder="(555) 555-5555"
                    placeholderChar={'\u2000'}
                    guide={true}
                    type="text"
                    value={input.contactNo.value}
                    onChange={event => this.handleInputChange({ contactNo: { value: event.target.value, hasError: false } })}
                  />
                  {input.contactNo.hasError ? this.renderErrorMessage('contactNo') : null}
                </div>
                <div style={{ marginBottom: '1.4rem' }}>
                  <label >Organization Theme</label>
                  <div style={{ flexDirection: 'row', display: 'flex', marginTop: 8 }}>
                    <a title='Click here' className='color-picker-btn' onClick={() => this.toggleColorPicker()}>
                      <div className='btn-label'>
                        {showColorPicker ? 'Set' : 'Select Theme'}
                      </div>
                    </a>
                    <div className='color-selected-value'>
                      <div className="color-box" style={{ backgroundColor: input.theme.value }} />
                      {input.theme.value}
                    </div>
                  </div>
                  {showColorPicker && <SketchPicker
                    color={this.state.input.theme.value}
                    onChangeComplete={this.handleChangeComplete}
                    closeOnClickOutside={this.closeOnClickOutside}
                  />}
                  {input.adminEmail.hasError ? this.renderErrorMessage('adminEmail') : null}
                </div>
                <div
                  className={`input-container${input.first_name.hasError || input.last_name.hasError ? ' error' : ''}`}
                >
                  <label htmlFor="name">Admin's Name</label>
                  <div
                    className="input-container multi-input-field"
                    style={{ flex: 1, display: "flex", marginBottom: 0 }}
                  >
                    <input
                      className="multi-input-field-input"
                      name="name"
                      type="text"
                      placeholder="First Name"
                      value={input.first_name.value}
                      onChange={event => this.handleInputChange({ first_name: { value: event.target.value, hasError: false } })}
                    />
                    <input
                      className="multi-input-field-input"
                      name="name"
                      type="text"
                      placeholder="Last Name"
                      value={input.last_name.value}
                      onChange={event => this.handleInputChange({ last_name: { value: event.target.value, hasError: false } })}
                    />
                  </div>
                  {input.first_name.hasError || input.last_name.hasError ? this.renderErrorMessage('name') : null}
                </div>
                <div className={`input-container${input.adminEmail.hasError ? ' error' : ''}`} >
                  <label >Admin's Email Address</label>
                  <input
                    name="adminEmail"
                    type="email"
                    placeholder="admin@adminemail.com"
                    value={input.adminEmail.value}
                    onChange={event => this.handleInputChange(
                      { adminEmail: { value: event.target.value, hasError: false } }
                    )}
                  />
                  {input.adminEmail.hasError ? this.renderErrorMessage('adminEmail') : null}
                </div>
              </div>
            </div>
            <div id="create_org_next" className="modal-actions" style={{marginBottom: 15}}>
              <a
                className="cta mg-brand2-color"
                onClick={this.toNextSlide}
              >
                Next »
              </a>
              <a
                className="modal-link"
                onClick={this.props.hideModal}
              >
                Cancel
              </a>
            </div>
          </div>

          <div className={`modal-slide${slide === 2 ? '' : ' hide'}`}>
          <label>Integrated Accounting System</label>
            <Panel>
              <div className="form-container" style={{ marginBottom: 10 }}>
                <div className="form-column">
                  <div 
                    style={{display: 'flex'}}
                    className={`input-container`}>
                    <label htmlFor="qbConnected">QuickBook Connected Account</label>
                    <input
                      name="qbConnected"
                      id="qbConnected"
                      type="radio"
                      style={{appearance: 'radio', minHeight: '1em'}}
                      value={config.accountPref.qbConnected.value}
                      checked={config.accountPref.qbConnected.value}
                      onChange={event => this.handleAccountPrefInputChange('qbConnected', { value: event.target.value, hasError: false })}
                    />
                  </div>
                </div>
                <div className="form-column">
                  <div 
                    style={{display: 'flex'}}
                    className={`input-container`}>
                    <label htmlFor="standAlone">Stand Alone Account</label>
                    <input
                      name="standAlone"
                      id="standAlone"
                      type="radio"
                      style={{appearance: 'radio', minHeight: '1em'}}
                      value={config.accountPref.standAlone.value}
                      checked={config.accountPref.standAlone.value}
                      onChange={event => this.handleAccountPrefInputChange('standAlone', { value: event.target.value, hasError: false })}
                    />
                  </div>
                </div>
              </div>
            </Panel>
            <label>Twillio</label>
            <Panel>
              <div >
                <div className="form-container">
                  <div className="form-column">
                    <div className={`input-container ${config.twillio.appId.hasError ? ' error' : ''}`}>
                      <label htmlFor="appid">AppId</label>
                      <input
                        name="appid"
                        placeholder="Enter AppId"
                        type="text"
                        value={config.twillio.appId.value}
                        onChange={event => this.handleTwillioInputChange('appId', { value: event.target.value, hasError: false })}
                      />
                      {config.twillio.appId.hasError ? this.renderErrorMessage('appId') : null}
                    </div>
                  </div>
                  <div className="form-column">
                    <div className={`input-container ${config.twillio.contactNo.hasError ? ' error' : ''}`}>
                      <label htmlFor="contactNo">Phone Number</label>
                      <MaskedTextInput
                        name="phone"
                        mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        placeholder="(555) 555-5555"
                        placeholderChar={'\u2000'}
                        guide={true}
                        type="text"
                        value={config.twillio.contactNo.value}
                        onChange={event => this.handleTwillioInputChange('contactNo', { value: event.target.value, hasError: false })}
                      />
                      {config.twillio.contactNo.hasError ? this.renderErrorMessage('contactNo') : null}
                    </div>
                  </div>
                </div>
                <div className="form-column">
                  <div style={{ marginBottom: 10 }} className={`input-container ${config.twillio.secretKey.hasError ? ' error' : ''}`}>
                    <label htmlFor="secretKey">Secret Key</label>
                    <input
                      name="secretKey"
                      placeholder="Enter Secret Key"
                      type="text"
                      value={config.twillio.secretKey.value}
                      onChange={event => this.handleTwillioInputChange('secretKey', { value: event.target.value, hasError: false })}
                    />
                    {config.twillio.secretKey.hasError ? this.renderErrorMessage('secretKey') : null}
                  </div>
                </div>
              </div>
            </Panel>
            <label>Intercom</label>
            <Panel>
              <div className="form-container" style={{ marginBottom: 10 }}>
                <div className="form-column">
                  <div className={`input-container ${config.intercom.appId.hasError ? ' error' : ''}`}>
                    <label htmlFor="appid">AppId</label>
                    <input
                      name="appid"
                      placeholder="Enter AppId"
                      type="text"
                      value={config.intercom.appId.value}
                      onChange={event => this.handleIntercomInputChange('appId', { value: event.target.value, hasError: false })}
                    />
                    {config.intercom.appId.hasError ? this.renderErrorMessage('appId') : null}
                  </div>
                </div>
                <div className="form-column">
                  <div className={`input-container ${config.intercom.token.hasError ? ' error' : ''}`}>
                    <label htmlFor="token">Token</label>
                    <input
                      name="token"
                      placeholder="Enter Token"
                      type="text"
                      value={config.intercom.token.value}
                      onChange={event => this.handleIntercomInputChange('token', { value: event.target.value, hasError: false })}
                    />
                    {config.intercom.token.hasError ? this.renderErrorMessage('token') : null}
                  </div>
                </div>
              </div>
            </Panel>
            <label>Stripe</label>
            <Panel>
              <div className="form-container" style={{ marginBottom: 10 }}>
                <div className="form-column">
                  <div className={`input-container ${config.stripe.secretKey.hasError ? ' error' : ''}`}>
                    <label htmlFor="secretKey">Secret Key</label>
                    <input
                      name="secretKey"
                      placeholder="Enter Secret Key"
                      type="text"
                      value={config.stripe.secretKey.value}
                      onChange={event => this.handleStripeInputChange('secretKey', { value: event.target.value, hasError: false })}
                    />
                    {config.stripe.secretKey.hasError ? this.renderErrorMessage('secretKey') : null}
                  </div>
                </div>
                <div className="form-column">
                  <div className={`input-container ${config.stripe.token.hasError ? ' error' : ''}`}>
                    <label htmlFor="token">Token</label>
                    <input
                      name="token"
                      placeholder="Enter Token"
                      type="text"
                      value={config.stripe.token.value}
                      onChange={event => this.handleStripeInputChange('token', { value: event.target.value, hasError: false })}
                    />
                    {config.stripe.token.hasError ? this.renderErrorMessage('token') : null}
                  </div>
                </div>
              </div>
            </Panel>
            <label>Mandrill</label>
            <Panel>
              <div className="form-container" style={{ marginBottom: 10 }}>
                <div className="form-column">
                  <div className={`input-container ${config.mandrill.secretKey.hasError ? ' error' : ''}`}>
                    <label htmlFor="secretKey">Secret Key</label>
                    <input
                      name="secretKey"
                      placeholder="Enter Secret Key"
                      type="text"
                      value={config.mandrill.secretKey.value}
                      onChange={event => this.handleMandrillInputChange('secretKey', { value: event.target.value, hasError: false })}
                    />
                    {config.mandrill.secretKey.hasError ? this.renderErrorMessage('secretKey') : null}
                  </div>
                </div>
                <div className="form-column">
                  <div className={`input-container ${config.mandrill.subAccount.hasError ? ' error' : ''}`}>
                    <label htmlFor="subAccount">Sub Account</label>
                    <input
                      name="subAccount"
                      placeholder="Enter Sub Account"
                      type="text"
                      value={config.mandrill.subAccount.value}
                      onChange={event => this.handleMandrillInputChange('subAccount', { value: event.target.value, hasError: false })}
                    />
                    {config.mandrill.subAccount.hasError ? this.renderErrorMessage('subAccount') : null}
                  </div>
                </div>
              </div>
            </Panel>
            <label>Lob</label>
            <Panel>
              <div className="form-container" style={{ marginBottom: 0 }}>
                  <div style={{ marginBottom: 10 }} className={`input-container ${config.lob.secretKey.hasError ? ' error' : ''}`}>
                    <label htmlFor="secretKey">Secret Key</label>
                    <input
                      name="secretKey"
                      placeholder="Enter Secret Key"
                      type="text"
                      value={config.lob.secretKey.value}
                      onChange={event => this.handleLobInputChange('secretKey', { value: event.target.value, hasError: false })}
                    />
                    {config.lob.secretKey.hasError ? this.renderErrorMessage('secretKey') : null}
                  </div>
              </div>
            </Panel>
            <div id="create-org" className="modal-actions" style={{marginBottom: 15}}>
              <a
                className={`cta mg-brand2-color${disableSubmit ? " disable" : ""}`}
                onClick={(event) => disableSubmit ? "" : this.handleSubmit(event)}
              >
                Add Organization »
              </a>
              <a
                className="modal-link"
                onClick={this.toPreviousSlide}
              >
                « Back
              </a>
            </div>
          </div>
        </form>
      </Modal>
    )
  }
}

export default withRouter(CreateOrganization);
