import React, { Component } from "react";
import ReactGA from "react-ga";
import Heap from "react-heap";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { instanceOf } from "prop-types";
import { Cookies, withCookies } from "react-cookie";
import Logo from "../../components/Logo";
import LoginForm from "../../components/Sessions/LoginForm";
// Components
import SessionsModal from "../../components/Sessions/SessionsModal";
import * as API from "../../utils/api";
import { getZohoPagesense } from "../../utils/helpers";

class Login extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isAppConfig: false,
    };
  }

  componentWillMount() {
    this.isThirdPartyAppConfigured();
    getZohoPagesense();
    ReactGA.pageview("/Login");
  }

  componentDidMount() {
    const { cookies } = this.props;
    cookies.remove("authUser", { path: "/", secure: true });
    cookies.remove("isLoggedIn", { path: "/", secure: true });
    cookies.remove("accessToken", { path: "/", secure: true }); 
    cookies.remove("refreshToken", { path: "/", secure: true });
  }

  isThirdPartyAppConfigured = () => {
    API.isThirdPartyAppConfigured().then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.errors) {
        if (data.isAppConfig) {
          this.setState((state) => ({
            ...state,
            isAppConfig: data.isAppConfig,
          }));
          return true;
        }
      }
    });
  };

  render() {
    const { isLoggedIn, user, location, heapEmail, heapAppId } = this.props;

    // heap 1119463531
    if (isLoggedIn && !user.verified) {
      return (
        <Redirect
          to={{
            pathname: "/confirm-account/phone",
            state: { from: location },
          }}
        />
      );
    } else if (isLoggedIn && user.super_admin) {
      return (
        <Redirect
          to={{
            pathname: "/super-admin/accounts",
            state: { from: location },
          }}
        />
      );
    } else if (isLoggedIn && user.admin) {
      return (
        <Redirect
          to={{
            pathname: "/admin/accounts",
            state: { from: location },
          }}
        />
      );
    } else if (user.onboarding_complete) {
      if (
        isLoggedIn &&
        !user.admin &&
        !user.super_admin &&
        !user.client_admin &&
        !user.is_employee
      ) {
        return (
          <Redirect
            to={{
              pathname: "/dashboard/accounts",
              state: { from: location },
            }}
          />
        );
      } else if ((user.client_admin || user.is_employee) && !user.verified_at) {
        return (
          <Redirect
            to={{
              pathname: `/users/${user.user_id}/set_password`,
              state: { from: location },
            }}
          />
        );
      } else if (isLoggedIn && user.client_admin) {
        return (
          <Redirect
            to={{
              pathname: "/client/dashboard",
              state: { from: location },
            }}
          />
        );
      } else if (isLoggedIn && user.is_employee) {
        return (
          <Redirect
            to={{
              pathname: "/employee/accounts",
              state: { from: location },
            }}
          />
        );
      }
    } else if (user.onboarding_complete === false) {
      return (
        <Redirect
          to={{
            pathname: "/onboarding/user-agreement",
            state: { from: location },
          }}
        />
      );
    } else if (isLoggedIn && user.client_admin) {
      return (
        <Redirect
          to={{
            pathname: "/client/dashboard",
            state: { from: location },
          }}
        />
      );
    } else if (isLoggedIn && user.is_employee) {
      return (
        <Redirect
          to={{
            pathname: "/employee/accounts",
            state: { from: location },
          }}
        />
      );
    }

    let { appName } = this.props;

    return (
      <div className="sessions fill-screen mg-session">
        <Helmet>
          <title>{appName} | Login</title>
        </Helmet>
        {heapAppId && heapEmail ? (
          <Heap appId={heapAppId} userId={heapEmail} />
        ) : null}

        <SessionsModal title="Sign into your account">
          <div className="sessions-modal-logo-container mg-logo-container">
            <Logo circular={true} />
          </div>
          <div className="sessions-modal-content">
            <h1 className="sessions-modal-heading" style={{ color: "#008bb2" }}>
              Sign into your account
            </h1>
            <LoginForm
              updateUserLogin={this.props.updateUserLogin}
              updateModalState={this.props.updateModalState}
            />
            <div className="sessions-modal-alternative-actions">
              <span>Don't have an account?</span>
              <Link to="/register" className="text-link mg-text-link">
                Sign Up Now
              </Link>
            </div>
          </div>
        </SessionsModal>
      </div>
    );
  }
}

export default withCookies(Login);
