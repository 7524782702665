import { loadStripe } from "@stripe/stripe-js";
import currencyFormatter from "currency-formatter";
import moment from "moment";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import shortid from "shortid";
import downloadImg from "../../assets/icons/download.png";
import viewIcon from "../../assets/icons/view.png";
import CardView from "../../components/CardView/CardView";
import ClientsLogo from "../../components/Consumer/ClientsLogo";
import ConsumerSidebar from "../../components/Consumer/ConsumerSidebar";
import InvoiceAndLineDetails from "../../components/ObjectList/ClientsAccounts/InvoiceAndLineDetails";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import * as API from "../../utils/api";
import ExpressHeader from "./ExpressHeader";
import ExpressPayment from "./ExpressPayment";

class InvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceDetails: props.invoiceDetails,
      stripeSessionId: "",
      expandDoc: false,
      makePayment: false,
      amount: {
        value: "",
        hasError: false,
      },
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.invoiceDetails != nextProps.invoiceDetails) {
      return {
        invoiceDetails: nextProps.invoiceDetails,
      };
    }
    return null;
  }

  handleClick = async (stripeSessionId) => {
    // Get Stripe.js instance
    const stripePromise = loadStripe(this.props.stripePubKey);
    const stripe = await stripePromise;

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: stripeSessionId,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  checkInvoicePastDue = (date) => {
    return moment(date).isBefore(new Date());
  };

  getAccountType = () => {
    const { invoiceDetails } = this.state;
    let flag = this.checkInvoicePastDue(invoiceDetails.due_date);
    if (flag) {
      return "past due";
    } else {
      return "current";
    }
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "amount") {
      message = "Please enter a valid amount";
    } else {
      message = "Please complete this field";
    }
    return <div className="input-error-message">{message}</div>;
  }

  validateForm = () => {
    let errorsArePresent = false;
    const { amount } = this.state;
    if (amount.value === "" || amount.value === null || amount.value < 0) {
      amount.hasError = true;
      errorsArePresent = true;
    }
    if (errorsArePresent) {
      this.setState({
        amount: this.state.amount,
      });
      return false;
    } else {
      return true;
    }
  };

  handleInputBlur = () => {
    let { amount } = this.state;
    if (amount.value) {
      amount.value = parseFloat(amount.value).toFixed(2);
      this.setState({ amount: this.state.amount });
    }
  };

  handleInputChange(newPartialInput) {
    if (!newPartialInput.amount.value) {
      this.setState((state) => ({
        ...state,
        ...newPartialInput,
      }));
    } else {
      var number = newPartialInput.amount.value.split(".");
      if (number && number.length > 1) {
        if (number[1].length > 2) {
          newPartialInput.amount.value = parseFloat(
            newPartialInput.amount.value
          ).toFixed(2);
        }
      }
      if (
        parseInt(newPartialInput.amount.value) <=
        parseInt(this.state.invoiceDetails.balance)
      ) {
        this.setState((state) => ({
          ...state,
          ...newPartialInput,
        }));
      }
    }
  }

  viewValidationDoc = (row) => {
    let props = {
      isOpenApi: true,
      type: row.document_name,
      arrange_pdf: true,
      doc_name: row.name,
      doc_id: row.document_id,
      invoiceId: row.invoice_id,
    };
    this.props.updateModalState(true, "SHOW_IMAGE", props);
  };

  downloadAttachedDocuement = (doc_id, fileName) => {
    API.getExpressAttachedDocuement(doc_id).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let flag = data.data.includes("base64");
          if (!flag) {
            data.data = "data:application/pdf;base64," + data.data;
          }
          let url = data.data;
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      }
    });
  };

  documentAction = (row) => {
    let imgExtension = row.document_name && row.document_name.split(".").pop();
    let fileSizeCheck =
      row.file_size_in_mb && row.file_size_in_mb > 3 ? true : false;
    let flag =
      imgExtension.toLowerCase() === "xls" ||
      imgExtension.toLowerCase() === "xlsx" ||
      imgExtension.toLowerCase() === "doc" ||
      imgExtension.toLowerCase() === "docx"
        ? false
        : true;
    flag = fileSizeCheck ? false : flag;

    return (
      <div>
        <a
          className="make-payment-button"
          style={{ cursor: "pointer", marginRight: 30 }}
          title="Download"
          onClick={() =>
            this.downloadAttachedDocuement(row.document_id, row.document_name)
          }
        >
          <img src={downloadImg} width="16" height="16" />
        </a>
        {flag && (
          <button
            title="View"
            style={{
              background: "transparent",
              cursor: "pointer",
            }}
            onClick={() => this.viewValidationDoc(row)}
          >
            <img src={viewIcon} width="20" height="20" />
          </button>
        )}
      </div>
    );
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];
    headings = ["Name", "Description", "Type", "Action"];

    columns = data.map((row) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Name",
            title: row.name,
          },
          {
            key: "Description",
            title: row.description,
          },
          {
            key: "Type",
            title: row.document_type ? row.document_type.name : "",
          },
          {
            key: "Action",
            title: this.documentAction(row),
          },
        ],
      };
    });

    return {
      headings,
      columns,
    };
  };

  renderDocuments = (data) => {
    let { expandDoc } = this.state;
    let count = data && data.length ? data.length : 0;
    return (
      <div>
        <div className="ic-wt-fields">
          <div className="he-hed he-sec-hed">Documents ({count})</div>
          <a
            key={shortid.generate()}
            className={`client-caret expander-caret consumer-brand ${
              expandDoc ? " down-caret" : ""
            }`}
            style={{ margin: "12px 0px 0px 12px" }}
            onClick={(event) => this.setState({ expandDoc: !expandDoc })}
          />
        </div>
        {expandDoc && (
          <div>
            {data && data.length ? (
              <div>
                <CardView data={this.getObjectListData(data)} />
                <InvoiceObjectList
                  data={this.getObjectListData(data)}
                  hideForMobile={true}
                  optionalTableClasses="invoice-doc"
                  fromConsumer={true}
                />
              </div>
            ) : (
              this.renderEmpty("No documents available")
            )}
          </div>
        )}
      </div>
    );
  };

  renderEmpty = (msg) => {
    return (
      <div
        className="admin-admins-empty-state"
        style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
      >
        <p>{msg}</p>
      </div>
    );
  };

  toggleMakePayment = (flag) => {
    this.setState({ makePayment: flag });
  };

  sidebarContent = () => {
    const { showPaymentScreen, invoiceDetails, amount } = this.state;
    return (
      <div className="consumer-sidebar">
        <div style={{ fontSize: 18, fontWeight: "bold", marginBottom: "1em" }}>
          Welcome,{" "}
          {invoiceDetails
            ? invoiceDetails.first_name + " " + invoiceDetails.last_name
            : null}
          !
        </div>
        <div
          className="sidebar-primary-text"
          style={{ marginBottom: "2em", display: "flex" }}
        >
          This invoice is {this.getAccountType()}
        </div>
        <label style={{ marginBottom: "0.3em" }}>Balance Due</label>
        <div
          className="invoice-amt-owed payment-balance"
          style={{ marginBottom: "1em" }}
        >
          {invoiceDetails.balance
            ? currencyFormatter.format(invoiceDetails.balance, { code: "USD" })
            : "0.00"}
        </div>
      </div>
    );
  };

  render() {
    const { appName, stripePubKey } = this.props;
    const { error, invoiceDetails, makePayment } = this.state;
    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <div>
        <Helmet>
          <title>
            {appName} | Invoice# {invoiceDetails.invoice_number}
          </title>
        </Helmet>
        <ExpressHeader appName={appName} />
        <div className="scrollbar exp-pay-scroll">
          {makePayment ? (
            <ExpressPayment
              stripePubKey={stripePubKey}
              invoiceDetails={invoiceDetails}
              updateAmount={this.props.updateAmount}
              toggleMakePayment={this.toggleMakePayment}
              updateModalState={this.props.updateModalState}
            />
          ) : (
            <div className="app-sidebar-layout-canvas">
              <ConsumerSidebar
                sidebarContent={this.sidebarContent()}
                exPay={true}
              />
              <div className="app-sidebar-layout-canvas-content setting-side-canvas big-device-grad scrollbar exp-pay-scroll-inner">
                <ClientsLogo color={"#000"} />
                <div className="header-make-payment">
                  <h1 className="app-section-consumer-heading consumer-brand">
                    Invoice# {invoiceDetails.invoice_number}
                  </h1>
                  <button
                    className="cta make-payment-btn mg-brand2-color"
                    onClick={() => this.toggleMakePayment(true)}
                  >
                    Make Payment
                  </button>
                </div>
                <InvoiceAndLineDetails invoiceDetails={invoiceDetails} />
                {this.renderDocuments(invoiceDetails.document)}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default InvoiceDetails;
