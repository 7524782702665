import React, { Component } from "react";
import { getTimeToDisplay } from "../../utils/helpers";
import Panel from "../../components/App/Panel";

class ClientsSettingSidebar extends Component {
  renderPaymentContent = () => {
    let { connectStripeData } = this.props;
    let status = "";
    let color = "red";
    if (connectStripeData && connectStripeData.stripe_connect_status) {
      status = connectStripeData.stripe_connect_status;
      if (connectStripeData.stripe_connect_status == "ACCEPTING PAYMENTS") {
        color = "green";
      } else if (
        connectStripeData.stripe_connect_status == "PENDING APPROVAL"
      ) {
        color = "";
      } else {
        color = "red";
      }
    }
    return (
      <div className="side-wd">
        <Panel>
          <div className="set-col-con">
            <h4
              className="panel-heading-secondary"
              style={{
                width: "auto",
                marginRight: 5,
                marginBottom: "0.075rem",
              }}
            >
              Status:
            </h4>
            <p
              style={
                color == "green"
                  ? {
                      fontSize: 14,
                      color: "#409D57",
                      fontFamily: "sofia_pro_bold",
                    }
                  : color == "red"
                  ? {
                      fontSize: 14,
                      fontFamily: "sofia_pro_bold",
                      color: "#fb5c82",
                      lineHeight: "120%",
                    }
                  : {
                      fontSize: 14,
                      fontFamily: "sofia_pro_bold",
                      color: "#000000",
                    }
              }
            >
              {status}
            </p>
          </div>
        </Panel>
        {color != "green" && (
          <Panel>
            <p className="qb-p">
              Enable your customized payment portal and start accepting payments
              from your customers today. Click the Enable Payments button to get
              started. Contact us with any questions.
            </p>
          </Panel>
        )}
        {color == "green" ? (
          <div className="history-btn">
            <button
              onClick={this.props.paymentDashboardClick}
              style={{ marginLeft: 0, maxWidth: "14rem" }}
            >
              Payments Dashboard
            </button>
          </div>
        ) : null}
      </div>
    );
  };

  renderQBContent = () => {
    const { isQBConnected, lastSyncDate, isEditPref } = this.props;
    return (
      <div className="side-wd">
        <Panel>
          <p className="qb-p">
            Import your QuickBooks Online account data, including Customer data,
            Invoice data and Payment data. Apply payments to your invoices made
            via your custom branded MakeGood payment processing account.
          </p>
        </Panel>
        <Panel>
          <div className="set-col-con">
            <h4 className="panel-heading-secondary set-col-con-key">Status:</h4>
            <p
              style={
                isQBConnected
                  ? { color: "#409D57", fontFamily: "sofia_pro_bold" }
                  : { color: "#fb5c82", fontFamily: "sofia_pro_bold" }
              }
            >
              {isQBConnected ? "CONNECTED" : "NOT CONNECTED"}
            </p>
          </div>
          <div className="set-col-con">
            <h4 className="panel-heading-secondary set-col-con-key">
              Last Sync:
            </h4>
            <p className="side-bar-value">
              {lastSyncDate ? getTimeToDisplay(lastSyncDate) : "NEVER"}
            </p>
          </div>
          <div className="set-col-con">
            <h4 className="panel-heading-secondary set-col-con-key">
              Next Sync:
            </h4>
            <p className="side-bar-value">{"01:00 AM EST"}</p>
          </div>
        </Panel>
        {!isEditPref && isQBConnected && (
          <div className="history-btn">
            <button
              onClick={(event) => {
                this.props.viewpreferences(true);
              }}
              style={{ marginLeft: 0 }}
            >
              Edit Preferences
            </button>
          </div>
        )}
        {isEditPref && isQBConnected && (
          <div className="history-btn qb-side-btns">
            <button
              onClick={(event) => {
                this.props.viewpreferences(false);
              }}
            >
              Sync
            </button>
            <button onClick={(event) => this.props.disconnectQB(event)}>
              Disconnect
            </button>
          </div>
        )}
      </div>
    );
  };

  renderGeneralContent = () => {
    return (
      <div className="side-wd">
        <Panel>
          <p className="qb-p">
            Use this page to configure your task settings, including whether to
            automatically or manually send the communication, when each task
            should trigger, how the communication will be sent, which clients
            the tasks apply to and an option to send the communications to an
            internal contact, such as an account manager.
          </p>
        </Panel>
      </div>
    );
  };

  render() {
    const { title, activeTab, utilities, optionalClassNames } = this.props;

    return (
      <div className="app-sidebar app-sidebar-left accounts-view-sidebar clients-side-bar col1-scroll scrollbar">
        <div
          className={`app-section-header combine-header client-section-header${
            optionalClassNames ? " " + optionalClassNames : ""
          }`}
        >
          <div
            className={`app-section-header-content`}
            style={{ marginRight: "2rem" }}
          >
            <h1 className="app-section-header-heading side-hed-col">{title}</h1>
          </div>
          <div className="tab-utility">{utilities}</div>
        </div>
        {activeTab === 3
          ? this.renderPaymentContent()
          : activeTab === 2
          ? this.renderQBContent()
          : activeTab === 1
          ? this.renderGeneralContent()
          : ""}
      </div>
    );
  }
}

export default ClientsSettingSidebar;
