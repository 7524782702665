import React, { Component } from 'react'
import { Helmet } from "react-helmet"
import { Redirect } from "react-router-dom"
import ReactGA from 'react-ga';

// API
import * as API from '../../utils/api'

import { networkMessage, showToast, getZohoPagesense } from '../../utils/helpers'

// Components
import AppCanvasLoader from '../../components/App/AppCanvasLoader'
import ClientsAddAdminForm from '../../components/Clients/Forms/ClientsAddAdminForm'
import AppSectionHeader from '../../components/App/AppSectionHeader'
import ObjectList from '../../components/ObjectList/ObjectList'
import ConfirmClientsDeletionModal from '../../components/App/Modals/Admin/ConfirmAdminDeletionModal'
import FlashMessage from '../../components/FlashMessage'

class ClientsAdmins extends Component {
  state = {
    addClientsFormIsVisible: false,
    clients: [],
    isLoading: true,
    input: {
      search: {
        value: ""
      }
    },
    showClientsDeletionModal: false,
    clientsToDelete: '',
    showFlash: false,
    error: {
      hasAnyError: false,
      statusCode: 200
    }
  }

  componentDidMount() {
    this.getClients();
    getZohoPagesense();
    ReactGA.pageview('/ClientsAdmins');
  }

  getClients = () => {
    API.fetchAllClients(this.props.user).then(data => {
      if (((typeof data != 'undefined') || (data != null)) && (!data.message)) {
        this.setState(state => ({
          ...state,
          clients: data.users,
          defaultClients: data.users,
          isLoading: false
        }))
      } else {
        this.setState(state => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          }
        }))
      }
    })
  }

  handleInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...newPartialInput
      }
    }),
      () => { this.handleSearch() }
    )
  }

  setClientsData = (dataSet = []) => {
    this.setState(state => ({
      ...state,
      clients: dataSet
    }))
  }

  handleSearch = () => {
    let queryString = this.state.input.search.value
    if (queryString) {
      API.clientsSearch(this.props.user, 'clients', queryString).then(data => {
        if (((typeof data != 'undefined') || (data != null)) && (data.results)) {
          this.setClientsData(data.results)
        } else {
          this.setClientsData()
        }
      })
    } else {
      this.setClientsData(this.state.defaultClients)
    }
  }

  handleAddClientsClick = () => {
    this.setState(state => ({
      ...state,
      addClientsFormIsVisible: true
    }))
  }

  handleAddClientsFormCancelation = () => {
    this.setState(state => ({
      ...state,
      addClientsFormIsVisible: false
    }))
  }

  handleRowActionClick = (event) => {
    const actionBehavior = event.target.getAttribute('data-behavior')
    const clientsId = event.target.getAttribute('data-target')

    switch (actionBehavior) {
      case "clients-delete":
        this.setClientsToDelete(clientsId)
        this.toggleClientsDeletionModal()
        break
      default:
        alert('Error: Something went wrong')
    }
  }

  setClientsToDelete = (clientsId) => {
    let selectedClients = this.getClients(clientsId)

    this.setState(state => ({
      ...state,
      clientsToDelete: selectedClients
    }))
  }

  toggleClientsDeletionModal = () => {
    const { showClientsDeletionModal } = this.state

    this.setState(state => ({
      ...state,
      showClientsDeletionModal: !showClientsDeletionModal,
    }))
  }

  handleModalSubmission = (clientsId) => {
    this.toggleClientsDeletionModal()
    this.deactivateClients(clientsId)
  }

  deactivateClients = (id) => {
    let clients = [...this.state.clients]
    let targetId = parseInt(id, 10)
    let newClientsState = clients.filter((clients) => {
      return clients.id !== targetId
    })

    if (newClientsState.length < clients.length) {
      API.deactivateClients(this.props.user, id).then(data => {
        if (((typeof data != 'undefined') || (data != null)) && !data.error) {
          this.componentDidMount()
          this.setState(state => ({
            ...state,
            showFlash: true,
            flashMessage: `${data.message}`
          }))
        } else {
          this.setState(state => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
            }
          }))
        }
      })
    }
  }

  getClients = (clientsId) => {
    let { clients } = this.state

    let selectedClients = clients.filter((clients) => {
      return clients.id === parseInt(clientsId, 10)
    })

    return selectedClients[0]
  }

  dismissFlash = () => {
    this.setState(state => ({
      ...state,
      showFlash: false
    }))
  }

  addClientsUser = (clientsUser) => {
    API.createClients(this.props.user, clientsUser).then(data => {
      if (((typeof data != 'undefined') || (data != null)) && (!data.errors)) {
        this.getClients()
        this.setState(state => ({
          ...state,
          showFlash: true,
          flashMessage: `${clientsUser.first_name} has been successfully added.`
        }))
      } else {
        this.setState(state => ({
          ...state,
          showFlash: true,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          },
          flashMessage: data.errors[0]
        }))
      }
    })
  }

  getClientsListData = () => {
    let { clients } = this.state
    return {
      headings: [
        'Name',
        'Role',
        'Status'
      ],
      columns: clients.map((clients) => {
        let statusPillClass = null
        let deleteMenuClass = 'alert'
        if (!clients.active) {
          statusPillClass = 'inactive'
          deleteMenuClass = 'alert remove-inactive-menu'
        }

        if (clients.deactivate_clients_id === this.props.user.user_id) {
          statusPillClass = 'inactive'
          deleteMenuClass = 'alert remove-inactive-menu'
        }

        if (clients.deactivate_clients_id !== null) {
          statusPillClass = 'inactive'
        }



        return {
          primaryColumn: {
            title: clients.first_name + ' ' + clients.last_name,
            secondaryInfo: clients.email
          },
          secondaryColumns: [
            {
              title: clients.role
            },
            {
              title: clients.active ? clients.deactivate_clients_id ? 'Deletion Pending' : 'Active' : 'Inactive',
              optionalClasses: `status-pill ${statusPillClass ? statusPillClass : ''}`
            }
          ],
          rowType: 'overflowMenu',
          rowActions: [
            {
              title: (clients.deactivate_clients_id !== null) ? 'Confirm Delete' : 'Delete Client',
              optionalClasses: deleteMenuClass,
              dataBehavior: 'clients-delete',
              dataTarget: clients.id,
            }
          ]
        }
      })
    }
  }

  render() {
    const { appName, user } = this.props
    const {
      addClientsFormIsVisible,
      showClientsDeletionModal,
      isLoading,
      clientsToDelete,
      input, 
      error
    } = this.state

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    let sectionHeaderUtilities = (
      <div className="app-section-header-utilities">
        <div className="admin-search-input-container">
          <input
            type="text"
            name="search"
            placeholder="Search"
            value={input.search.value}
            className="admin-search-input"
            onChange={event => this.handleInputChange(
              { search: { value: event.target.value } }
            )}
          />
          <span className="search-icon"/>
        </div>
        <button
          className={`cta${addClientsFormIsVisible ? ' hide' : ''}`}
          onClick={this.handleAddClientsClick}
        >
          + Add Client
        </button>
      </div>
    )

    const modal = showClientsDeletionModal ? (
      <ConfirmClientsDeletionModal
        handleOfferActionResponse={this.handleOfferActionResponse}
        user={user}
        clients={clientsToDelete}
        submitAndRemoveClients={this.handleModalSubmission}
        hideModal={() => this.toggleClientsDeletionModal()}
      />
    ) : null

    return isLoading
      ? (
        <AppCanvasLoader />
      )
      : (
        <div className="admin-admins">
          <Helmet>
            <title>{appName} | Clients</title>
          </Helmet>
          {this.state.showFlash ? (
            <FlashMessage
              message={this.state.flashMessage}
              handleFlashDismiss={this.dismissFlash}
            />
          ) : null}
          <AppSectionHeader
            title="Clients"
            utilities={sectionHeaderUtilities}
          />
          {addClientsFormIsVisible &&
            <ClientsAddAdminForm
              closeForm={() => this.handleAddClientsFormCancelation()}
              handleFormSubmission={(clientsUser) => this.addClientsUser(clientsUser)}
            />
          }
          {this.state.clients.length > 0 ? (
            <ObjectList
              optionalClasses={"admins"}
              data={this.getClientsListData()}
              rowActionClick={(event) => this.handleRowActionClick(event)}
            />
          ) : (
              <div className="admin-admins-empty-state">
                <p>
                  No results found for:
                  </p>
                <h3 className="admin-admins-empty-state-heading empty-mesg-mg">
                  {this.state.input.search.value}
                </h3>
              </div>
            )}
          {showClientsDeletionModal ? modal : ''}
          {showClientsDeletionModal ? (
            <div className="curtain" ></div>
          ) : ''}
        </div>
      )
  }
}

export default ClientsAdmins
