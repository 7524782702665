import React, { Component } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import MaskedTextInput from "react-text-mask";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import Panel from "../../components/App/Panel";
import ClientsEditSettingSidebar from "../../components/Clients/ClientsEditSettingSidebar";
import ClientsContactSetting from "../../components/Clients/Forms/ClientsContactSetting";
// API
import * as API from "../../utils/api";
import {
  getZohoPagesense,
  showToast,
  validateEmail,
} from "../../utils/helpers";
import stateDataShortNames from "../../utils/stateDataShortNames.json";

class ClientsEditSettings extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    consumer: "",
    input: {
      companyName: {
        value: "",
        hasError: false,
      },
      displayName: {
        value: "",
        hasError: false,
      },
      first_name: {
        value: "",
        hasError: false,
      },
      last_name: {
        value: "",
        hasError: false,
      },
      email: {
        value: "",
        hasError: false,
      },
      phone_number: {
        value: "",
        hasError: false,
      },
      address_line1: {
        value: "",
        hasError: false,
      },
      address_line2: {
        value: "",
        hasError: false,
      },
      city: {
        value: "",
        hasError: false,
      },
      state: {
        value: "",
        hasError: false,
      },
      zipcode: {
        value: "",
        hasError: false,
      },
    },
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    open_additional_details: false,
    showValidationScreen: false,
    last_billing_email: ''
  };

  componentDidMount() {
    this.getConsumerData();
    getZohoPagesense();
    ReactGA.pageview("/ClientsEditAccount");
  }

  getConsumerData = () => {
    const accountId = this.props.match.params.id;
    API.getClientsConsumer(this.props.user, accountId).then((data) => {
      let isAccessDenied =
        data && data.message && data.message == "Access denied." ? true : false;

      if (
        (typeof data != "undefined" || data != null) &&
        !data.message &&
        !isAccessDenied
      ) {
        this.setConsumerData(data.account.consumer);
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  setConsumerData = (consumerData) => {
    this.setState((state) => ({
      isLoading: false,
      consumer: consumerData,
      last_billing_email: consumerData.billing_primary_email,
      input: {
        companyName: {
          ...state.input.companyName,
          value: consumerData.company_name,
        },
        displayName: {
          ...state.input.displayName,
          value: consumerData.display_name,
        },
        first_name: {
          ...state.input.first_name,
          value: consumerData.billing_first_name,
        },
        last_name: {
          ...state.input.last_name,
          value: consumerData.billing_last_name,
        },
        email: {
          ...state.input.email,
          value: consumerData.billing_primary_email,
        },
        phone_number: {
          ...state.input.phone_number,
          value: consumerData.billing_phone_no,
        },
        address_line1: {
          ...state.input.address_line1,
          value: consumerData.billing_address_line1,
        },
        address_line2: {
          ...state.input.address_line2,
          value: consumerData.billing_address_line2,
        },
        city: {
          ...state.input.city,
          value: consumerData.billing_city,
        },
        state: {
          ...state.input.state,
          value: consumerData.billing_state,
        },
        zipcode: {
          ...state.input.zipcode,
          value: consumerData.billing_zip,
        },
      },
    }));
  };

  handleSendLink = () => {
    const cunsumerId = this.props.match.params.id;
    API.sendRegistrationLink(this.props.user, cunsumerId).then((data) => {
      if (typeof data != "undefined" || data != null) {
        if (data.status_code == 200) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { input, consumer } = this.state;

    if (this.validateForm()) {
      // if(this.state.last_billing_email !== input.email.value){
      //   this.props.updateModalState(true, "CONFIRM_ALERT", {
      //     message: "Do you want to add/replace Contact email id too same like billing email?",
      //     confirmClick: () => {
      //       this.submitBillingInfo(true);
      //     },
      //     closeOnClickOutside: true,
      //     cancelClick: () => {
      //       this.submitBillingInfo(false);
      //     },
      //     isCancelCallback: true
      //   },);
      // } else {
      //   this.submitBillingInfo(false);
      // }
      this.submitBillingInfo(false)
    }
  };

  submitBillingInfo = (emailFlag) => {
    const { input, consumer } = this.state;
    let consumerForSubmission = {
      user_id: consumer.id,
      company_name: input.companyName.value,
      display_name: input.displayName.value,
      billing_first_name: input.first_name.value,
      billing_last_name: input.last_name.value,
      billing_primary_email: input.email.value,
      billing_phone_no: input.phone_number.value,
      billing_address_line1: input.address_line1.value,
      billing_address_line2: input.address_line2.value,
      billing_city: input.city.value,
      billing_state: input.state.value,
      billing_zip: input.zipcode.value,
      // is_change_email: emailFlag
    };
    API.updateConsumerBillingDetails(
      this.props.user,
      consumerForSubmission
    ).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.status_code && data.status_code == 200) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
            onOKClick: () => {
              if(this.state.last_billing_email !== input.email.value){
                this.setState({last_billing_email: input.email.value});
                this.getConsumerData();
                this.props.updateModalState(true, "SUCCESS", {
                  message: `Please notify ${input.first_name.value} ${input.last_name.value} that you have updated their username for logging into the Billing & Payment Portal`,
                  title: 'Alert'
                });
              } 
            },
            isOKCallback: true
          });
          
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
        
        
      } else {
        this.setState((state) => ({
          ...state,
          hasAnyError: true,
        }));
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
        });
      }
    });
  }

  validateForm = () => {
    let errorsArePresent = false;
    // Determine which input group to check for errors
    let inputs = { ...this.state.input };
    // Check inputs for errors
    Object.entries(inputs).forEach(([key, value]) => {
      if (key === "companyName") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      } else if (key === "displayName") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      } else if (key === "email") {
        if (!validateEmail(value.value) && value.value !== "") {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      } else if (key === "address_line1") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      } else if (key === "city") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      } else if (key === "state") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      } else if (key === "zipcode") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      }
    });

    this.setState({ input: inputs });

    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "companyName") {
      message = "Please enter company name";
    } else if (input === "displayName") {
      message = "Please enter a display name";
    } else if (input === "email") {
      message = "Please enter a valid email address";
    } else if (input === "street_address") {
      message = "Please enter a street address";
    } else if (input === "city") {
      message = "Please enter a city";
    } else if (input === "state") {
      message = "Please select a state";
    } else if (input === "zipcode") {
      message = "Please enter a zip code";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  render() {
    const { appName, user, history } = this.props;
    const { consumer, input, isLoading, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    let first_name = consumer.first_name? consumer.first_name : "";
    let last_name = consumer.last_name? consumer.last_name : "";

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className="client-scroll-dashboard scrollbar">
        <Helmet>
          <title>
        {appName} | {first_name + " " + last_name}
          </title>
        </Helmet>
        <div className="app-sidebar-layout-canvas">
          <ClientsEditSettingSidebar
            {...this.props}
            backPath={`/client/accounts/${consumer.id}`}
            backText={"Back to Account"}
            account={consumer}
            user={user}
            history={history}
            handleSendLink={this.handleSendLink}
          />
          <div className="app-sidebar-layout-canvas-content setting-side-canvas big-device-grad col1-scroll scrollbar">
            <div className="he-hed">Edit Settings</div>
            <Panel>
              <div className="edit-setting-screen">
                <form className="admin-form">
                  <div className={`modal-slide`}>
                    <div className="form-container">
                      <div className="form-column">
                        <header
                          className="modal-slide-header"
                          style={{ textAlign: "left", marginBottom: "0.5em" }}
                        >
                          <div
                            className={`account-profile-header`}
                            style={{ margin: 0 }}
                          >
                            Billing Contact Information
                          </div>
                        </header>
                        <div
                          className={`input-container${
                            input.companyName.hasError ? " error" : ""
                          }`}
                        >
                          <label htmlFor="name">Company Name</label>
                          <input
                            name="name"
                            type="text"
                            placeholder="Company Name"
                            value={input.companyName.value || ""}
                            onChange={(event) =>
                              this.handleInputChange({
                                companyName: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                          {input.companyName.hasError
                            ? this.renderErrorMessage("companyName")
                            : null}
                        </div>
                        <div
                          className={`input-container${
                            input.displayName.hasError ? " error" : ""
                          }`}
                        >
                          <label htmlFor="name">Display Name</label>
                          <input
                            name="display_name"
                            type="text"
                            placeholder="Display Name"
                            value={input.displayName.value || ""}
                            onChange={(event) =>
                              this.handleInputChange({
                                displayName: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                          {input.displayName.hasError
                            ? this.renderErrorMessage("displayName")
                            : null}
                        </div>
                        <div className={`input-container`}>
                          <label htmlFor="name">Name</label>
                          <div className="input-container multi-input-field">
                            <input
                              className="multi-input-field-input"
                              name="name"
                              type="text"
                              placeholder="First Name"
                              value={input.first_name.value || ""}
                              onChange={(event) =>
                                this.handleInputChange({
                                  first_name: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                            <input
                              className="multi-input-field-input"
                              name="name"
                              type="text"
                              placeholder="Last Name"
                              value={input.last_name.value || ""}
                              onChange={(event) =>
                                this.handleInputChange({
                                  last_name: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div
                          className={`input-container${
                            input.email.hasError ? " error" : ""
                          }`}
                        >
                          <label htmlFor="email">Email Address</label>
                          <input
                            name="email"
                            type="email"
                            placeholder="address@email.com"
                            value={input.email.value || ""}
                            onChange={(event) =>
                              this.handleInputChange({
                                email: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                          {input.email.hasError
                            ? this.renderErrorMessage("email")
                            : null}
                        </div>
                        <div
                          className={`input-container${
                            input.phone_number.hasError ? " error" : ""
                          }`}
                        >
                          <label htmlFor="phone">Phone Number</label>
                          <MaskedTextInput
                            name="phone"
                            mask={[
                              "(",
                              /\d/,
                              /\d/,
                              /\d/,
                              ")",
                              " ",
                              /\d/,
                              /\d/,
                              /\d/,
                              "-",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            placeholder="(555) 555-5555"
                            placeholderChar={"\u2000"}
                            guide={true}
                            type="text"
                            value={input.phone_number.value || ""}
                            onChange={(event) =>
                              this.handleInputChange({
                                phone_number: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-column">
                        <header
                          className="modal-slide-header"
                          style={{ textAlign: "left", marginBottom: "0.5em" }}
                        >
                          <div
                            className={`account-profile-header`}
                            style={{ margin: 0 }}
                          >
                            Billing Address Information
                          </div>
                        </header>
                        <div
                          className={`input-container${
                            input.address_line1.hasError ? " error" : ""
                          }`}
                        >
                          <label htmlFor="streetAddress">Street Address</label>
                          <input
                            name="streetAddress"
                            type="text"
                            placeholder="Enter an Address"
                            value={input.address_line1.value || ""}
                            onChange={(event) =>
                              this.handleInputChange({
                                address_line1: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                          {input.address_line1.hasError
                            ? this.renderErrorMessage("street_address")
                            : null}
                        </div>
                        <div className={`input-container`}>
                          <label htmlFor="unit">Suite # / Unit #</label>
                          <input
                            name="unit"
                            type="text"
                            placeholder="Apt. #"
                            value={input.address_line2.value || ""}
                            onChange={(event) =>
                              this.handleInputChange({
                                address_line2: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                        </div>
                        <div
                          className={`input-container${
                            input.city.hasError ? " error" : ""
                          }`}
                        >
                          <label htmlFor="city">City</label>
                          <input
                            name="city"
                            type="text"
                            placeholder="Enter a City"
                            value={input.city.value || ""}
                            onChange={(event) =>
                              this.handleInputChange({
                                city: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                          {input.city.hasError
                            ? this.renderErrorMessage("city")
                            : null}
                        </div>
                        <div
                          className={`input-container${
                            input.state.hasError ? " error" : ""
                          }`}
                        >
                          <label htmlFor="state">State</label>
                          <Select
                            inputProps={{
                              autoComplete: "none",
                              autoCorrect: "off",
                              spellCheck: "off",
                            }}
                            className="form-select"
                            name="state"
                            value={input.state.value || ""}
                            placeholder="Select"
                            options={stateDataShortNames}
                            resetValue=""
                            onChange={(event) =>
                              this.handleInputChange({
                                state: { value: event.value, hasError: false },
                              })
                            }
                          />
                          {input.state.hasError
                            ? this.renderErrorMessage("state")
                            : null}
                        </div>
                        <div
                          className={`input-container${
                            input.zipcode.hasError ? " error" : ""
                          }`}
                        >
                          <label htmlFor="zipcode">Zip Code</label>
                          <MaskedTextInput
                            guide={true}
                            mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                            name="zipcode"
                            placeholder="90210"
                            placeholderChar={"\u2000"}
                            type="text"
                            value={input.zipcode.value || ""}
                            onChange={(event) =>
                              this.handleInputChange({
                                zipcode: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                          {input.zipcode.hasError
                            ? this.renderErrorMessage("zipcode")
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="modal-actions">
                      <a
                        className="cta mg-brand2-color"
                        onClick={this.handleSubmit}
                      >
                        Save Changes
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </Panel>
            <ClientsContactSetting
              updateModalState={this.props.updateModalState}
              user={this.props.user}
              consumerData={this.state.consumer}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ClientsEditSettings;
