import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Intercom from 'react-intercom';
import { Link, Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

// Components
import MarketingHeader from '../components/Marketing/MarketingHeader';
import LandingHero from '../components/Landing/LandingHero/LandingHero';
import StepIconList from '../components/Landing/StepIconList/StepIconList';
import TabGroup from '../components/Landing/TabGroup';
import SeeForYourself from '../components/Landing/SeeForYourself';
import ExpanderSet from '../components/Landing/ExpanderSet/ExpanderSet';
import MarketingFooter from '../components/Marketing/MarketingFooter';
import RegistrationComComp from './NewLanding/RegistrationComComp'

// Image Imports
import ControlIcon from '../assets/images/landing/icons/control@2x.png';
import FlexibilityIcon from '../assets/images/landing/icons/flexibility@2x.png';
import TransparencyIcon from '../assets/images/landing/icons/transparency@2x.png';

import * as API from '../utils/api'

import {getZohoPagesense} from '../utils/helpers'

class MarketingHomepage extends Component {
  static propTypes = {
    location: PropTypes.string.isRequired,
    appName: PropTypes.string
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isAppConfigure: true,
      isAppConfig: false
    }
  }

  componentWillMount() {

    var isAppConfigured = this.getConfiguredStatus();

    if (isAppConfigured) {
      this.setState(state => ({
        ...state,
        loading: false,
        isAppConfigure: isAppConfigured
      }))
      if (!this.state.isAppConfig) {
        var isAppConfig = this.getAppThirdPartyConfiguredStatus();
        if (isAppConfigured && isAppConfig) {
          this.setState(state => ({
            ...state,
            loading: false,
            isAppConfig: isAppConfig
          }))
        }
      }
    }
  }

  componentDidMount(){
    getZohoPagesense();
    ReactGA.pageview('/MarketingHomepage');
  }

  async getConfiguredStatus() {

    var me = this;
    localStorage.setItem("isAppConfigured", false);

    await API.isAppConfigure().then(data => {

      if ((typeof data != 'undefined' || data != null) && (!data.errors)) {

        if (data.isAppConfigured) {

          me.setState(state => ({
            ...state,
            loading: false,
            isAppConfigure: true
          }))
          localStorage.setItem("isAppConfigured", true);
          return true;
        } else {
          me.setState(state => ({
            ...state,
            loading: false,
            isAppConfigure: false
          }))
          localStorage.setItem("isAppConfigured", false);
          return false;
        }
      } else {
        me.setState(state => ({
          ...state,
          loading: false,
          isAppConfigure: false
        }))
        localStorage.setItem("isAppConfigured", false);
        return false;
      }
    });
  }

  async getAppThirdPartyConfiguredStatus() {
    var me = this;
    localStorage.setItem("isAppConfig", false);
    await API.isThirdPartyAppConfigured().then(data => {
      if ((typeof data != 'undefined' || data != null) && (!data.errors)) {
        if (data.isAppConfig) {
          me.setState(state => ({
            ...state,
            loading: false,
            isAppConfig: true
          }))
          localStorage.setItem("isAppConfig", true);
          return true;
        } else {
          me.setState(state => ({
            ...state,
            loading: false,
            isAppConfig: false
          }))
          localStorage.setItem("isAppConfig", false);
          return false;
        }
      } else {
        me.setState(state => ({
          ...state,
          loading: false,
          isAppConfig: false
        }))
        localStorage.setItem("isAppConfig", false);
        return false;
      }
    });
  }




  render() {
    const { appName, location, intercomAppId } = this.props;
    // if (this.state.isAppConfigure && this.state.isAppConfig) {
    // } else {
      return <Redirect to={{ pathname: '/login' }} />
    // }




    return (
      <div className="landing">
        {intercomAppId && <Intercom appID={intercomAppId} />}
        <Helmet>
          <title>{appName} | Welcome!</title>
        </Helmet>
        <MarketingHeader
          path={this.props.location}
          appName={this.props.appName}
        />
        <LandingHero appName={this.props.appName} />
        <section className="intro-section">
          <div className="inner-wrap">
            <div className="section-content-left">
              <div className="section-header section-header-left">
                <h3>Welcome to {appName}</h3>
              </div>
              <RegistrationComComp
              appName={appName}
              />
              {/* <p>
              {appName} has taken a unique approach to the client bill-pay experience. Each of our patient’s experience is important from service to payment. We understand that sometimes its not always easy to pay bills, and for that reason we offer our patients a flexible, custom payment option that works for them – at every step of the way.
              </p>
              <p>
                <strong>Join {appName} for free today and take control of your financial future.</strong>
              </p> */}
            </div>
          </div>
        </section>
        <section>
          <div className="inner-wrap">
            <div className="section-header landing-section-header">
              <h3 className="landing-section-header-heading">
                <span>Getting started. As simple as one, two, three.</span>
              </h3>
              <div className="landing-section-header-surheading">
                Signing up to the {appName} platform gives you the freedom to:
                  </div>
            </div>
            <StepIconList />
          </div>
        </section>
        <section>
          <div className="inner-wrap">
            <div className="section-header landing-section-header">
              <h3 className="landing-section-header-heading">
                <span>Payments that work for you.</span>
              </h3>
              <div className="landing-section-header-surheading">
                Our payment options give you the flexibility to manage how, and when you pay.
                  </div>
            </div>
          </div>
          <TabGroup />
        </section>
        <section className="benefits-section">
          <div className="inner-wrap">
            <div className="section-header landing-section-header">
              <h3 className="landing-section-header-heading">
                <span>Countless benefits</span>
              </h3>
              <div className="landing-section-header-surheading">
                {appName} gives you the freedom to take charge of your financial future through:
                  </div>
            </div>
            <ul className="benefits-list">
              <li className="benefits-list-item">
                <div className="benefits-list-item-icon" style={{ backgroundImage: `url(${ControlIcon})` }}></div>
                <h4 className="benefits-list-item-heading">
                  Complete Control
                    </h4>
                <div className="benefits-list-item-body">
                  You determine how you want to manage your delinquent debt.
                    </div>
              </li>
              <li className="benefits-list-item">
                <div className="benefits-list-item-icon" style={{ backgroundImage: `url(${FlexibilityIcon})` }}></div>
                <h4 className="benefits-list-item-heading">
                  Financial Flexibility
                    </h4>
                <div className="benefits-list-item-body">
                  Negotiate and set up payments that work for you, either through regular monthly amounts, or by making us a one-time offer to settle your debt once and for all.
                    </div>
              </li>
              <li className="benefits-list-item">
                <div className="benefits-list-item-icon" style={{ backgroundImage: `url(${TransparencyIcon})` }}></div>
                <h4 className="benefits-list-item-heading">
                  Total Transparency
                    </h4>
                <div className="benefits-list-item-body">
                  {appName} provides a history of all offers, decisions and payments for your reference, available through a single, centralized platform.
                    </div>
              </li>
            </ul>
            <Link
              className="cta"
              title={"Join " + appName + " Today"}
              to="/register"
            >
              Take Control Today
                </Link>
          </div>
        </section>
        <section className="product-highlight-section pull-up">
          <div className="inner-wrap">
            <h4>
              {appName} works across desktop, iOS, and Android devices.
                </h4>
          </div>
          <div className="devices">
            <div className="inner-wrap">
              <div className="devices-image"></div>
            </div>
          </div>
        </section>
        <section className="faq-section">
          <div className="inner-wrap">
            <div className="section-header landing-section-header">
              <h3 className="landing-section-header-heading">
                <span>
                  You’ve got questions,
                      <br />
                  we’ve got answers.
                    </span>
              </h3>
            </div>
            <ExpanderSet appName={this.props.appName} />
          </div>
        </section>
        <SeeForYourself appName={this.props.appName} />
        <MarketingFooter appName={this.props.appName} companyAddress={this.props.companyAddress}
          adminEmail={this.props.adminEmail} />
      </div>
    );
  }
}

export default MarketingHomepage;
