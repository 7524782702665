import moment from "moment";
import React, { Component } from "react";
import ReactGA from "react-ga";
import shortid from "shortid";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import Tabs from "../../components/Tabs";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import ClientsSidebar from "../../components/Clients/ClientsSidebar";
import HeaderOverflowMenu from "../../components/ObjectList/HeaderOverflowMenu";
import HeaderMenuItem from "../../components/HeaderMenuItem";
import DropdownButton from "../../components/DropdownButton";
import MenuItem from "../../components/MenuItem";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import * as API from "../../utils/api";
import Emitter from "../../utils/event-emitter";
import {
  getDollarAmount,
  getZohoPagesense,
  showToast,
} from "../../utils/helpers";
import ClientsUploadValidationDoc from "./ClientsUploadValidationDoc";

import deleteImg from "../../assets/icons/delete.png";
import downloadImg from "../../assets/icons/download.png";
import editImg from "../../assets/icons/edit_blue.svg";
import viewIcon from "../../assets/icons/view.png";

class ClientsAccountsView extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    user: {},
    consumer: {},
    debts: [],
    selectedDebt: [],
    rowAction: [],
    creditMemoList: [],
    statementList: [],
    selectedOPT: 'Invoices',
    isLoading: true,
    isTableLoading: true,
    activeTab:1,
    selectedOfferForCounter: {},
    upcoming_activities: [],
    recent_activities: [],
    showCounterOfferModal: false,
    currentModal: null,
    optionalModalProps: null,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    open_additional_details: false,
    showValidationScreen: false,
    isOpenInvoiceView: false
  };

  componentDidMount() {
    this.getData();
    this.getAccountActivities();
    getZohoPagesense();
    this.setRowActions()
    ReactGA.pageview("/ClientsAccountsView");
  }

  //get updated account details based on updated accountId
  componentWillReceiveProps(props) {
    if (this.props.match.params.id !== props.match.params.id) {
      const id = props.match.params.id;
      this.getData(id);
      this.getAccountActivities(id);
      // this.getCreditMemoList(id);
    }
  }

  //get clients accounts details
  getData(id) {
    const accountId = id ? id : this.props.match.params.id;
    API.getClientsConsumer(this.props.user, accountId).then((data) => {
      let isAccessDenied =
        data && data.status_code && data.status_code == 401 ? true : false;
      Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if ((typeof data != "undefined" || data != null) && !isAccessDenied) {
        this.setState((state) => ({
          ...state,
          user: this.props.user,
          consumer: data.account.consumer,
          debts: data.account.debts,
          isLoading: false,
          isTableLoading: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          isTableLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  //get clients Credit memo list
  getCreditMemoList(id) {
    const accountId = id ? id : this.props.match.params.id;
    API.getClientCreditMemoList(this.props.user, accountId).then((data) => {
      let isAccessDenied =
        data && data.status_code && data.status_code == 401 ? true : false;
      // Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if ((typeof data != "undefined" || data != null) && !isAccessDenied) {
        this.setState((state) => ({
          ...state,
          user: this.props.user,
          creditMemoList: data.data,
          // isLoading: false,
          isTableLoading: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          // isLoading: false,
          isTableLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  //get clients Credit memo list
  getGenerateStatementList(id) {
    const accountId = id ? id : this.props.match.params.id;
    API.getGenerateStatementList(this.props.user, accountId).then((data) => {
      let isAccessDenied =
          data && data.status_code && data.status_code == 401 ? true : false;
      // Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if ((typeof data != "undefined" || data != null) && !isAccessDenied) {
        this.setState((state) => ({
          ...state,
          user: this.props.user,
          statementList: data.data,
          // isLoading: false,
          isTableLoading: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          // isLoading: false,
          isTableLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
                ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
                : 500,
          },
        }));
      }
    });
  }

  generateStatement() {
    API.generateStatement(this.props.user, this.props.match.params.id).then((data) => {
      let isAccessDenied = data && data.status_code && (data.status_code == 400 || data.status_code == 401)? true : false;
      // Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      console.log('isAccessDenied', isAccessDenied)
      if ((typeof data != "undefined" || data != null) && !isAccessDenied) {
        this.getGenerateStatementList();
      } else {
        if(data.message){
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
            title: 'Info'
          });
        }else{
          this.setState((state) => ({
            ...state,
            // isLoading: false,
            isTableLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                  ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                  : 500,
            },
          }));
        }
      }
    });
  }



  //get clients Open invoice list
  getOpenInvoiceList(id) {
    const accountId = id ? id : this.props.match.params.id;
    API.getOpenInvoicesList(this.props.user, accountId).then((data) => {
      let isAccessDenied =
        data && data.status_code && data.status_code == 401 ? true : false;
      // Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if ((typeof data != "undefined" || data != null) && !isAccessDenied) {
        let tempData = [...data.data]
        if(tempData && tempData.length) {
          tempData.forEach((ele) => {
            ele.emailCheck = false;
          })
        }
        this.setState((state) => ({
          ...state,
          user: this.props.user,
          debts: tempData,
          // isLoading: false,
          isTableLoading: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          // isLoading: false,
          isTableLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }


  //delete clients Credit memo
  deleteCreditMemoAPI(id) {
    const accountId = id ? id : this.props.match.params.id;
    API.deleteClientsCreditMemo(this.props.user, id).then((data) => {
      let isAccessDenied =
        data && data.status_code && data.status_code == 401 ? true : false;
      // Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if ((typeof data != "undefined" || data != null) && !isAccessDenied) {
        this.setState((state) => ({
          ...state,
          isTableLoading: false,
        }));
        this.getCreditMemoList();
      } else {
        this.setState((state) => ({
          ...state,
          // isLoading: false,
          isTableLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  //getInvoiceDetails
  getInvoiceDetails = (invoiceId) => {
    let { user } = this.props;
    API.getInvoiceDetails(user, invoiceId).then((data) => {
      Emitter.emit('IS_QUICKBOOK_CONNECTED', data.qb_connected);
      if (
        (typeof data != "undefined" || data != null) &&
        data.message === "success" &&
        !data.error
      ) {
        if (data.data) {
          this.handleEditClick(data.data);
        }
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
              (data.status_code && data.status_code == 401 ? 401 : 500) ||
              500
              : 500,
          },
        }));
      }
    });
  };

  //go to edit invoice
  handleEditClick = (data) => {
    let { history } = this.props;
    const id = this.props.match.params.id;
    history.push({
      pathname: `/client/add/invoices`,
      state: {
        invoiceDetails: data,
        backPath: `/client/accounts/${id}`,
      },
    });
  };

  //opens edit setting screen
  handleEditSetting = () => {
    let { history } = this.props;
    const id = this.props.match.params.id;
    if (id) {
      history.push(`/client/accounts/${id}/edit-setting`);
    }
  };

  //opens open-invoice view with checkboxes
  openInvoiceView = () => {
    this.setState({isOpenInvoiceView: true}, () => {
      this.getOpenInvoiceList()
    })
  };

  //get accounts recent activity and upcoming activity data
  getAccountActivities(id) {
    const accountId = id ? id : this.props.match.params.id;
    API.getAccountActivities(this.props.user, accountId).then((data) => {
      if (typeof data != "undefined" || data != null) {
        if (data.data) {
          let { recent_activities, upcoming_activities } = data.data;

          //sort recent activities
          if (recent_activities && recent_activities.length) {
            recent_activities.sort(function (a, b) {
              return new Date(b.due_date) - new Date(a.due_date);
            });
          }

          //sort upcoming activities
          if (upcoming_activities && upcoming_activities.length) {
            upcoming_activities.sort(function (a, b) {
              return new Date(a.due_date) - new Date(b.due_date);
            });
          }

          this.setState({ recent_activities, upcoming_activities });
        }
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  deleteCreditMemo = (id) => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: "Are you sure you want to delete this credit memo?",
      confirmClick: () => {
        this.deleteCreditMemoAPI(id)
      },
      closeOnClickOutside: true,
    });
  }

  goToEditInvoice = (id) => {
    this.getInvoiceDetails(id)
  }

  downloadInvoice = (doc_id, fileName) => {
    const { user } = this.props;
    API.getAttachedDocuement(user, doc_id, 'debts').then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let flag = data.data.includes("base64");
          if (!flag) {
            data.data = "data:application/pdf;base64," + data.data;
          }
          let url = data.data;
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
              (data.message && data.message == "Access denied."
                ? 401
                : 500) ||
              500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  getAccountFromData = (id, users) => {
    let currentId = parseInt(id, 10);
    let user = users.filter(function (user) {
      return user.id === currentId;
    });

    return user[0];
  };

  //on invoice click opens invoice details screen
  rowExpanderClick = (event) => {
    let { history } = this.props;
    const id = this.props.match.params.id;
    const invoiceId = event.rowData.debt_invoice.id;
    if (invoiceId) {
      history.push({
        pathname: `/client/invoices/${invoiceId}`,
        state: { backPath: `/client/accounts/${id}` },
      });
    }
  };

  //on credit memo click opens invoice details screen
  rowExpanderClickForMemo = (event) => {
    let { history } = this.props;
    const id = this.props.match.params.id;
    const creditMemoId = event.rowData.id;
    // const creditMemoId = 1;
    if (creditMemoId) {
      history.push({
        pathname: `/client/creditMemo/${creditMemoId}`,
        state: { backPath: `/client/accounts/${id}` },
      });
    }
  };

  reloadData = () => {
    this.getData();
  };

  renderLatestPaymentDate = (date) => {
    return moment.utc(date).format("MM/DD/YYYY");
  };

  renderSendNewMailModal(consumerId) {
    const { consumer, user } = this.state;
    let multipleInvoiceData = this.getCheckedInvoiceList();
    let invoiceListSTR = this.getCheckedInvoiceList();
    let tempConsumer = {...consumer};
    tempConsumer.user_id = consumer.id;
    let props = {
      user: user,
      consumerId: consumerId,
      invoiceDetails: {user: tempConsumer},
      isMultipleInvoices: true,
      multipleInvoiceData: multipleInvoiceData
    };
    this.props.updateModalState(true, "SEND_NEW_MAIL", props);
  }

  sendMultipleInvoice = () => {
    const { invoiceDetails, user } = this.state;
    let consumerId = user.user_id ? user.user_id : "";
    let isValid = this.checkSendEmailValid();
    if (isValid) {
      this.renderSendNewMailModal(consumerId);
    } else {
      this.props.updateModalState(true, "SUCCESS", {
        message: `Please select atleast one invoice to send an email.`,
        title: 'Alert'
      });
    }
  }

  closeOpenInvoiceView = () => {
    this.setState({isOpenInvoiceView: false})
  }

  getCreditMemoStatus = (data) => {
    let status = '';
    if (parseFloat(data.total_amount) === parseFloat(data.balance)) {
      status = 'Unapplied';
    } else if (parseFloat(data.balance) === 0.0) {
      status = 'Applied';
    } else if ((parseFloat(data.total_amount) - parseFloat(data.balance)) > 0) {
      status = 'Partially Applied';
    } 
    return status;
  }

  //get invoice paid amount based on total amount and balance
  getPaidAmount = (invoice) => {
    let amount = 0;
    if (invoice) {
      amount = invoice.total_amt
        ? invoice.balance
          ? parseFloat(invoice.total_amt) - parseFloat(invoice.balance)
          : invoice.total_amt
        : invoice.total_amt;
    }
    return amount.toFixed(2);
  };

  //check for invoice is past due or not
  checkInvoicePastDue = (date) => {
    return moment(date).isBefore(new Date());
  };

  getAccountStatus = (rowData) => {
    let flag = this.checkInvoicePastDue(rowData.due_date);
    return !flag;
  };

  checkSendEmailValid = () => {
    const { debts } = this.state;
    let ct = 0;
    let isSelcted = false;
    if (debts && debts.length) {
      debts.map((ele, index) => {
        if (ele.emailCheck) {
          ct++;
        }
      });
      if (ct > 0) {
        //TO_DO display/enable send email button...
        isSelcted = true;
      } else {
        //TO_DO hide/disable send email button... 
        isSelcted = false;
      }
    }
    return isSelcted
  }

  getCheckedInvoiceList = () => {
    const { debts } = this.state;
    let invoiceIdsOBJ = {
      invoiceListArray: [],
      invoiceListSTR: ''
    }
    if (debts && debts.length) {
      debts.map((ele, index) => {
        if (ele.emailCheck) {
        invoiceIdsOBJ.invoiceListArray.push(parseInt(ele.debt_invoice.id))
        invoiceIdsOBJ.invoiceListSTR = invoiceIdsOBJ.invoiceListSTR !== '' ? invoiceIdsOBJ.invoiceListSTR + ', ' + ele.debt_invoice.invoice_number : ele.debt_invoice.invoice_number;
        }
      });
    }
    return invoiceIdsOBJ;
  }

  onCheckboxChange = (e, item, index) => {
    const { debts } = this.state;
    let tempDebts = [...debts];
    tempDebts[index].emailCheck = e.target.checked;
    this.setState({debts: tempDebts}, () => {
      this.checkSendEmailValid()
    });
  }

  //returns formatted table data
  getObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = [
      // "",
      "Inv. #",
      "Inv. Date",
      "Due Date",
      "Total",
      "Amount Paid",
      "Discount",
      "Balance",
      "Action",
      "expander",
    ];

    columns = data.map((debt, index) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          // {
          //   key: "",
          //   title: <div className="email-value-wrapper">
          //     <input className="email-checkbox" type="checkbox" id={`tax00${index}`} name={'tax'} value={'tax'} checked={debt.is_taxable} onChange={(e) => this.onCheckboxChange(e, debt, index) } />
          //   </div>
          // },
          {
            key: "Inv. #",
            title: debt.debt_invoice.invoice_number,
          },
          {
            key: "Inv. Date",
            title: this.renderLatestPaymentDate(debt.debt_invoice.txn_date),
          },
          {
            key: "Due Date",
            title: this.renderLatestPaymentDate(debt.debt_invoice.due_date),
          },
          {
            key: "Total",
            title: getDollarAmount(debt.debt_invoice.total_amt),
          },
          {
            key: "Amount Paid",
            title: getDollarAmount(this.getPaidAmount(debt.debt_invoice)),
          },
          {
            key: "Discount",
            title: getDollarAmount(debt.debt_invoice.adjustment),
          },
          {
            key: "Balance",
            title: getDollarAmount(debt.debt_invoice.balance),
          },
          {
            key: "Action",
            title: <> 
              <button
                title="Edit"
                style={{
                  background: "transparent",
                  cursor: "pointer",
                  marginRight: 10,
                }}
                onClick={() => this.goToEditInvoice(debt.debt_invoice.id)}
              >
                <img src={editImg} width="16" height="16" />
              </button>
              {debt.mg_document ? <button
                title="Download"
                style={{
                  background: "transparent",
                  cursor: "pointer",
                  marginRight: 10
                }}
                onClick={() => this.downloadInvoice(debt.mg_document.document_id, debt.mg_document.name)}
              >
                <img src={downloadImg} width="16" height="16" />
              </button> : null}
              </>
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
          },
        ],
        status: this.getAccountStatus(debt.debt_invoice),
        taskStatus:
          debt.debt_invoice.balance && debt.debt_invoice.balance <= 0
            ? true
            : false,
        rowData: debt,
      };
    });

    return {
      headings,
      columns,
    };
  };

  //returns formatted table data with checkboxes
  getObjectListDataWithCheckbox = (data) => {
    let headings = [],
      columns = [];

    headings = [
      "",
      "Inv. #",
      "Inv. Date",
      "Due Date",
      "Total",
      "Amount Paid",
      "Discount",
      "Balance",
      "expander",
    ];

    columns = data.map((debt, index) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "",
            title: <div className="email-value-wrapper">
              <input className="email-checkbox" type="checkbox" id={`tax00${index}`} name={'tax'} value={'tax'} checked={debt.emailCheck} onChange={(e) => this.onCheckboxChange(e, debt, index) } />
            </div>
          },
          {
            key: "Inv. #",
            title: debt.debt_invoice.invoice_number,
          },
          {
            key: "Inv. Date",
            title: this.renderLatestPaymentDate(debt.debt_invoice.txn_date),
          },
          {
            key: "Due Date",
            title: this.renderLatestPaymentDate(debt.debt_invoice.due_date),
          },
          {
            key: "Total",
            title: getDollarAmount(debt.debt_invoice.total_amt),
          },
          {
            key: "Amount Paid",
            title: getDollarAmount(this.getPaidAmount(debt.debt_invoice)),
          },
          {
            key: "Discount",
            title: getDollarAmount(debt.debt_invoice.adjustment),
          },
          {
            key: "Balance",
            title: getDollarAmount(debt.debt_invoice.balance),
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
          },
        ],
        status: this.getAccountStatus(debt.debt_invoice),
        taskStatus:
          debt.debt_invoice.balance && debt.debt_invoice.balance <= 0
            ? true
            : false,
        rowData: debt,
      };
    });

    return {
      headings,
      columns,
    };
  };

   //returns formatted credit memo table data
   getMemoObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = [
      "Credit No.",
      "Date",
      "Amount",
      "Status",
      "Action",
      "expander",
    ];

    columns = data.map((debt) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Credit No.",
            title: debt.credit_memo_number,
          },
          {
            key: "Date",
            title: this.renderLatestPaymentDate(debt.date),
          },
          {
            key: "Amount",
            title: getDollarAmount(debt.total_amount),
          },
          {
            key: "status",
            title: this.getCreditMemoStatus(debt),
          },
          {
            key: "Action",
            title: this.getCreditMemoStatus(debt) === 'Unapplied'  ? <button
              title="Delete"
              style={{
                background: "transparent",
                cursor: "pointer",
                marginRight: 15,
              }}
            onClick={() => this.deleteCreditMemo(debt.id)}
            >
              <img src={deleteImg} width="16" height="16" />
            </button> : '-'
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
          },
        ],
        // status: this.getAccountStatus(debt.debt_invoice),
        // taskStatus:
        //   debt.debt_invoice.balance && debt.debt_invoice.balance <= 0
        //     ? true
        //     : false,
        rowData: debt,
      };
    });

    return {
      headings,
      columns,
    };
  };

  //returns formatted credit memo table data
  getStatementObjectListData = (data) => {
    let headings = [],
        columns = [];

    headings = [
      "Month",
      "Statement Date",
      "",
      "Action"
    ];

    columns = data.map((statement) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Month",
            title: statement.month
          },
          {
            key: "Statement Date",
            title: this.renderLatestPaymentDate(statement.statement_date)
          },
          {
            key: "",
            title: ""
          },
          {
            key: "Action",
            title: <>
              <button
                  title="View"
                  className="view-button"
                  style={{
                    background: "transparent",
                    cursor: "pointer",
                    marginRight: 15
                  }}
                  onClick={() => this.viewStatement(statement.id)}
              >
                <img src={viewIcon} width="16" height="16" />
              </button>
              <a
                className="make-payment-button"
                style={{ cursor: "pointer", marginRight: 15 }}
                title="Download"
                onClick={() =>
                    this.downloadStatement(statement.id, statement.month, statement.statement_date)
                }
            >
              <img src={downloadImg} width="16" height="16" />
            </a>
            </>
          }
        ],
        rowData: statement
      };
    });

    return {
      headings,
      columns,
    };
  };

  viewStatement = (statement_id) => {
    const { user } = this.props;
    var me = this;
    API.downloadStatement(user, statement_id).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let flag = data.data.includes("base64");
          if (!flag) {
            data.data = "data:application/pdf;base64," + data.data;
          }
          let props = {
            image: data.data,
            user: me.props.user,
          };
          me.props.updateModalState(true, "SHOW_IMAGE", props);
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
                ? data.status ||
                (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                500
                : 500,
          },
          showLoader: false,
        }));
      }
    });



  };

  downloadStatement = (statement_id, statement_month, statement_date) => {
    const { user } = this.props;
    let temp_date = statement_date.split('-')[2];
    console.log('temp_date--------',temp_date);
    API.downloadStatement(user, statement_id).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let flag = data.data.includes("base64");
          if (!flag) {
            data.data = "data:application/pdf;base64," + data.data;
          }
          let url = data.data;
          let a = document.createElement("a");
          a.href = url;
          a.download = temp_date+'_'+statement_month;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
                ? data.status ||
                (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                500
                : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  emptyView = (activities) => {
    return (
      <div className="empty-activity" style={{ marginBottom: "1em" }}>
        <p>There are currently no {activities} to review.</p>
      </div>
    );
  };

  //show table and card data
  renderTable = (tableData) => {
    const {isOpenInvoiceView} = this.state;
    let emptyStateMessage = "No Invoices Available";

    return tableData.length > 0 ? (
      <div>
        <div className="big-device-grad invoice-line">
          <ClientsCardView
            data={isOpenInvoiceView ? this.getObjectListDataWithCheckbox(tableData) : this.getObjectListData(tableData)}
            isStatus={true}
            rowActionClick={(event) => this.rowExpanderClick(event)}
          />
        </div>
        <InvoiceObjectList
          data={isOpenInvoiceView ? this.getObjectListDataWithCheckbox(tableData) : this.getObjectListData(tableData)}
          rowActionClick={(event) => this.rowExpanderClick(event)}
          isStatus={true}
          hideForMobile={true}
        />
      </div>
    ) : (
      <div
        className="admin-admins-empty-state"
        style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
      >
        {this.state.isTableLoading ? (
          <AppCanvasLoader />
        ) : (
          <p>{emptyStateMessage}</p>
        )}
      </div>
    );
  };

  renderCreditMemoTable = (tableData) => {
    let emptyStateMessage = "No Credit Memos Available";

    return tableData.length > 0 ? (
      <div>
        <div className="big-device-grad invoice-line">
          <ClientsCardView
            data={this.getMemoObjectListData(tableData)}
            isStatus={false}
            rowActionClick={(event) => this.rowExpanderClickForMemo(event)}
          />
        </div>
        <InvoiceObjectList
          data={this.getMemoObjectListData(tableData)}
          rowActionClick={(event) => this.rowExpanderClickForMemo(event)}
          isStatus={false}
          hideForMobile={true}
        />
      </div>
    ) : (
      <div
        className="admin-admins-empty-state"
        style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
      >
        {this.state.isTableLoading ? (
          <AppCanvasLoader />
        ) : (
          <p>{emptyStateMessage}</p>
        )}
      </div>
    );
  }


  renderStatementTable = (tableData) => {
    let emptyStateMessage = "No Statement Available";
    return tableData.length > 0 ? (
        <div>
          <div className="big-device-grad invoice-line">
            <ClientsCardView
                data={this.getStatementObjectListData(tableData)}
                isStatus={false}
                rowActionClick={(event) => this.rowExpanderClickForMemo(event)}
            />
          </div>
          <InvoiceObjectList
              data={this.getStatementObjectListData(tableData)}
              rowActionClick={(event) => this.rowExpanderClickForMemo(event)}
              isStatus={false}
              hideForMobile={true}
          />
        </div>
    ) : (
        <div
            className="admin-admins-empty-state"
            style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
        >
          {this.state.isTableLoading ? (
              <AppCanvasLoader />
          ) : (
              <p>{emptyStateMessage}</p>
          )}
        </div>
    );
  }

  //calls when add invoice button click to open add invoice screen
  handleAddInvoiceClick = (e) => {
    let { history } = this.props;
    const id = this.props.match.params.id;
    history.push({
      pathname: `/client/add/invoices`,
      state: {
        consumer: this.state.consumer,
        backPath: `/client/accounts/${id}`,
      },
    });
  };

  //calls when add invoice button click to open add credit memo screen
  handleAddCreditMemoClick = (e) => {
    let { history } = this.props;
    const id = this.props.match.params.id;
    history.push({
      pathname: `/client/add/creditMemo`,
      state: {
        consumer: this.state.consumer,
        backPath: `/client/accounts/${id}`,
      },
    });
  };

  //calls when add invoice button click to open add credit memo screen
  handleGenerateStatementClick = (e) => {
    this.generateStatement();
  };

  //get header with add invoice button
  getSectionHeaderUtilities = () => {
    const { activeTab, isOpenInvoiceView } = this.state;

    return (
      activeTab === 1 ?
      <div className="right-section">
          {isOpenInvoiceView && 
            <button
              className="mg-brand2-color send-invoice-email-btn"
              onClick={() => this.sendMultipleInvoice()}
              style={{marginRight: '15px'}}
              >
                Send Email
              </button>}
          <DropdownButton
            title="+ Add Invoice"  //Options
            primaryLink="/client/accounts"
            overrideStyle={true}
            parentOptClasses={"account-re-down-mob"}
            buttonInnerClasses={"down-inner"}
            forFilter={true}
            clientMobile={true}
            totalFilter={true}
            onPrimaryClick={(event) => { }}
          >
            <MenuItem handleClick={this.handleAddInvoiceClick}>
              Add Invoice
            </MenuItem>
          {!isOpenInvoiceView ? <MenuItem handleClick={this.openInvoiceView}>
              Send Multiple Invoices
            </MenuItem>
              : <MenuItem handleClick={this.closeOpenInvoiceView}>
                Back to all invoices list
          </MenuItem>}
          </DropdownButton>
      </div>
        : activeTab === 2 ?
          <DropdownButton
              title="+ Generate Statement"
              primaryLink="/client/accounts"
              overrideStyle={true}
              parentOptClasses={"account-re-down-mob"}
              buttonInnerClasses={"down-inner"}
              forFilter={true}
              clientMobile={true}
              totalFilter={true}
              onPrimaryClick={(event) => { }}
          >
            <MenuItem handleClick={this.handleGenerateStatementClick}>
              Generate Statement
            </MenuItem>
          </DropdownButton>
        : <DropdownButton
          title="+ Add Credit Memo"
          primaryLink="/client/accounts"
          overrideStyle={true}
          parentOptClasses={"account-re-down-mob"}
          buttonInnerClasses={"down-inner"}
          forFilter={true}
          clientMobile={true}
          totalFilter={true}
          onPrimaryClick={(event) => { }}
        >
          <MenuItem handleClick={this.handleAddCreditMemoClick}>
            Add Credit Memo
          </MenuItem>
        </DropdownButton>
    );
  };

  //get three dot popover 
  renderThreeDotOptions = () => {
    return (
      <HeaderOverflowMenu showThreeDots={true} optionalClasses={'account-dropdown-optional'} innerOptionalClasses={'account-dropdown'}>
        {this.state.rowAction.map((rowAction) => {
          return (
            <HeaderMenuItem
              key={shortid.generate()}
              rowAction={rowAction}
              dataBehavior={rowAction.dataBehavior}
              handleClick={(event, rowAction) =>
                this.handleOptionClick(event, rowAction)
              }
              optionalClasses={`drop-down-options account-drop-down-options ${rowAction && rowAction.title && rowAction.title === this.state.selectedOPT ? 'selected-account-opt' : ''}`}
            >
              {rowAction}
            </HeaderMenuItem>
          );
        })}
      </HeaderOverflowMenu>
    )
  }
  
  setRowActions = () => {
    let { user } = this.props;
    let data = [
      {
        title: 'Invoices',
        dataBehavior: "invoices",
        icon: (
          <div
            className={`app-client-header-user-avatar app-client-header-icon`}
          >
            {/* <span>{this.renderUserInitials(user)}</span> */}
          </div>
        ),
      },
      {
        title: "Credit Memo",
        dataBehavior: "credit-memo",
        icon: (
          <div className="app-client-header-icon">
            {/* <img src={settings} width="20" height="20" /> */}
          </div>
        ),
      },
    ];
    this.setState({ rowAction: data });
  };

  //calls when select any option.
  handleOptionClick = (event, rowAction) => {
    let { history } = this.props;
    const actionBehavior =
      rowAction && rowAction.dataBehavior ? rowAction.dataBehavior : "";
    const actionTitle = rowAction && rowAction.title ? rowAction.title : "";

    switch (actionBehavior) {
      case "invoices":
        this.onOptionChange(actionTitle);
        break;
      case "credit-memo":
        this.onOptionChange(actionTitle);
        break;
      default:
        this.setState({selectedOPT: 'Invoices'});
    }
  };

  onOptionChange = (index) => {
    const { activeTab } = this.state;
    const id = this.props.match.params.id;
    this.setState({ isTableLoading: true }, () => {
      if (activeTab === 1) {
        this.getData(id);
      } else if (activeTab === 2) {
        this.getGenerateStatementList(id);
      }else if (activeTab === 3) {
        this.getCreditMemoList(id);
      }
    })
  }

  //calls when tab click event triggers
  handleTabChange = (tabIndex) => {
    if (tabIndex !== this.state.activeTab) {
      this.setState({
        activeTab: tabIndex,
        isOpenInvoiceView: false
      }, () => {
        this.onOptionChange(tabIndex);
      });
      
    }
    
  };

  //render UI for Tabbar
  getSectionHeaderUtilitiesNew() {
    const { activeTab } = this.state;
    // const tabLabels = ["Tasks", "QuickBooks", "Payments", "Goods & Services", "Terms"];
    const tabLabels = ["Invoices", "Statements", "Credit Memos",];

    return (
      <Tabs
        labels={tabLabels}
        activeTarget={activeTab}
        handleTabChange={this.handleTabChange}
        optionalInTabClasses="mg-tabs-tab"
        optionalClasses="account-tp-sp con-fit large-tabs account-tabs"
      />
    );
  }

  render() {
    const { appName, user, history } = this.props;
    const {
      consumer,
      debts,
      isLoading,
      showValidationScreen,
      selectedDebt,
      upcoming_activities,
      recent_activities,
      error,
      selectedOPT,
      creditMemoList,
      activeTab,
      statementList
    } = this.state;

    let backPath = showValidationScreen
      ? `/client/accounts/${consumer.id}`
      : "/client/accounts";
    let backText = showValidationScreen
      ? "Back to Account"
      : "Back to All Accounts";

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }
    let first_name = consumer.first_name? consumer.first_name : ""
    let last_name = consumer.last_name? consumer.last_name : ""

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className="client-scroll-dashboard scrollbar">
        <Helmet>
          <title>
            {appName} | {first_name + " " + last_name}
          </title>
        </Helmet>
        <div className="app-sidebar-layout-canvas">
          <ClientsSidebar
            {...this.props}
            backPath={backPath}
            backText={backText}
            account={consumer}
            user={user}
            history={history}
            upcoming_activities={upcoming_activities}
            recent_activities={recent_activities}
            handleEditSetting={this.handleEditSetting}
          />
          <div className="app-sidebar-layout-canvas-content setting-side-canvas big-device-grad col1-scroll scrollbar">
            {showValidationScreen ? (
              <ClientsUploadValidationDoc
                {...this.props}
                debtData={selectedDebt}
                consumer={consumer}
              />
            ) : (
              <div>
                <div
                className="account-view-subheader-wrapper"
                  style={{
                    display: "flex",
                    marginBottom: "1em",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="he-hed" style={{ marginBottom: "0em", marginTop: "0em", display: "flex", flexDirection: "row" }}>
                    {/* Invoices */}
                    {/* <div className="selected-account-view-title">
                      {this.state.selectedOPT}
                    </div> */}
                    {/**TO_DO */}
                    {/* {this.renderThreeDotOptions()} */}
                    {this.getSectionHeaderUtilitiesNew()} 
                  </div>
                  {this.getSectionHeaderUtilities()}
                </div>
                {/* {this.renderTable(debts)} */}
                {activeTab === 1 ? this.renderTable(debts) : activeTab === 2 ? this.renderStatementTable(statementList) : this.renderCreditMemoTable(creditMemoList)}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ClientsAccountsView;
