import React, { Component } from 'react'
import { withRouter, Redirect } from "react-router-dom"
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import moment from 'moment';
import BottomScrollListener from 'react-bottom-scroll-listener';
import ReactGA from 'react-ga';

// API
import * as API from '../../../utils/api'

import {getZohoPagesense} from '../../../utils/helpers';

// Components
import AppCanvasLoader from '../../../components/App/AppCanvasLoader'
import ObjectHistoryList from '../../../components/ObjectList/ObjectHistoryList';
import AppSectionHeader from '../../../components/App/AppSectionHeader';

class EmployeeMailchimpHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            savedData: [],
            fromChild: '',
            isLoading: true,
            hasMoreData: false,
            showFlash: false,
            filterBy: {
                value: props.filterBy || 'All',
                offset: 0,
            },
            search: {
                value: props.searchText || '',
            },
            showLoader: false
        }
    }

    componentDidMount() {
        this.getHistoryData();
        getZohoPagesense();
        ReactGA.pageview('/MailchimpHistory');
    }

    componentWillReceiveProps(nextProps) {
        if ((nextProps.searchText !== this.props.searchText) || (nextProps.filterBy != this.props.filterBy)) {
            this.setState(state => ({
                ...state,
                filterBy: {
                    ...state.filterBy,
                    value: nextProps.filterBy || 'All',
                    offset: 0
                },
                search: {
                    value: nextProps.searchText
                },
                savedData: [],
                isLoading: true
            }), () => {
                this.getHistoryData();
            })
        }
    }

    handleContainerOnBottom = () => {
        if (!this.state.hasMoreData) {
            let offsetCount = parseInt(this.state.offset) + 1
            this.setState(state => ({
                ...state,
                offset: offsetCount
            }),
                () => {
                    this.getHistoryData();
                });
        }
    }

    getHistoryData() {
        const { user } = this.props;
        const { filterBy, search } = this.state;
        let campaign_id = this.props.match.params.id;

        this.setState(state => ({ ...state, showLoader: true }));
        API.fetchMailchimpCampaignMemberHistory(user, campaign_id, filterBy.offset, search.value).then(data => {
            if ((typeof data != 'undefined' || data != null) && (!data.errors && !data.error)) {
                let savedData = this.state.savedData;
                let newData = savedData.concat(data.data.sent_to);
                this.setState(state => ({
                    savedData: newData,
                    isLoading: false,
                    hasMoreData: data.data.length > 0 ? false : true,
                    showLoader: false
                }))

                this.setState(state => ({
                    ...state,
                    filterBy: {
                        ...state.filterBy,
                        offset: data.offset
                    }
                }));
            } else {
                this.setState(state => ({
                    ...state,
                    isLoading: false,
                    error: {
                        hasAnyError: true,
                        statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
                    },
                    showLoader: false
                }))
            }
        })
    }


    renderLatestPaymentDate = (date) => {
        return moment(date).format("MM/DD/YYYY");
    }

    getObjectListData = (data) => {
        let headings = [],
            columns = [];
        headings = [
            "Name",
            "Email Address",
            "Status",
            "Last Open",
            "Tracking Id"
        ]

        columns = data.map((row) => {
            return {
                primaryColumn: {},
                secondaryColumns: [
                    {
                        title: row.merge_fields ? row.merge_fields.FNAME + ' ' + row.merge_fields.LNAME : 'n/a'
                    },
                    {
                        title: row.email_address,
                        // optionalClasses: `consumer-payment-history-column`
                    },
                    {
                        title: row.status,
                    },
                    {
                        title: row.last_open ? moment(row.last_open).format('MM/DD/YYYY') : 'n/a',
                    },
                    {
                        title: row.email_id,
                    }
                ]
            }
        })
        return {
            headings,
            columns
        }
    }

    render() {
        const { isLoading } = this.state;

        return isLoading
            ? (
                <div style={{ marginTop: `10rem` }}>
                    <AppCanvasLoader />
                </div>
            )
            : (
                <div>
                    <AppSectionHeader
                        title="Mail History"
                        optionalClassNames="secondary"
                        backLink={{
                            text: "Back to all email",
                            path: "/employee/messageHistory/1"
                        }}
                    />
                    {
                        this.state.savedData.length > 0 ?
                            <BottomScrollListener onBottom={this.handleContainerOnBottom} debounce={0}>
                                {scrollRef => (
                                    <div>
                                        <ObjectHistoryList
                                            optionalClasses={"object-debt-list"}
                                            data={this.getObjectListData(this.state.savedData)}
                                            scrollRef={scrollRef}
                                        />
                                        {this.state.showLoader && <div><AppCanvasLoader /></div>}
                                    </div>
                                )}
                            </BottomScrollListener>
                            :
                            <div className="admin-admins-empty-state">
                                <h2>No email history available.</h2>
                            </div>
                    }
                </div>
            );
    }

}

export default withRouter(EmployeeMailchimpHistory)
