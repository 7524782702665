import React, { Component } from "react";
import { Table } from "reactstrap";
import shortid from "shortid";
import moment from "moment";

class InvoiceObjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleExpanderClick = (event, rowData) => {
    event.preventDefault();
    this.props.rowActionClick(rowData);
  };

  showOnlyHeader = (header) => {
    return (
      header === "Paid Amount" ||
      header === "Discount" ||
      header === "Auto Scheduled"
    );
  };

  renderRowExpander = (rowData, col) => {
    let { fromConsumer } = this.props;
    let type = col.icon;
    if (type && type === "up-down") {
      return (
        <a
          key={shortid.generate()}
          className={`client-caret expander-caret${
            col.isExpanded ? " down-caret" : ""
          }${fromConsumer ? " consumer-brand" : ""}`}
          onClick={(event) => this.handleExpanderClick(event, rowData)}
        ></a>
      );
    } else if (type && type === "eye") {
      return (
        <a
          key={shortid.generate()}
          className={`object-row-expander`}
          style={{ marginLeft: -16, marginTop: 5 }}
          onClick={(event) => this.handleExpanderClick(event, rowData)}
        >
          <svg className="icon object-row-view">
            <use xlinkHref="#view" />
          </svg>
        </a>
      );
    } else
      return (
        <a
          key={shortid.generate()}
          className={`client-caret expander-caret`}
          onClick={(event) => this.handleExpanderClick(event, rowData)}
        ></a>
      );
  };

  renderPrimaryColumn = (primaryColumn) => {
    let primaryContent = (
      <div className="object-row-header-name primary-column-title">
        {primaryColumn.title}
      </div>
    );

    let secondaryContent = primaryColumn.secondaryInfo ? (
      <div className="object-row-header-secondary-info primary-column-info">
        {primaryColumn.secondaryInfo}
      </div>
    ) : null;

    return (
      <div>
        {primaryContent}
        {secondaryContent}
      </div>
    );
  };

  showSortContent = (header) => {
    const { sortHeaders } = this.props;
    if (header === sortHeaders[0]) {
      return (
        <div
          className="caret-header-title"
          onClick={() => this.props.sortInvoiceData(header, !sortHeaders[1])}
        >
          {header}
          <div
            id="table-header-caret"
            className={`client-caret${
              sortHeaders[1] ? " client-caret-up" : ""
            }`}
            style={{ marginLeft: "0.4em" }}
          />
        </div>
      );
    } else {
      return (
        <div
          className="caret-header-title"
          onClick={() => this.props.sortInvoiceData(header, true)}
        >
          {header}
          {header !== 'Action' && <div
            id="table-header-caret"
            className={`client-caret`}
            style={{ marginLeft: "0.4em" }}
          />}
        </div>
      );
    }
  };

  render() {
    let {
      data,
      isStatus,
      isBeforeIcon,
      hideForMobile,
      optionalClasses,
      optionalTableClasses,
      scrollRef,
      sortHeaders,
      isPrimaryColumn,
    } = this.props;
    let status_style = isStatus ? " header-status-space" : "";
    let primary_col_style = isPrimaryColumn ? " primary-column" : "";
    return (
      <Table
        responsive
        hover
        className={`invoice-object-list${
          hideForMobile ? " mobile-hide-list" : ""
        }${optionalTableClasses ? " " + optionalTableClasses : ""}`}
      >
        <thead>
          <tr className="list-header">
            {isBeforeIcon && <th style={{ flex: 0, marginRight: "20px" }} />}
            {data.headings &&
              data.headings.map((header, index) => {
                return (
                  <th
                    key={shortid.generate()}
                    className={
                      index == 0
                        ? status_style
                        : header === "expander"
                        ? " row-expander"
                        : ""
                    }
                  >
                    {header === "expander"
                      ? ""
                      : sortHeaders
                      ? header === "expander"
                        ? null
                        : this.showOnlyHeader(header)
                        ? header
                        : this.showSortContent(header)
                      : header}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody
          ref={scrollRef}
          className={`${optionalClasses ? optionalClasses : ""}`}
        >
          {data.columns &&
            data.columns.map((item, index) => {
              return (
                <tr key={shortid.generate()}>
                  {isStatus ? (
                    <td
                      className={`status-point ${
                        item.taskStatus ? "col3" : item.status ? "col1" : "col2"
                      }`}
                    />
                  ) : isBeforeIcon ? (
                    item.icon
                  ) : (
                    ""
                  )}
                  {item.primaryColumn && (
                    <td key={shortid.generate()} className={primary_col_style}>
                      {this.renderPrimaryColumn(item.primaryColumn)}
                    </td>
                  )}
                  {item.secondaryColumns &&
                    item.secondaryColumns.map((col, index) => {
                      if (col.rowType && col.rowType === "arrow-click") {
                        return this.renderRowExpander(item, col);
                      } else if (col.rowType && col.rowType === "hide-task") {
                        return <div style={{ width: 12 }} />;
                      }
                      return (
                        <td
                          key={shortid.generate()}
                          className={col.optionalClasses}
                        >
                          {col.title}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  }
}

export default InvoiceObjectList;
