import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Table } from "reactstrap";
import shortid from "shortid";
import editIcon from "../../../assets/icons/edit_blue.svg"

class AccountsObjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleExpanderClick = (event, rowData) => {
    event.preventDefault();
    this.props.rowActionClick(rowData);
  };

  goToEditSetting = (e, path) => {
    e.preventDefault();
    let finalPath = path + '/edit-setting';
    console.log('finalPath ==> ', finalPath);
    this.props.history.push(finalPath);
  }

  renderRowExpander = (rowData) => {
    return (
      <a
        key={shortid.generate()}
        className={`client-caret expander-caret`}
        onClick={(event) => this.handleExpanderClick(event, rowData)}
      ></a>
    );
  };

  renderPrimaryColumn = (primaryColumn) => {
    let primaryContent = (
      <div className="object-row-header-name primary-column-title">
        {primaryColumn.title}
        {primaryColumn.editIcon
        ? <img 
            className="edit-icon" 
            src={editIcon}
            onClick={(e) => this.goToEditSetting(e, primaryColumn.rowPath)} /> 
        : null}
      </div>
    );

    let secondaryContent = primaryColumn.secondaryInfo ? (
      <div className="object-row-header-secondary-info primary-column-info">
        {primaryColumn.secondaryInfo}
      </div>
    ) : null;

    return (
      <Link to={primaryColumn.rowPath} style={{ textDecoration: "none" }}>
        {primaryContent}
        {secondaryContent}
      </Link>
    );
  };

  showSortContent = (header) => {
    const { sortHeaders } = this.props;
    if (header === sortHeaders[0]) {
      return (
        <div
          className="caret-header-title"
          onClick={() => this.props.sortAccountData(header, !sortHeaders[1])}
        >
          {header}
          <div
            className={`client-caret${
              sortHeaders[1] ? " client-caret-up" : ""
            }`}
            style={{ marginLeft: "0.4em" }}
          />
        </div>
      );
    } else {
      return (
        <div
          className="caret-header-title"
          onClick={() => this.props.sortAccountData(header, true)}
        >
          {header}
          <div className={`client-caret`} style={{ marginLeft: "0.4em" }} />
        </div>
      );
    }
  };

  render() {
    let {
      data,
      isPrimaryColumn,
      isStatus,
      hideForMobile,
      optionalClasses,
      optionalTableClasses,
      sortHeaders,
    } = this.props;
    let primary_col_style = isPrimaryColumn ? " primary-column" : "";
    let status_style = isStatus
      ? primary_col_style + " header-status-space"
      : primary_col_style;
    return (
      <Table
        responsive
        hover
        className={`account-object-list${
          hideForMobile ? " mobile-hide-list" : ""
        }${optionalTableClasses ? " " + optionalTableClasses : ""}`}
      >
        <thead>
          <tr className="list-header">
            {data.headings &&
              data.headings.map((header, index) => {
                return (
                  <th
                    key={shortid.generate()}
                    className={
                      index == 0
                        ? status_style
                        : header === "expander"
                        ? " row-expander"
                        : ""
                    }
                  >
                    {header === "expander"
                      ? ""
                      : sortHeaders
                      ? header === "Last Payment Date" ||
                        header === "Next Payment Date"
                        ? header
                        : this.showSortContent(header)
                      : header}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody
          ref={this.props.scrollRef}
          className={`${optionalClasses ? optionalClasses : ""}`}
        >
          {data.columns &&
            data.columns.map((item, index) => {
              return (
                <tr key={shortid.generate()}>
                  {isStatus && (
                    <td
                      className={`status-point ${
                        item.status ? "col1" : "col2"
                      }`}
                    />
                  )}
                  {item.primaryColumn && (
                    <td key={shortid.generate()} className={primary_col_style}>
                      {this.renderPrimaryColumn(item.primaryColumn)}
                    </td>
                  )}
                  {item.secondaryColumns &&
                    item.secondaryColumns.map((col, index) => {
                      if (col.rowType && col.rowType === "arrow-click") {
                        return this.renderRowExpander(item);
                      }
                      return (
                        <td
                          key={shortid.generate()}
                          className={col.optionalClasses}
                        >
                          {col.title}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  }
}

export default withRouter(AccountsObjectList);
