import React, { Component } from 'react';
import moment from "moment";
import ObjectHistoryList from '../../../../components/ObjectList/ObjectHistoryList';
// Components
import Modal from '../Modal';
import Panel from '../../Panel';


var statusPillClass = '';
class ShowMailDescriptionModal extends Component {

  getObjectListData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      "Timestamp",
      "IP Address",
      "Location"
    ]

    columns = data.map((row) => {
      return {
        primaryColumn: {},
        secondaryColumns: [
          {
            title: moment.unix(parseInt(row.timestamp)).format("MM/DD/YYYY HH:mm:ss"),
          },
          {
            title: row.ip
          },
          {
            title: row.location,
            optionalClasses: 'open-click-location'
          },
        ]
      }
    })
    return {
      headings,
      columns
    }
  }

  render() {
    let { mailInfo } = this.props.optionalProps;
    statusPillClass = (mailInfo.status == 'sent') ? 'success-message' : 'fail-message';

    return (
      <Modal
        title="Details"
        optionalClasses="add-account-modal mail-details scroll"
        hideModal={this.props.hideModal}
        closeOnClickOutside={true}
      >
        <div className="mail-details-mobile">
          <Panel>
          <div className='view-modal row-alignment'>
            <h4 class="panel-heading-secondary adjust-header">Email: </h4>
            <p >{mailInfo.email}</p>
          </div>
          <div className='view-modal row-alignment'>
            <h4 class="panel-heading-secondary adjust-header">Sender :</h4>
            <p>{mailInfo.sender}</p>
          </div>
          <div className='view-modal row-alignment'>
            <h4 class="panel-heading-secondary adjust-header">Template :</h4>
            <p>{mailInfo.template}</p>
          </div>
          <div className='view-modal row-alignment'>
            <h4 class="panel-heading-secondary adjust-header">Subject :</h4>
            <p>{mailInfo.subject}</p>
          </div>
          <div className='view-modal row-alignment modal-view-mobile'> 
            <div className='view-modal'>
              <h4 class="panel-heading-secondary adjust-nested-header">Subaccount :</h4>
              <p className="detail">{mailInfo.subaccount}</p>
            </div>
            <div className='view-modal'>
              <h4 class="panel-heading-secondary re-arrange-status">Status :</h4>
              <p className={statusPillClass}>{mailInfo.status}</p>
            </div>
          </div>
          <div className='view-modal modal-view-mobile'>
            <div className='view-modal'>
              <h4 class="panel-heading-secondary adjust-nested-header">Open Count :</h4>
              <p>{mailInfo.open_count}</p>
            </div>
            <div className='view-modal'>
              <h4 class="panel-heading-secondary adjust-nested-header">Click Count :</h4>
              <p>{mailInfo.click_count}</p>
            </div>
          </div>
          </Panel>
          {mailInfo.open_detail && mailInfo.open_detail.length > 0 ?
            <div>
              <ObjectHistoryList
                optionalClasses={"object-debt-list"}
                data={this.getObjectListData(mailInfo.open_detail)}
                hideScroll={true}
              />
            </div>
            : <div></div>}
        </div>
      </Modal>
    )
  }
}

export default ShowMailDescriptionModal;