import React, { Component } from "react";
import { Link } from "react-router-dom";

class ConsumerSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      title,
      subTitle,
      sidebarContent,
      optionalClassNames,
      BackLink,
      exPay,
    } = this.props;
    return (
      <div
        className={`app-sidebar app-sidebar-left accounts-view-sidebar clients-side-bar col1-scroll scrollbar ${
          exPay ? " payment-side-bar" : " consumer-side-bar"
        }`}
      >
        {title || subTitle || BackLink ? (
          <div
            className={`app-section-header client-section-header${
              optionalClassNames ? " " + optionalClassNames : ""
            }`}
            style={{ flexDirection: "column", alignItems: "flex-start", marginBottom: "1rem" }}
          >
            <div className="app-section-header-content consumer-sidebar">
              {BackLink ? (
                <Link
                  to={BackLink}
                  className="app-section-header-back-link"
                  style={{ margin: "7px 0px 0px 0px" }}
                  onClick={() => this.props.showHistory(false)}
                >
                  « Back
                </Link>
              ) : null}
              {title && (
                <h1
                  className="app-section-header-heading side-hed-col"
                  style={{ fontSize: "2em" }}
                >
                  {title}
                </h1>
              )}
              {subTitle && <h4>{subTitle}</h4>}
            </div>
          </div>
        ) : null}
        <div className="consumer-sidebar">{sidebarContent}</div>
      </div>
    );
  }
}

export default ConsumerSidebar;
