import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
// Components
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import Tabs from "../../components/Tabs";
import ToggleSwitch from "../../components/ToggleSwitch";
import * as API from "../../utils/api";

class ServiceSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceList: [],
      offset: 0,
      isLoading: true,
      tabLoader: false,
      activeTab: 1,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
    };
  }

  componentDidMount() {
    this.getClientServices(false);
  }

  reload = (flag) => {
    this.setState({ offset: 0 }, () => this.getClientServices(flag));
  };

  handleTabChange = (tabIndex) => {
    this.setState(
      {
        activeTab: tabIndex,
      },
      () => {
        this.getClientServices(false, true);
      }
    );
  };

  getClientServices = (reload, tabLoad) => {
    let { offset, serviceList, activeTab } = this.state;
    let type = activeTab == 1 ? "NonInventory" : "Service";

    if (tabLoad) this.setState({ tabLoader: true });

    API.getClientServices(this.props.user, offset, type).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let check = reload ? serviceList : false;
          let newData = check ? serviceList.concat(data.data) : data.data;
          this.setState((state) => ({
            ...state,
            serviceList: newData,
            isLoading: false,
            hasMoreData: data.data.length < 10 ? false : true,
          }));
        }
        this.setState({
          isLoading: false,
          showLoader: false,
          tabLoader: false,
        });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          tabLoader: false,
          error: {
            hasAnyError: true,
            message: data && data.error ? data.error : "",
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  //pagination api call when scroll reached bottom of the screen
  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
          showLoader: true,
        }),
        () => {
          this.getClientServices(true);
        }
      );
    }
  };

  //calls when "Add Service" button is click
  handleAddServiceClick = (e) => {
    e.preventDefault();
    const { user } = this.props;
    let props = {
      user: user,
      activeTab: this.state.activeTab,
      reload: this.reload,
    };
    //opens Add Service popup modal
    this.props.updateModalState(true, "ADD_SERVICE", props);
  };

  //calls on row arrow click 
  rowExpanderClick = (e) => {
    let props = {
      user: this.props.user,
      activeTab: this.state.activeTab,
      reload: this.reload,
      rowData: e.rowData,
    };
    //opens Edit Service popup modal
    this.props.updateModalState(true, "ADD_SERVICE", props);
  };

  showRate = (item) => {
    let rate = 0.0;
    if (item.rate) {
      rate = parseFloat(item.rate).toFixed(2);
    }
    if (item.is_rate_per_unit) {
      rate = rate + "/unit";
    } else {
      rate = rate + "/hour";
    }
    return "$" + rate;
  };

  //returns formatted table and card data
  getObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = ["Name", "Description", "Taxable", "Rate", "expander"];

    columns = data.map((item, index) => {
      return {
        primaryRow: {
          key: "Name",
          title: item.name ? item.name : "",
        },
        secondaryColumns: [
          {
            title: item.name ? item.name : "",
            hideForMobile: true,
          },
          {
            key: "Description",
            title: item.description,
          },
          {
            key: "Taxable",
            title: <ToggleSwitch isOn={item.is_taxable} />,
          },
          {
            key: "Rate",
            title: this.showRate(item),
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
          },
        ],
        rowData: item,
      };
    });

    return {
      headings,
      columns,
    };
  };

  showLoader = () => {
    return (
      <div style={{ marginTop: "20%" }}>
        <AppCanvasLoader />
      </div>
    );
  };

  render() {
    const { appName } = this.props;
    const { isLoading, error, serviceList, activeTab, tabLoader } = this.state;

    const tabLabels = ["Goods", "Services"];

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      this.showLoader()
    ) : (
      <div className="consumer-help">
        <Helmet>
          <title>{appName} | Goods & Services</title>
        </Helmet>
        <div className="big-device-grad ">
          <div className="goods-services-utility">
            <div>
              <div className="he-hed">Goods & Services</div>
              <Tabs
                labels={tabLabels}
                activeTarget={activeTab}
                handleTabChange={this.handleTabChange}
                optionalInTabClasses="mg-tabs-tab"
                optionalClasses="consumer-pay-tab tp-sp con-fit services-tab"
              />
            </div>
            <input
              type="submit"
              value={activeTab == 1 ? "+ Add Goods" : "+ Add Services"}
              className="cta mg-brand2-color"
              style={{ width: "auto" }}
              onClick={(event) => this.handleAddServiceClick(event)}
            />
          </div>
          <br />
          {tabLoader ? (
            this.showLoader()
          ) : serviceList && serviceList.length ? (
            <BottomScrollListener
              onBottom={this.handleContainerOnBottom}
              debounce={0}
            >
              {(scrollRef) => (
                <div ref={scrollRef} className="send-invoice-scroll scrollbar">
                  {/* cardview for mobile device */}
                  <ClientsCardView
                    data={this.getObjectListData(serviceList)}
                    rowActionClick={this.rowExpanderClick}
                  />
                  {/* list view for big devices */}
                  <InvoiceObjectList
                    data={this.getObjectListData(serviceList)}
                    hideForMobile={true}
                    rowActionClick={this.rowExpanderClick}
                    optionalTableClasses="client-service-list"
                  />
                  {this.state.showLoader && (
                    <div>
                      <AppCanvasLoader />
                    </div>
                  )}
                </div>
              )}
            </BottomScrollListener>
          ) : (
            <div className="admin-admins-empty-state">
              <h3 className="admin-admins-empty-state-heading empty-mesg-mg">
                No Services Available
              </h3>
              <p>There are currently no services available.</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ServiceSettings;
