import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import { withRouter } from "react-router-dom";
import "react-select/dist/react-select.css";
import AppCanvasLoader from "../../../../components/App/AppCanvasLoader";
import ToggleSwitch from "../../../../components/ToggleSwitch";
import * as API from "../../../../utils/api";
import ObjectDebtList from "../../../ObjectList/ObjectDebtList";
import Modal from "../Modal";

class ContactPreferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowToggle: false,
      selectedCheckBox: [],
      consumers: [],
      selected_contacts: [],
      isLoading: true,
      offset: 0,
      showLoader: false,
      buttonVisibility: false,
      hasError: false,
    };
  }

  componentDidMount() {
    let { user, selected_contacts } = this.props;
    this.setState({
      selected_contacts: selected_contacts,
    });
    if (user) {
      this.getSelectedContacts(false);
    }
  }

  handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    let data = this.removeDuplicate(this.state.selected_contacts);
    // if (data && data.length) {
      this.props.hideModal(data);
    // } else {
      // this.setState({ hasError: true });
    // }
  };

  getSelectedContacts = (reload) => {
    let { user, item } = this.props;
    this.setState((state) => ({ ...state, showLoader: true }));
    API.getSelectedContacts(user, item.id, this.state.offset).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        let consumers = reload ? this.state.consumers : false;
        let newData = consumers ? consumers.concat(data.data) : data.data;
        this.setState(
          (state) => ({
            ...state,
            consumers: newData,
            isLoading: false,
            hasMoreData: data.data.length < 10 ? false : true,
            showLoader: false,
          }),
          () => {
            this.setLocalId();
          }
        );
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  setLocalId = () => {
    let { consumers, selected_contacts } = this.state;
    if (consumers && consumers.length) {
      consumers.map((consumer, index) => {
        consumer.localId = index;
        if (selected_contacts && selected_contacts.length) {
          let index = selected_contacts.findIndex(
            (o) => o === consumer.user_id
          );
          if (index > -1) {
            consumer.is_selected = true;
          } else {
            consumer.is_selected = false;
          }
        }
      });
    }

    this.setState({
      consumers: consumers,
    });
  };

  removeDuplicate = (data) => {
    if (data) {
      return data.filter((value, index) => data.indexOf(value) === index);
    }
  };

  turnOnChecked = () => {
    this.state.consumers.map((consumer) => {
      if (consumer.is_checked) {
        consumer.is_selected = true;
        let index = this.state.selected_contacts.indexOf(consumer.user_id);
        if (index === -1) {
          this.state.selected_contacts.push(consumer.user_id);
        }
      }
    });
    this.setState({
      consumers: this.state.consumers,
      selected_contacts: this.state.selected_contacts,
    });
  };

  turnOffChecked = () => {
    this.state.consumers.map((consumer) => {
      if (consumer.is_checked) {
        consumer.is_selected = false;
        let index = this.state.selected_contacts.indexOf(consumer.user_id);
        if (index > -1) {
          this.state.selected_contacts.splice(index, 1);
        }
      }
    });
    this.setState({
      consumers: this.state.consumers,
      selected_contacts: this.state.selected_contacts,
    });
  };

  toggleButton = (user_id) => {
    this.state.consumers.map((consumer, index) => {
      if (consumer.user_id === user_id) {
        consumer.is_selected = !consumer.is_selected;
        if (consumer.is_selected) {
          let index = this.state.selected_contacts.indexOf(consumer.user_id);
          if (index === -1) {
            this.state.selected_contacts.push(consumer.user_id);
          }
        } else {
          let index = this.state.selected_contacts.indexOf(consumer.user_id);
          if (index > -1) {
            this.state.selected_contacts.splice(index, 1);
          }
        }
      }
    });
    this.setState({
      consumers: this.state.consumers,
      selected_contacts: this.state.selected_contacts,
      hasError: false,
    });
  };

  multiSelect = () => {
    if (this.state.selectedCheckBox.length > 1) {
      let first = this.state.selectedCheckBox[
        this.state.selectedCheckBox.length - 2
      ];
      let last = this.state.selectedCheckBox[
        this.state.selectedCheckBox.length - 1
      ];
      if (first > last) {
        let temp = first;
        first = last;
        last = temp;
      }
      this.state.consumers.map((consumer) => {
        if (consumer.localId >= first && consumer.localId <= last) {
          consumer.is_checked = true;
        }
      });
    }
    this.setState({ consumers: this.state.consumers });
  };

  onCheckboxClick = (event, item) => {
    const consumerId = event.target.name;
    let isShiftKey = event.shiftKey;
    this.state.consumers.map((consumer) => {
      if (consumer.user_id == consumerId) {
        if (event.target.checked) {
          consumer.is_checked = true;
          this.state.selectedCheckBox.push(item.localId);
        } else {
          consumer.is_checked = false;
          this.state.selectedCheckBox = [];
        }
      }
    });
    this.setState({ selectedCheckBox: this.state.selectedCheckBox }, () => {
      isShiftKey && this.multiSelect();
      this.buttonVisibility();
    });
  };

  buttonVisibility = () => {
    let { consumers, buttonVisibility } = this.state;
    let flag = false;
    consumers.map((item) => {
      if (item.is_checked) {
        flag = true;
      }
      this.setState({ buttonVisibility: flag });
    });
  };

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.getSelectedContacts(true);
        }
      );
    }
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = [];

    columns = data.map((item, index) => {
      return {
        primaryColumn: {},
        secondaryColumns: [
          {
            title: (
              <input
                name={item.user_id}
                value={item.user_id}
                key={item.user_id}
                type="checkbox"
                checked={item.is_checked}
                style={{
                  WebkitAppearance: `checkbox`,
                  width: `20px`,
                }}
                onChange={() => {}}
                onClick={(event) => this.onCheckboxClick(event, item)}
              />
            ),
            opClassesPar: "task-checkbox",
          },
          {
            title: item.display_name ? item.display_name : "",
          },
          {
            title: (
              <ToggleSwitch
                isOn={item.is_selected}
                toggleButton={() => this.toggleButton(item.user_id)}
              />
            ),
            opClassesPar: "task task-toggle task-toggle-sm",
          },
        ],
        fullRowIsClickable: false,
        rowType: "",
        optionalRowClasses: "object-row-inner-padding",
      };
    });

    return {
      headings,
      columns,
    };
  };

  getTopComponent = () => {
    let { selected_contacts, buttonVisibility } = this.state;
    return (
      <div>
        <p>
          Select multiple clients using the check box next to their name. Check
          one box and shift-click further down the list to select all the
          clients between. Alternatively, you can select or de-select clients
          individually using the on/off switch to the right of their name.
        </p>
        <div className="en-dis-btns">
          <button
            className={`cta modal-btns${
              buttonVisibility ? " mg-brand2-color" : " disable"
            }`}
            onClick={(event) => this.turnOnChecked(event)}
          >
            Enable
          </button>
          <button
            className={`cta modal-btns${
              buttonVisibility ? " mg-brand2-color" : " disable"
            }`}
            onClick={(event) => this.turnOffChecked(event)}
          >
            Disable
          </button>
        </div>
        <div className={`contact-count`} style={{ marginBottom: "1rem" }}>
          {selected_contacts &&
          selected_contacts.length &&
          selected_contacts.length == 1
            ? "Selected Contact: "
            : "Selected Contacts: "}
          {selected_contacts && selected_contacts.length
            ? selected_contacts.length
            : 0}
        </div>
      </div>
    );
  };

  render() {
    let { consumers, isLoading, hasError } = this.state;
    return (
      <Modal
        title="Select Clients for Contact Preferences"
        closeOnClickOutside={true}
        hideModal={this.handleSubmit}
        style={{ maxWidth: `35em`, paddingBottom: "1em" }}
        optionalClasses="scroll task-popup-sp"
        titleClasses="modal-header"
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <div>
            {this.getTopComponent()}
            {consumers && consumers.length > 0 && (
              <BottomScrollListener
                onBottom={this.handleContainerOnBottom}
                debounce={0}
              >
                {(scrollRef) => (
                  <div>
                    <ObjectDebtList
                      data={this.getObjectListData(consumers)}
                      scrollRef={scrollRef}
                      removePadding={true}
                      showLoader={this.state.showLoader}
                    />
                  </div>
                )}
              </BottomScrollListener>
            )}
            {hasError ? (
              <div
                className="input-container error"
                style={{
                  marginBottom: "1.5em",
                  marginTop: "-1em",
                  display: "flex",
                }}
              >
                <div className="input-error-message" style={{ marginTop: 0 }}>
                  Please Select contact
                </div>
              </div>
            ) : null}
            <div className="modal-actions" style={{ marginBottom: "2rem" }}>
              <a
                className="cta mg-brand2-color"
                style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
                onClick={(event) => this.handleSubmit(event)}
              >
                Done
              </a>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

export default withRouter(ContactPreferences);
