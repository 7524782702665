import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import MaskedTextInput from "react-text-mask";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";
import ToggleSwitch from "../../../ToggleSwitch";
// API
import * as API from "../../../../utils/api";
import { showToast } from "../../../../utils/helpers";
import ContactPreferences from "./ContactPreferencesModal";
import Modal from "../Modal";
import InfoIcon from "../../../../assets/images/quickbooks/ic_info_gray.png";

class EditTaskConfig extends Component {
  state = {
    input: {
      auto_send: false,
      auto_send_on_import: false,
      delivery_method: "Email & Letter",
      internal: false,
      is_all_client: false,
      name: "",
      reminder_schedule: 0,
      contact_count: 0,
      selected_contacts: [],
      state: false,
      ccPref: "All Clients",
      document_type_ids: [],
    },
    documentType: [],
    docTypeList: [],
    showPrefPopUp: false,
    delivery_Categories: [],
    ccOptions: [
      {
        label: "All Clients",
        value: "All Clients",
        flag: true,
      },
      {
        label: "Selected",
        value: "Selected",
        flag: false,
      },
    ],
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    let { delivery_Categories } = this.state;
    let { optionalProps } = this.props;
    if (optionalProps && optionalProps.item) {
      if (optionalProps.item.is_all_client) {
        optionalProps.item.ccPref = "All Clients";
      } else {
        optionalProps.item.ccPref = "Selected";
      }
      if (!optionalProps.item.state) {
        optionalProps.item.auto_send = false;
        optionalProps.item.auto_send_on_import = false,
        optionalProps.item.internal = false;
        optionalProps.item.is_all_client = false;
        optionalProps.item.ccPref = "All Clients";
        optionalProps.item.delivery_method = "Email & Letter";
      }
      if(optionalProps.item.document_types && optionalProps.item.document_types.length) {
        optionalProps.item.document_type_ids = [];
        optionalProps.item.document_types.map((id) => {
          optionalProps.item.document_type_ids.push(id.id)
        })
        this.state.documentType = optionalProps.item.document_types
      }

      if(optionalProps.item.communication_methods) {
        let i = optionalProps.item.communication_methods.findIndex((o) => o == 'Email');
        let i1 = optionalProps.item.communication_methods.findIndex((o) => o == 'Letter');
        if(i > -1) {
          delivery_Categories.push({
            label: "Email Only",
            value: "Email Only",
          })
        } if(i1 > -1) {
          delivery_Categories.push({
            label: "Letter Only",
            value: "Letter Only",
          })
        }
        if(i > -1 && i1 > -1) {
          delivery_Categories.push({
            label: "Email & Letter",
            value: "Email & Letter",
          })
        }
      }
     
      this.setState({ input: optionalProps.item, documentType: this.state.documentType }, () => {
        this.getDocumentType();
      });
    }
  }

  getDocumentType = () => {
    let { user } = this.props;
    API.getDocumentType(user).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          this.setSelectData(data.data);
        }
      } else {
        this.setState((state) => ({
          ...state,
          hasAnyError: true,
          disable: false,
        }));
      }
    });
  };

  setSelectData = (data) => {
    if (data && data.length) {
      data.map((i, n) => {
        i.label = i.name;
        i.value = i.name;
      });
    }
    this.setState({ docTypeList: data });
  };

  checkDeliveryMethod = (selectedDeliveryMethod) => {
    let { input } = this.state;
    let flag = false;
    if (input.communication_methods.includes(selectedDeliveryMethod) && selectedDeliveryMethod !== "Email_and_Letter") {
      flag = true;
    } else if (selectedDeliveryMethod === "Email_and_Letter") {
      if(input.communication_methods.includes("Email") && input.communication_methods.includes("Letter")){
        flag = true;
      } else {
        flag = false;
      }
    } else {
      flag = false;
    }
    return flag;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let { input } = this.state;
    input.delivery_method =
      input.delivery_method === "Email Only"
        ? "Email"
        : input.delivery_method === "Letter Only"
        ? "Letter"
        : "Email_and_Letter";
    input.task_id = input.id;
    delete input["document_types"];

    // if(!this.checkDeliveryMethod(input.delivery_method)){
    //   let emailMSG = "No email template assigned, please contact support@makegoodapp.com to have a email template assigned to this communication task."
    //   let letterMSG = "No letter template assigned, please contact support@makegoodapp.com to have a letter template assigned to this communication task."
    //   this.props.updateModalState(true, "ERROR", {
    //     message: input.delivery_method == "Email" ? emailMSG : letterMSG,
    //   });
    //   return;
    // }

    if (input.reminder_schedule >= 0) {
      $("#edit-task").replaceWith('<div class="spinner"></div>');
      API.updateTaskSetting(this.props.optionalProps.user, input).then(
        (data) => {
          if (
            (typeof data != "undefined" || data != null) &&
            data.status_code &&
            data.status_code === 200
          ) {
            this.props.optionalProps.reload();
            this.handleModalClose();
          } else {
            this.setState((state) => ({
              ...state,
              error: {
                hasAnyError: true,
                statusCode: data
                  ? data.status ||
                    (data.message && data.message == "Access denied."
                      ? 401
                      : 500) ||
                    500
                  : 500,
              },
            }));
            if( data.status_code === 400) {
                let errorMSG = ''
                let emailMSG = "No email template assigned, please contact support@makegoodapp.com to have a email template assigned to this communication task."
                let letterMSG = "No letter template assigned, please contact support@makegoodapp.com to have a letter template assigned to this communication task."
                if (data.message.includes('Email')) {
                  errorMSG = emailMSG;
                } else if (data.message.includes('Letter')) {
                  errorMSG = letterMSG;
                }
                this.props.updateModalState(true, "ERROR", {
                  message: errorMSG,
                });
            }
          }
        }
      );
    }
  };

  toggleButton = (event, type) => {
    let target = event.target
    event.preventDefault();
    if (type === "S") {
      this.state.input.state = !this.state.input.state;
    } else if (type === "A") {
      this.state.input.auto_send = !this.state.input.auto_send;
    } else if (type === "AIC") {
      this.state.input.auto_send_on_import = target.checked;
    } else if (type === "I") {
      this.state.input.internal = !this.state.input.internal;
    }
    if (!this.state.input.state) {
      this.state.input.auto_send = false;
      this.state.inputauto_send_on_import = false;
      this.state.input.internal = false;
      this.state.input.is_all_client = true;
      this.state.input.ccPref = "All Clients";
      this.state.input.delivery_method = "Email & Letter";
    }
    this.setState({ input: this.state.input });
    if(target.type && target.type == 'checkbox') {
      setTimeout(() => {
        this.setState({ input: this.state.input });
      }, 100);
    }
  };

  openCotactPref = (checklist) => {
    if (checklist) {
      this.state.input.is_all_client = false;
      this.state.input.contact_preferences = checklist;
      this.state.input.contact_count = checklist.length;
    }
    this.setState({
      showPrefPopUp: !this.state.showPrefPopUp,
      input: this.state.input,
    });
  };

  onSelect = (selectedList) => {
    let { input } = this.state;
    input.document_type_ids = [];
    if (selectedList && selectedList.length) {
      selectedList.map((item) => {
        input.document_type_ids.push(item.id);
      });
    }
    this.setState({ input });
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  renderErrorMessage(input) {
    let message = "";

    if (input === "reminder_schedule") {
      message = "Please enter a valid days";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  handleModalClose = () => {
    this.props.hideModal();
  };

  render() {
    let {
      delivery_Categories,
      input,
      error,
      ccOptions,
      showPrefPopUp,
    } = this.state;
    let { user } = this.props;
    const style = {
      container: { display: "flex" },
      content: { width: "70%", paddingTop: `10px` },
    };

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (showPrefPopUp) {
      return (
        <ContactPreferences
          user={user}
          item={input}
          hideModal={this.openCotactPref}
          selected_contacts={input.selected_contacts}
          contact_preferences={
            input.contact_preferences ? input.contact_preferences : []
          }
        />
      );
    }

    return (
      <Modal
        title="Task Configurations"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        optionalClasses="scroll"
        titleClasses="modal-header"
      >
        <form>
          <div style={style.container}>
            <div style={{ width: `70%`, marginTop: "1rem" }}>
              Communication Tasks :{" "}
            </div>
            <div
              className={`input-container`}
              style={{ marginBottom: "1rem", marginTop: "1rem" }}
            >
              {input.name ? input.name : ""}
            </div>
          </div>
          <div style={style.container}>
            <div style={style.content}>Task State : </div>
            <div
              className={`input-container`}
              style={{ marginTop: "10px", marginBottom: "1rem" }}
            >
              <ToggleSwitch
                isOn={input.state}
                toggleButton={(event) => this.toggleButton(event, "S")}
              />
            </div>
          </div>
          <div style={style.container}>
            <div style={style.content}>Auto Send : </div>
            <div
              className={`input-container`}
              style={{ marginTop: "10px", marginBottom: "1rem" }}
            >
              <ToggleSwitch
                isOn={input.auto_send}
                toggleButton={(event) => this.toggleButton(event, "A")}
              />
              {input.name == 'Send Invoice To Client' && <div style={{...style.container, alignItems: 'center', marginTop: 10}}>
            <input
              name="auto-send"
              type="checkbox"
              style={{
                WebkitAppearance: `checkbox`,
                width: `16px`,
                marginRight: `10px`,
              }}
              checked={input.auto_send_on_import}
              onChange={(event) => {this.toggleButton(event, "AIC")}}
            /><div style={{fontSize: 14}}>Send Upon Import / Create</div>
            <img
              src={InfoIcon}
              style={{height: 22, width: 22, marginLeft: 10}}
              title="When checked, Invoices will automatically send to customer contacts upon importing invoices or creating new invoices. Does not apply to edited invoices."
            />
          </div>}
            </div>
          </div>
          <div style={style.container}>
            <div style={style.content}>Internal : </div>
            <div
              className={`input-container`}
              style={{ marginTop: "10px", marginBottom: "1rem" }}
            >
              <ToggleSwitch
                isOn={input.internal}
                toggleButton={(event) => this.toggleButton(event, "I")}
              />
            </div>
          </div>
          <div style={style.container}>
            <div style={style.content}>Delivery Schedule : </div>
            <div
              className={`input-container${
                input.reminder_schedule === "" ? " error" : ""
              }${input.reminder_schedule > 0 ? " amount-input-value" : ""}`}
            >
              <span className="delivery-schedule">
                {input.reminder_schedule > 0
                  ? input.send_login === "before"
                    ? "-"
                    : input.send_login === "after"
                    ? "+"
                    : ""
                  : ""}
              </span>
              <MaskedTextInput
                guide={true}
                mask={[/\d/, /\d/, /\d/]}
                readOnly={
                  input.state
                    ? input.auto_send_on_import
                      ? true
                      : false
                    : false
                }
                name="reminder_schedule"
                placeholder="0"
                placeholderChar={"\u2000"}
                type="text"
                value={input.reminder_schedule}
                onChange={(event) =>
                  this.handleInputChange({
                    reminder_schedule: event.target.value,
                  })
                }
              />
              {input.reminder_schedule === ""
                ? this.renderErrorMessage("reminder_schedule")
                : null}
            </div>
          </div>
          <div style={style.container}>
            <div style={style.content}>Delivery Method : </div>
            <div className={`input-container`}>
              <Select
                inputProps={{
                  autoComplete: "none",
                  autoCorrect: "off",
                  spellCheck: "off",
                }}
                resetValue=""
                className="form-select"
                name="Delivery Method"
                value={input.delivery_method}
                placeholder="Email & Letter"
                options={input.state ? delivery_Categories : []}
                onChange={(event) =>
                  this.handleInputChange({
                    delivery_method: event ? event.value : "",
                  })
                }
              />
            </div>
          </div>
          <div style={style.container}>
            <div style={style.content}>Document Type : </div>
            <div className={`input-container`}>
              <Multiselect
                options={this.state.docTypeList}
                displayValue="name"
                placeholder="Select attach documents"
                onSelect={this.onSelect}
                onRemove={this.onSelect}
                selectedValues={this.state.documentType}
              />
            </div>
          </div>
          <div style={style.container}>
            <div style={style.content}>Client Contact Preferences : </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                className={`input-container select-view`}
                style={{ marginBottom: "1rem" }}
              >
                <Select
                  inputProps={{
                    autoComplete: "none",
                    autoCorrect: "off",
                    spellCheck: "off",
                  }}
                  resetValue=""
                  className="form-select"
                  name="Client Contact Preferences"
                  value={input.ccPref}
                  placeholder="All Clients"
                  options={input.state ? ccOptions : []}
                  onChange={(event) =>
                    this.handleInputChange({
                      is_all_client: event && event.flag ? event.flag : false,
                      ccPref: event ? event.value : "",
                    })
                  }
                />
                <button
                  disabled={input.is_all_client ? true : false}
                  className={`select-inner-btn mg-brand2-color${
                    input.is_all_client ? "" : " active"
                  }`}
                  onClick={() => this.openCotactPref()}
                >
                  Select
                </button>
              </div>
              {input.is_all_client ? (
                <div style={{ marginBottom: "1rem" }} />
              ) : (
                <div className={`input-container contact-count`}>
                  {input.contact_count && input.contact_count == 1
                    ? "Selected Contact: "
                    : "Selected Contacts: "}
                  {input.contact_count ? input.contact_count : "0"}
                </div>
              )}
            </div>
          </div>
          <div className="modal-actions" id="edit-task">
            <a
              className="cta cta mg-brand2-color"
              style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
              onClick={(event) => this.handleSubmit(event)}
            >
              Submit
            </a>
          </div>
        </form>
      </Modal>
    );
  }
}

export default withRouter(EditTaskConfig);
